<div class="Lightbox TextAreaWithConfirmationPopup" [ngClass]="{ '__is-open': true }">
  <div class="Popup_ComunicacaoGenerica">
        
    <div class="Popup_Content">
      
      <div class="modalBody">
        <!-- <form [formGroup]="formProp" *ngIf="abreFormProp === true" #formConteudo> -->
        <form [formGroup]="formProp" #formConteudo>
          <div class="header-form">
            <img [src]="detalhes.imagem" alt="imagem do seguro" />
            <div class="logo-content-area" [ngClass]="{'__border-bottom': detalhes.title == 'Conteúdo'}" >
              <div class="logo-area">
                <img [src]="getImgSeguros(detalhes.icon)" alt="imagem do seguro" />
                <p>{{ detalhes.title }}</p>
              </div>
              <p>{{ detalhes.texto }}</p>
            </div>
          </div>
          <!-- <p class="header-form"> Selecione para onde deseja contratar a cobertura do seguro conteúdo:</p> -->
          <div class="FormConteudo" *ngIf="detalhes.title == 'Conteúdo'" >
            <div class="FormGroup">

              <p class="descricao-select">Selecione para onde deseja contratar a cobertura do seguro conteúdo:</p>

              <ds-option-button
                [opcoes]="condominioUnidade"
                [reset]="0"
                [label]="''"
                (selecionaItem)="selecionarItem($event)"
                [reset]="1"
                width="100%"
              ></ds-option-button>
            </div>
          </div>
          <div class="FormGroup">
            <div class="buttonArea">
              
              <ds-button
                [leftIcon]="detalhes.title === 'Conteúdo' ? 'fas fa-chevron-left' : ''"
                type="Outline"
                (click)="abrirFormConteudo()"
                [full]="true"
                [fullOnMobile]="true"
              >
              {{detalhes.title === 'Conteúdo' ? 'Escolher outro seguro' : 'Cancelar'}}
              </ds-button>

              <ds-button
                (click)="detalhes.title === 'Conteúdo' ? enviarPropriedade() : openExternalLink()"
                [inactive]="detalhes.title === 'Conteúdo' ? formProp.invalid : false"
                [full]="true"
                [fullOnMobile]="true"
              >
              Avançar
              </ds-button>
    
            </div>
          </div>
        </form>
      </div>

      <div class="buttonArea">
        
      </div>
    </div>
    
  </div>
</div>