import { Component,  OnInit, ViewEncapsulation, Input, ElementRef, OnDestroy } from "@angular/core";
import { LightBoxesService } from "../lightboxes.services";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contratacao-de-cliente',
  templateUrl: './contratacao-de-cliente.component.html',
  styleUrls: ['./contratacao-de-cliente.component.scss'],
})
export class ContratacaoDeClienteComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;

  public form: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public secondClick: boolean = false;

  private element: any;
  private promisse: Promise <boolean>;
  private resolve;

  constructor(private service: LightBoxesService, private el: ElementRef, public fb: UntypedFormBuilder) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      return;
    }

    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.close();
      }
    });

    this.service.add(this);
    this.createForm();
  }

  ngOnDestroy(): void {
    this.element.remove();
  }

  createForm() {
    this.form = this.fb.group({
      mxmId: ['', Validators.required],
    });

    this.form.get('mxmId').valueChanges.subscribe(val => {
      if(this.secondClick) {
        this.secondClick = false;
      }
    });
  }

  open(mensagem: { title: string, nome: string, cpf: string  }, time: number = 1000): Promise <boolean> {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('Popup_Header_Title')[0].innerHTML = mensagem.title;
    this.element.getElementsByClassName('Popup_Content_MsgDefault')[0].innerHTML = `Nome: ${mensagem.nome}`;
    this.element.getElementsByClassName('Popup_ContratacaoCliente_Cpf')[0].innerHTML = `CPF: ${mensagem.cpf}`;
    
    if (time === 0) return new Promise(resolve => {
      this.resolve = resolve;
    });

    return new Promise(resolve => {
      setTimeout(function () {
        a.firstChild.classList.remove(n);
        resolve(false);
      }, time);
    });
  }

  primeiroClick() {
    this.form.valid ? this.secondClick = true : this.isSubmitted = true;
  }

  confirmar(): void {
    if(!this.form.invalid) {
      this.resolve(this.form.value.mxmId);
      this.resetState();
      this.element.firstChild.classList.remove(this.className);
    }
    else {
      this.isSubmitted = true;
    }
  }

  close(): void {
    this.resetState();
    this.resolve(false);
    this.element.firstChild.classList.remove(this.className);
  }

  resetState() {
    window.setTimeout(() => {
      this.secondClick = false;
      this.isSubmitted = false;
      this.form.reset();
    }, 200);
  }

  setClassError(input: string) {
    return {
      '__is-error': this.isSubmitted && this.form.get(input).invalid
    };
  }
}