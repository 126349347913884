import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-selecao',
  templateUrl: './modal-selecao.component.html',
  styleUrls: ['./modal-selecao.component.scss'],
})
export class ModalSelecaoComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() itensLista = []
  @Output() onSelectedList = new EventEmitter()
  
  constructor(private componentRef: ElementRef) {
    // document.body.appendChild(componentRef.nativeElement)
   }

  ngOnInit() {}

  ngAfterViewInit(): void {
    document.body.appendChild(this.componentRef.nativeElement)

  }

  ngOnDestroy() {
    document.body.removeChild(this.componentRef.nativeElement);
  }

  onSelect(indice:any){
    this.itensLista.forEach(item => {
      item.check = false;
    })

    this.itensLista[indice].check = true

    this.onSelectedList.emit(this.itensLista)
  }
}
