import { Injectable } from '@angular/core';
import { CondominioService } from '../services/condominio.service';
import { ConstantsService } from '../services/constants.service';
import { CondominioDTO } from '../services/DTO/response/Condominio.DTO';
import { FuncionalidadeDTO } from '../services/DTO/response/Funcionalidade.DTO';
import { PerfilDTO } from '../services/DTO/response/Perfil.DTO';

@Injectable()
export class CondominiumFacade {

  constructor(
    private condominioService: CondominioService,
    public constant: ConstantsService,
  ) { }
  
  select(condSelecionado: CondominioDTO, perfis: Array<PerfilDTO>, funcionalidades: Array<FuncionalidadeDTO>) {
    if (condSelecionado == undefined) return;
    
    return this.condominioService.logarCondominio(condSelecionado, perfis, funcionalidades).then(() => {
      this.constant.condominioImovelAtivo = this.getCodigoCondominio(condSelecionado);
      CondominioService.trocaCondominio.next(condSelecionado);
      this.condominioService.verificaAdesaoCondominio();
    });
  }

  private getCodigoCondominio(condominio: CondominioDTO) {
    return "CO-" + condominio.contaEmpresa;
  }
}