import { PessoaResultDTO } from './PessoaResultDTO';

export class TokenResultDTO {
    pessoa: PessoaResultDTO;
    pessoaFoto: PessoaFoto;
    condominiosImoveis: any;
    tokenApiServicos: string;
    token: { token: string }
    exibirBannerCovidFunc: string;
    exibirBannerAutenticacao: string;
    notificacaoVersaoAPP: NotificacaoVersaoAPP;
    optins: Optins; 
    funcionalidadeRedeParcerias: boolean;
}

export class NotificacaoVersaoAPP {
    exibirAlerta: boolean;
    mensagem: string;
    numeroVersao: string;   
    rota: string;
}

export class PessoaFoto {
  arquivo: string;
  nome: string;
  tipo: string;
}

export class Optins {
    exibirModalAdesaoBoletoDigital: boolean;
    exibirModalAssinaturaDigital: boolean;
    documentosAssinaturaDigital: boolean;
    optinIptu: OptinIPTU;
}

export class OptinIPTU {
    anoVigencia: string;
    exibirModalIptu: boolean;
    imoveisIptu: Array<ImoveisIptu>;
}

export class ImoveisIptu {
    dscImovel: string;
    numContrato: string;
}
