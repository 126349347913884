import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ds-empty-result',
  templateUrl: './empty-result.component.html',
  styleUrls: ['./empty-result.component.scss'],
})
export class EmptyResultComponent implements OnInit {
  @Output() onClear = new EventEmitter();

  public isFading: boolean = false;

  constructor() { }

  ngOnInit() {}

  handleButtonClick() {
    this.onClear.emit();
  }
}
