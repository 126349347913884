import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ds-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() color: string = "Primary";
  @Input() type: string = "Solid";
  @Input() full: boolean = false;
  @Input() inactive: boolean = false;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input('p-accept') accept: string;
  @Output('p-change') change: EventEmitter<Event> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {}

  handleClick() {
    const fileInputElement = this.fileInput.nativeElement as HTMLElement;

    // Por alguma razãa, não funcionou atribuir o atributo accept no html,
    // então estou fazendo a atribuição aqui, com javascript.
    if(this.accept)
      fileInputElement.setAttribute('accept', this.accept);
      
    fileInputElement.click();
  }

  handleChange(event: Event) {
    this.change.emit(event);
  }
}