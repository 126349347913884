<ng-container *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(863)">
  <div class="saldo-em-contas-desktop-component" *ngIf="saldoEmContas && saldoEmContas.length > 0">
    <h1>Saldo em contas</h1>
  
    <ng-container *ngFor="let saldoEmConta of saldoEmContas">
      <ng-container *ngFor="let saldoContas of saldoEmConta.listaSaldoContas">
        <div class="Block">
          <div class="Block_Container">
            <div class="Block_Title">
              {{ saldoContas.descricaoConta }}
            </div>
  
            <div class="table">
              <div class="table__labels">
                <div class="table__labels__item">
                  Descrição
                </div>
      
                <div class="table__labels__item __is-right ">
                  Valor (R$)
                </div>
              </div>
  
              <ng-container *ngFor="let lancamento of saldoContas.listaLancamentos">
                <div class="table__line">
                  <div class="table__line__value">{{ lancamento.descricao_Lancamento }}</div>
                  <div class="table__line__value __is-right bold" [ngClass]="{'__is-danger': lancamento.valor_Lancamento[0] === '-'}">
                    {{ lancamento.valor_Lancamento }}
                  </div>
                </div>
              </ng-container>
  
              <div class="table__line">
                <div class="table__line__value __is-primary __is-big bold">TOTAL</div>
                <div class="table__line__value __is-right __is-big bold" [ngClass]="{'__is-danger': saldoContas.subTotal[0] === '-'}">
                  {{ saldoContas.subTotal }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  
    <div class="flex-end">
      <ds-button *ngIf="possuiPermissaoAcesso(218)" routerLink="/extrato/sindico">
        Veja mais
      </ds-button>
    </div>
  </div>
</ng-container>