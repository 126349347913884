<div class="Block_Popup_Lateral ">
  <div class="Popup_Lateral_Icon">
      <i class="fas fa-exclamation-circle"></i>
  </div>
  <div class="Popup_Lateral_Text">
     <!-- <ng-content></ng-content> -->
    <div class="Popup_Lateral_Title">
      Poxa vida!
    </div>
    <div class="Popup_Lateral_Subtitle">
        Parece que algo deu errado.
    </div>
  </div>
</div>
