import { Component } from '@angular/core';
// import { Base64 } from '@ionic-native/base64/ngx';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Platform } from '@ionic/angular';
import { ProfilePictureFacade } from '../../facades/profile-picture.facade';
import { Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';

@Component({
  selector: 'popup-change-user-image',
  templateUrl: './popup-change-user-image.component.html',
  styleUrls: ['./popup-change-user-image.component.scss'],
})
export class PopupChangeUserImageComponent {

  public isOpen = false;

  constructor(
    // private camera: Camera,
    // private base64: Base64,
    private profilePictureFacade: ProfilePictureFacade,
    private platform: Platform,
  ) { }

  show() {
    this.isOpen = true;
  }

  hide() {
    this.isOpen = false;
  }

  async takePhoto() {
    await this.openCamera(1);
    this.hide();
  }

  async uploadPhotoFromDeviceGalery() {
    await this.openCamera(2);
    this.hide();
  }

  private async openCamera(type: number) {
    const options: ImageOptions = {
      quality: 50,
      resultType: this.platform.is('android') && type == 1 ? CameraResultType.Base64 : CameraResultType.DataUrl,
      source: type === 1 ? CameraSource.Camera : CameraSource.Photos,
      direction: CameraDirection.Rear,
      allowEditing: false,
      correctOrientation: true,
    }

    Camera.getPhoto(options).then(photo => {
      if(photo) {
        if (this.platform.is('ios') || (this.platform.is('android') && type == 2)) {
          this.profilePictureFacade.change('gallery.jpeg', photo.dataUrl);
        }

        if (((this.platform.is('android') || !this.platform.is('ios')) && type == 1)) {
          this.profilePictureFacade.change('camera.jpeg', photo.base64String.toString().split(',')[1]);
        }
      }
    })
  }
}
