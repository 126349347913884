import { FormArray, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { StateService } from 'src/app/shared/services/stateService';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { AdesaoIptuService } from 'src/app/modules/adesao-iptu/services/adesao-iptu.service';
import { LightBoxesService } from '../lightboxes';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-adesao-iptu',
  templateUrl: './modal-adesao-iptu.component.html',
  styleUrls: ['./modal-adesao-iptu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalAdesaoIptuComponent implements OnInit {
  public values = [];
  public iptu: any;
  public cadastrado: any;
  public jaCadastrado: boolean;
  public anoVigenciaIPTU!:string;
  public imoveisCadastrados: any[];
  public loading:boolean = true

  @Input() isOpenModalIPTU: boolean = false;
  @Output() closeModalIPTU = new EventEmitter()
  
  constructor(
    public stateService: StateService,
    private fb: UntypedFormBuilder,
    private iptuService: AdesaoIptuService,
    private lightboxesService: LightBoxesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.params.subscribe(_ => {
      this.ngOnInit();
    })
  }

  ngOnInit() {
    this.fetchData();
  }

  async fetchData() {
    const storedOptins = JSON.parse(sessionStorage.getItem('optins'));
    this.anoVigenciaIPTU = JSON.parse(sessionStorage.getItem('anoVigenciaIPTU'))
    this.cadastrado = await this.getCadastroPrevio()
  
    if(storedOptins && storedOptins.optinIptu) {
      this.jaCadastrado = this.cadastrado !== null;
      
      this.iptu = JSON.parse(sessionStorage.getItem('optins')).optinIptu;
      this.values = this.iptu.imoveisIptu.map(imovel => ({
        numContrato: imovel.numContrato,
        formaPagamento: this.jaCadastrado ? this.cadastrado.find(c => c.contratoLocacao === imovel.numContrato).formaPagamento : 1,
      }));
    }

    this.montarImoveis(this.iptu.imoveisIptu, this.values)
  }

  montarImoveis( iptu, values ){
    this.imoveisCadastrados = iptu;
    for (let index = 0; index < iptu.length; index++) {
      
      for (let i = 0; i < values.length; i++) {
        if(values[i].numContrato === iptu[index].numContrato){
          this.imoveisCadastrados[index] = {
            ...iptu[index],
            formaPagamento: values[i].formaPagamento
          }
        }      
      }
      
    }
  }

  setStatus(event, pos: number) {
    this.values[pos].formaPagamento = event.detail.value;
  }

  closeModal() {
    this.closeModalIPTU.emit();
  }

  get nome(): string {
    let consulta = this.stateService.getPessoaLogada();
    if (consulta)
      return consulta.Nome;
  }

  submit() {
    const payload = this.buildPayload();

    this.iptuService.cadastrarFormaPagamento(payload).subscribe(
      res => {
        this.lightboxesService.openOkPopup('Pronto!', `Sua opção pela forma de pagamento do IPTU foi enviada com sucesso. Você poderá ver a opção escolhida na página "IPTU ${this.anoVigenciaIPTU}" no menu do usuário.`);
      },
      err => {
        this.lightboxesService.openOkPopup('Atenção!', err.error.message);
      }
    )

    this.closeModalIPTU.emit();
  }

  private buildPayload() {
    return {
      cpfCnpj: this.stateService.getPessoaLogada().CpfCnpj,
      listaImoveisPagamentoIptu: this.values,
    }
  }

  
  private getCadastroPrevio(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.iptuService.getFormaDePagamentoIptu().subscribe(
        res => {
          resolve(res);
        },
        err => {
          this.lightboxesService.openOkPopup('Atenção', 'Não foi possível obter informações do cadastro de IPTU. Tente novamente mais tarde.');
          this.routeToHome();
          resolve(null);
        }
      );
    })
  }

  routeToHome() {
    this.router.navigate(['/home']);
    sessionStorage.removeItem('exibirModalIPTU');
  }
}
