<div class="Atalho_Popup_Content UserMenuItems">
  <div class="ListActions">
    <!-- <div class="ListActions_Item">
      <a  routerLink="/usuario/edicao-dados" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <span class="apsa-icon-duotoneblack-dados-acesso">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </div>
        <div class="ListActions_Item_Label">Dados de acesso</div>
      </a>
    </div> -->

    <div
      class="ListActions_Item"
      *ngIf="platformIsDesktop()"
    >
      <a (click)="handleTrocarFotoDoPerfilClick()">
        <div class="ListActions_Item_Ico">
          <i class="far fa-image"></i>
        </div>

        <div class="ListActions_Item_Label">Trocar foto do perfil</div>

        <input
          type="file"
          style="display: none;"
          accept=".jpg,.png,.jpeg"
          (change)="handleTrocarFotoDoPerfilFileChange($event)"
          #fileInput
        >
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="platformIsDesktop() && profilePictureFacade.exists()"
    >
      <a (click)="profilePictureFacade.remove()">
        <div class="ListActions_Item_Ico">
          <i class="far fa-trash-alt"></i>
        </div>

        <div class="ListActions_Item_Label">Apagar foto do perfil</div>
      </a>
    </div>

    <div
      *ngIf="this.stateService.exibirInfoEmailNaoConfirmado()"
      class="ListActions_Item"
    >
      <a (click)="enviarEmailConfirmacao()">
        <div class="ListActions_Item_Ico EmailAdviceIcon">
          <i class="fas fa-exclamation"></i>
        </div>

        <div class="ListActions_Item_Label">Seu email não foi confirmado!</div>
      </a>
    </div>

    <!-- LISTA COMEÇA AQUI -->
    <ng-container *ngIf="!this.stateService.possuiPerfilGerencial() && this.stateService.possuiAcessoFuncionalidadeGeral(850)">
      <div class="ListActions_Item">
       <a (click)="redirectAdesaoBoletoDigital()">
         <div class="ListActions_Item_Ico">
           <i class="fas fa-barcode"></i>
         </div>
         
         <div class="ListActions_Item_Label">Adesão de boleto digital</div>
       </a>
     </div>
   </ng-container> 

   <!-- <div *ngIf="this.exibeFuncaoAvalieApp()" class="ListActions_Item __is-star">
      <a (click)="avaliacaoApp()">
        <div class="ListActions_Item_Ico">
          <i class="far fa-star"></i>
        </div>
        <div class="ListActions_Item_Label">
          Avalie o APP
        </div>
      </a>
    </div> -->

  <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(828)" class="ListActions_Item">
    <a (click)="redirect()">
      <div class="ListActions_Item_Ico">
        <i class="apsa-icon-outline-beneficios-pra-voce"></i>
      </div>

      <div class="ListActions_Item_Label">Comodidades para você</div>
    </a>
  </div>

    <div *ngIf="this.permitirEditarDadosUsuario()" class="ListActions_Item">
      <a (click)="redirectEditUserData()">
        <div class="ListActions_Item_Ico">
          <span class="apsa-icon-duotoneblack-dados-cadastrais">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
            <span class="path5"></span>
            <span class="path6"></span>
            <span class="path7"></span>
            <span class="path8"></span>
            <span class="path9"></span>
            <span class="path10"></span>
            <span class="path11"></span>
          </span>
        </div>
        <div class="ListActions_Item_Label">Dados Cadastrais</div>
      </a>
    </div>

    <ng-container *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(872)">
      <div class="ListActions_Item">
       <a (click)="routeToRecorrenciaCartaoCredito()">
         <div class="ListActions_Item_Ico">
          <i class="fas fa-credit-card"></i>
         </div>
         
         <div class="ListActions_Item_Label">{{ descricaoRecorrenciaCartaoDeCreditoCondominio || 'Pagamento automático em cartão de crédito' }}</div>
       </a>
     </div>
   </ng-container>

    <div class="ListActions_Item" 
      *ngIf="this.exibirCartaoDeCreditoLocatario && 
        (
          stateService.getPessoaLogada().CpfCnpj === '13168672777' || 
          stateService.getPessoaLogada().CpfCnpj === '11403787085' ||
          stateService.getPessoaLogada().CpfCnpj === '11443524794' ||
          stateService.getPessoaLogada().CpfCnpj === '00229828760'
        )"
    >
      <a (click)="routeToRecorrenciaCartaoCreditoLocatario()">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-credit-card"></i>
        </div>
        
        <div class="ListActions_Item_Label">{{ descricaoRecorrenciaCartaoDeCreditoLocacao || 'Pagamento automático em cartão de crédito' }}</div>
      </a>
    </div>
    
    <div class="ListActions_Item ListActions_Item_FaleComASeguradora __is-new">
      <a routerLink="/seguros" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="far fa-building"></i>

          <div class="overlayed-icon">
            <i class="fas fa-heart"></i>
          </div>

          <div class="tag-new"></div>

        </div>
        <div class="ListActions_Item_Label">Contrate seu seguro</div>
      </a>
    </div>

    <div class="ListActions_Item ListActions_Item_FaleComAApsa">
      <a routerLink="/fale-com-a-apsa/contatos" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-phone fa-2x"></i>
        </div>
        <div class="ListActions_Item_Label">Contatos APSA</div>
      </a>
    </div>

    <div class="ListActions_Item ListActions_Item_FaleComAApsa" *ngIf="constant.condominioImovelAtivo">
      <a routerLink="/fale-com-a-apsa" routerLinkActive="active">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-outline apsa-icon-outline-mail __is-smaller-icon"></i>
        </div>
        <div class="ListActions_Item_Label">Fale com a apsa</div>
      </a>
    </div>

    <div
      class="ListActions_Item"
      *ngIf="this.exibirOpcaoIPTU()"
    >
      <!-- <a (click)="redirectAdesaoIPTU()"> -->
      <a routerLink="/adesao-iptu">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-home"></i>
        </div>
        
        <div class="ListActions_Item_Label">IPTU {{ anoVigenciaIPTU }}</div>
      </a>
    </div>

    <div class="ListActions_Item" *ngIf="showFingerprintItem && !(isFingerprintEnabled$ | async)">
      <label (click)="enableFingerprintLoginFeature()">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-fingerprint"></i>
        </div>
        <div class="ListActions_Item_Label">
          <b>Habilitar</b> autenticação de digital
        </div>
      </label>
    </div>

    <div class="ListActions_Item" *ngIf="showFingerprintItem && (isFingerprintEnabled$ | async)">
      <label (click)="disableFingerprintLoginFeature()">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-fingerprint"></i>
        </div>
        <div class="ListActions_Item_Label">
          <b>Desabilitar</b> autenticação de digital
        </div>
      </label>
    </div>

    <div class="ListActions_Item" *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)">
      <a routerLink="/minhas-solicitacoes">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-tasks"></i>
        </div>

        <div class="ListActions_Item_Label">Minhas solicitações</div>
      </a>
    </div>

    <ng-container *ngIf="this.platform.is('mobile')">
      <div class="ListActions_Item">
        <a routerLink="/perguntas-respostas">
          <div class="ListActions_Item_Ico">
            <span>
              <i class="far fa-question-circle" style="font-size: 24px;"></i>
            </span>
          </div>
  
          <div class="ListActions_Item_Label">Perguntas e Respostas</div>
        </a>
      </div>
    </ng-container>

    
    <ng-container  *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(132)">
      <div class="ListActions_Item">
        <a routerLink="/informe-rendimento">
          <div class="ListActions_Item_Ico">
            <span>
              <i class="fas fa-file-invoice" style="font-size: 24px;"></i>
            </span>
          </div>
  
          <div class="ListActions_Item_Label">Informe de rendimentos dos seus imóveis</div>
        </a>
      </div>
    </ng-container>

    <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(117)" class="ListActions_Item">
      <a (click)="handleFornecedoresOptionClick()">
        <div class="ListActions_Item_Ico">
          <span>
            <i class="fas fa-toolbox" style="font-size: 24px;"></i>
          </span>
        </div>

        <div class="ListActions_Item_Label">Fornecedores</div>
      </a>
    </div>

    <div class="ListActions_Item" *ngIf="this.platform.is('mobile')">
      <a routerLink="/acessibilidade">
        <div class="ListActions_Item_Ico">
          <i class="Item_Icon_Fake">Aa</i>
        </div>

        <div class="ListActions_Item_Label">Acessibilidade</div>
      </a>
    </div>

    <div class="ListActions_Item ListActions_Item_Whatsapp">
    <!-- <div *ngIf="this.condominioValidado" class="ListActions_Item ListActions_Item_Whatsapp"> -->
      <a (click)="avaliacaoApp()">
        <div class="ListActions_Item_Ico">
          <i class="fas fa-star"></i>
        </div>
        <div class="ListActions_Item_Label"> {{stateService.platformIsDeviceMobile() ? "Avalie nosso App" : "Avalie nosso site"}}</div>
      </a>
    </div>

    <div class="ListActions_Item">
      <a routerLink="/sobre">
        <div class="ListActions_Item_Ico">
          <span class="apsa-icon-duotoneblack-sobre2-duotone">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
            <span class="path5"></span>
          </span>
        </div>

        <div class="ListActions_Item_Label">Sobre/Termos e políticas</div>
      </a>
    </div>

    <div class="ListActions_Item ListActions_Item_Logout">
      <label (click)="sair()">
        <div class="ListActions_Item_Ico">
          <i class="apsa-icon-outline-logout"></i>
        </div>
        <div class="ListActions_Item_Label">Sair</div>
      </label>
    </div>
  </div>
</div>