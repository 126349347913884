<section class="swiperSeguros">
  <div class="headerArea">
    <div class="imageArea">
      <div class="Header_Title_Icone position-relative">
        <i class="far fa-building"></i>
          
        <div class="overlayed-icon">
          <i class="fas fa-heart"></i>
        </div>
      </div>
    </div>
    <div class="title">Contrate seu seguro</div>
  </div>

  <!-- <swiper-container [slidesPerView]="3" [loop]="false">
    <ng-container *ngFor="let seguro of tipoSeguros">
      <swiper-slide *ngIf="seguro.ativo" >
        <app-quick-card
            title={{seguro.title}}
            link={{seguro.link}}
            icon={{seguro.icon}}
            [compact]="true"
            [type]="2"
            [soonTag]=seguro.breve
          ></app-quick-card>
      </swiper-slide>
    </ng-container>
  </swiper-container> -->
  <div class="swipper">

    <div class="swiper-wrapper">
      <ng-container *ngFor="let seguro of tipoSeguros">
        <div class="swiper-slide" *ngIf="seguro.ativo" >
          <app-quick-card
            title={{seguro.title}}
            link={{seguro.link}}
            icon={{seguro.icon}}
            [compact]="true"
            [type]="2"
            [soonTag]=seguro.breve
            [detalhes]="seguro"
            (emitDetalhesSeguro)="setDetalhesEAbreModal($event)"
          ></app-quick-card>
        </div>
      </ng-container>
    </div>
    <div class="swiper-pagination swipper-pagination"></div>
  </div>

</section>

<modal-seleao-condominio *ngIf="abrirModalSeguros"
[detalhes]="tipoSelecionado"
[formProp]="formProp"
[condominioUnidade]="unidades"
(abriModal)="abrirModalSeguros = false"
(enviar)="enviarPropriedade()"
></modal-seleao-condominio>
