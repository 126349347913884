import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { LoadingScreenService } from "./loading-screen.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
    //encapsulation: ViewEncapsulation.None, 
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
    loading: any = false;
    loadingSubscription: Subscription;
    message = this.loadingScreenService.getMessage();

    constructor(
        private loadingScreenService: LoadingScreenService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
        this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(
            debounceTime(200)
        ).subscribe((value) => {
            this.loading = value;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
    }
}
