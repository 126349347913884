<div class="AcessibilidadeComponent">
  <div class="FormGroup FormGroup_TamanhoFonte">
    <div class="FormGroup_Label">Tamanho da fonte</div>
    <div class="FormGroup_Input">
      <ion-range (ionChange)="selecionarFonte($event);" class="Range" min="1" max="3" step="1" snaps="true" ticks="true" pin="true">
        <div class="Range_Icon" slot="start">a</div>
        <div class="Range_Icon" slot="end">A</div>
      </ion-range>
    </div>
  </div>
  <div class="FormGroup FormGroup_PlanoFundo">
    <div class="FormGroup_Label">Plano de fundo</div>
    <div class="FormGroup_Input">
      <div class="ListSelect">
        <div class="ListSelect_Content __is-full-list">
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo1"
          [ngClass]="{'__is-active': (temaAcessibilidade === 1) }">
            <a (click)="selecionarTema(0)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Clássico</div>
            </a>
          </div>
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo2"
          [ngClass]="{'__is-active': (temaAcessibilidade === 2) }">
            <a (click)="selecionarTema(1)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Claro</div>
            </a>
          </div>
          <div class="ListSelect_Content_Item Content_Item_PlanoFundo3"
          [ngClass]="{'__is-active': (temaAcessibilidade === 3) }">
            <a (click)="selecionarTema(2)">
              <div class="Content_Item_Icon">
                <i class="fas fa-check"></i>
              </div>
              <div class="Content_Item_Label">Escuro</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>