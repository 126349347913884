import { NativeBiometric } from "@capgo/capacitor-native-biometric";
import { EBiometryPluginResult, BiometryPlugin, BiometryPluginOptions } from "./BiometryPlugin";
import { Injectable } from "@angular/core";

@Injectable()
export class CapacitorBiometryPluginImplService implements BiometryPlugin {
  async authenticate(options: BiometryPluginOptions): Promise<EBiometryPluginResult> {
    try {
      await NativeBiometric.verifyIdentity(options);
  
      return EBiometryPluginResult.SUCCESS;
    }
    catch(err) {
      return this.mapError(err);
    }
  }

  async isAvaiable(): Promise<boolean> {
    try {
      return (await NativeBiometric.isAvailable()).isAvailable;
    }
    catch(err) {
      return false;
    }
  }

  private mapError(error: Error): EBiometryPluginResult {
    // TODO
    return EBiometryPluginResult.ERROR;
  }
}