import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StateService } from 'src/app/shared/services/stateService';
import { environment } from 'src/environments/environment';
import { CepDTO } from './DTO/GetCep.DTO';
import { TipoDocumentoDTO } from './DTO/TipoDocumento.DTO';
import { IDetalheDoAgendamento } from './DTO/IDetalheDoAgendamento';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntregaExpressaService {

  private urlContratacao: string;
  private urlCondDigital: string;

  //private methodsContratacao;
  private methodsCondDigital;

  constructor(
    private http: HttpClient,
    public stateService: StateService
  ) {
    this.urlContratacao = environment.apiContratacao.host;
   // this.methodsContratacao = environment.apiContratacao.endpoints.methods.entregaExpressa;

    this.urlCondDigital = environment.apiCondominioDigital.host;
    this.methodsCondDigital = environment.apiCondominioDigital.endpoints.methods.entregaExpressa;
   }

  private getHeaderCondDigital(){
    return new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : this.stateService.getTokenApiCondominioDigital()
    })
  }

  // verificaCep(cep: string){
  //   const parametros = new HttpParams() 
  //     .set('cep', cep);

  //     return this.http.get<CepDTO>(`${this.urlContratacao + this.methodsContratacao.getCep}`,
  //       {headers:this.getHeaderContratacao(), params:parametros})
  // }

  getTiposDocumentos(){
    return this.http.get<Array<TipoDocumentoDTO>>(`${this.urlCondDigital + this.methodsCondDigital.getTipos}`,
      { headers:this.getHeaderCondDigital() })
  }

  getDatas(){
    return this.http.get<Array<string>>(`${this.urlCondDigital + this.methodsCondDigital.getDatas}`,
      { headers:this.getHeaderCondDigital() })
  }

  getProximosFeriados() {
    return this.http.get<Array<string>>(`${this.urlCondDigital + this.methodsCondDigital.getProximosFeriados}`,
      { headers: this.getHeaderCondDigital() })
  }

  agendamentoMalote(body){

    return this.http.post<string>(`${this.urlCondDigital + this.methodsCondDigital.agendamentoMalote}`, body,
      { headers: this.getHeaderCondDigital()})
  }

  getValidacaoCadastrar(){
    const condominio = this.stateService.getCondominioLogado();
    let parametros = new HttpParams()
      .set('contaEmpresa', condominio.contaEmpresa);

      return this.http.get<any>(`${this.urlCondDigital + this.methodsCondDigital.validarCadastro}`, 
      {headers:this.getHeaderCondDigital(), params:parametros})
  }

  verificaAdesaoCondominio(contaEmpresa, contaCondominio){
    let parametros = new HttpParams()
      .set('contaEmpresa', contaEmpresa)
      .set('contaCondominio', contaCondominio);

      return this.http.get<any>(`${this.urlCondDigital + this.methodsCondDigital.verificaAdesao}`, 
      {headers:this.getHeaderCondDigital(), params:parametros})
  }

  listMalote(paginaAtual, dataInicial?, dataFinal?, descricao?, idTipo?, idStatus?, contaEmpresaNomeCondominio?){
    let parametros = new HttpParams()
      .set('paginaAtual', paginaAtual)
      .set('tamanhoPagina', '20')
    
    // se o perfil for colab, a conta empresa não é enviada
    if(!this.stateService.possuiPerfilColaboradorEntregaExpressa()){
      const condominio = this.stateService.getCondominioLogado();
      parametros = parametros.append('contaEmpresa', condominio.contaEmpresa);
    }

    if(dataInicial)
      parametros = parametros.append('dataInicial', dataInicial);

    if(dataFinal)
      parametros = parametros.append('dataFinal', dataFinal);

    if(descricao)
      parametros = parametros.append('descricao', descricao);

    if(idTipo)
      parametros = parametros.append('idTipo', idTipo);
    
    if(contaEmpresaNomeCondominio) 
      parametros = parametros.append('contaEmpresaNomeCondominio', contaEmpresaNomeCondominio);

    parametros = parametros.append('idsStatus', idStatus ? idStatus : '1,2');
  
    return this.http.get<any>(`${this.urlCondDigital + this.methodsCondDigital.agendamentoMalote}`, 
      {headers:this.getHeaderCondDigital(), params:parametros})
      .pipe(map(response => {
        if(response && response.data && response.data.length > 0)
          response.data.forEach(agendamento => agendamento.selected = false);

        return response;
      }));
  }

  confirmarAgendamento(id: string) {
    const idUsuarioLogado = this.stateService.getPessoaLogada().Id;
    
    const options = { headers: this.getHeaderCondDigital() }
    const body = {
      uid: id,
      idUsuario: idUsuarioLogado,
    };
    
    const url = this.urlCondDigital + this.methodsCondDigital.confirmarAgendamento;

    return this.http.post<any>(url, body, options);
  }

  cancelarAgendamento(id: string, descricao: string) {
    const idUsuarioLogado = this.stateService.getPessoaLogada().Id;
    
    const options = { headers: this.getHeaderCondDigital() }
    const body = {
      uid: id,
      descricao: descricao,
      idUsuario: idUsuarioLogado,
    };
    
    const url = this.urlCondDigital + this.methodsCondDigital.cancelarAgendamento;

    return this.http.post<any>(url, body, options);
  }

  finalizarAgendamento(id: string) {
    const idUsuarioLogado = this.stateService.getPessoaLogada().Id;
    
    const options = { headers: this.getHeaderCondDigital() }
    const body = {
      uid: id,
      idUsuario: idUsuarioLogado,
    };
    
    const url = this.urlCondDigital + this.methodsCondDigital.finalizarAgendamento;

    return this.http.post<any>(url, body, options);
  }

  finalizarAgendamentos(ids: number[]) {
    const idUsuarioLogado = this.stateService.getPessoaLogada().Id;
    
    const options = { headers: this.getHeaderCondDigital() }
    const body = {
      listUids: ids,
      idUsuario: idUsuarioLogado,
    };
    
    const url = this.urlCondDigital + this.methodsCondDigital.finalizarAgendamentos;

    return this.http.post<any>(url, body, options);
  }

  finalizarAgendamentoComOcorrencia(id: string, descricao: string) {
    const idUsuarioLogado = this.stateService.getPessoaLogada().Id;
    
    const options = { headers: this.getHeaderCondDigital() }
    const body = {
      uid: id,
      descricao: descricao,
      idUsuario: idUsuarioLogado,
    };
    
    const url = this.urlCondDigital + this.methodsCondDigital.finalizarAgendamentoComOcorrencia;

    return this.http.post<any>(url, body, options);
  }

  getDetalhe(id: string): Observable<IDetalheDoAgendamento> {
    const url = this.urlCondDigital + this.methodsCondDigital.getDetalhe;

    const queryParams = new HttpParams()
      .set('uid', id);

    const options = {
      headers: this.getHeaderCondDigital(),
      params: queryParams,
    };

    return this.http.get<IDetalheDoAgendamento>(url, options);
  }
}
