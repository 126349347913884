import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { LightBoxesService } from "../lightboxes.services";

@Component({
  selector: 'app-ligthboxes-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultLightBoxesComponent implements OnInit {

  title: string = 'Pronto!';
  message: string = 'Seu recado já está no mural.';

  teste:string;
  @Input() id: string;
  @Input() className: string;
  private element: any;
  private timeoutID: any;
  private promiseResolveOnOkClickOrTimeout: (value?: any) => void;

  constructor(private service: LightBoxesService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const IDNotGiven = !this.id;

    if(IDNotGiven)
      return;

    this.moveThisElementToBottomOfDOM();
    this.closePopupOnBackgroundClick();
    this.addThisPopupToService();
  }

  ngOnDestroy(): void {
    // this.service.remove(this.id); // TODO: Retirado para não bugar
    this.element.remove();
    this.element.getElementsByClassName('Button_Content_Label fechar')[0].innerHTML = 'Ok'
  }

  open(mensagem: { title: string, text: string }, time: number = 1000, fechar:boolean = false): Promise<boolean> {
    this.element.firstChild.classList.add(this.className);

    if(mensagem.text) {
      this.element.getElementsByClassName('Popup_Header_Title')[0].innerHTML = mensagem.title;
      this.element.getElementsByTagName("p")[0].innerHTML = mensagem.text;
    }

    if(fechar){
      this.element.getElementsByClassName('Button_Content_Label fechar')[0].innerHTML = 'Fechar';
    }


    return new Promise(resolve => {
      this.promiseResolveOnOkClickOrTimeout = resolve;

      this.timeoutID = setTimeout(() => {
        this.element.firstChild.classList.remove(this.className);
        this.promiseResolveOnOkClickOrTimeout();
      }, time);
    });
  }

  close(): void {
    this.element.firstChild.classList.remove(this.className);
    this.tryToClearPopupTimeout();
    this.promiseResolveOnOkClickOrTimeout();
  }

  moveThisElementToBottomOfDOM() {
    document.body.appendChild(this.element);
  }

  closePopupOnBackgroundClick() {
    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.close();
      }
    });
  }
  
  addThisPopupToService() {
    this.service.add(this);
  }

  private tryToClearPopupTimeout() {
    if(this.timeoutID) {
      clearTimeout(this.timeoutID);
      this.timeoutID = null;
    }
  }
}
