import { TermoAssinaturaDigitalDTO } from './DTO/Request/TermoAssinaturaDigital.DTO';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { StateService } from './stateService';

@Injectable({
  providedIn: 'root'
})
export class AssinaturaDigitalService {
  
  private urlApiHost = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods;

  constructor(private http: HttpClient, public stateService: StateService) {}

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  cadastrarTermoAssinaturaDigital(data: TermoAssinaturaDigitalDTO) {    
    return this.http.post<any>(`${this.urlApiHost + this.methods.optinsUsuario.cadastrarAssinaturaDigital}`, data,
       { headers: this.getHeader() })
    }

}
