<ng-container *ngIf="tipoExibicao === 'gestor'" >
  <a
    class="cotas-com-vencimento-no-mes"
    *ngIf="(stateService.possuiAcessoFuncionalidade(877) && cotasMensais !== null) || (stateService.possuiAcessoFuncionalidade(878) && cotasDevidas !== null)"
  >
    <ng-container *ngIf="stateService.possuiAcessoFuncionalidade(877) && cotasMensais !== null">
      <h1>
        Cotas com vencimento no mês
      </h1>
    
      <div class="arrecadacao arrecadacao--labels">
        <div class="arrecadao__description">Arrecadação</div>
        <div class="arrecadao__value">Valor (R$)</div>
      </div>
    
      <div class="arrecadacao">
        <div class="arrecadao__description">
          Cotas recebidas até {{ cotasMensais.dataPagto }}<br>
          Quantidade de cotas pagas: {{ cotasMensais.qttCotasPaga }}
        </div>
    
        <div class="arrecadao__value">
          {{ cotasMensais.qttCotasPaga }}
        </div>
      </div>
    
      <div class="arrecadacao">
        <div class="arrecadao__description">
          Cotas a receber<br>
          Cotas em débito: {{ cotasMensais.qttCotasReceber }}
        </div>
    
        <div class="arrecadao__value">
          {{ cotasMensais.valorCotasReceber }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="stateService.possuiAcessoFuncionalidade(878) && cotasDevidas !== null">
      <h1>
        Cotas devidas
      </h1>
    
      <div class="warning">
        <i class="fas fa-exclamation-triangle"></i>
    
        <p class="description">
          Valor total de cotas<br>
          devidas acumuladas:
        </p>
    
        <p class="value __is-danger">
          R$ {{ cotasDevidas.valorTotal }}
        </p>
      </div>
      
      <ds-button class="segunda-via" routerLink="/cotas" [full]="true" >Visualizar cotas</ds-button>
    </ng-container>
  </a>
</ng-container>

<ng-container *ngIf="tipoExibicao === 'condomino'" >
  <a
    class="cotas-com-vencimento-no-mes custom-padding-top"
  > 
    <div class="title-area">
      <img src="../../../assets/icon/segunda-via-boletos-primary.svg" />
      <h1>
        2ª via de Boletos
      </h1>
    </div>

    <img class="boleto-img" src="../../../assets/img/boleto_apsa.png">
    
    <p class="descricao" >Acesse agora a área de segunda via de boletos da APSA e facilite o seu dia a dia.</p>
    <ds-button class="segunda-via" routerLink="/cotas/visualizacaoIndividual" [full]="true" >Acessar meus boletos</ds-button>
  </a>
</ng-container>