<div class="UploadHTML5Component" *ngIf="listaImagens && listaImagens.length > 0">
  <div class="Form_ExibirAnexos_Titulo">
    {{ getDescricaoListagemArquivos() }}
  </div>

  <div class="Form_ExibirAnexos_Lista">
    <div
      class="ExibirAnexos_Lista_Item"
      *ngFor="let item of listaImagens;"
      [ngClass]="{'__is-mobile-image': item.Tipo.includes('image')}"
    >
      <div class="Lista_Item_Delete" (click)="excluirAnexo(item)">
        <i class="fas fa-trash"></i>
      </div>
      <div class="Lista_Item_ViewDocument" (click)="openLinkAnexo(item.UrlDownload)" *ngIf="item.UrlDownload">
        <i class="far fa-eye"></i>
      </div>

      <img
        *ngIf="item.Tipo.includes('image')"
        src="data:/image/jpeg;base64,{{item.Conteudo}}"
        class="__is-mobile-image"
      >

      <div
        *ngIf="!item.Tipo.includes('image')"
        [ngClass]="this.constants.statusMenu ? '__is-sidebar-open' : '__is-sidebar-closed'"
        class="Lista_Item_Content"
        [title]="item.Nome"
      >
        <div *ngIf="item.Tipo.includes('spreadsheetml') || item.Tipo.includes('xls') || item.Tipo.includes('xlsx')"
          class="Item_Content_File __is-bggreen">
          <div class="Content_File_Icon">
            <i class="far fa-file-excel"></i>
          </div>

          <div class="Content_File_TypeText ">
            {{ limitChars(item.Nome) }}
          </div>
        </div>

        <div *ngIf="item.Tipo.includes('word')" class="Item_Content_File __is-bgblue">
          <div class="Content_File_Icon">
            <i class="fas fa-file-word"></i>
          </div>

          <div class="Content_File_TypeText">
            {{ limitChars(item.Nome) }}
          </div>
        </div>

        <div *ngIf="item.Tipo.includes('pdf')" class="Item_Content_File __is-bgred">
          <div class="Content_File_Icon">
            <i class="fas fa-file-pdf"></i>
          </div>

          <div class="Content_File_TypeText">
            {{ limitChars(item.Nome) }}
          </div>
        </div>

        <div *ngIf="!item.Tipo.includes('spreadsheetml') && !item.Tipo.includes('xls') 
        && !item.Tipo.includes('xlsx') && !item.Tipo.includes('word') && !item.Tipo.includes('pdf')"
          class="Item_Content_File __is-bggray">
          <div class="Content_File_Icon">
            <i class="fas fa-file"></i>
          </div>

          <div class="Content_File_TypeText">
            {{ limitChars(item.Nome) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="EnvioDocumentoCadastrar_Form_Upload">
  <label class="Form_Upload_Action" [ngClass]="{ '__is-full': isFullButton }">
    <div class="Buttons" *ngIf="this.stateService.platformIsDeviceMobile(); else PlataformaEhDesktopOuNavegadorMobile">
      <!-- Botão de anexar somente imagens -->
      <button class="Button Button_Primary Button_Outline" (click)="openCamera(2)" >
        <div class="Button_Content"  >
          <div class="Button_Content_Icon">
            <i class="apsa-icon-solid-image"></i>
          </div>
        </div>
      </button>

      <!-- Botão de tirar foto -->
      <button class="Button Button_Primary Button_Outline Buttons_First" (click)="openCamera(1)" >
        <div class="Button_Content" >
          <div class="Button_Content_Icon">
            <i class="apsa-icon-solid-camera"></i>
          </div>
        </div>
      </button>

      <!-- Botão de anexar imagens E documentos -->
      <button *ngIf="exibeBotaoDocumentosMobile || rotasBotaoUploadDocumentos.includes(this.rotaAtual)"
        class="Button Button_Primary Button_Outline" (click)="anexarDocumentosApps()">
        <div class="Button_Content">
          <div class="Button_Content_Icon">
            <i class="apsa-icon-solid-file-pdf"></i>
          </div>
        </div>
      </button>
    </div>

    <ng-template #PlataformaEhDesktopOuNavegadorMobile>
      <div
        class="Button Button_Primary Button_Outline Buttons_First"
        [ngClass]="{ '__is-full': isFullButton }"
      >
        <div class="Button_Content">
          <div class="Button_Content_Icon">
            <i class="fas fa-paperclip"></i>
          </div>
          <div class="Button_Content_Label">{{getDescricao()}}</div>
        </div>
      </div>
    </ng-template>

    <input *ngIf="this.stateService.platformIsDesktopOrMobileWeb()" class="Form_Upload_Input" type="file"
    [(ngModel)]="avatar" (change)="onFileChange($event)" #fileInput
    accept=".xls,.xlsx,.jpg,.jpeg,.png,.doc,.pdf, .docx">
  </label>

  
</div>