import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StateService } from './stateService';

@Injectable()
export class UserService {
  public methods = environment.apiCondominioDigital.endpoints.methods.usuario; 
  public host = environment.apiCondominioDigital.host;;

  constructor(
    private http: HttpClient,
    public stateService: StateService,
  ) {

  }

  editarOuCadastrarFoto(filename: string, base64image: string): Observable<string> {
    const url = this.host + this.methods.cadastrarOuEditarFotoPessoa;
    
    const payload = {
      id_Pessoa: this.stateService.getPessoaLogada().Id,
      arquivo: base64image,
      nome: filename,
    };
    
    const options = {
      headers: this.getHeader(),
    };

    return this.http.post<string>(url, payload, options);
  }

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  removerFotoDoUsuario(): Observable<string> {
    const url = this.host + this.methods.excluirFoto;
    
    const payload = {
      id_Pessoa: this.stateService.getPessoaLogada().Id,
    };
    
    const options = {
      headers: this.getHeader(),
    };

    return this.http.post<string>(url, payload, options);
  }
}