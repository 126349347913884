<div
  class="QuickCardComponent"
  [ngClass]="{
    '__is-highlighted': this.id === 50 || this.id === 7,
    '__is-compact': this.compact,
    'segurosCard': this.type === 2
  }" 
>
  <label *ngIf="this.type === 1" (click)="redirect()">
    <div class="Icon" [innerHTML]='constructIcon()'>
    </div>

    <div class="Title">
      {{ title }}
    </div>
  </label>

  <div [ngClass]="{'emBreveTag': soonTag === true}" 
  *ngIf="this.type === 2" class="labelSeguros" (click)="redirect()">

    <!-- <img src="/assets/svg/icon-{{icon}}.svg" alt=""> -->
    <img [src]="getImgSeguros(icon)" alt="">
    <div class="titulo">{{title}}</div>
    <div class="soonTag" *ngIf="soonTag === true">Em breve</div>
  </div>
</div>