import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnDestroy
} from "@angular/core";
import { timeout } from "rxjs/operators";

import { Router } from "@angular/router";
import { LightBoxesService } from '../../lightboxes';

@Component({
  selector: "app-sucesso",
  templateUrl: "./sucesso.component.html",
  styleUrls: ["../popup.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SucessoComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;
  private element: any;

  constructor(
    private service: LightBoxesService,
    private el: ElementRef,
    private router: Router
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Block_Popup_Lateral") {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.service.remove(this.id);
    this.element.remove();
  }

  open(mensagem: { title: string, text: string}, time: number = 1000): Promise<boolean> {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('Popup_Lateral_Title')[0].innerHTML = mensagem.title;
    this.element.getElementsByClassName('Popup_Lateral_Subtitle')[0].innerHTML = mensagem.text;

    return new Promise(resolve => {
      setTimeout(function() {
        a.firstChild.classList.remove(n);
        resolve(true);
      }, time);
    });
  }

  close(): void {
    this.element.firstChild.classList.remove(this.className);
  }
}
