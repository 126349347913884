import { Injectable } from '@angular/core';
import { PessoaFoto } from 'src/app/modules/login/services/DTO/response/TokenResult.DTO';
import { LightBoxesService } from '../components/lightboxes/lightboxes.services';
import { UserService } from '../services/user.service';

@Injectable()
export class ProfilePictureFacade {
  constructor(
    private userService: UserService,
    private lightboxesService: LightBoxesService,
  ) { }

  remove() {
    this.userService.removerFotoDoUsuario().subscribe(
      response => this.handleRemoveSuccess(response),
      error => this.handleRemoveError(error),
    );
  }

  change(fileName: string, base64img: string) {
    this.userService.editarOuCadastrarFoto(fileName, base64img).subscribe(
      response => this.handleChangeSuccess(response, fileName, base64img),
      error => this.handleChangeError(error),
    );
  }

  exists(): boolean {
    return sessionStorage.getItem('pessoaFoto') !== null;
  }

  private handleRemoveSuccess(response: string) {
    this.openOkPopup('Sucesso', 'Você removeu a sua foto!');
    this.removeFromSessionStorage();
  }

  private handleRemoveError(error: any) {
    this.openOkPopup('Erro', error.error.message);
  }

  private handleChangeSuccess(APIResponse: string, fileName: string, fileContent: string) {
    this.openOkPopup('Legal!', 'Sua foto foi trocada com sucesso!');
    this.changeInSessionStorage(fileName, fileContent);
  }

  private handleChangeError(error: any) {
    this.openOkPopup('Erro', error.error.message);
  }

  private changeInSessionStorage(filename: string, base64img: string) {
    const pessoaFoto: PessoaFoto = JSON.parse(sessionStorage.getItem('pessoaFoto')) || {};

    pessoaFoto.arquivo = base64img;
    pessoaFoto.nome = filename;
    pessoaFoto.tipo = pessoaFoto.tipo || 'image/jpeg';

    sessionStorage.setItem('pessoaFoto', JSON.stringify(pessoaFoto));
  }

  private removeFromSessionStorage() {
    sessionStorage.removeItem('pessoaFoto');
  }

  private openOkPopup(title: string, message: string): Promise<boolean> {
    return this.lightboxesService.open(
      'custom-generico',
      { title, text: message },
      15_000
    );
  }
}