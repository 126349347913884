// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMock: true,
  appStoreURL: 'https://apps.apple.com/br/app/apsa/id1171170928',
  playStoreURL: 'https://play.google.com/store/apps/details?id=com.apsa&hl=pt_BR',
  idsCampanhaSeguro:{
    idCampanha:13
  },
  apiGT: {
    //host: '//localhost:1593/api',
    host: 'https://apsagtapi.hmg.marlin.com.br/api',
    //host: '//areaexclusiva.apsa.com.br/wsapsagt/api',
    acesso: {
      login: 'usrWebservice',
      senha: '4ps43xc1o51v4'
    },
    endpoints: {
      token: '/security/token',
      achadosEPerdidos: {
        getListagem: '/v1/LostFounds',
        getStatusFiltro: '/v1/LostFoundStatus',
        cadastro: '/v1/LostFounds',
        getUnidades: '/v1/Units',
      },
      espacoGT: {
        getEspaco: '/v1/spaces/listarEspacosCD',
        tiposDeEspacos: '/v1/spaceTypes',
        cadastroEspacoGT: '/v1/spaces'
      },
      checklistreports: {
        post: {
          cadastroChecklist: '/v1/checklistreports'
        },
        get: {
          listChecklist: '/v1/checklistreports',
          listarReservasCondominio: '/v1/reservations/ListReservationsGroupByDate',
          buscarUnidadesMorador: '/v1/residents/getByCPF',
          buscarUnidadesCondominio: '/v1/units',
          buscarBlocosUnidades: '/v1/units/listGroupByBlocks',
          buscarEspacosCondominio: '/v1/spaces/{0}/{1}',
          buscarEspacosReservaveis: '/v1/spaces/{0}/{1}/reservables',
          buscarEspacosReservaveisComCotasAbertas: '/v1/spaces/{0}/{1}/reservablesWithOpenQuotas?openQuotas=true',
          buscarTermoAceiteMudanca: '/v1/acceptanceTermMovings',
          buscarTermoAceiteEspaco: '/v1/acceptanceTermSpaces/getBySpace',
          buscarEspacoSemImagens: '/v1/spaces/WithoutImages',
          buscarConfiguracoesReservaEspaco: '/v1/spaceReservationConfigurations/getBySpace',
          buscarMoradorPorUnidade: '/v1/residents/getByUnity',
          listarHorariosDisponiveisReservaEspaco: '/v1/spaceReservations/getTimeSuggestionsForSpaceReservation',
          listarHorariosDisponiveisReservaMudanca: '/v1/movingReservations/getTimeSuggestionsForReservation',
          buscarDetalheReserva: '/v1/reservations/getByIdAndType',
          listarChecklistCondominio: '/v1/checklistreports/ForApp',
          listarOcorrenciasCondominio: '/v1/occurrences/ForApp',
          listarEspacosCadastroOcorrencia: '/v1/spaces',
          listarTiposChecklist: '/v1/checklistTypes',
          buscarEspacoPorQRCode: '/v1/spaces/getByQrCode',
          cadastrarOcorrencia: '/v1/occurrences',
          listarEspacosComChecklist: '/v1/spaces/{0}/{1}/withChecklist',
          listarChecklistsPorEspaco: '/v1/spaces/SearchByIdForChecklistPageSelector?id',
          listarItensChecklist: '/v1/checklists/GetItensList?id',
          cadastrarReserva: '/v1/spaceReservations/createMultiples',
          cadastrarReservaMudanca: '/v1/movingReservations/createMultiples',
          buscarReservaPorTipoeId: '/v1/reservations/getByIdAndTypeForApp?id={0}&type={1}',
          cancelarReserva: '/v1/reservations/CancelReservation?id={0}&profileCode={1}&type={2}&cpf={3}',
          atualizarReserva: '/v1/spaceReservations/sendUpdatedFiles',
          downloadArquivoGT: 'http://apsa.hmg.marlin.com.br/arquivoGt.aspx',
          buscarDetalheOcorrencia: '/v1/occurrences/ForApp',
          buscarLogsOcorrencia: '/v1/logsoccurrences?id={0}',
          editarDetalheOcorrencia: '/v1/occurrences/{0}',
          buscarDadosColaborador: '/v1/collaborators',
          buscarDadosMorador: '/v1/residents',
          buscarReservasMoradorGT: '/v1/reservations/GetMyReservationsForResidentGroupByDate',
          buscarUnidadesMoradorGT: '/v1/units/listGroupByBlocksResident',
          buscarBlocosGT: '/v1/Blocks',

          espacosSemImagem: '/v1/Spaces/WithoutImages',
          buscarEspacoPorData: '/v1/spaceReservations/getSpaceCalendarByDate',
          bloquearReservaEspacos: '/v1/spaceReservations/lockMultiplesPeriods',
          detalheReservaEspaco: '/v1/spaceReservationConfigurations/getBySpace/',
          // detalheReservaEspaco: '/v1/spaceReservationConfigurations',
          buscarMudancaPorData: '/v1/movingReservations/getChangesCalendarByDate',
          bloquearReservaMudanca: '/v1/movingReservations/lockMultiplesPeriods',
          // detalheMudancaBloco: '/v1/movingReservationSettings/',
          detalheMudancaBloco: '/v1/movingReservationSettings',

          detalheCardEspaco: '/v1/spaceReservationConfigurations/getHorariosResumo',
          detalheCardMudanca: '/v1/movingReservationSettings/getHorariosResumo'
        },
        delete: {
          deleteColaborador: '/v1/collaborators/delete',
          deleteMorador: '/v1/residents/delete',
          liberarEspacosReserva: '/v1/spaceReservations/unlockPeriod',
          liberarMudancaReserva: '/v1/movingReservations/unlockPeriod'
        }
      },
      accessAlert: {
        get: {
          totalAlertas: "/v1/acessAlert/GetCountAlerts",
          listarAlertas: "/v1/acessAlert/GetAlerts"
        }
      },
      parameterCollaboratorAlert: {
        get: "/v1/parameterCollaboratorAlert",
        post: "/v1/parameterCollaboratorAlert",
        put: "/v1/parameterCollaboratorAlert"
      },
      collaborator: {
        post: "/v1/collaborators",
        get: '/v1/collaborators',
        put: '/v1/collaborators',
      },
      resident: {
        get: '/v1/residents',
        put: '/v1/residents',
      },
      muralGT: {
        getMensagens: '/v1/publications',
        cadastroMensagem: '/v1/publications',
        deleteMensagem: '/v1/publications',
        getDetalheMensagem: '/v1/publications',
        buscarUnidadesMoradorGT: '/v1/units/listGroupByBlocks',
        editarMensagem: '/v1/publications'
      },
      contratos: {
        buscarContratosGT: '/v1/contracts',
        buscarContratoGTPorUid: '/v1/contracts/{id}',
        cadastrarContratoGT: '/v1/contracts',
        alterarCadastroGT: '/v1/contracts/{id}',
        deletarCadastroGT: '/v1/contracts/{id}',
      },
      courrier: {
        listagem: '/v1/correspondences',
        getMensagens: '/v1/courrierMessages/GetByName',
        getFilterBlocks: '/v1/blocks',
        getUnidades: '/v1/units/listGroupByBlocks',
        getBlocks: '/v1/units/listGroupByBlocks',
      },
      inventario: {
        listagem: '/v1/Spaces/ForReport',
        getInventarioItemImagens: '/v1/spaceItems/GetImages',
        getSpaces: '/v1/Spaces'
      },
      ocorrenciaGT: {
        espacos: '/v1/Spaces',
        tipoFiltro:  '/v1/Checklists',
        getListagem: '/v1/Occurrences/ManagerOccurrenceReport',
        downloadOcorrencia: '/v1/occurrences/downloadOccurrenceDetails',
        downloadListaOcorrenciasExcel: '/v1/occurrences/downloadListOccurrencesExcel'
      },
    }
  },
  apiServicos: {
    host: 'https://wsapsa.hmg.marlin.com.br/api',
    //host: 'https://areaexclusiva.apsa.com.br/wsapsa/api',
    //host: '//areaexclusiva.apsa.com.br/wsapsa/api',
    //host: 'http://localhost:19398/api',
    acesso: {
      login: 'usrWebservice',
      senha: '4ps43xc1o51v4'
    },
    endpoints: {
      token: '/Autenticacao/Login',
      dadosUsuario: '/MinhaPagina/ObterDadosUsuario',
      condominios: {
        //listarCondominios: '/MinhaPagina/ObterCondominiosAcessoUsuario'
      },
      condomino: {
        // listarCondominos: '/Condomino/ListarCondominosPorCondominio',
        // listarCotas: '/Condomino/ListarCotasCondomino',
        // emitir2viaBoleto: '/Condomino/EmitirSegundaViaBoletoCondomino',
        // enviar2viaBoletoEmail: '/Condomino/BuscarDadosBoletoCondomino',
        //copiarCodigoBarras: '/Condomino/ObterLinhaDigitavelBoletoCondomino'
      },
      funcionarios: {
        // listarFuncionarios: '/Funcionario/ObterFuncionariosCondominio',
        // buscarDetalheFuncionario: '/Funcionario/ObterDadosFuncionario',
        // buscarValeEmprestimosFuncionario: '/Funcionario/ObterValeFuncionario',
        // buscarHorasExtrasFuncionario: '/Funcionario/ObterHorasExtrasFuncionario',
        // buscarValeTransporteFuncionario: '/Funcionario/ObterValeTransporteFuncionario',
        // buscarFeriasFuncionario: '/Funcionario/ObterFeriasFuncionario',
        // buscarTelefonesFuncionario: '/Funcionario/ObterTelefonesFuncionario',
        // buscarEnderecosFuncionario: '/Funcionario/ObterEnderecosFuncionario',
        buscarProventosDescontosFuncionario: '/Funcionario/buscarProventosDescontos'
      }
    }
  },
  apiMaloteVirtual: {
    //host: '//localhost:22186/api',
    host: 'https://apsagtapimalote.hmg.marlin.com.br/api',
    hostWorkflow: 'https://doitt-gestaopagamentos-api.hmg.marlin.com.br/api',
    //host: '//areaexclusiva.apsa.com.br/wsapsamalote/api',
    endpoints: {
      token: '/security/token',
      methods: {
        listarDocumentos: '/v1/documentos?',
        listarDocumentosGerencial: '/v1/documentos/all',
        getImpressao: '/v1/documentos/getImpressao',
        cadastrarDocumentos: '/v1/documentos/contas',
        buscarPorId: '/v1/documentos',
        downloadArquivoGT: 'http://apsa.hmg.marlin.com.br/ArquivoMaloteVirtual.aspx?ArquivoUid',
        cadastrarDocumentoCorrespondencia: '/v1/documentos/correspondencias',
        cadastrarDocumentoPPC: '/v1/documentos/documentoPPC',
        getValidacoes: '/v1/documentos/validacoes',
        getTipos: '/v1/documentos/getTipos',
        getNossoNumero: '/v1/documentos/getDetalheNossoNumero',
        getNaoTratados: '/v1/documentos/getNaoTratados',
        getDatasCadastro: '/v1/documentos/getDatasCadastro',
        editaDocumento: '/v1/documentos/update',
        getFornecedor: '/v1/documentos/buscaFornecedor',
        getContasBancarias: '/v1/documentos/buscaContasBancarias',
        getFluxoDeCaixa: '/v1/documentos/buscaFluxosDeCaixa',
        getGrupoFornecedores: '/v1/documentos/buscaGrupoFornecedores',
        getBeneficiarios:'/v1/documentos/buscarBeneficiarios',
        fornecedorMatriculaDebitoAutomatico: '/v1/documentos/FornecedorMatriculaDebitoAutomatico',
        documentoDebitoAutomatico: '/v1/documentos/documentoDebitoAutomatico',
        getTitulos:'/v1/Pagamentos/',
        cancelarTitulo:'/v1/Pagamentos/cancelarPagamento/'
      }
    }
  },
  apiCondominioDigital: {
    host: 'https://condominiodigitalapi.hmg.marlin.com.br/api',
    //host: '//localhost:4948/api',
    //host: '//areaexclusiva.apsa.com.br/digital-api/api',
    endpoints: {
      methods: {
        listarCondominios: '/v1/condominio/listarCondominiosUsuarioLogado?cpf=',
        registrarReclamacao: '/v1/condomino/registrarReclamacao',
        idParametroReserva: '/v1/parametro',
        dadosSindicos:'/v1/condominio/getDadosSindico',
        acompanhamentoOrcamentario: {
          getOrcamento: '/v1/acompanhamentoOrcamentario',
          getPeriodoDisponivel: '/v1/acompanhamentoOrcamentario/getPeriodoDisponivel',
          getSaldoDevedor: '/v1/acompanhamentoOrcamentario/getSaldoDevedor',
          getUrlDownload: '/v1/acompanhamentoOrcamentario/getUrlDownload',
          getUrlDownloadExcel: '/v1/acompanhamentoOrcamentario/getUrlDownloadExcel',
          getDetalhamentoOrcamentario: '/v1/acompanhamentoOrcamentario/getDetalhamentoOrcamentario',
        },
        achadosEPerdidos: {
          getListagem: '/v1/achadosPerdidos/',
          getStatusFiltro: '/achadosPerdidosStatus',
          cadastro: '/v1/achadosPerdidos',
          getUnidades: '/v1/dadosCondomino/getUnidadesCondominio',
        },
        entregaExpressa: {
          getTipos: '/v1/agendamentoMalote/getTipos',
          cancelarAgendamento: '/v1/agendamentoMalote/cancelar',
          confirmarAgendamento: '/v1/agendamentoMalote/confirmar',
          finalizarAgendamento: '/v1/agendamentoMalote/finalizar',
          finalizarAgendamentos: '/v1/agendamentoMalote/finalizarAgendamentoLote',
          finalizarAgendamentoComOcorrencia: '/v1/agendamentoMalote/finalizarAgendamentoComPendencia',
          agendamentoMalote: '/v1/agendamentoMalote',
          getDetalhe: '/v1/agendamentoMalote/getDetalhe',
          validarCadastro: '/v1/agendamentoMalote/getValidacaoCadastrar',
          verificaAdesao: '/v1/agendamentoMalote/verificaAdesao',
          getDatas: '/v1/agendamentoMalote/getDataCadastro',
          getProximosFeriados: '/v1/agendamentoMalote/getProximosFeriados',
          postMaloteInternoApsa:'/v1/agendamentoMalote/ChameAMotoInternoApsa',
        },
        autenticacaoDigital: {
          possuiAutenticacao: '/v1/autenticacaoDigital/possuiAutenticacao',
          create: '/v1/autenticacaoDigital/create',
          login: '/v1/autenticacaoDigital/login',
          delete: '/v1/autenticacaoDigital'
        },
        condominio: {
          getDadosGerenteConta: "/v1/condominio/getDadosGerenteConta",
          getUnidadesCondominio: "/v1/condominio/getunidadesCondominio",
          validarExibicaoWpp: "/v1/condominio/validarExibicaoWhatsApp",
          getInformesQuitacao: "/v1/condominio/getInformesQuitacao",
          getContaByCNPJ: '/v1/condominio/getContaByCNPJ',
          getListaGestoresCondominio: '/v1/condominio/getListaGestoresCondominio',
          getKitBoasVindas: '/v1/condominio/getKitBoasVindas',
          getCotasMensal: '/v1/condominio/getCotasMensal',
          getCalendarioDeVisitas: '/v1/condominio/getCalendarioVisitas',
          getProximaVisita: '/v1/condominio/getProximaVisita',
          getUnidadesInadimplentes: '/v1/condominio/getUnidadesInadimplentes',
          getUnidadesInadimplentesDownload: '/v1/condominio/downloadRelatorioInadimplencia',
          getReceitasPrevistas: '/v1/condominio/getReceitasPrevistas',
          getReceitasPrevistasDownload: '/v1/condominio/downlodRelatorioReceitasPrevistas',
          getBannerRota: '/v1/condominio/getBannerRota',
          getTiposSeguro: '/v1/condominio/getTiposSeguro',
          cadastrarMensagemCorretora: '/v1/condominio/CadastrarMensagemCorretora',
          cadastrarPessoaSeguroSaude: '/v1/condomino/CadastroSchatzPlanoSaude',
          cadastrarSeguroConteudo: '/v1/condomino/CapturarLead',
          cadastrarPessoaSeguroSaudePet: '/v1/condomino/CadastroSchatzPlanoSaudePet'
        },
        pastaDigital: {
          listagemPrestacaoContas: '/v1/pastaDigital',
          listaGestores: '/v1/pastaDigital/getGestores',
          getGestor: '/v1/pastaDigital/getGestor',
          aprovarDocumento: '/v1/pastaDigital/aprovar',
          desaprovarDocumento: '/v1/pastaDigital/desaprovar',
          getComentarios: '/v1/pastaDigital/getComentarios',
          enviaComentario: '/v1/pastaDigital/createComentario',
        },
        reservasConv: {
          espacosReservaveis: '/v1/espaco/reservaveis',
          espacosReservaveisSemConfig: '/v1/espaco/reservaveisSemConfiguracao',
          sugestaoHorarios: '/v1/espacoReserva/getSugestaoHorarios',
          listarBlocos: '/v1/dadosCondomino/getUnidadesCondominio',
          cadastrarReservaConv: '/v1/espacoReserva/criarReservas',
          buscarReservas: '/v1/espacoReserva/buscarReservas',
          buscarDetalheReservaConv: '/v1/espacoReserva/getDetalheReserva',
          cancelarReservaConv: '/v1/espacoReserva/cancelarReserva',
          horariosMudancaConv: '/v1/mudancaReserva/getSugestaoHorarios',
          cadastrarMudancaReservaConv: '/v1/mudancaReserva/criarReservas',
          enviarArquivo: '/v1/espacoReserva/adicionarArquivo',
          espacoPorUid: '/v1/espaco/getByUid',
          sugestaoHorarioEspaco: '/v1/espacoReserva/getHorariosResumo',
          sugestaoHorarioMudanca: '/v1/mudancaReserva/getHorariosResumo',
          getRelatorioEspacoReserva: '/v1/espacoReserva/getRelatorioEspacoReserva',
        },
        relatoriosPrime: {
          getRelatoriosPrime: '/v1/relatoriosPrime',
        },
        relatoriosOcorrencia: {
          download: '/v1/condominio/downloadPdfExcelOcorrencia',
        },
        cadastroDeEspacos: {
          postEspacos: '/v1/espaco',
          getEspaco: '/v1/espaco',
          dadosEdicaoEspacos: '/v1/espaco/getByUid',
          getEspacoPorQR: '/v1/espaco/getByQRCode'
        },
        condomino: {
          adesaoCampanha:'v1/condomino/AdesaoCampanha',
          listarPorCondominio: "/v1/condomino/listarCondominosCondominio",
          getAssunto: '/v1/condomino/getAssuntosFaleComApsa',
          enviarFaleComApsa: '/v1/condomino/FaleComApsa',
          getDadosCondominos: '/v1/dadosCondomino/getDadosCondomino',
          getDadosProcurador: '/v1/dadosCondomino/getDadosProcurador',
          usuarioAceiteTermo: '/v1/condomino/UsuarioAceiteTermo',
          cadastrarUsuarioAceiteTermo: '/v1/condomino/CadastrarUsuarioAceiteTermo',
          getSeguros: '/v1/condomino/TipoSeguro',
          verificarAdesaoHotSite: '/v1/condomino/VerificarAdesaoHotSite',
          desabilitarSchatzCadastroLeads: '/v1/condomino/DesabilitarSchatzCadastroLeads',
          schatzCliques: '/v1/condomino/SchatzCliques'
        },
        dadosCondominio: {
          dadosCadastrais: '/v1/condominio/getDadosCadastraisCondominio',
        },
        documentos: {
          listarConvencaoRegulamento: "/v1/documentos/listarConvencaoRegulamento",
          listarAtasCircularesConvocacoes: "/v1/documentos/listarAtasCircularesConvocacoes",
          filtrarAtasCircularesConvocacoes: "/v1/documentos/filtrarAtasCircularesConvocacoes",
          getDetalheAtasCircularesConvocacoes: "/v1/documentos/getDetalheAtasCircularesConvocacoes",
          getLinkDocumentos:"/v1/documentos/buscarUrlImpressao"
        },
        login: {
          realizarLogin: '/v1/login',
          redefinirSenha: '/v1/login/alterarSenha',
          getCondominiosImoveis: "/v1/login/getImoveisCondominiosUsuario",
          alterarDados: "/v1/login/alterarDados",
          recuperarSenha: "/v1/login/redefinirSenhaUsuario",
          recuperarLogin: "/v1/login/getIdentificacaoUsuario",
          verificarLoginExistente: "/v1/login/getLoginExistente",
          cadastraAcessibilidade: '/v1/login/createAcessibilidade',
          loginPorCpf: '/v1/login/byCpfCnpj',
          getTelefone: '/v1/login/getTelefone',
          getVersaoApp: '/v1/login/getVersaoApp',
          redefinirSenhaNovoNumero: '/v1/login/redefinirSenhaUsuarioNovoNumero',
          cadastraAcessoPagina: '/v1/login/cadastraAcesso',
          getFuncionalidadesFrequentes: '/v1/login/getFuncionalidadesFrequentes',
          loginEncriptado: '/v1/login/loginCriptografado',
          getImagensCarrossel: '/v1/login/getImagensCarrossel',
        },
        inventario: {
          getPdfOuExcel: '/v1/condominio/downloadPdfExcelInventario',
        },
        redeDeParcerias: {
          listarCondominios: '/v1/redeParcerias/listarCondominios',
          listarFuncionarios: '/v1/redeParcerias/listaFuncionarios',
        },
        cotas: {
          getDetalheCota: '/v1/cotas/getDetalheCota',
          getEmailCondomino: '/v1/cotas/getEmailCondomino',
          listarUnidadesCotas: '/v1/cotas/getUnidadesCotas',
          getDetalhePagamento: '/v1/cotas/getDetalhePagamento',
          validaPagamentoCartao: '/v1/cotas/validaPagamentoCartao',
          enviarEmailCondomino: '/v1/cotas/enviarEmailCondomino',
          emitirSegundaVia: '/v1/cotas/getEmitirSegundaViaBoletoCondominio',
          buscarValorCota: '/v1/cotas/getValorAtualizado',
          tokenizarCartaoCredito: '/v1/pagamentoCielo/tokenizarCartao',
          realizarPagamentoCartaoCredito: '/v1/pagamentoCielo/realizarPagamento',
          getHashBandeiras: '/v1/pagamentoCielo/getHashBandeiras',
          getRelatorios: '/v1/pagamentoCielo/all',
          getUrlDeImpressaoDeRelatorios: '/v1/pagamentoCielo/imprimirRelatAll',
          getTermosDeUso: '/v1/pagamentoCielo/getTermosUso',
          getLinhaDigitavel: '/v1/cotas/getLinhaDigitavel',
          getCotasDevidas: '/v1/cotas/getCotasDevidas',
          downloadRelatorioCotas: '/v1/cotas/downloadRelatorioCotas',
          getUnidadesCotas: '/v1/cotas/getUnidadesCotas',
          getTemplateBoleto: '/v1/cotas/getTemplateBoleto',
          getProvidenciaApsa: '/v1/cotas/getProvidenciaApsa',
          possuiCotasAbertas: '/v1/cotas/getCotasAbertas',
          getCotasPagas: '/v1/cotas/getCotasPagas',
          getCotasComVencimento: '/v1/cotas/getCotasComVencimento',
          downloadListasCotas: '/v1/cotas/downloadListasCotas'
        },
        saldoContas: {
          getSaldoContas: '/v1/cotas/getSaldoContas'
        },
        recorrenciaCartao: {
          cadastrarRecorrencia: '/v1/recorrenciaCartao/cadastrarRecorrencia',
          getInfoCartao: '/v1/recorrenciaCartao/getInfoCartao',
          cancelarRecorrencia: '/v1/recorrenciaCartao/cancelarRecorrencia',
          editarRecorrencia: '/v1/recorrenciaCartao/alterarRecorrencia',
          getTermoUso: '/v1/recorrenciaCartao/getTermoUso',
          getDetalhamentoRecorrencia: '/v1/recorrenciaCartao/getDetalhamentoRecorrencia',
          downloadDetalhamentoRecorrencia: '/v1/recorrenciaCartao/DownloadDetalhamentoRecorrencia',
        },
        locatario: {
          getUrlBoleto: "/v1/locatario/getUrlBoleto",
          getCodigoBarras: "/v1/locatario/getCodigoBarrasBoleto",
          enviarBoletoPorEmail: "/v1/locatario/enviaBoletoEmail",
          getEmailLocatario: '/v1/locatario/getEmail',
          getTemplateBoleto: '/v1/locatario/getTemplateBoleto',
          getDadosIRProprietario: '/v1/locatario/getDadosIRProprietario',
          getRelacaoDeDevedores: '/v1/locatario/getRelacaoDevedores',
          getDownloadRelacaoDevedores: '/v1/locatario/downloadRelacaoDevedores',
          getDownloadExcelRelacaoDevedores: '/v1/locatario/downloadExcelRelacaoDevedores',
          getDocumentosLocacao:'/v1/locatario/getDocumentosLocacao',
          realizarPagamentoLocatario: '/v1/pagamentoCielo/realizarPagamentoLocatario',
          tokenizarCartaoDeCreditoLocatario: '/v1/pagamentoCielo/tokenizarCartao',
          getBoletoCartao: '/v1/locatario/getBoletoCartao',
          getInfoCartaoLocatario: '/v1/recorrenciaCartao/getInfoCartaoLocatario',
          getBoletosPago:'/v1/locatario/getBoletosPago',
          getUrlBoletoPago: '/v1/locatario/getUrlBoletoPago',
          cancelarRecorrenciaLocatario: '/v1/recorrenciaCartao/cancelarRecorrenciaLocatario',
          alterarRecorrenciaLocatario: '/v1/recorrenciaCartao/alterarRecorrenciaLocatario',
          cadastrarRecorrenciaLocatario: '/v1/recorrenciaCartao/cadastrarRecorrenciaLocatario',
          getStatusBoletoLocatario: '/v1/login/getStatusBoletoLocatario',
        },
        extrato: {
          getExtratoProprietario: '/v1/extrato/getExtratoProprietario',
          getExtratoProprietarioTodos: '/v1/extrato/getExtratoProprietarioTodos',
          getExtratoSindico: '/v1/extrato/getExtratoSindico',
          getDocumentoExtratoSindico: '/v1/extrato/getDocumentoExtratoSindico',
          getTiposContas: '/v1/extrato/getBlocosEDescricaoContasExtratoSindico',
          getEnderecoImovel: '/v1/extrato/popularListaImoveis'
        },
        contracheque: {
          buscarContraChequeFuncionario: '/v1/funcionario/getContraCheque'
        },
        mural: {
          postarMensagem: '/v1/mural',
          editarMensagem: '/v1/mural',
          buscarTiposDeMensagem: '/v1/mural/getTiposMensagem',
          buscarMensagens: '/v1/mural/getMensagensFiltro',
          buscarMensagemDetalhe: '/v1/mural/getMensagem'
        },
        enquetes: {
          listarEnquetes: '/v1/enquete/getEnquetes',
          listarEnquetePorUid: '/v1/enquete/getEnqueteByUid',
          getTiposMensagem: '/v1/enquete/getTiposEnquete',
          getRespostasEnquete: '/v1/enquete/getRespostasEnquete',
          cadastrarEnquete: '/v1/enquete',
          cadastrarRespostaVoto: '/v1/enquete/cadastrarVoto',
          getResultadoParcial: '/v1/enquete/getResultadoParcialEnquete',
          exluirEnquete: '/v1/enquete/excluirEnquete',
          encerrarVotacao: '/v1/enquete/encerrarVotacao',
          editarEnquete: '/v1/enquete/alterarEnquete'
        },
        centralAtendimento: {
          getCondominios: '/v1/centralAtendimento/getFiltroCondominio',
          getPessoas: '/v1/centralAtendimento/getFiltroPessoa',
          getImoveis: '/v1/centralAtendimento/getFiltroImovel',
          getlogEnvioBoletos: '/v1/centralatendimento/getLogEnvioBoletos',
          getlogEnvioReservas: '/v1/centralatendimento/getLogEnvioReservas',
          getlogEnvioDocumentos: '/v1/centralatendimento/logEnvioDocumentos',
          getDownloadEvidencias: '/v1/centralatendimento/getDownloadEvidencias',
          getDownloadLogsDocumentos: '/v1/centralatendimento/getDownloadLogsDocumentosExcel',
          consultarDadosCpf: '/v1/centralatendimento/consultarDadosCpf'
          
        },
        Unidade: {
          cadastraMorador: '/v1/dadosCondomino/cadastrarMoradorGT',
          getUnidadesCondominio: '/v1/dadosCondomino/getUnidadesCondominio'
        },
        ocorrencias: {
          buscarEspacosDisponiveis: '/v1/ocorrencia/getEspaco',
          buscarTiposOcorrencias: '/v1/ocorrencia/getTipoOcorrencia',
          cadastrarOcorrencia: '/v1/ocorrencia',
          buscarOcorrencias: '/v1/ocorrencia/getOcorrencias',
          baixarDetalheOcorrencia: '/v1/ocorrencia/downloadDetalhesOcorrencia',
          buscarDetalheOcorrencia: '/v1/ocorrencia/getDetalhe',
          editarDetalheOcorrencia: '/v1/ocorrencia/createHistorico',
          deletarOcorrencia: '/v1/ocorrencia',
          downloadListaOcorrenciasExcel: '/v1/ocorrencia/downloadListaOcorrenciasExcel'
        },
        promocoesBeneficios: {
          getCategorias: '/v1/promocoes/getCategorias',
          cadastrarPromocao: '/v1/promocoes',
          editarPromocao: '/v1/promocoes',
          getPromocoes: '/v1/promocoes',
          deletarPromocao: '/v1/promocoes',
          getPromocaoPorUID: '/v1/promocoes/getByUid',
          cadastrarLogBeneficio: '/v1/promocoes/cadastrarLogBeneficio',
        },
        colaboradores: {
          getColaboradores: '/v1/funcionario/getColaboradores'
        },
        funcionarios: {
          getDadosPorCPF: '/v1/funcionario/getDadosFuncionario',
          getTelefone: '/v1/funcionario/getBuscarTelefones',
          getAnosContraCheque: '/v1/funcionario/getAnosContraCheque',
          buscarEnderecos: '/v1/funcionario/buscarEnderecos',
          buscarValeEmprestimo: '/v1/funcionario/buscarValesEmprestimo',
          buscarHoraExtra: '/v1/funcionario/buscarHoraExtra',
          buscarValeTransporte: '/v1/funcionario/listarValeTransporte',
          listarFuncionarios: '/v1/funcionario/listarFuncionariosCondominio',
          buscarDadosFuncionario: '/v1/funcionario/getBuscarDadosFuncionario',
          buscarFeriasFuncionario: '/v1/funcionario/getFerias',
          simulacaoFerias: '/v1/funcionario/getSimulacaoFerias',
          simulacaoRescisao: '/v1/funcionario/getSimulacaoRescisao',
          getDadosIR: '/v1/funcionario/getDadosIR',
          downloadDemonstrativoIR: '/v1/funcionario/downloadDemonstrativoIR',
          getQuadroHorarioFuncionarios: '/v1/funcionario/getQuadroHorarioFuncionarios',
          getEscalaRevezamento: '/v1/funcionario/getEscalaRevezamento',
          downloadEscalaRevezamento: '/v1/funcionario/downloadEscalaRevezamento',
          getDemonstrativoIRPrestadorServico: '/v1/funcionario/getDemonstrativoIRPrestadorServico',
          validarLancamentoDeDescontosEProventos: '/v1/funcionario/validarCompetenciaCadastroVerbas',
          cadastrarVerbas: '/v1/funcionario/cadastrarVerbas',
          gravacaoProventosDescontos: '/v1/funcionario/gravacaoProventosDescontos',
          deletarDadosGravadosProventosDescontos: '/v1/funcionario/deletarDadosGravadosProventosDescontos',
          definirFuncionarioChefe: '/v1/funcionario/definirFuncionarioChefe',
          deletarFuncionarioChefe: '/v1/funcionario/deletarFuncionarioChefe',
          funcionarioGravacaoProventosDescontos: '/v1/funcionario/funcionarioGravacaoProventosDescontos',
          devolverParaAlteracao: '/v1/funcionario/devolverParaAlteracao',
          gestorEditGravacaoProventosDescontos: '/v1/funcionario/gestorEditGravacaoProventosDescontos',
          getProventosDescontosByAgConta: '/v1/funcionario/getProventosDescontosByAgConta',
          relatorioProventosDescontos: '/v1/funcionario/relatorioProventosDescontos'
        },
        queroMeCadastrar: {
          buscarPorCpfCnpj: '/v1/pessoaCadastro/getPerfil',
          validarDadosFiador: '/v1/pessoaCadastro/validarDadosFiador',
          cadastrarLogin: '/v1/pessoaCadastro/cadastrarLogin',
          validarDadosProprietario: '/v1/pessoaCadastro/validarDadosProprietario',
          validarDadosLocatario: '/v1/pessoaCadastro/validarDadosLocatario',
          validarDadosFuncionario: '/v1/pessoaCadastro/validarDadosFuncionario',
          validarDadosProcurador: '/v1/pessoaCadastro/validarDadosProcurador',
          validarGestor: '/v1/pessoaCadastro/validarDadosGestor',
          validarDadosCondomino: '/v1/pessoaCadastro/validarDadosCondomino',
          enviaEmailConfirmacao: '/v1/pessoaCadastro/enviaEmailConfirmacao'
        },
        configuracaoReservas: {
          cadastrarConfiguracao: '/v1/espacoReservaConfiguracao/apsa',
          editarConfiguracao: '/v1/espacoReservaConfiguracao',
          listarHistorico: '/v1/espacoReservaConfiguracao/apsa',
          excluirConfiguracao: '/v1/espacoReservaConfiguracao/apsa',
        },
        configuracaoMudanca: {
          listarBlocos: '/v1/mudancaReservaConfiguracao/listarBlocos',
          cadastrarConfiguracao: '/v1/mudancaReservaConfiguracao/apsa',
          editarConfiguracao: '/v1/mudancaReservaConfiguracao',
          getConfiguracao: '/v1/mudancaReservaConfiguracao/apsa',
          cadastraTermo: '/v1/mudancaReservaConfiguracao/cadastraTermo',
          getTermoMudanca: '/v1/mudancaReservaConfiguracao',
        },
        optinsUsuario: {
          listarCondominiosUnidades: '/v1/optinsUsuario/listarCondominiosUnidades',
          cadastrarEditarAdesaoUnidade: '/v1/optinsUsuario/CadastrarEditarAdesaoUnidade',
          listarImoveisLocatario: '/v1/optinsUsuario/listarImoveisLocatario',
          cadastrarAssinaturaDigital: '/v1/optinsUsuario/CadastroAssinaturaDigital',
          getPoliticaPrivacidade: '/v1/optinsUsuario/getPoliticaPrivacidade',
          getTermosCondicoesUso: '/v1/optinsUsuario/getTermosCondicoesUso',
          cadastrarAdesaoIptu: '/v1/optinsUsuario/cadastrarFormaPagamentoIptu',
          escolhaFormaPagamentoIptu: '/v1/optinsUsuario/getEscolhaFormaPagamentoIptu',
        },
        usuario: {
          cadastrarOuEditarFotoPessoa: '/v1/pessoaCadastro/cadastrarEditarFoto',
          excluirFoto: '/v1/pessoaCadastro/excluirFoto',
          exclusaoContaUsuario: '/v1/pessoaCadastro/exclusaoContaUsuario'
        },
        emissaoBoleto: {
          emissaoBoleto: '/v1/emissaoBoleto/getBoleto',
          emissaoBoletos: '/v1/centralatendimento/downloadPacBoletosProcurador',
          emissaoBoletoProcurador: '/v1/emissaoBoleto/getBoletoProcurador',
        },
        minhasSolicitacoes: {
          getNaturezas: '/v1/minhasSolicitacoes/getNaturezas',
          minhasSolicitacoes: '/v1/minhasSolicitacoes',
          getDocumentoModeloDeHorasExtras: '/v1/funcionario/getDocModeloHorasExtras',
        },
        bloqueioFuncionalidade: {
          listarBloqueios: '/v1/bloqueioFuncionalidade',
          deletarBloqueios: '/v1/bloqueioFuncionalidade',
          cadastrarBloqueios: '/v1/bloqueioFuncionalidade',
          getFuncionalidades: '/v1/bloqueioFuncionalidade/getFuncionalidades',
        },
        relatoriosGerenciais: {
          relatoriosPrime: '/v1/relatoriosPrime',
        },
        perguntaResposta: {
          getPerguntasRespostas: '/v1/perguntaResposta/listarPerguntasRespostas',
          getCategorias: '/v1/perguntaResposta/getCategorias'
        },
        processosJuridicos: {
          getListaProcessos: '/v1/processosJuridicos/getListaProcessos',
          getSolicitacaoAndamentoCJR: '/v1/processosJuridicos/getSolicitacaoAndamentoCJR',
          enviarEmailSolicitacao: '/v1/processosJuridicos/enviarEmailSolicitacao',
        },
        contratos: {
          buscarContratos: '/v1/contrato/buscarContratos',
          buscarContratoPorUid: '/v1/contrato/buscarContratoPorUid',
          getListaServicos: '/v1/contrato/getListaServicos',
          cadastrarContrato: '/v1/contrato/cadastrarContrato',
          alterarCadastro: '/v1/contrato/alterarCadastro',
          deletarCadastro: '/v1/contrato',
        },
        prestacaoContas: {
          getPeriodoDisponivel: '/v1/prestacaoContas/getPeriodoDisponivel',
          getPrestacaoContaAnalitica: '/v1/prestacaoContas/getPrestacaoContaAnalitica',
          getPrestacaoContasSintetica: '/v1/prestacaoContas/getPrestacaoContasSintetica',
          getPrestacaoContasDetalhada: '/v1/prestacaoContas/getPrestacaoContasDetalhada',
          getUrlDownloadPdfSinteticaDetalhada: '/v1/prestacaoContas/getUrlDownloadPdfSinteticaDetalhada',
          getUrlDownloadPdfAnalitica: '/v1/prestacaoContas/getUrlDownloadPdfAnalitica',
          getUrlDownloadExcelSinteticaDetalhada: '/v1/prestacaoContas/getUrlDownloadExcelSinteticaDetalhada',
          getUrlDownloadExcelAnalitica: '/v1/prestacaoContas/getUrlDownloadExcelAnalitica',
          getDetalhamentoPrestContas: '/v1/prestacaoContas/getDetalhamentoPrestContas'
        },
        documentosFiscais: {
          getDocumentosFiscais: '/v1/condominio/getDocumentosFiscais',
        },
        correio:{
          correio: '/v1/correio',
          correspondencia:'/v1/correspondencia',
          getCorreioPorNome: '/v1/correio/GetByName',
          getTipoDeMensagensCorreio:'/v1/correio/getTiposMensagemCorreio',
          getCorrespondenciaPorAlerta:'/v1/correspondencia/BuscarPorAlerta',
          getCorrespondenciaPorId:'/v1/correspondencia/buscarPorId/',
          getBlocos:'/v1/dadosCondomino/getUnidadesCondominio'
        },
        arquivoDigital:{
          getListaArquivosDigitais: '/v1/arquivoDigital/getListaArquivosDigitais',
          getArquivo: '/v1/arquivoDigital/getArquivo'
        },
        envioPagamentos:{
          setMensagemConsultor:'/v1/condomino/FaleComConsultorViaEmail'
        },
        morador: {
          cadastraMorador: '/v1/morador/cadastrarConvencional',
          getDadosMoradorConv: '/v1/morador',
          deletaMoradorConv: '/v1/morador/Delete',
          getDadosMoradorPorCpf: '/v1/morador/BuscarMoradorPorCPF'
        },
        colaborador: '/v1/colaborador',
        acessoRapido: {
          historico: '/v1/acesso-rapido/historico',
          favoritos: '/v1/acesso-rapido/favoritos'
        }
      }

    }
  },
  apiPat: {
    hostPat: 'https://apsaapipat.hmg.marlin.com.br/api',
    endpoints: {
      methods: {
        patGenerica: '/v1/pat/generica',
      },
    },
  },
  apiContratacao: {
    host: 'https://cndcontratacaoapi.hmg.marlin.com.br/api',
    acesso: {
      login: 'condominiodigital@apsa',
      senha: '83892d5910ea'
    },
    endpoints: {
      token: '/Login',
      methods: {
        clientes: {
          getClientes: '/UsuarioImplantacao',
          putCliente: '/UsuarioImplantacao',
        }
      },
    }
  },
  apiMonitoramentoLog:{
    host:'https://apilogmonitoramentorobos.hmg.marlin.com.br/api',
    endpoints:{
      methods:{
        listarRoboSemPaginacao:'/Robo/ListarRoboSemPaginacao',
        listarFuncionalidadeRobo:'/Funcao/BuscarTodasFuncoesRobo/',
        listarResultadosPaginados:'/Log/BuscaSimplesPaginada',
      }
    }
  },
  apiGestaoPagamentos:{
    host:"https://gestaopagamentos-api.hmg.marlin.com.br/api",
    endpoints:{
      methods:{
        getTitulos:'/v1/Pagamentos/',
        cancelarTitulo:'/v1/Pagamentos/cancelarPagamento/'
      }
    }
  },
  config: {
    IdOrigemLead: 1,
    IdAcaoMarketing: 1,
    idAnalytics: 'UA-21747079-1',
    googleMapsApiKey: 'AIzaSyB01CSo75EWpUxtkXLQ07QIFEFYQZGsOvM',
    onesignalId: '023495e3-7083-4e0d-a692-231a5aee018e',
    googleProjectId: '741725346473',
    googleStartTrackeId: 'UA-155545649-1',
  },
  routes: {
    loginRoute: '/login',
    homeRoute: '/home',
    menuRoute: '/menu',
    pagamentoRoute: '/pagamentos'
    // loginRoute: '/digital/login',
    // homeRoute: '/digital/home',
    // menuRoute: '/digital/menu',
    // pagamentoRoute: '/digital/pagamentos'
  }
};

