<div class="Seguros">
  <div class="Seguros-header">
    <div class="logo-seguradora">
      <img
        src="assets/img/seguros_schatz.png"
        [ngClass]="{'__should-apply-white-filter': (themeService.getActiveThemeName() | async) === 'Theme_Dark'}"
      >
    </div>
  
    <p class="header-text">
      Olá {{this.nomeUsuario}}, a corretora de Seguros Schatz tem mais de 50 anos de tradição e soluções completas para condomínios, imóveis e clientes individuais.
      <br><br>
      Conheça nossos seguros sob medida para o que você precisa!
    </p>
  </div>

  <div class="Seguros-painel">
    <ng-container *ngFor="let item of tipoSeguros">
      <div>
        <div 
        class="seguros-card"
        (click)="acaoClicar(item.title, formArea)"
        [ngClass]="{'__is-open': tipoSelecionado?.icon === item.icon && (abreFormProp || abreFormSeguro || abreFormPet) , '__em-breve':item.breve===true}" 
        *ngIf="item.ativo">
        
        <!-- <img [attr.stroke]=" (themeService.getActiveThemeName() | async) === 'Theme_Dark' ? '#5590CF' : '#ff6e0f'" [ngClass]="{'iconePequeno':item.breve}" [src]="'/assets/svg/icon-' + item.icon + '.svg'" class="seguros-card-img" 
        *ngIf="item.title !=='Conteúdo' && item.title !=='Plano de saúde', else imagensSelecao"> -->
        <img [ngClass]="{'iconePequeno':item.breve}" [src]="getImgSeguros(item.icon)" class="seguros-card-img" 
        *ngIf="item.title !=='Conteúdo' && item.title !=='Plano de saúde' && item.title !=='Pet', else imagensSelecao">
  
        <ng-template #imagensSelecao>
          <img [src]="getImgSeguros(item.icon)" class="seguros-card-img"  
          *ngIf="(abreFormProp === false && item.title ==='Conteúdo') || (abreFormSeguro === false && item.title ==='Plano de saúde') || (abreFormPet === false && item.title ==='Pet')">
          <img [src]="'assets/svg/icon-' + item.icon + '-open.svg'" class="seguros-card-img"  
          *ngIf="(abreFormProp === true && item.title ==='Conteúdo') || (abreFormSeguro === true && item.title ==='Plano de saúde') || (abreFormPet === true && item.title ==='Pet')">
          <!-- <img [src]="'/assets/svg/icon-' + item.icon + '.svg'" class="seguros-card-img"  
          *ngIf="(abreFormProp === false && item.title ==='Conteúdo') || (abreFormSeguro === false && item.title ==='Plano de saúde')">
          <img [src]="'/assets/svg/icon-' + item.icon + '-open.svg'" class="seguros-card-img"  
          *ngIf="(abreFormProp === true && item.title ==='Conteúdo') || (abreFormSeguro === true && item.title ==='Plano de saúde')"> -->
        </ng-template>
  
          <p class="seguro-label" [ngClass]="{'emBreve_label':item.breve}">{{item.title}}</p>
        <div class="seguro-arrow"  *ngIf="item.title ==='Conteúdo' || item.title ==='Plano de saúde' || item.title ==='Pet'">
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="emBreve"
         *ngIf="item.breve" >
          <p class="breve">Em breve</p>
        </div>
      </div>
    </div>

    </ng-container>
  </div>
  <div #formArea >

    <div class="Seguros-forms" *ngIf="abreFormSeguro === true || abreFormPet === true">
      <form [formGroup]="form" *ngIf="abreFormSeguro === true"  >
        <div class="header-form" #saude >
          <img [src]="srcImagemSeguro" alt="imagem do seguro"/>
          <p class="title">Plano de saúde</p>
          <p class="description">{{ tipoSelecionado.texto }}</p>
          <p class="description">Complete nosso formulário e receba um plano de saúde feito sob medida para você e sua família. <br> Não espere, comece a investir na sua saúde hoje mesmo!</p>
        </div>
        <div class="FormSeguro">
          <div class="FormGroup">
            <div class="FormGroup_Label">Nome:</div>
            <div class="FormGroup_Input">
              <input 
                required
                type="text"
                formControlName="nome"
                id="nome" 
              >
            </div>
              <p class="Erro" *ngIf="form.get('nome').hasError('required') && form.get('nome').touched">Este campo é obrigatório</p>
              <p class="Erro" *ngIf="form.get('nome').hasError('minlength')">Quantidade mínima de 10 caracteres</p>
              <p class="Erro" *ngIf="form.get('nome').hasError('pattern')">Nome inválido.</p>
              
          </div>
      
          <div class="FormGroup" [ngClass]="setClassError('dataNascimento')">
            <div class="FormGroup_Label">Data de Nascimento:</div>
            <div class="FormGroup_Input">
              <input 
                required
                formControlName="dataNascimento"
                id="dataNascimento"
                type="date"
                max="9999-12-31"
              >
            </div>
            <p class="Erro"  *ngIf="form.get('dataNascimento').hasError('required') && form.get('dataNascimento').touched">Este campo é obrigatório</p>
            <p class="Erro"  *ngIf="form.get('dataNascimento').hasError('dataInvalida') && form.get('dataNascimento').touched">Data de Nascimento Inválida</p>
          </div>
      
          <div class="FormGroup" [ngClass]="setClassError('cnpj')">
            <div class="FormGroup_Label">CNPJ do condomínio:</div>
            <div class="FormGroup_Input">
              <input 
                required
                formControlName="cnpj"
                id="cnpj"
                type="text"
                placeholder="Digite o CNPJ do condomínio" 
                (keyup)="formatarCNPJ()"
                [brmasker]="{mask:'00.000.000/0000-00', len:18}"
                
              >
            </div>
            <p class="Erro" *ngIf="form.get('cnpj').hasError('required') && form.get('cnpj').touched">Este campo é obrigatório</p>
            <p class="Erro" *ngIf="form.get('cnpj').hasError('minlength') || form.get('cnpj').hasError('pattern')">CNPJ inválido</p>
            <!-- <p class="Erro" *ngIf="form.get('nome').hasError('pattern')">Nome inválido.</p> -->
  
          </div>
      
          <div class="FormGroup" [ngClass]="setClassError('qntVidas')">
            <div class="FormGroup_Label">Quantidade de Vidas:*</div>
            <div class="FormGroup_Input">
              <input 
                required
                formControlName="qntVidas"
                id="qntVidas"
                type="number"
                min="0"
              >
            </div>
            <p class="Erro"  *ngIf="form.get('qntVidas').hasError('required') && form.get('qntVidas').touched">Este campo é obrigatório</p>
            <p class="Erro"  *ngIf="form.get('qntVidas').hasError('min') && form.get('qntVidas').touched">Quantidade mínima de 2 vidas</p>
          </div>
  
          <div class="FormGroup" [ngClass]="setClassError('telefone')">
            <div class="FormGroup_Label">Telefone para contato:</div>
            <div class="FormGroup_Input">
              <input 
                type="text"
                name="telefone" 
                formControlName="telefone" 
                placeholder="Digite o seu melhor número"
                [brmasker]="{phone: true}"
              >
              <p class="Erro"  *ngIf="(form.get('telefone').hasError('required') || form.get('telefone').hasError('pattern')) && form.get('telefone').touched">Telefone inválido</p>
            </div>
          </div>
          
          <div class="FormGroup" [ngClass]="setClassError('email')">
            <div class="FormGroup_Label">E-mail:</div>
            <div class="FormGroup_Input">
              <input 
                type="text"
                name="email" 
                formControlName="email" 
                placeholder="Digite o seu melhor e-mail"
                >
                <!-- pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" -->
              <p class="Erro"  *ngIf="form.get('email').hasError('required') && form.get('email').touched">E-mail obrigatório</p>
              <p class="Erro"  *ngIf="(form.get('email').hasError('email') || form.get('email').hasError('pattern')) && form.get('email').touched">E-mail inválido</p>
            </div>
          </div>
        </div>
  
        <div class="FormGroup">
          <div class="buttonArea">
            
            <ds-button
              [full]="true"
              (click)="enviarDadosSaude()"
              [inactive]="form.invalid || isSubmitting"
            >
              Enviar
            </ds-button>
            <button class="Button Button_Outline Button_Solid mobileOnly" (click)="abrirFormSeguro()">
              <div class="Button_Content">
                <div class="Button_Content_Label">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M5.84375 11.145L12.4844 17.7856C12.9434 18.2446 13.6855 18.2446 14.1396 17.7856L15.2432 16.6821C15.7021 16.2231 15.7021 15.481 15.2432 15.0269L10.5361 10.3198L15.2432 5.61279C15.7021 5.15381 15.7021 4.41162 15.2432 3.95752L14.1445 2.84424C13.6855 2.38525 12.9434 2.38525 12.4893 2.84424L5.84863 9.48486C5.38477 9.94385 5.38477 10.686 5.84375 11.145Z" fill="#FF6E0F"/>
                  </svg>
                  Escolher outro seguro
                </div>
              </div>
            </button>
          </div>
        </div>
      </form>
  
      <form [formGroup]="formPet" *ngIf="abreFormPet === true">
        <div class="header-form" #pet >
          <img [src]="srcImagemSeguro" alt="imagem do seguro"/>
          <p class="title">Pet</p>
          <p class="description">{{ tipoSelecionado.texto }}</p>
          <p class="description">Complete nosso formulário e receba um plano de saúde feito sob medida para o seu pet. <br> Não espere, comece a investir na saúde do seu animalzinho hoje mesmo!</p>
        </div>
        <div class="FormSeguro __is-pet">
          <div class="FormGroup nome">
            <div class="FormGroup_Label">Nome do tutor:</div>
            <div class="FormGroup_Input">
              <input 
                required
                type="text"
                formControlName="nome"
                id="nome" 
              >
            </div>
              <p class="Erro" *ngIf="formPet.get('nome').hasError('required') && formPet.get('nome').touched">Este campo é obrigatório</p>
              <p class="Erro" *ngIf="formPet.get('nome').hasError('minlength')">Quantidade mínima de 10 caracteres</p>
              <p class="Erro" *ngIf="formPet.get('nome').hasError('pattern')">Nome inválido.</p>
              
          </div>
      
          <div class="FormGroup telefone" [ngClass]="setClassError('telefone')">
            <div class="FormGroup_Label">Telefone para contato:</div>
            <div class="FormGroup_Input">
              <input 
                type="text"
                name="telefone" 
                formControlName="telefone" 
                placeholder="Digite o seu melhor número"
                [brmasker]="{phone: true}"
              >
              <p class="Erro"  *ngIf="(formPet.get('telefone').hasError('required') || formPet.get('telefone').hasError('pattern')) && formPet.get('telefone').touched">Telefone inválido</p>
            </div>
          </div>
          
          <div class="FormGroup email" [ngClass]="setClassError('email')">
            <div class="FormGroup_Label">E-mail:</div>
            <div class="FormGroup_Input">
              <input 
              type="text"
                name="email" 
                formControlName="email" 
                placeholder="Digite o seu melhor e-mail"
                >
                <!-- pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" -->
              <p class="Erro"  *ngIf="formPet.get('email').hasError('required') && formPet.get('email').touched">E-mail obrigatório</p>
              <p class="Erro"  *ngIf="(formPet.get('email').hasError('email') || formPet.get('email').hasError('pattern')) && formPet.get('email').touched">E-mail inválido</p>
            </div>
          </div>
  
          <div class="FormGroup area-planos" [ngClass]="setClassError('email')">
            <div class="FormGroup_Label">Escolha o plano que mais de adequa a necessidade do seu pet:</div>
            <div class="FormGroup_Input">
              <div class="planos">
                <ng-container *ngFor="let plano of planosPet;let i = index" >
                  <div class="plano" [ngClass]="{'__is-selected': this.formPet.get('plano').value == i + 1, '__custom-flex': !plano.inclui}" >
                    <p class="header">{{ plano.titulo }}</p>
  
                    <div class="plano-incluido" *ngIf="plano.inclui" >
                      <div class="area-descricao">
                        <i class="fas fa-paw"></i>
                        <p>Coberturas do plano {{ plano.inclui }}</p>
                      </div>
                      <i class="fas fa-plus"></i>
                    </div>
    
                    <ul>
                      <li *ngFor="let item of plano.itens" >
                        <i class="fas fa-paw"></i>
                        <p>{{ item }}</p>
                      </li>
                    </ul>
                    
                    <div class="price-check-area">
                      <div class="price">R$ {{ plano.preco }}</div>
                      
                      <div class="check-area" (click)="setPlanoPet(i + 1)" >
                        <input type="radio" [value]="(i + 1).toString()" name="plano" formControlName="plano" id="plano">
                        <p>Selecionar</p>
                      </div>
                    </div>
                    
                  </div>
                </ng-container>
                
              </div>
            </div>
          </div>
          
        </div>
  
        <div class="FormGroup">
          <div class="buttonArea">
            
            <ds-button
              [full]="true"
              (click)="enviarDadosPet()"
              [inactive]="formPet.invalid || isSubmitting"
            >
              Enviar
            </ds-button>
            <button class="Button Button_Outline Button_Solid mobileOnly" (click)="abrirFormPet()">
              <div class="Button_Content">
                <div class="Button_Content_Label">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M5.84375 11.145L12.4844 17.7856C12.9434 18.2446 13.6855 18.2446 14.1396 17.7856L15.2432 16.6821C15.7021 16.2231 15.7021 15.481 15.2432 15.0269L10.5361 10.3198L15.2432 5.61279C15.7021 5.15381 15.7021 4.41162 15.2432 3.95752L14.1445 2.84424C13.6855 2.38525 12.9434 2.38525 12.4893 2.84424L5.84863 9.48486C5.38477 9.94385 5.38477 10.686 5.84375 11.145Z" fill="#FF6E0F"/>
                  </svg>
                  Escolher outro seguro
                </div>
              </div>
            </button>
          </div>
        </div>
      </form>
  
    </div>
  </div>
</div>


<!-- modal -->
<modal-seleao-condominio *ngIf="abreFormProp === true"
[detalhes]="tipoSelecionado"
[formProp]="formProp"
[condominioUnidade]="unidades"
(abriModal)="abrirFormConteudo()"
(enviar)="enviarPropriedade()"
></modal-seleao-condominio>