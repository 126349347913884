import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { StateService } from 'src/app/shared/services/stateService';
import { LightBoxesService } from '../lightboxes.services';

interface IOpenObjectArgument {
  title: string;
  textAreaDefaultValue: string;
  text: string;
}

@Component({
  selector: 'app-text-link-options',
  templateUrl: './text-link-options.component.html',
  styleUrls: ['./text-link-options.component.scss'],
})
export class TextLinkOptionsComponent implements OnInit {

  @Input() id: string;
  @Input() className: string;


  private element: any;
  // private promisse: Promise<boolean>;
  private resolve;
  public buttonTxt1: string = ''
  public buttonTxt2: string = ''
  private externalUrl: string = '';
  
  constructor(private service: LightBoxesService, private el: ElementRef, private stateService: StateService) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.recusar();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    // TODO: Retirado para não bugar
    // this.service.remove(this.id);
    this.element.remove();
  }

  open(mensagem: { title: string, subtitle: string, link: string, buttonTxt1?: string,  buttonTxt2?: string }): Promise<boolean> {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('title')[0].innerHTML = mensagem.title;
    this.element.getElementsByClassName('subtitle')[0].innerHTML = mensagem.subtitle;
    this.element.getElementsByClassName('recusar')[0].innerHTML = mensagem.buttonTxt1;
    this.element.getElementsByClassName('confirmar')[0].innerHTML = mensagem.buttonTxt2;

    if(mensagem.buttonTxt1 && mensagem.buttonTxt2) {
      this.buttonTxt1 = mensagem.buttonTxt1
      this.buttonTxt2 = mensagem.buttonTxt2
    }

    this.externalUrl = mensagem.link;

    // if(mensagem.link) {
    //   this.element.querySelector('.Popup_Link_Container').style.display = 'flex';
    //   this.element.querySelector('.Popup_Link_Container button').innerHTML = mensagem.link;
    // }
    // else {
    //   this.element.querySelector('.Popup_Link_Container').style.display = 'none';
    // }
    
    return new Promise(resolve => { this.resolve = resolve; });

    // return new Promise(resolve => {
    //   setTimeout(function () {
    //     a.firstChild.classList.remove(n);
    //     //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.remove(this.className);
    //     resolve(false);
    //   }, time);
    // });
  }

  confirmar(): void {
    this.resolve('yes');
    this.element.firstChild.classList.remove(this.className);
  }

  recusar(): void {
    this.resolve('no');
    this.element.firstChild.classList.remove(this.className);
  }

  link(): void {
    this.resolve('link');
    this.element.firstChild.classList.remove(this.className);
  }
}
