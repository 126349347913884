<div class="ListaCondominos_SearchBox_Form">
  <form (ngSubmit)="filter()" [formGroup]="form">
    <div class="SearchBox_Form_Input">
      
      <input
        formControlName="searchTerm"
        type="search"
        [placeholder]="placeholder"
        (keydown.enter)="filter()"
      >

      <div class="Form_Input_Icon">
          <i
            class="fas fa-times"
            (click)="unFilter()"
            [ngClass]="{'__is-active': isFiltered}"
          ></i>
      </div>
    </div>

    <button class="SearchBox_Form_SubmitButton">
      <i class="apsa-icon-solid apsa-icon-solid-magnifying-glass" (click)="filter()"></i>
      <span>Buscar</span>
  </button>
  </form>
</div>