import { Directive, ElementRef } from '@angular/core';
import { timeInterval } from 'rxjs/operators';

@Directive({
  selector: '[passwordToggle]'
})
export class PasswordToggleDirective {

  public shown: boolean = false;
  public shownIcon: string = '<i class="fas fa-eye"></i>';
  public hidenIcon: string = '<i class="fas fa-eye-slash"></i>';
  public divWithIconInside: HTMLElement;
  public inputParent: HTMLElement;

  constructor(
    public elementRef: ElementRef
  ) {
    this.createDivWithIconInside(this.hidenIcon);
    this.styleElements();
    this.bindIconClickListener();
    this.setInputParent();
    this.putDivWithIconInsideInputParent();
  }
  
  createDivWithIconInside(newFontAwesomeIconHMTL: string) {
    this.divWithIconInside = document.createElement('div');
    this.divWithIconInside.innerHTML = newFontAwesomeIconHMTL;
  }

  styleElements() {
    this.styleIconInsideDiv();
    this.styleDivWithIconInside();
    this.styleInput();
  }

  styleInput() {
    this.elementRef.nativeElement.style.paddingRight = '37px';
  }

  styleDivWithIconInside() {
    this.divWithIconInside.style.position = 'absolute';
    this.divWithIconInside.style.right = '12px';
    this.divWithIconInside.style.top = '50%';
    this.divWithIconInside.style.transform = 'translateY(-50%)';
    this.divWithIconInside.style.zIndex = '2';
  }

  styleIconInsideDiv() {
    const icon = this.divWithIconInside.children[0] as HTMLElement;

    icon.style.cursor = 'pointer';
    icon.style.color = '#707070';
  }
    
  bindIconClickListener() {
    this.divWithIconInside.firstChild.addEventListener('click', () => {
      this.toggle();
    });
  }

  setInputParent() {
    this.inputParent = this.elementRef.nativeElement.parentNode;
  }

  putDivWithIconInsideInputParent() {
    this.inputParent.appendChild(this.divWithIconInside);
  }

  changeFontAwesomeIcon(newFontAwesomeIconHMTL: string) {
    this.divWithIconInside.innerHTML = newFontAwesomeIconHMTL;

    this.styleIconInsideDiv();
    this.bindIconClickListener();
  }

  toggle() {
    this.shown = !this.shown;

    if (this.shown) {
      this.elementRef.nativeElement.setAttribute('type', 'text');
      this.changeFontAwesomeIcon(this.shownIcon);
    }
    else {
      this.elementRef.nativeElement.setAttribute('type', 'password');
      this.changeFontAwesomeIcon(this.hidenIcon);
    }
  }
}
