<a
  class="whatsapp-service"
  href="https://api.whatsapp.com/send/?phone=5521967810747&text&app_absent=0"
  target="_blank"
  *ngIf="isVisible"
>
  <i class="fa-brands fa-whatsapp"></i>

  <p>
    Atendimento<br>
    <strong>via WhatsApp</strong>
  </p>
</a>