import { Component, OnInit, Input } from '@angular/core';

interface Infogroup {
  label: any,
  value: any,
  hideIfFalsyValue?: boolean,
  full?: boolean
};

@Component({
  selector: 'ds-infogroup-list',
  templateUrl: './infogroup-list.component.html',
  styleUrls: ['./infogroup-list.component.scss'],
})
export class InfogroupListComponent implements OnInit {
  @Input() list: Infogroup[];

  constructor() { }

  ngOnInit() {}

  isVisible(info: Infogroup): boolean {
    return (info.hideIfFalsyValue && info.value) || (!info.hideIfFalsyValue);
  }
}
