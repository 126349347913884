import { filter, windowCount } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CondominioService } from '../../services/condominio.service';
import { ConstantsService } from '../../services/constants.service';
import { IGetTiposSegurosResponse } from '../../services/DTO/response/IGetTiposSeguroResponse';
import { StateService } from '../../services/stateService';
import { ThemeService } from '../../services/theme.service';
import { Validations } from '../../../shared/utils/validations';
import { LightBoxesService } from '../lightboxes';
import { ResultCondominosListDTO } from 'src/app/modules/condomino/services/DTO/response/ResultCondominosList.DTO';
import { CondominoService } from 'src/app/modules/condomino/services/condomino.service';

interface ISeguros {
  title: string,
  link: string,
  icon: string,
  breve: boolean,
  ativo: boolean,
  imagem: string,
  planos: {titulo: string, inclui: string, itens: string[], preco: string}[],
  texto: string
}
@Component({
  selector: 'app-fale-com-a-seguradora',
  templateUrl: './fale-com-a-seguradora.component.html',
  styleUrls: ['./fale-com-a-seguradora.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FaleComASeguradoraComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public formProp: UntypedFormGroup;
  public formPet: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public imoveis = [];
  public unidadesCondomino = [];
  public nomePropriedades = [];
  public condominioUnidade= [];
  public unidades = []
  public isSubmitting: boolean = false;
  public subscription: Subscription;
  public nomeUsuario = this.stateService.getPessoaLogada() ? this.stateService.getPessoaLogada().Nome : null;
  public abreFormSeguro : boolean = false;
  public abreFormPet : boolean = false;
  public abreFormProp: boolean = false;
  public tipoSeguros : ISeguros[];
  public buscarUnidade;
  public mobileOrMobileWeb: boolean = false;
  public listaPerfis: any[];
  public regexEmail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
  public srcImagemSeguro: string = '';
  public tipoSelecionado: ISeguros;
  public param: string = null;

  public planosPet: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private condominioService: CondominioService,
    private condominoServide: CondominoService,
    private lightboxesService: LightBoxesService,
    public constants: ConstantsService,
    public themeService: ThemeService,
    public activedRoute: ActivatedRoute,
    public stateService: StateService,
    private lightBoxesService: LightBoxesService,
    private router: Router
  ) {


    activedRoute.params.subscribe(val => {
      this.param = val.menu;
      this.unidades = []
      this.condominioUnidade = []
      this.getTipoSeguros()
      this.nomeUsuario = this.stateService.getPessoaLogada() ? this.stateService.getPessoaLogada().Nome : null;
          
      this.isSubmitted = false;
      this.createForms()

      if(this.stateService.platformIsDevice() || this.stateService.platformIsMobileWeb()) { this.mobileOrMobileWeb =  true;}
      this.listaPerfis = this.stateService.obterPerfisEmTodosOsCondominiosEImoveis().map(perfil => {
        return perfil.idPerfil
      })
      // console.log('obterperfil:',this.listaPerfis)

      
      //regras para condominos
      if (this.listaPerfis.indexOf(5) != -1) {
        // console.log('possuiPerfilCondomino')
        

        this.imoveis = this.stateService.getListaCondominiosImoveis().condominios;
        // console.log('imoveis:',this.imoveis)

        this.nomePropriedades = this.imoveis.map(({ condominio }) => condominio.nome);

        this.stateService.listarCondominiosUnidades().subscribe(
          (item) => {
            if (item) {
              item.forEach(informacoes => {
                let imovelPool = this.imoveis.filter(imovel => {
                  return imovel.condominio.contaEmpresa === informacoes.contaEmpresaCondominio && imovel.condominio.agencia === informacoes.agenciaCondominio
                }).map(unidade => {
                  // console.log('unidade',  unidade)
                  return {'ehContaPool': unidade.condominio.ehContaPool}
                })
                // console.log('imovel pool', informacoes)
                informacoes.unidades.forEach(unidade => {
                  let condominio = {
                    condominio: informacoes.nomeCondominio ,
                    nomeExibicao:informacoes.nomeCondominio + ' - ' + unidade.descricaoUnidade,
                    unidade: unidade,
                    agencia: informacoes.agenciaCondominio,
                    conta: informacoes.contaEmpresaCondominio,
                    subDivisao: unidade.idSubdivisao,
                    idUnidade:unidade.idUnidade,
                    nomeUnidade:unidade.descricaoUnidade,
                    codUnidade:unidade.idUnidade,
                    ehPool: imovelPool,
                    codWeb:informacoes.contaCondominio
                  }
                  
                  this.condominioUnidade.push(condominio)
                });
              });

              this.unidades = this.condominioUnidade.map( ({nomeExibicao}) => nomeExibicao).sort((a, b) => (a.toUpperCase() > b.toUpperCase()) ? 1 : -1)
                // console.log('unidades', this.unidades)
              this.unidades.push('Outro Imóvel')
              this.unidades = [...new Set(this.unidades)];
              
            }
          },
          (err) => {
            if (err) {
              // console.log(err, 'erro');
            }
          }
        );
        
      }
      //regras pra locatário
      if (this.listaPerfis.indexOf(2) != -1) {
        // console.log('possuiPerfilLocatario')
        let unidadesMapeadas = this.stateService.getListaCondominiosImoveis()
        // console.log('lista imóveis', unidadesMapeadas)
        this.condominioUnidade = unidadesMapeadas.imoveisLocatario.map(res => {
          let item = {
            nomeExibicao:res.imovel.enderecoimovel,
            codImovel:res.imovel.codigoImovelPrinipal,
          }
          return item
        })

        this.unidades = this.condominioUnidade.map(unidade => {
          return unidade.nomeExibicao
        }).sort((a, b) => (a.toUpperCase() > b.toUpperCase()) ? 1 : -1)
        this.unidades.push('Outro Imóvel')
      }
      

      if(val !== null || val !== undefined){
        // console.log('val', val)
        if(val.menu === 'conteudo') this.abrirFormConteudo()
        if(val.menu === 'saude') this.abrirFormSeguro()
        if(val.menu === 'pet') this.abrirFormPet()
        
      }
      // this.ngOnInit();
    });
  }
  
  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
  }

  getTipoSeguros(){
    this.condominioService.getSeguros().subscribe(res =>{
      this.tipoSeguros = res;
      let planoPet = this.tipoSeguros.find(item => item.icon === 'pet');
      this.planosPet = planoPet.planos;
      if(this.param) {
        this.tipoSelecionado = this.tipoSeguros?.find(item => item.icon === this.param);
        this.srcImagemSeguro = this.tipoSelecionado?.imagem;
      }

    })
  }

  createForms(){

    const noWhiteSpaceAtBeginnigAndEnd = /^[^\s]+(\s+[^\s]+)*$/;
    // const apenasLetras = /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/
    const apenasLetras = /^([a-zA-Zà-úÀ-Ú]|-|_|\s)+$/
    const regexTelefone = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:[0-9]\d|[2-9])\d{3})\-?(\d{4}))$/;
    const cnpjRegex = /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/
    
    this.form = this.fb.group({
      nome: ['', [Validators.required, Validators.minLength(10), Validators.pattern(noWhiteSpaceAtBeginnigAndEnd), Validators.pattern(apenasLetras)]],
      dataNascimento: ['', [Validators.required, this.validarDataNascimento]],
      cnpj: ['', [Validators.required, Validators.minLength(18), Validators.pattern(cnpjRegex)]],
      qntVidas: ['', [Validators.required, Validators.min(2)]],
      telefone:['',[Validators.required,this.isPhoneNumberValid, Validators.pattern(regexTelefone)]],
      email:['',[Validators.required,Validators.email, Validators.pattern(this.regexEmail)]]
    });

    this.formProp = this.fb.group({
      propriedade: ['', Validators.required],
    });

    this.formPet = this.fb.group({
      nome: ['', [Validators.required, Validators.minLength(10), Validators.pattern(noWhiteSpaceAtBeginnigAndEnd), Validators.pattern(apenasLetras)]],
      telefone:['',[Validators.required,this.isPhoneNumberValid, Validators.pattern(regexTelefone)]],
      email:['',[Validators.required,Validators.email, Validators.pattern(this.regexEmail)]],
      plano: ['', [Validators.required]]
    })
  }
  
  get formControls() {
    return this.form.controls;
  }

  setClassError(input: string) {
    return {
      '__is-error': this.isSubmitted && this.form.get(input).invalid
    };
  }

  abrirForms(tipo:string){
    if(tipo === 'Conteúdo') this.abrirFormConteudo()
    if(tipo === 'Plano de saúde') this.abrirFormSeguro()
  }

  acaoClicar(tipo:string, formArea: HTMLElement){
    let tipoSelecionado = this.tipoSeguros.find(item => item.title === tipo);
    if(tipoSelecionado) {
      this.tipoSelecionado = tipoSelecionado;
      this.srcImagemSeguro = tipoSelecionado.imagem;
    }
    
    if(this.tipoSelecionado.icon === 'outros-seguros') {
      this.cadastrarCliqueSchatz('Outros-seguros');
      this.stateService.openExternalLink(this.tipoSelecionado.link);
      return;
    }

    if(this.tipoSelecionado && (this.tipoSelecionado.title !== 'Plano de saúde'  && tipoSelecionado.title !== 'Pet')) {
      this.abrirFormConteudo();
      return;
    }


    switch(tipo){
      case 'Plano de saúde': 
        this.abrirFormSeguro();
        window.innerWidth > 800 ? setTimeout(() => formArea.scrollIntoView({ behavior: 'smooth', block: 'start' }), 200) : document.querySelector('app-index').scrollTo(0,0);
        
        break;
        case 'Pet': 
        this.abrirFormPet();
        window.innerWidth > 800 ? setTimeout(() => formArea.scrollIntoView({ behavior: 'smooth', block: 'start' }), 200) : document.querySelector('app-index').scrollTo(0,0);
        break;
    }
  }

  selecionarItem(item){
    if(item !== null && item !== undefined){
      this.formProp.get('propriedade').setValue(item)
    }
  }

  abrirFormSeguro(){
    //evitando que dois forms abram junto
    if(this.abreFormProp === true){
      this.abreFormProp = false;
    }

    if(this.abreFormPet === true) this.abreFormPet = false;
    
    this.abreFormSeguro = !this.abreFormSeguro
    // console.log('scroll',document.querySelector('app-index'))
    if(this.mobileOrMobileWeb)
      document.querySelector('app-index').scrollTo(0,0)

    // if(this.abreFormSeguro) this.scroll('saude');
  }

  abrirFormConteudo(){
    //evitando que dois forms abram juntos
    if(this.listaPerfis.indexOf(2) !== -1 || this.listaPerfis.indexOf(5) !== -1){
      if(this.abreFormSeguro === true){
        this.abreFormSeguro = false;
      }

      if(this.abreFormPet === true) this.abreFormPet = false;

      this.abreFormProp = !this.abreFormProp
    }else{
      this.stateService.openExternalLink('https://schatzcorretora.aggilizador.com.br/residence')
    }
    // window.scrollTo(0, 0)
    if(this.mobileOrMobileWeb)
      document.querySelector('app-index').scrollTo(0,0)

  }

  abrirFormPet(){
    //evitando que outros forms abram junto
    if(this.abreFormProp === true){
      this.abreFormProp = false;
    }

    if(this.abreFormSeguro === true){
      this.abreFormSeguro = false;
    }

    this.abreFormPet = !this.abreFormPet

    // if(this.abreFormPet) this.scroll('pet');
  }
  
  enviarPropriedade(){
    if (this.formProp.invalid) {
      Validations.markFormGroupTouched(this.formProp);
      return;
    }
    // console.log('condominio', this.condominioUnidade)
    let unidade = this.condominioUnidade.filter(unidade => {
      return unidade.nomeExibicao === this.formProp.get('propriedade').value
    })[0]

    if(this.formProp.get('propriedade').value === 'Outro Imóvel' || this.listaPerfis.indexOf(5) !== -1 && unidade.ehPool == false ){
      this.stateService.openExternalLink('https://schatzcorretora.aggilizador.com.br/residence')
      this.cadastrarCliqueSchatz();
      this.abreFormProp = !this.abreFormProp;
      this.formProp.reset()
    }else{

      let pessoaLogada = this.stateService.getPessoaLogada()
      this.abreFormProp = !this.abreFormProp
      const dadosFormPropriedade= {
        // propriedade: this.formProp.get('propriedade').value,
        idCampanha: 20,
        nomeCondominio: unidade.condominio || '',
        agência: unidade.agencia || 0,
        conta: unidade.conta || 0,
        codUnidade:this.listaPerfis.indexOf(2) == -1 ? unidade.codUnidade : 0,
        codWeb:this.listaPerfis.indexOf(2) == -1 ? unidade.codWeb : 0,
        nomeCliente: pessoaLogada.Nome || '',
        idCliente: pessoaLogada.Id || 0,
        cpfCnpj: pessoaLogada.CpfCnpj || '', 
        email: pessoaLogada.Email || '',
        endereco: unidade.nomeExibicao,
        sqsubd:unidade.subDivisao || 0,
        squnid:unidade.idUnidade || 0,
        codigoImovel: this.listaPerfis.indexOf(2) != -1 ? unidade.codImovel : 0 , //locatario
      }

      // console.log("dados", dadosFormPropriedade)
  
      this.condominioService.cadastrarPessoaSeguroConteudo(dadosFormPropriedade).subscribe(
        (response) => {
          // console.log('response', response)
          this.cadastrarCliqueSchatz();
          if(response.indexOf('http') != -1){
            
            window.open(response, '_blank');
            // this.lightBoxesService.openOkPopup('Sucesso', 'Sua solicitação foi enviada para corretora, em breve entraremos em contato.')
            this.lightboxesService.openOkPopup('Seguro Conteúdo Schatz', 'Acesse nosso hotsite para mais informações e contratação do seguro conteúdo. <br><br><br><bv></bv> <a href="'+ response +'" target="_blank" style="text-decoration: none;"><button _ngcontent-dgg-c142="" class="Button Button_Primary Button_Solid __is-full"><div _ngcontent-dgg-c142="" class="Button_Content"><div _ngcontent-dgg-c142="" class="Button_Content_Label">Acessar detalhes </div></div></button></a>',60000, true);
          }else{

            this.lightBoxesService.openOkPopup('Atenção', response)
          }
          this.abrirFormConteudo();
          this.formProp.reset();
        },
        (error:any) => {
          // console.log(error)
          this.lightBoxesService.openOkPopup('Erro', 'Sua solicitação não foi enviada')
        }
      );
    }

  }

  enviarDadosSaude(){
    if (this.form.invalid) {
      Validations.markFormGroupTouched(this.form);
      return;
    }
    else{
      const pessoaLogada = this.stateService.getPessoaLogada();
      const dataNascimento = this.form.get('dataNascimento').value;

      const patternFormataData = /(\d{2})\-(\d{2})\-(\d{4})/;


      const dataFormatada = new Date(dataNascimento.replace(patternFormataData, '$3-$2-$1'));

      const dadosFormSaude = {
        idPessoa: parseInt(pessoaLogada.Id),
        dataInclusao: new Date(),
        nome: this.form.get('nome').value,
        dataNascimento: dataFormatada,
        cpfCnpj: this.form.get('cnpj').value,
        qtVidas: this.form.get('qntVidas').value,
        telefone: this.form.get('telefone').value,
        email: this.form.get('email').value,
      }

      this.cadastrarCliqueSchatz('Saude');

      this.condominioService.cadastrarPessoaSeguroSaude(dadosFormSaude).subscribe( {
        next: res => {
          this.lightBoxesService.openOkPopup('Sucesso', 'Sua solicitação foi enviada para corretora, em breve entraremos em contato.')
          this.form.reset()
          this.abrirFormSeguro()
          
        },
        error: err => {
          this.lightBoxesService.openOkPopup('Erro', 'Sua solicitação não foi enviada')
          
        }
      });
    }
  }

  enviarDadosPet(){
    if (this.formPet.invalid) {
      Validations.markFormGroupTouched(this.form);
      return;
    }
    else{
      const pessoaLogada = this.stateService.getPessoaLogada();

      const patternFormataData = /(\d{2})\-(\d{2})\-(\d{4})/;

      const dadosFormPet = {
        // idPessoa: parseInt(pessoaLogada.Id),
        // dataInclusao: new Date(),
        nome: this.formPet.get('nome').value,
        telefone: this.formPet.get('telefone').value,
        email: this.formPet.get('email').value,
        planoEscolhido: this.formPet.get('plano').value
      }

      this.cadastrarCliqueSchatz('Pet');

      this.condominioService.cadastrarPessoaSeguroSaudePet(dadosFormPet).subscribe({
        next: res => {
          this.lightBoxesService.openOkPopup('Sucesso', 'Sua solicitação foi enviada para corretora, em breve entraremos em contato.')
          this.formPet.reset()
          this.abrirFormPet()
          
        }, 
        error: err => {
          this.lightBoxesService.openOkPopup('Erro', 'Sua solicitação não foi enviada')
          
        }
      });
    }
  }

  selecinarMascara(value:string){
    let ehCpf = value.length <= 11;
    return{
      mask: ehCpf ? '000.000.000-00' : '00.000.000/0000-00',
      len: value.length
    }
  }

  validarNome(){
    if (!(this.form.get('nome').value).match(/^(\s)+$/)) {
      this.form.get('nome').value.replace(/^\s+|\s+$/g, '');
  }
  
  }

  formatarCNPJ(){
    if(!(this.form.get('cnpj').value).match(/^(\s)+$/)){
      if(!(this.form.get('cnpj').value.length > 14)){
        this.form.get('cnpj').setValue(
          this.form.get('cnpj').value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
        )
      }
    }
    
  }

  validarDataNascimento(control) {
    const dataNascimento = new Date(control.value);
    const hoje = new Date();
    const anoAtual = hoje.getFullYear();
    const cemAnosAtras = anoAtual - 100;
    const dataCemAnosAtras = new Date(cemAnosAtras, hoje.getMonth(), hoje.getDate());
    const dataMinima = new Date();
    dataMinima.setFullYear(dataMinima.getFullYear() - 18);
  
    if (!(dataCemAnosAtras <= dataNascimento && dataNascimento < hoje)) {
      return { dataInvalida: true };
    }else   if (dataNascimento > dataMinima) {
      return { dataInvalida: true };
    }else{

      return null;
    }

    
  
  }

  isPhoneNumberValid(control: AbstractControl) {
    if(control?.value) {
      if(control.value.length === 11)
        return { invalidPhoneNumber: true };
    }

    return null;
  }

  espacosEmBrancoValidator(control) {
    const trimmedValue = control.value.trim();

    if (!trimmedValue || /\s{2,}/.test(trimmedValue)) {
      return { 'espacosEmBranco': true };
    }

    return null;
  }

  corrigirEspacos() {
    const nomeControl = this.form.get('nome');
    const trimmedValue = nomeControl.value.trim();

    // Remover espaços consecutivos no início e no final da string
    const stringPura = trimmedValue.replace(/^\s+|\s+$/g, '');

    // Permitir um único espaço entre palavras
    const stringTratada = stringPura.replace(/\s{2,}/g, ' ');

    // Atualizar o valor no controle
    nomeControl.setValue(stringTratada);
  }

  getImgSeguros(icon:string){
    let tema = this.themeService.getActiveThemeName().value

    if(tema === 'Theme_Default' || tema === 'Theme_Grayed'){
      return `assets/svg/icon-${icon}.svg`
    }else{
      return`assets/svg/icon-${icon}-dark.svg`
    }
  }

  setPlanoPet(tipo: number) {
    this.formPet.get('plano').setValue(tipo.toString());
  }

  cadastrarCliqueSchatz(botao?: string) {
    let unidade = this.condominioUnidade.find(unidade => {
      return unidade?.nomeExibicao === this.formProp.get('propriedade').value
    })

    if(!unidade) {
      unidade = this.condominioUnidade.find(unidade => (unidade.nomeExibicao as String).includes(this.stateService.getCondominioLogado()?.nome))
    }

    // console.log('unidade:', unidade);

    let payload = {
      tipoUsuario: 
        this.stateService.getCondominioLogado() ? 'Condômino' : 
        this.stateService.possuiPerfilLocatario() ? 'Locatário' : 
        this.stateService.possuiPerfilProprietario() ? 'Proprietario' : null,
      unidadeImovel: unidade?.nomeExibicao ? unidade.nomeExibicao : null,
      nomeUsuario: this.stateService.getPessoaLogada().Nome,
      idPessoa: this.stateService.getPessoaLogada().Id,
      Botao: this.tipoSelecionado ? this.tipoSelecionado.icon.charAt(0).toUpperCase() + this.tipoSelecionado.icon.slice(1) : botao
    }

    this.condominioService.schatzCliques(payload).subscribe({next: res => {
      // console.log('cadastrou clique', res);
    }});
  }

}
