import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ds-footer-actions',
  templateUrl: './footer-actions.component.html',
  styleUrls: ['./footer-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
