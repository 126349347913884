import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { StateService } from '../../services/stateService';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BiometryService } from '../../services/biometry.service';

@Component({
  selector: 'app-modal-biometria',
  templateUrl: './modal-biometria.component.html',
  styleUrls: ['./modal-biometria.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalBiometriaComponent implements OnInit {
  public isOpen$ = this.biometryService.isBiometryModalOpenAsObservable();
  public marcouBiometria = new UntypedFormControl(false);
  public nome_usuario = this.stateService.getPessoaLogada().Nome;

  constructor(
    public stateService: StateService,
    private activedRoute: ActivatedRoute,
    private biometryService: BiometryService,
  ) { 
    this.activedRoute.params.subscribe(val => {
      this.nome_usuario = this.stateService.getPessoaLogada().Nome;
    });
  }

  ngOnInit() { }

  close() {
    this.biometryService.denyBiometry();
  }

  register() {
    this.biometryService.registerFingerprintBiometry();
  }

  get isIOS(): boolean {
    return this.stateService.platformIsIos();
  }
}
