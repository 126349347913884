import { Guid } from 'guid-typescript';
import { CondominioGTDTO } from './CondominioGT.DTO';
import { UnidadeDTO } from './Unidade.DTO';

export class BlocoGtDTO {
  Id: number;
  Uid: Guid;
  Name: string;
  Condominium: CondominioGTDTO;
  CondominiumId: number;
  CondominiumMXMId: number;
  SubdivisaoMXMId: number;
  CondominiumIdSubdivisaoId: string;
  HasResidents: Boolean;
  Unities: UnidadeDTO[];

  idSubDivisao: number
}
