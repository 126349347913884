<label class="FormGroup_Label">{{label}}</label>
<div class="selectArea">
  <div class="selectInput" [ngStyle]="{'width':width ? 'auto' : ''}" (click)="open = !open">
    {{ itemSelecionado || placeholder }}
    <!-- <i class="fas fa-times" *ngIf="this.preenchido === true;" (click)="cleanSelect()"></i> -->
    <i class="fas fa-chevron-down"></i>
  </div>
  <div class="optionsArea" [ngStyle]="{'width':width ? 'auto' : ''}" [ngClass]="{ __ShowOptions: open }">
    <!-- <div class="optionItem" (click)="selecionarItem()"></div> -->
    <div
    class="optionItem"
    (click)="selecionarItem(opcao)"
    *ngFor="let opcao of opcoes"
    >
    <!-- {{ getOpcaoSelect(opcao) }} -->
    {{ opcao }}
  </div>
</div>
</div>

