import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

//service
import { OnsignalService } from './shared/services/onsignal.service';
import { environment } from 'src/environments/environment';
// import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { StateService } from './shared/services/stateService';
import { LightBoxesService } from './shared/components/lightboxes';
import { register } from 'swiper/element/bundle';
import { StatusBar, Style } from '@capacitor/status-bar';
import { MobileAccessibility } from '@awesome-cordova-plugins/mobile-accessibility/ngx';
import { SplashScreen } from '@capacitor/splash-screen';

register();


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private lightboxService: LightBoxesService,
    public platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private onsignalService: OnsignalService,
    private acessibilidade: MobileAccessibility,
    public stateService: StateService,
  ) {
    this.initializeApp();
    this.setAndroidBackButtonBehavior();
  }

  private setAndroidBackButtonBehavior(): void {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribe(() => {
        if (window.location.pathname == "/login" || window.location.pathname == "/")
          this.lightboxService.open(
            'custom-mural-cadastrado', {
            title: 'Sair',
            text: 'Deseja sair do aplicativo?'
          }, 0
          ).then(event => {
            if (event) {
              navigator['app'].exitApp()
            }
          })

        else
          navigator['app'].backButton();
      });
    }
  }

  initializeApp() {
    if(this.stateService.platformIsDeviceMobile()) {
      StatusBar.setStyle({ style: Style.Light });
      StatusBar.setBackgroundColor({ color: '#ffffff' });
      StatusBar.setOverlaysWebView({ overlay: false })
    }
    this.platform.ready().then((data) => {
      if (environment.production) {
        if(this.stateService.platformIsDevice()) {
          this.acessibilidade.updateTextZoom();
          this.acessibilidade.usePreferredTextZoom(false);
          this.acessibilidade.setTextZoom(100) 
          this.onsignalService.initMobile();
        }
        else {
          this.onsignalService.init(); // desktop
        }
      };
      // this.onsignalService.init(); // desktop
    });
  }

  // oneSignalInit(): void {
  //   OneSignal.initialize(environment.config.onesignalId);
  //   let myClickListener = async function(event) {
  //         let notificationData = JSON.stringify(event);
  //     };
  //   OneSignal.Notifications.addEventListener("click", myClickListener);
  //   OneSignal.Notifications.requestPermission(true)
  // }


}
