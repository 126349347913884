import { Component, OnInit, Input } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';

@Component({
  selector: 'ds-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input('p-title') title: string;
  @Input() position: string = "left";
  @Input() mobilePosition: string = "left-bottom";

  public isOpen: boolean = false;

  handleMouseOver() {
    if(this.stateService.platformIsDesktop()) {
      this.isOpen = true;
    }
  }

  handleMouseOut() {
    this.isOpen = false;
  }

  handleClick() {
    this.isOpen = !this.isOpen;
  }

  constructor(
    public stateService: StateService,
  ) { }

  ngOnInit() {}
}
