import { Injectable } from '@angular/core';
import { StateService } from './stateService';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private activeThemeName: BehaviorSubject<string>;

  constructor(public stateService: StateService) {
    this.activeThemeName = new BehaviorSubject<string>(this.stateService.getTemaAcessibilidade());
  }

  public getActiveThemeName(): BehaviorSubject<string> {
      return this.activeThemeName;
  }

  public setActiveThemeName(newActiveThemeName: string): void {
    this.activeThemeName.next(newActiveThemeName);
  }
}
