import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { StateService } from 'src/app/shared/services/stateService';

@Injectable({
  providedIn: 'root'
})
export class PoliticaPrivacidadeService {

  private urlApiHost = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints;

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  constructor(private http: HttpClient, public stateService: StateService) { }

  getPoliticaPrivacidade() {
    return this.http.get<any>(
      `${this.urlApiHost + this.methods.methods.optinsUsuario.getPoliticaPrivacidade}`,
      { headers: this.getHeader() }
    );
  }
}
