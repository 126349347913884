import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';
import { LightBoxesService } from '../lightboxes';
import { StateService } from '../../services/stateService';
import { ModalService } from '../../services/modal.service';

@Injectable()
export class AvaliacaoLojaComponent {

  constructor(
    public platform: Platform,
    private lightBoxesService: LightBoxesService,
    public stateService: StateService,
    private modalService: ModalService,
  ) { }

  public avaliacaoLoja(acessoMenu = false) {
      this.modalAvaliacao(acessoMenu);
  }

  modalAvaliacao(acessoMenu: boolean) {
    const salvoSessao = JSON.parse(localStorage.getItem('popupAvaliacao'));
    const appAvaliadoEmCotas = JSON.parse(localStorage.getItem('appAvaliadoEmCotas'));
    
    if (/*this.stateService.platformIsDevice() && */((!appAvaliadoEmCotas && !salvoSessao) || acessoMenu)) {

      let linkLoja: string;
      if (this.platform.is('ios')) linkLoja = 'https://apps.apple.com/br/app/apsa/id1171170928';
      if (this.platform.is('android')) linkLoja = 'https://play.google.com/store/apps/details?id=com.apsa&hl=pt_BR';
      
        this.lightBoxesService.openYesOrNoWithLink(
          'yes-or-no-with-link', {
          title: 'Gostando do app?',
          text: 'Avalie sua experiência utilizando nosso aplicativo. Sua opinião é muito importante para continuarmos melhorando!',
          link: acessoMenu ? null : 'Lembrar depois',
        }, 0
        )
          .then(event => {
            if (event === 'yes') {
              this.stateService.cadastroAcessoPagina(49)
              this.stateService.openExternalLink(linkLoja);

              if(!acessoMenu)
                localStorage.setItem('appAvaliadoEmCotas', 'true');
            }
            else if (event === 'no') {
              this.stateService.cadastroAcessoPagina(48);
              this.modalService.open('avaliar-app');

              if(!acessoMenu)
                localStorage.setItem('appAvaliadoEmCotas', 'true');
            }
            else if (event === 'link') {
              localStorage.setItem('popupAvaliacao', 'true');
            }
          })
    }
  }

  registrarReclamacao(item: string){
    // this.stateService.registrarReclamacao(item).subscribe(item => {
    //   this.lightBoxesService.open(
    //     'custom-generico', {
    //     title: '',
    //     text: item
    //   }, 5000
    //   )
    // }, err => {
    //   this.lightBoxesService.open(
    //     'custom-generico', {
    //     title: 'Atenção',
    //     text: err.error.message
    //   }, 5000
    //   )
    // })
  }

}