<div class="Modal Modal_EspacosReserva" [ngClass]="{ '__is-open': open }">
  <!-- TODO: Adicionar a class "__is-open" para habilitar o modal -->
  <div class="Modal_Close" (click)="close()">
    <i class="fa fa-times"></i>
  </div>
  <!-- <div class="Modal_Header">
    Nome espaço teste
    {{ espaco ? espaco.Name : "" }}
  </div> -->
  <div class="Modal_Content">
    <div class="Modal_EspacosReserva_Texto">
      {{ espaco?.descricao ? espaco.descricao : espaco?.Name ? espaco.Name : '' }}
    </div>

    <div class="fotos-container" >
      
      <div class="fotos-wrapper-container">
        <button
        class="arrow-container-left"
        [ngClass]="{'__is-inactive': this.swiper?.activeIndex === 0}"
        *ngIf="this.swiper && this.platform.is('desktop')"
        (click)="this.prevSlide()"
        >
          <i class="fa fa-chevron-left"  ></i>
        </button>

        <div class="swiper">
          <div class="swiper-wrapper">
            <!-- Slides -->
            <div class="swiper-slide">
              <div class="foto-placeholder">1</div>
            </div>
            <div class="swiper-slide">
              <div class="foto-placeholder">2</div>
            </div>
            <div class="swiper-slide">
              <div class="foto-placeholder">3</div>
            </div>
          </div>
          <div class="swiper-pagination" [ngClass]="{'__has-bullets': !this.platform.is('desktop')}" ></div>
        </div>

        <button
          class="arrow-container-right"
          *ngIf="this.swiper && this.platform.is('desktop')"
          [ngClass]="{'__is-inactive': this.swiper?.activeIndex && this.swiper?.activeIndex === 2}"
          (click)="this.nextSlide()"
          >
          <i class="fa fa-chevron-right"  ></i>
        </button>

      </div>

    </div>

    <div class="detalhes" *ngIf="espaco && espaco['configuracoes']" >
      <div class="detalhes-title">
        Dias disponíveis para reserva:
      </div>
  
      <div class="detalhes-info" *ngIf="espaco['configuracoes']" >
        <ng-container *ngFor="let config of espaco['configuracoes']" >
          <p> 
            <strong>{{ config.dia }} - {{ config.periodoInicial }} até {{ config.periodoFinal }}</strong>  
            <span *ngIf="config.tempoMinimoReserva" >• Tempo total de reserva: {{ config.tempoMinimoReserva }} </span> 
          </p>
        </ng-container>
      </div>
    </div>

    <div class="info-observacoes" *ngIf="espaco?.observacoes" >
      <div class="info-observacoes__title">Observações sobre o espaço:</div>
      <div class="info-observacoes__body">
        {{espaco.observacoes}}
      </div>
    </div>


    <div class="info" *ngIf="espaco && espaco['documentos']">
      <div class="info-anexos">
        <div class="info-anexos__title">Anexos</div>
        <div class="info-anexos__content" *ngFor="let doc of espaco?.documentos" >
          <a target="_blank" [download]="doc.nome" [href]="'data:' + doc.tipo + ';base64,' + doc.conteudo" >{{doc.nome}}</a>
        </div>
      </div>
    </div>

  </div>
</div>
