import { ConstantsService } from './../services/constants.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LightBoxesService } from '../components/lightboxes';

@Injectable()
export class Interceptor401 implements HttpInterceptor {
  constructor(
    private router: Router,
    private lightBoxesService: LightBoxesService,
    public constants: ConstantsService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              sessionStorage.clear();
              this.router.navigate(['/'], { queryParams: { showMsg: true, title: 'Acesso expirado', mensagem: 'Faça o login novamente' } });
              this.constants.condominioImovelAtivo = null;

              return;
            }
          }
        }
      )
    );
  }
}
