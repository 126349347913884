<div class="FaleComAApsaComponent" 
  [ngClass]="{ 
    '__is-sindico': possuiPerfilSindico, 
    '__is-mobile': possuiPerfilSindico && isMobile, 
    '__is-desktop': possuiPerfilSindico && isDesktop 
  }" 
  >  
  <ng-container *ngIf="possuiPerfilSindico">
    <div class="Text_Message" [ngClass]="{ '__is-mobile': isMobile }" >
      <h1>Olá, {{ pessoaLogada.Nome }},</h1>
      <p>Queremos saber mais sobre a sua experiência com a Apsa. Neste canal as dúvidas, sugestões,
        reclamações e esclarecimentos serão analisadas e respondidas pelos nossos executivos. A sua opinião
        é muito importante para nós! Se preferir utilizar o canal telefônico, nossos executivos estão à sua
        disposição no horário comercial, exceto nos finais de semana e feriados, através dos telefones
        abaixo:
      </p>
      <div class="Text_Message_Phones">
        <p>RJ (21)3995-3302</p>
        <p>BA (71)3599-0925</p>
        <p>PE (81)3995-0119</p>
      </div>
    </div>
  </ng-container>
  
  <form [formGroup]="formFaleComApsa">
    <div class="Atalho_CentralAtendimento_Form">
      <div class="FormGroup">
        <div class="FormGroup_Label">Tipo de mensagem</div>

        <div class="FormGroup_Input">
          <select name="tipoMensagem" formControlName="tipoMensagem">
            <option value="1">Elogio</option>
            <option value="2">Reclamação</option>
            <option value="3">Solicitação</option>
            <option value="4">Sugestão</option>
          </select>
        </div>

        <div class="FormGroup_Erro">{{ getErrorMessage('tipoMensagem') }}</div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup_Label">Assunto</div>
          <div class="FormGroup_Input">
          <select name="idAssunto" formControlName="idAssunto">
            <option value="">Selecione um assunto</option>
            <ng-container *ngIf="listaAssuntos.length !== 0; else listaVazia"> 
              <ng-container *ngFor="let item of listaAssuntos" >
                <option [value]="item.idAssunto">
                  {{item.descricaoAssunto}}
                </option>
              </ng-container>
            </ng-container>
            
            <ng-template #listaVazia >
              <option [selected] value="7">
                Outros
              </option>
            </ng-template>
            
          </select>
        </div>

        <div class="FormGroup_Erro">{{ getErrorMessage('idAssunto') }}</div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup_Label">Mensagem</div>

        <div class="FormGroup_Input">
          <textarea cols="30" rows="5" name="mensagem" formControlName="mensagem" placeholder="Escreva sua mensagem aqui"></textarea>
        </div>

        <div class="FormGroup_Erro">{{ getErrorMessage('mensagem') }}</div>
      </div>

      <div class="FormGroup">
        <button class="Button Button_Primary Button_Solid" [ngClass]="{'disabled': !this.formFaleComApsa.valid }" (click)="enviarMensagem()">
          <div class="Button_Content">
            <div class="Button_Content_Label">Enviar mensagem</div>
          </div>
        </button>
      </div>
    </div>
  </form>

  <!-- <div *ngIf="gerenteConta" class="Atalho_CentralAtendimento_Gestor">
    <div *ngIf="this.ehGestaoTotal" class="CentralAtendimento_Gestor_Title">
      Gestor(a)
    </div>

    <ng-container *ngIf="!possuiPerfilSindico">
      <div *ngIf="!this.ehGestaoTotal" class="CentralAtendimento_Gestor_Title">
        Consultor(a)
      </div>
  
      <div class="CentralAtendimento_Gestor_Foto" [ngClass]="{'__no-photo': !gerenteConta.urlFoto}">
        
        <img *ngIf="gerenteConta.urlFoto; else semFoto" src="{{gerenteConta.urlFoto}}" alt="">

        <ng-template #semFoto >
          <i class="far fa-image"></i>
        </ng-template>
      </div>
  
      <div class="CentralAtendimento_Gestor_Nome">
        {{gerenteConta.nome}}
      </div>
    </ng-container>
  </div> -->
</div>