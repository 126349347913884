import { Injectable } from "@angular/core";
// import { Base64 } from "@ionic-native/base64/ngx";
// import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { Platform } from "@ionic/angular";
import * as moment from "moment";
import { StateService } from "../../services/stateService";
import { CameraPlugin, ICameraPluginPicture } from "./CameraPlugin";
import { Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';


@Injectable({ providedIn: 'root' })
export default class CordovaCameraPluginImpl implements CameraPlugin {

  constructor(
    private platform: Platform,
    // private camera: Camera,
    private stateService: StateService,
    // private base64: Base64,
  ) { }

  async takePictureAsBase64() {
    const options: ImageOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      direction: CameraDirection.Rear,
      allowEditing: false,
      correctOrientation: true,
    }

    return this.takePictureWithOptions(options);
  }

  async selectPictureFromGallaryAsBase64(): Promise<ICameraPluginPicture> {
    const options: ImageOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Photos,
      direction: CameraDirection.Rear,
      allowEditing: false,
      correctOrientation: true,
    }

    return this.takePictureWithOptions(options);
  }

  private async takePictureWithOptions(options: ImageOptions): Promise<ICameraPluginPicture> {
    await Camera.getPhoto(options).then(photo => {
      if(photo) {
        const fileName = moment().format("DD-MM-YYYY_HH-mm-ss") + '.jpeg';
    
        if (this.platform.is('ios')) {
          return this.iosPictureFormatAdapter(photo.dataUrl, fileName);
        }
        else if (this.platform.is('android')) {
          return this.androidPictureFormatAdapter(photo.dataUrl, fileName);
        }

      } else {
      }
    })
    return null
  }

  private iosPictureFormatAdapter(imageData: any, fileName: string): ICameraPluginPicture {
    const src = this.stateService.getCaminhoImagemExibicao("image/jpeg", imageData);

    return {
      base64: imageData,
      mimeType: "image/jpeg",
      fileName: fileName,
      src: src,
    };
  }

  private async androidPictureFormatAdapter(imageData: string, fileName: string): Promise<ICameraPluginPicture> {
    const src = this.stateService.getCaminhoImagemExibicao("image/jpeg", imageData);
    return {
      base64: imageData,
      mimeType: "image/jpeg",
      fileName: fileName,
      src: src
    };
  }
}