<div class="Lightbox">
  <div class="Popup Popup_MuralCadastrado">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">CI-998</div>
    </div>
    
    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        <p>Não foi possivel processar sua solicitação. Por favor tente mais tarde.</p>
      </div>
    </div>

    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <button class="Button Button_Primary Button_Solid __is-full" (click)="close()">
          <div class="Button_Content">
            <div class="Button_Content_Label fechar">
              Ok
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
