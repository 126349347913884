import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ds-light-collapse',
  templateUrl: './light-collapse.component.html',
  styleUrls: ['./light-collapse.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LightCollapseComponent {
  @Input('p-is-open') isCollapseOpen: boolean = false;
  @Input('p-title') title: string;
  @Input('p-icon') icon: string;

  toggle() {
    this.isCollapseOpen = !this.isCollapseOpen;
  }
}
