<ds-button
  [color]="color"
  [type]="type"
  [full]="full"
  [inactive]="inactive"
  [leftIcon]="leftIcon"
  [rightIcon]="rightIcon"
  (click)="handleClick()"
>
  <ng-content></ng-content>

  <input #fileInput type="file" (change)="handleChange($event)">
</ds-button>
