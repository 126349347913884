import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConstantsService {

  public statusMenuSubject = new BehaviorSubject(false);
  private condominioImovelAtivoSubject = new BehaviorSubject<string>(undefined);
  public condominioImovelMostrado: string;
  public trocaRotaMenu: Subject<any> = new Subject<any>()
  public appVersion: string = "1.91.11";

  constructor() { }

  get statusMenu() {
    return this.statusMenuSubject.getValue();
  }

  set statusMenu(newValue: boolean) {
    this.statusMenuSubject.next(newValue);
  }

  getCondominioImovelAtivoAsObservable(): Observable<string> {
    return this.condominioImovelAtivoSubject.asObservable();
  }

  set condominioImovelAtivo(newValue: string) {
    this.condominioImovelAtivoSubject.next(newValue);
  }

  get condominioImovelAtivo() {
    return this.condominioImovelAtivoSubject.getValue();
  }
}
