import { FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';


import { StateService } from '../../services/stateService';
import { LightBoxesService } from '../lightboxes';

@Component({
  selector: 'adesao-de-boleto-digital',
  templateUrl: './adesao-de-boleto-digital.component.html',
  styleUrls: ['./adesao-de-boleto-digital.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdesaoDeBoletoDigitalComponent implements OnInit {

  @Input() listaCondominios;
  @Input() listaImoveis;
  @Input() imoveisAgrupadosPorContrato;
  @Output() close = new EventEmitter;
  @Output() listaImoveisEditada = new EventEmitter

  constructor(
    public stateService: StateService,
    private lightBoxesService: LightBoxesService
  ) {}

  ngOnInit() { 
  }

  // fetchData() {
  //   this.fetchCondominios();
  // }

  // fetchCondominios() {
  //   this.service.getCondominios().subscribe(data => {
  //     this.condominios = data;
  //   });
  // }

  get nome(): string {
    var consulta = this.stateService.getPessoaLogada();
    if (consulta) {
      return consulta.Nome;
    }
  }

  closeModal() {
    this.close.emit();
  }

  openTutorial() {
    const img = this.stateService.platformIsDesktop() 
      ? '../../../../assets/gif/adesao-boleto-digital-desktop.gif' 
      : '../../../../assets/gif/adesao-boleto-digital-mobile.gif'
    
    this.lightBoxesService.openImageModal('image-with-toggle-and-ok-button', {
      title: '',
      imgPath: img,
      toggleLabel:'',
      url:''
    }, 0, true)
  }

  enviarListaEditada(dados) {
    this.listaImoveisEditada.emit(dados)
  }
}
