import { Guid } from "guid-typescript";
import { CondominioGTDTO } from './CondominioGT.DTO';
import { BlocoGtDTO } from './BlocoGt.DTO';
import { MoradorDTO } from '../Request/Morador.DTO';

export class UnidadeDTO {
    Uid: Guid
    Description: string;
    UnityMXMId: number;
    Condominium: CondominioGTDTO;
    Block: BlocoGtDTO
    HasResident: boolean
    ResidentWithProfileEnable: boolean
    Resident: MoradorDTO
    cpfCnpjCondomino: string;
}
