import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from './components/button/button.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { LightCollapseComponent } from './components/light-collapse/light-collapse.component';
import { InfogroupListComponent } from './components/infogroup-list/infogroup-list.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { CardComponent } from './components/card/card.component';
import { FooterActionsComponent } from './components/footer-actions/footer-actions.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { BoxWithDashedBorderComponent } from './components/box-with-dashed-border/box-with-dashed-border.component';
import { EmptyResultComponent } from './components/empty-result/empty-result.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LinkDownloadComponent } from './components/link-download/link-download.component';
import { FilterItemComponent } from './components/filter-item/filter-item.component';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { OptionButtonComponent } from './components/option-button/option-button.component';
import { BrMaskerModule } from 'br-mask';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';

@NgModule({
  declarations: [
    CollapseComponent,
    ButtonComponent,
    InfogroupListComponent,
    CardComponent,
    CardListComponent,
    FooterActionsComponent,
    TooltipComponent,
    UploadButtonComponent,
    BoxWithDashedBorderComponent,
    EmptyResultComponent,
    LightCollapseComponent,
    SearchInputComponent,
    LinkDownloadComponent,
    FilterItemComponent,
    CollapsibleComponent,
    OptionButtonComponent,
    MonthPickerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrMaskerModule
  ],
  exports: [
    CollapseComponent,
    ButtonComponent,
    InfogroupListComponent,
    CardComponent,
    CardListComponent,
    FooterActionsComponent,
    TooltipComponent,
    UploadButtonComponent,
    BoxWithDashedBorderComponent,
    EmptyResultComponent,
    LightCollapseComponent,
    SearchInputComponent,
    LinkDownloadComponent,
    FilterItemComponent,
    CollapsibleComponent,
    OptionButtonComponent,
    MonthPickerComponent
  ],
})
export class DesignSystemModule { }
