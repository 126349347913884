import { StateService } from 'src/app/shared/services/stateService';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ActivatedRoute } from '@angular/router';
import { AdesaoBoletoButton } from '../buttons/adesao-boleto-digital/adesao-boleto-button.component';


@Component({
  selector: 'modal-adesao-de-boleto-digital',
  templateUrl: './modal-adesao-de-boleto-digital.component.html',
  styleUrls: ['./modal-adesao-de-boleto-digital.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalAdesaoDeBoletoDigitalComponent implements OnInit, OnDestroy {
  @Input() isOpen: boolean = false;
  @Output() closeModal = new EventEmitter()
  @Output() listaImoveisEditada = new EventEmitter
  public listaCondominios: any[] = null
  public listaImoveis: any[] = []
  public imoveisAgrupadosPorContrato = []

  constructor(
    private modalService: ModalService,
    public stateService: StateService,
    private activatedRoute: ActivatedRoute,
    private adesaoBoletoButton: AdesaoBoletoButton
  ) {
    activatedRoute.params.subscribe(item => {
      if(this.stateService.platformIsDevice()) {
        this.getListaCondominiosUnidades();
        this.getListaImoveis();
      }
    })
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.modalService.removeHasModalClassFromContainerDiv();
  }

  close() {
    this.adesaoBoletoButton.close();
    this.closeModal.emit();
    
    // if (this.stateService.platformIsDeviceMobile()){
    //   this.closeModal.emit();
    // } 

  }

  cadastraEditaAdesaoUnidade(){
    this.adesaoBoletoButton.cadastraEdita(this.listaCondominios, this.listaImoveis, 'mobile');
    this.closeModal.emit();
  }

  getListaCondominiosUnidades() {
    if(this.stateService.getPerfisUnidades()) {

      const listaStorage = JSON.parse(sessionStorage.getItem('listaCondominiosAdesaoBoleto'));

    if(listaStorage){
      this.listaCondominios = listaStorage;
    } else {
      this.stateService.listarCondominiosUnidades().subscribe(
        (item) => {
          if (item) {
            // console.log('itme',item)
            this.listaCondominios = item;
            sessionStorage.setItem('listaCondominiosAdesaoBoleto', JSON.stringify(item));
          }
        },
        (err) => {
          if (err) {
            // console.log(err, 'erro');
          }
        }
      );

    }
    }
  }

  getListaImoveis() {
    // console.log('chegou lista de imóveis')
    if(this.stateService.tipoPerfilLocatario()) {
  
      const listaImoveisStorage = JSON.parse(sessionStorage.getItem('listaImoveisAdesaoBoleto'));
      // console.log('listaImoveis', listaImoveisStorage)
  
    if(listaImoveisStorage){
      this.listaImoveis = listaImoveisStorage;
      this.getImoveisAgrupadosPorContrato()

    } 
    else {
      this.stateService.listarImoveis().subscribe(
        (item) => {
          if (item) {
            // console.log('itme',item)
            this.listaImoveis = item;
           this.getImoveisAgrupadosPorContrato()
            sessionStorage.setItem('listaImoveisAdesaoBoleto', JSON.stringify(item));
          }
        },
          (err) => {
            if (err) {
              // console.log(err, 'erro');
            }
          });
      }
    }
  }

  getImoveisAgrupadosPorContrato() {
    this.imoveisAgrupadosPorContrato =  this.listaImoveis.reduce((resultado, imovelAtual) => {
      const found = resultado.find(obj => obj.contratoLocacao === imovelAtual.contratoLocacao);
      if (found) {
        found.enderecoImovel.push(imovelAtual.enderecoImovel);
      } else {
        resultado.push({
          contratoLocacao: imovelAtual.contratoLocacao,
          enderecoImovel: [imovelAtual.enderecoImovel],
          aderido: imovelAtual.aderido
        });
      }
      return resultado;
    }, []);

  }

  enviarListaEditada(dados) {
    this.listaImoveis = dados
  }
}
