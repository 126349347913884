export const Texts = {
    form: {
        error: {
            textInputValue: {
                required: 'Campo obrigatório',
                minlength: 'Pode nos informar mais detalhes? (Mínimo de 20 caracteres)'
            }
        }
    }
};
