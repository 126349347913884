<div class="month-picker" [ngClass]="{'full-width': fullWidth}" >
  <header>
    <button (click)="previousPage()" [ngClass]="{'disabled': !canGoBack}">
      <i class="fas fa-chevron-left"></i>
    </button>

    <span>{{yearPage}}</span>

    <button (click)="nextPage()" [ngClass]="{'disabled': !canGoNext}">
      <i class="fas fa-chevron-right"></i>
    </button>
  </header>

  <section class="months-container">
    <button
      *ngFor="let m of selectablesMonths; let i = index"
      [ngClass]="getActiveClass(i+1, !m.selectable)"
      (click)="pick(i+1)"
    >
      {{m.month}}
    </button>
  </section>
</div>