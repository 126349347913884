<div class="InfoGroupList">
  <ng-container *ngFor="let infogroup of list">
    <div
      class="InfoGroup"
      [ngClass]="{ '__is-full-width': infogroup.full }"
      *ngIf="isVisible(infogroup)"
    >
      <div class="InfoGroup_Label">{{ infogroup.label }}</div>
      <div class="InfoGroup_Value">{{ infogroup.value }}</div>
    </div>
  </ng-container>
</div>