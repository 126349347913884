import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {

  constructor(public stateService: StateService) { }

  ngOnInit() {
    this.stateService.validarTemaUsuario()
  }
}
