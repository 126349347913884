import { ImagemArquivoDTO } from '../../../../../shared/services/DTO/Request/ImagemArquivo.DTO';
import { EspacoDTO } from '../../../../../shared/services/DTO/Request/Espaco.DTO';
import { Guid } from "guid-typescript";

export class TermoAceiteEspacoDTO {
    AcceptanceTermFile: ImagemArquivoDTO
    RequiredSignedTerm: boolean
    Space: EspacoDTO;
    Text: string;
    Description: string;
    Uid: Guid;
}
