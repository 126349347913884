import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterMenu',
    pure: false
})
export class FilterMenuPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }
        
        return items.filter(item => item.type == filter["type"]);
    }
}