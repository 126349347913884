<div class="Modal Modal_TermosReserva" [ngClass]="{ '__is-open': open }">
  <!-- TODO: Adicionar a class "__is-open" para habilitar o modal -->
  <div class="Modal_Close" (click)="close()">
    <i class="fa fa-times"></i>
  </div>
  <div class="Modal_Header">
    {{ (termoAceiteEspaco && termoAceiteEspaco.Space) ? termoAceiteEspaco.Space.Name : (termoAceiteEspaco && termoAceiteEspaco.Description) ? termoAceiteEspaco.Description : null}}
  </div>
  <div class="Modal_Content">

    <div class="Modal_TermosReserva_Texto" *ngIf="termoAceiteEspaco && termoAceiteEspaco.Text">
      {{ (termoAceiteEspaco) ? termoAceiteEspaco.Text : ""}}
    </div>

    <div *ngIf="termoAceiteEspaco && termoAceiteEspaco.AcceptanceTermFile">
      <a (click)="baixarTermo(termoAceiteEspaco)">{{termoAceiteEspaco.AcceptanceTermFile.FileName}}</a>
    </div>


  </div>

</div>
