export enum EBiometryPluginResult {
  SUCCESS,
  ERROR,
}

export interface BiometryPlugin {
  authenticate: (options: BiometryPluginOptions) => Promise<EBiometryPluginResult>;
  isAvaiable: () => Promise<boolean>;
}

export const BiometryPluginToken = 'BIOMETRY_PLUGIN_IMPLEMENTATION';

export interface BiometryPluginOptions {
  title: string;
  subtitle: string;
  description: string;
  maxAttempts: number;
  negativeButtonText: string;
}