import { Guid } from 'guid-typescript';

export class ListaTipoChecklistDTO {
    Data: Array<TipoChecklistDTO>;
}

export class TipoChecklistDTO {
    AllowDelete: boolean;
    Enabled: boolean;
    Name: string;
    Uid: Guid;

    constructor(public uid: Guid, name?: string) {
        this.Uid = uid;
        this.Name = name
    }
}
