<!-- Component de Listagem de Documentos -->
<div class="Page_Tab_Content">
  <div class="EnvioDocumento_ListaHistorico">
    <div class="ListEmpty" *ngIf="!listaDocumentos || listaDocumentos.length === 0">
      <div class="ListEmpty_Icon">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      <div class="ListEmpty_Text">
        Nenhum documento encontrado
      </div>
    </div>

    <div class="ListCards __is-full-width">
      <div class="ListCards_Wrapper">
        <div class="ListCards_Wrapper_Card" *ngFor="let item of listaDocumentos;">
          <a routerLink="/{{componenteRota}}/detalhe/{{item.Id}}" routerLinkActive="active">
            
            <div 
              class="StatusIndicator"
              [ngClass]="getStatusColorClass(item.IdStatus)"
            ></div>

            <div class="Wrapper_Card_Content">
              
              <div *ngIf="!this.stateService.getCondominioLogado()" class="ChecklistHistorico_ListaPreenchida_GrupoInfo __is-full">
                <div class="ListaPreenchida_GrupoInfo_Label">Nome do Condomínio</div>
                <div class="ListaPreenchida_GrupoInfo_Value">{{(item.NomeCondominio) ? item.NomeCondominio : 'Não identificado'}}</div>
              </div>
              
              <div class="ChecklistHistorico_ListaPreenchida_GrupoInfo __is-full">
                <div class="ListaPreenchida_GrupoInfo_Label">Data do envio</div>
                <div class="ListaPreenchida_GrupoInfo_Value"> {{item.DataEnvio | date:'fullDate'}}</div>
              </div>

              <div class="ChecklistHistorico_ListaPreenchida_GrupoInfo __is-full"  >
                <div class="ListaPreenchida_GrupoInfo_Label">Tipo de documento</div>
                <div  class="ListaPreenchida_GrupoInfo_Value" *ngIf="item.TipoDocumento == 'Correspondência' || item.TipoDocumento == 'Débito Automático' || item.TipoDocumento == 'Anexo PPC'" >
                  {{item.TipoDocumento}}</div>
                <div *ngIf="item.TipoDocumento == 'Conta a pagar'" class="ListaPreenchida_GrupoInfo_Value">
                  {{item.TipoDocumento}} {{(item.DescricaoTipoContaPagar) ? ' - ' + item.DescricaoTipoContaPagar : ''}}
                </div>
              </div>


              <div *ngIf="item.TipoDocumento != 'Correspondência' && item.TipoDocumento !== 'Anexo PPC'"
                class="ChecklistHistorico_ListaPreenchida_GrupoInfo __is-full">
                <div class="ListaPreenchida_GrupoInfo_Label">Data de Vencimento</div>
                <div class="ListaPreenchida_GrupoInfo_Value">{{item.DataVencimento | date:'fullDate'}}</div>
              </div>

              <div class="ChecklistHistorico_ListaPreenchida_GrupoInfo __is-full" *ngIf="item.DescricaoInstrucao && item.TipoDocumento !== 'Anexo PPC'" >
                <div class="ListaPreenchida_GrupoInfo_Label">Descrição</div>
                <div class="ListaPreenchida_GrupoInfo_Value">{{item.DescricaoInstrucao}}</div>
              </div>

              

              <div class="ChecklistHistorico_ListaPreenchida_GrupoInfo __is-full">
                <div class="ListaPreenchida_GrupoInfo_Label">Status</div>
                <div class="ListaPreenchida_GrupoInfo_Value" [ngClass]="getStatusColorClass(item.IdStatus)">{{item.Status}}</div>
              </div>

              <div *ngIf="(item.TipoDocumento == 'Conta a pagar' || item.TipoDocumento === 'Débito Automático' ) && item.Valor"
                class="ChecklistHistorico_ListaPreenchida_GrupoInfo __is-full">
                <div class="ListaPreenchida_GrupoInfo_Label">Valor</div>
                <div class="ListaPreenchida_GrupoInfo_Value">R$ {{ formatValue(item.Valor) }}</div>
              </div>
            </div>

            <div class="Wrapper_Card_Icon">
              <i class="fas fa-chevron-right"></i>
            </div>
          </a>
        </div>

      </div>
    </div>
  </div>
</div>
