import * as moment from "moment";

export class DateUtils {
  public static getFirstDateOfThisMonthFormatted(): string {
    return moment().format('YYYY-MM-01');
  }
  
  public static getLastDateOfThisMonthFormatted(): string {
    const daysInThisMonth = moment().daysInMonth();

    return moment().format(`YYYY-MM-${daysInThisMonth}`);
  }
}