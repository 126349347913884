import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { StateService } from '../services/stateService';
import { environment } from '../../../environments/environment';
import { ResultListAlertaDTO } from './DTO/response/AlertaDTO';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertaService {
  private urlApiHost = environment.apiGT.host;
  private methods = environment.apiGT.endpoints;

  constructor(private http: HttpClient, private routerService: StateService) { }

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': this.routerService.getTokenApiGT()
    });
  }

  public getTotalAlertas(origem: string, cpfPessoaLogada: string, profileCode: string, condominioAgencia: string, condominioConta: string) {
    var parametros = new HttpParams()
      .set("pOrigem", origem)
      .set("viewModel.responsibleCpf", cpfPessoaLogada)
      .set("viewModel.responsibleProfile.code", profileCode)
      .set("viewModel.condominium.account", condominioConta)
    return this.http.get<any>(
      `${this.urlApiHost + this.methods.accessAlert.get.totalAlertas}`,
      { params: parametros, headers: this.getHeader() }
    );
  }

  getAlertas(origem: string, cpfPessoaLogada: string, profileCode: string, condominioAgencia: string, condominioConta: string) {
    var parametros = new HttpParams()
      .set("pOrigem", origem)
      .set("viewModel.responsibleCpf", cpfPessoaLogada)
      .set("viewModel.responsibleProfile.code", profileCode)
      .set("viewModel.condominium.account", condominioConta)
    return this.http.get<ResultListAlertaDTO>(
      `${this.urlApiHost + this.methods.accessAlert.get.listarAlertas}`,
      { params: parametros, headers: this.getHeader() }
    );
  }
}
