import {
  OnDestroy,
  OnInit,
  Component,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  OnChanges,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
// import { IonSlides } from "@ionic/angular";
import { BehaviorSubject, Subscription } from "rxjs";
import Swiper from 'swiper'

@Component({
  selector: "app-slider-select",
  templateUrl: "./slider-select.component.html",
  styleUrls: ["./slider-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SliderSelectComponent implements OnDestroy, OnInit, AfterViewInit {
  // @ViewChild("slider") slider: IonSlides;
  @ViewChild("swiper") swiperRef: ElementRef | undefined;

  @HostListener('window:resize', ['$event']) onResize(event: Event) {
    this.swiper.update();
    if(this.router.isActive("prestacao-contas", false)) this.slideToEnd();
  }

  @Output() onSelectOption = new EventEmitter<string[]>();
  @Input("p-default-selection") defaultSelection: string[];
  @Input() options: string[] = [];
  @Input("p-step") step = 1;
  @Input("p-force-one-selection-on-mobile") forceOneSelectionOnMobile: boolean =
    false;
  @Input("p-show-fast-forward-arrow") showFastForwardArrow: boolean = true;
  @Input("p-show-fast-backward-arrow") showFastBackwardArrow: boolean = true;

  swiper?: Swiper;

  public selectedOptions = new BehaviorSubject(null);
  public subscription: Subscription = null;
  public carouselConfig: any = {
    slidesPerView: "auto",
  };
  public oneSelectionMode: boolean = false;
  private requiredSelection: number = null;
  private _defaultSelection: string[];

  constructor(private router: Router) {}

  ngOnInit() {
    // this.swiperReady();
    this.selectedOptions.subscribe((options) => {
      if (options) {
        this.onSelectOption.emit(options);
      }
    });

    this._defaultSelection = this.defaultSelection;
    this.selectedOptions.next(this._defaultSelection);
    this.requiredSelection = this.defaultSelection.length;
  }

  swiperReady() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  ngAfterViewInit() {
    this.swiperReady();
    this.swiper.allowTouchMove = false;
    this.swiper.slideTo(this.options.length -1);
    // this.slideToEnd();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  slideNext() {
    if (this.canSlideNext()) {
      const moveLength = this.getfirstSelectedOptionIndex() + this.step;
      this.swiper?.slideTo(moveLength);

      this.selectedOptions.next(
        this.options.slice(moveLength, moveLength + this.requiredSelection)
      );

      this.swiper.slideTo(this.getfirstSelectedOptionIndex() + 1);
    }
  }

  slidePrevious() {
    if (this.canSlidePrevious()) {
      const moveLength = this.getfirstSelectedOptionIndex() - this.step;
      this.swiper?.slideTo(moveLength);

      this.selectedOptions.next(
        this.options.slice(moveLength, moveLength + this.requiredSelection)
      );

      this.swiper.slideTo(this.getfirstSelectedOptionIndex() - 1);
    }
  }

  slideToStart() {
    if (this.canSlidePrevious()) {
      this.swiper?.slideTo(0);
      this.selectedOptions.next(this.options.slice(0, this.requiredSelection));
    }
  }

  slideToEnd() {
    if (this.canSlideNext()) {
      this.swiper?.slideTo(this.options.length - 1);
      this.selectedOptions.next(
        this.options.slice(
          this.options.length - this.requiredSelection,
          this.options.length
        )
      );
    }
  }

  isOptionSelected(option: string): boolean {
    return this.selectedOptions.getValue().includes(option);
  }

  public resetToDefaultSelection() {
    this.selectedOptions.next(this._defaultSelection);
    this.swiper?.slideTo(this.getLastSelectedOptionIndex());
  }

  public canSlidePrevious() {
    return this.getfirstSelectedOptionIndex() > 0;
  }

  public canSlideNext(): boolean {
    return this.getLastSelectedOptionIndex() + this.step < this.options.length;
  }

  private getfirstSelectedOptionIndex() {
    const firstSelectedOption = this.selectedOptions.getValue()[0];
    const firstSelectedOptionIndex = this.options.findIndex(
      (opt) => opt === firstSelectedOption
    );

    return firstSelectedOptionIndex;
  }

  private getLastSelectedOptionIndex() {
    const selectedOptionsLength = this.selectedOptions.getValue().length;
    const lastSelectedOption =
      this.selectedOptions.getValue()[selectedOptionsLength - 1];
    const lastSelectedOptionIndex = this.options.findIndex(
      (opt) => opt === lastSelectedOption
    );

    return lastSelectedOptionIndex;
  }

  slidesPorRotaEWidth(): number {
    if (this.router.isActive("prestacao-contas", false)) {
      if (window.innerWidth > 1600) {
        return 8;
      }
      if (window.innerWidth > 1400) {
        return 6;
      }
      if (window.innerWidth > 1200) {
        return 5;
      }
      if (window.innerWidth > 1000) {
        return 4;
      }
    } else {
      return 1;
    }
  }

  // private pickDesign() {
  //   if(this.requiredSelection === 1) {
  //     this.swiper.options['slidesPerView'] = 1;
  //     this.swiper.allowTouchMove = false;
  //     this.carouselConfig['slidesPerView'] = 1;
  //     this.oneSelectionMode = true;

  //     window.setTimeout(() => {
  //       this.swiper?.update();
  //     }, 3000);
  //   }
  // }
}
