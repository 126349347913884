import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomController } from '@ionic/angular';

interface AccessibilityLevelItem {
  cssVariableName: string;
  px: number;
}

interface AccessibilityLevel {
  levelName: string;
  styles: AccessibilityLevelItem[];
}

const defaultVariableValues: AccessibilityLevelItem[] = [
  { cssVariableName: '--px-0', px: 0 },
  { cssVariableName: '--px-1', px: 1 },
  { cssVariableName: '--px-2', px: 2 },
  { cssVariableName: '--px-3', px: 3 },
  { cssVariableName: '--px-4', px: 4 },
  { cssVariableName: '--px-5', px: 3 },
  { cssVariableName: '--px-6', px: 3 },
  { cssVariableName: '--px-7', px: 3 },
  { cssVariableName: '--px-8', px: 8 },
  { cssVariableName: '--px-9', px: 9 },
  { cssVariableName: '--px-10', px: 10 },
  { cssVariableName: '--px-11', px: 11 },
  { cssVariableName: '--px-12', px: 12 },
  { cssVariableName: '--px-13', px: 13 },
  { cssVariableName: '--px-14', px: 14 },
  { cssVariableName: '--px-15', px: 15 },
  { cssVariableName: '--px-16', px: 16 },
  { cssVariableName: '--px-17', px: 17 },
  { cssVariableName: '--px-18', px: 18 },
  { cssVariableName: '--px-19', px: 19 },
  { cssVariableName: '--px-20', px: 20 },
  { cssVariableName: '--px-21', px: 21 },
  { cssVariableName: '--px-22', px: 22 },
  { cssVariableName: '--px-23', px: 23 },
  { cssVariableName: '--px-24', px: 24 },
  { cssVariableName: '--px-25', px: 25 },
  { cssVariableName: '--px-26', px: 26 },
  { cssVariableName: '--px-28', px: 28 },
  { cssVariableName: '--px-29', px: 29 },
  { cssVariableName: '--px-30', px: 30 },
  { cssVariableName: '--px-32', px: 32 },
  { cssVariableName: '--px-35', px: 35 },
  { cssVariableName: '--px-40', px: 40 },
  { cssVariableName: '--px-48', px: 48 },
  { cssVariableName: '--px-50', px: 50 },
  { cssVariableName: '--px-56', px: 56 },
  { cssVariableName: '--px-59', px: 59 },
  { cssVariableName: '--px-70', px: 70 },
  { cssVariableName: '--px-72', px: 72 },
  { cssVariableName: '--px-77', px: 77 },
  { cssVariableName: '--px-90', px: 90 },
  { cssVariableName: '--px-100', px: 100 },
  { cssVariableName: '--px-290', px: 290 },
  { cssVariableName: '--px-302', px: 302 },
];

@Injectable({
  providedIn: 'root'
})
export class AccessibilityLevelSwitcher {
  private accessibilityLevels: AccessibilityLevel[] = [];

  constructor(private domCtrl: DomController, @Inject(DOCUMENT) private document) {
    this.accessibilityLevels = this.generateAccessibilityLevels()
  }

  generateAccessibilityLevels(): AccessibilityLevel[] {
    return [
      {
        levelName: 'default',
        styles: defaultVariableValues,
      },
      {
        levelName: 'big',
        styles: this.increasePixelsInAcessibilityLevelItems(defaultVariableValues, 0.1),
      },
      {
        levelName: 'enormous',
        styles: this.increasePixelsInAcessibilityLevelItems(defaultVariableValues, 0.2),
      },
    ];
  }

  increasePixelsInAcessibilityLevelItems(items: AccessibilityLevelItem[], increasePercentageAmount: number): AccessibilityLevelItem[] {
    return items.map((item: AccessibilityLevelItem) => {
      return { ...item, px: item.px + (item.px * increasePercentageAmount) }
    });
  }

  setAccessibilityLevel(levelName: string): void {
    const level = this.accessibilityLevels.find(accessibilityLevel => accessibilityLevel.levelName === levelName);

    if (level) {
      this.domCtrl.write(() => {
        level.styles.forEach(style => {
          document.documentElement.style.setProperty(style.cssVariableName, style.px + 'px');
        });
      });
    }
    else {
      console.error(`setTheme(): Invalid \"${levelName}\" level.`);
    }
  }

  setAccessibilityLevelByIndex(accessibilityLevelPositionInArray: number) {
    const level = this.accessibilityLevels[accessibilityLevelPositionInArray];

    if (level) {
      this.setAccessibilityLevel(level.levelName);
    }
  }

  getAccessibilityLevelNameByIndex(index: number) {
    return this.accessibilityLevels[index].levelName;
  }

  getAccessibilityLevelNumberByIndex(name: string): number {
    const fontList: any[] = ['default', 'big', 'enormous']
    return fontList.indexOf(name);
  }

}
