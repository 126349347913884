import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent implements OnInit {
  @Input('p-name') name: string;
  @Input('p-is-dismissible') isDismissible: string;
  @Output('p-click') onClick = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit() {}

  emitClick(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
