<div class="busca-itens-area">
    <i class="fas fa-search"></i>
    <input
        #inputBusca
        (focus)="exibirResultados = true"
        (blur)="timeOutExibicao()"
        type="text" 
        placeholder="O que você está buscando?" 
        [(ngModel)]="busca" 
        (ngModelChange)="atualizarBusca()"
        [ngClass]="{'__atalhos': estilo === 'atalhos'}"
        (keydown.enter)="handleClickBusca(this.resultadoBusca.length > 0 ? this.resultadoBusca[0] : null)"
    >
    <i *ngIf="this.busca" (click)="this.busca = ''" class="fa-solid fa-xmark"></i>

    <div class="area-resultado-busca" *ngIf="exibirResultados || busca" >

        <ng-container *ngIf="busca; else placeholderBusca" >
            <div class="sugeridos-container">
                <p *ngIf="resultadoBusca.length > 0" class="sugeridos">resultados sugeridos</p>
                <section *ngIf="resultadoBusca.length > 0; else semResultado"  >
                    <ng-container *ngFor="let item of resultadoBusca" >
                        <div class="item" (click)="handleClickBusca(item)">
                            <i class="fas fa-search"></i>
                            <div class="text-area">
                                <p class="titulo">
                                    <span >{{getTextoDestacado(item?.titulo, true)}}</span>{{ getTextoDestacado(item?.titulo) }}
                                </p>
                                <p *ngIf="item?.subItem" class="rota">{{ item.subItem }}</p>
                            </div>
                        </div>
                    </ng-container>
                </section>
            </div>

            <div class="recentes-container" *ngIf="historico && historico.length > 0" >
                <p class="sugeridos">resultados recentes</p>
                <section >
                    <div class="item" *ngFor="let item of historico" (click)="handleClickBusca(item)" >
                        <i class="fas fa-history"></i>
                        <div class="text-area">
                            <p class="titulo" [innerHTML]="item.titulo" ></p>
                            <p *ngIf="item.subItem" class="rota">{{ item.subItem }}</p>
                        </div>
                    </div>
                </section>
            </div>

            <ng-template #semResultado>
                <section class="sem-resultados">
                    <p class="titulo-sem-resultados">Nenhum resultado encontrado</p>
                    <p class="desc-sem-resultados">Tente usar um termo diferente em sua busca.</p>
                </section>
            </ng-template>

        </ng-container>

        <ng-template #placeholderBusca>
            <section class="placeholder">
                <p class="titulo-placeholder">Digite qualquer termo para iniciar</p>
                <p class="desc-placeholder"><strong>Dica</strong>: Seja claro e direto em sua busca.</p>
            </section>

            <div class="recentes-container" *ngIf="historico && historico.length > 0" >
                <p class="sugeridos">resultados recentes</p>
                <section >
                    <div class="item" *ngFor="let item of historico" (click)="handleClickBusca(item)" >
                        <i class="fas fa-history"></i>
                        <div class="text-area">
                            <p class="titulo" [innerHTML]="item.titulo" ></p>
                            <p *ngIf="item.subItem" class="rota">{{ item.subItem }}</p>
                        </div>
                    </div>
                </section>
            </div>

        </ng-template>

    </div>

</div>