<ion-app>

  <ion-router-outlet></ion-router-outlet>

  <app-mural-cadastrado id="custom-mural-cadastrado" className="__is-open">
  </app-mural-cadastrado>

  <app-ligthboxes-default id="custom-generico" className="__is-open">
  </app-ligthboxes-default>

  <app-text-input-with-confirmation id="text-input-with-confirmation" className="__is-open">
  </app-text-input-with-confirmation>

  <app-text-area-with-confirmation id="text-area-with-confirmation" className="__is-open">
  </app-text-area-with-confirmation>

  <app-image-with-toggle-and-ok-button id="image-with-toggle-and-ok-button" className="__is-open">
  </app-image-with-toggle-and-ok-button>
  
  <app-contratacao-de-cliente id="popup-cliente-contratacao" className="__is-open">
  </app-contratacao-de-cliente>

</ion-app>
