import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoadingScreenService } from "./loading-screen.service";
import { finalize } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
    private urlApiWorkflow = environment.apiMaloteVirtual.hostWorkflow;
    private methods = environment.apiMaloteVirtual.endpoints.methods
    private methodsCondominioDigital = environment.apiCondominioDigital.endpoints.methods
    private host = environment.apiCondominioDigital.host;

    activeRequests: number = 0;

    /**
     * URLs que não aparece o loanding
     */
    skippUrls = [this.urlApiWorkflow + this.methods.getTitulos, this.host + this.methodsCondominioDigital.acessoRapido.favoritos, this.host + this.methodsCondominioDigital.acessoRapido.historico];

    constructor(private loadingScreenService: LoadingScreenService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let displayLoadingScreen = true;

        for (const skippUrl of this.skippUrls) {
            console.log("request.url",request.url)
            if (new RegExp(skippUrl).test(request.url)) {
                displayLoadingScreen = false;
                break;
            }
        }

        if (displayLoadingScreen) {
            if (this.activeRequests === 0) {
                this.loadingScreenService.startLoading();
            }
            this.activeRequests++;

            return next.handle(request).pipe(
                finalize(() => {
                    this.activeRequests--;
                    if (this.activeRequests === 0) {
                        this.loadingScreenService.stopLoading();
                    }
                })
            )
        } else {
            return next.handle(request);
        }
    };

}
