import { FuncionalidadeDTO } from '../response/Funcionalidade.DTO';

export class PessoaLogadaDTO {
    Id: string;
    IdAcesso: string;
    DataUltimoAcesso: string;
    EmailConfirmado: boolean;
    Nome: string;
    CpfCnpj: string;
    Email: string;
    Login: string;
    AcessoGerencial: boolean
    CentralAtendimento: boolean
    perfisColaborador: Array<number>
    funcionalidadesColaborador: Array<FuncionalidadeDTO>
}
