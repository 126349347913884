import { Component, OnInit, Output, EventEmitter, Input, AfterViewChecked, OnChanges } from '@angular/core';
import { BlocoGtDTO } from '../../services/DTO/response/BlocoGt.DTO';
import { ReservaService } from 'src/app/modules/reserva/services/reserva.service';
import { UnidadeDTO } from '../../services/DTO/response/Unidade.DTO';
import { LoginDTO } from 'src/app/modules/login/services/DTO/request/Login.DTO';

@Component({
  selector: 'app-blocos',
  templateUrl: './blocos.component.html',
  styleUrls: ['./blocos.component.scss'],
})
export class BlocosComponent implements OnInit, OnChanges {

  @Output() allBlocks = new EventEmitter();
  @Output() setBloco = new EventEmitter();
  @Input() selected: BlocoGtDTO;
  @Input() foiAbertoPeloDetalheCondomino: boolean;
  @Input() showAllUnits: boolean;

  public blocks: BlocoGtDTO[] = [];
  public block: BlocoGtDTO = new BlocoGtDTO;
  public disabled: boolean = false;

  constructor(private service: ReservaService) { }

  ngOnInit() {

    if (this.selected) {
      this.block = this.selected;
      this.disabled = true;
    }

    this.service.listarBlocosUnidades(this.showAllUnits).subscribe(item => {
      this.blocks = item['Blocks'];
      this.emitAllBlocks();

    }).add(() => {
      if (this.selected) {
        this.block = this.blocks.filter(item => item.Uid === this.selected.Uid)[0];
        this.disabled = true;
        this.selectBlock(this.block);
      }
    });
  }

  ngOnChanges() {
    if(this.selected){   
      this.verificaSeFoiAbertoPeloDetalheCondomino();
    }
  }

  selectBlock(block: BlocoGtDTO) {
    this.block = block;
    this.setBloco.emit(block);
  }

  emitAllBlocks() {
    this.allBlocks.emit(this.blocks)
  }

  verificaSeFoiAbertoPeloDetalheCondomino() {
      this.block = this.selected;
  }

}
