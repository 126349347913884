import { Injectable } from '@angular/core';
import { Cache } from './../utils/storage.provider';
import { environment } from './../../../environments/environment';
import { SessionStorageService } from './session-storage.service';
import { OneSignal } from '@awesome-cordova-plugins/onesignal';
import { Platform } from '@ionic/angular';

let OneSignalBrowser;

const url = '';

@Injectable({
  providedIn: 'root'
})
export class OnsignalService {

  private oneSignalInit;
  public sessionPessoa: {
    id
  } = {
      id: null
    };

  constructor(
    private sessionStorageService: SessionStorageService, 
    private oneSignal: OneSignal, 
    public platform: Platform
    ) {
    this.sessionPessoa = JSON.parse(this.sessionStorageService.get('pessoaLogada'));
    // console.log(this.sessionPessoa)
  }



  public initMobile() {
    this.sessionPessoa = JSON.parse(this.sessionStorageService.get('pessoaLogada'));
    this.oneSignal.startInit(environment.config.onesignalId, environment.config.googleProjectId);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    // this.oneSignal.setExternalUserId('3240f2f4-6805-4107-a1b4-42fae3edd768');
    if (this.sessionPessoa)
    this.oneSignal.sendTags({ user_id: this.sessionPessoa.id, device: this.platform.is('ios') ? 'ios' : (this.platform.is('android')) ? 'android' : 'desktop' })

    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      // console.log(data)
    });

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;

    });

    this.oneSignal.endInit();

  }

  public init() {
    this.sessionPessoa = JSON.parse(this.sessionStorageService.get('pessoaLogada'));

    this.oneSignalInit ? console.log('Already Initialized') : this.addScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', (callback) => {
      // console.log('OneSignal Script Loaded');
      this.initOneSignal();
    })
  }

  addScript(fileSrc, callback) {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = callback;
    script.src = fileSrc;
    head.appendChild(script);
  }

  initOneSignal() {
    OneSignalBrowser = window['OneSignal'] || [];


    // console.log('Init OneSignal');
    OneSignalBrowser.push(['init', {
      appId: environment.config.onesignalId,
      autoRegister: true,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: false,
      },
    }]);
    // console.log('OneSignal Initialized');
    this.checkIfSubscribed();
    this.getTags();
  }

  subscribe() {
    OneSignalBrowser.push(() => {
      OneSignalBrowser.push(['registerForPushNotifications'])
      OneSignalBrowser.on('subscriptionChange', (isSubscribed) => {
        // console.log('The user\'s subscription state is now:', isSubscribed)
        this.listenForNotification();
        OneSignalBrowser.getUserId().then((userId) => {
          // console.log('User ID is', userId);
          // this.oneSignalId = userId;
        });
      });
    });
  }

  listenForNotification() {
    OneSignalBrowser.on('notificationDisplay', (event) => {
      // console.log('OneSignal notification displayed:', event);
      this.listenForNotification();
    });
  }

  getUserID() {
    OneSignalBrowser.getUserId().then((userId) => {
      //this.oneSignalId = userId;
    });
  }

  setTags() {
    if (this.sessionPessoa)
      OneSignalBrowser.sendTags({ user_id: this.sessionPessoa.id, device: 'web' });
  }

  getTags() {
    const sessionPessoa = this.sessionPessoa;
    OneSignalBrowser.getTags().then(function (tags) {
      // console.log(tags)
      if (!Object.keys(tags).length && sessionPessoa) {
        OneSignalBrowser.sendTags({ user_id: sessionPessoa.id, device: 'web' });
      }
    });
  }

  checkIfSubscribed() {
    OneSignalBrowser.push(() => {
      OneSignalBrowser.isPushNotificationsEnabled((isEnabled, error) => {
        // console.log(error)
        if (isEnabled) {
          // console.log('Push notifications are enabled!');
          this.getUserID();
        } else {
          // console.log('Não está habilitado');
          this.subscribe();
        }
      }, (error) => {
        // console.log('Não permitiu');
      });
    });
  }
}
