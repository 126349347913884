<div
  *ngIf="mostUsedFeatures"
  class="MostUsedFeaturesComponent"
  [ngClass]="{'__is-compact': design === 'compact'}"
>
  <ds-light-collapse p-title="Mais acessados" p-is-open="true">
    <!--Propriedade auto não está funcional :/-->
    <div *ngIf="tipoSlider() === 1" >
      
      <div class="swiper" >
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngIf="this.stateService.possuiAcessoFuncionalidade(219) || this.stateService.possuiAcessoFuncionalidade(220)" >
            <app-quick-card
              title="2ª Via de boletos"
              link="/cotas"
              icon="apsa-icon-colorido-cotas"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide">
            <app-quick-card
              title="Extrato"
              link="/extrato/sindico"
              icon="apsa-icon-colorido-extrato"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide">
            <app-quick-card
              title="Normas e documentos"
              *ngIf="this.stateService.possuiAcessoFuncionalidade(810)"
              link="/documento"
              icon="apsa-icon-colorido-malotes"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide">
            <app-quick-card
              *ngIf="this.stateService.possuiAcessoFuncionalidade(215) || this.stateService.possuiAcessoFuncionalidade(216) || this.stateService.possuiAcessoFuncionalidade(217)"
              title="Prestação de contas"
              link="/prestacao-contas"
              icon="apsa-icon-colorido-financeiro"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide">
            <app-quick-card
              [id]="52"
              title="Atalhos"
              link="/menu"
              icon="apsa-icon-solid-menu-funcionalidade"
              [compact]="design === 'compact'"  
          ></app-quick-card>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    

    <div *ngIf="tipoSlider() === 2">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngIf="this.stateService.possuiAcessoFuncionalidade(218)" >
            <app-quick-card
              title="Extrato"
              link="/extrato/sindico"
              icon="apsa-icon-colorido-extrato"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide" *ngIf="this.stateService.possuiPerfilProprietario()" >
            <app-quick-card
              title="Extrato"
              link="/extrato/proprietario"
              icon="apsa-icon-colorido-extrato"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide" *ngIf="this.stateService.possuiAcessoFuncionalidade(215) || this.stateService.possuiAcessoFuncionalidade(216) || this.stateService.possuiAcessoFuncionalidade(217)" >
            <app-quick-card
              title="Prestação de contas"
              link="/prestacao-contas"
              icon="apsa-icon-colorido-financeiro"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide" *ngIf="this.stateService.possuiAcessoFuncionalidade(306)" >
            <app-quick-card
              title="Envio de Documentos"
              link="/envio-documento"
              icon="apsa-icon-colorido-envio-documentos"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide" *ngIf="this.stateService.possuiAcessoFuncionalidade(885)" >
            <app-quick-card
              [id]="51"
              title="Relação inadimplência"
              link="/cotas-inadimplentes"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
          <div class="swiper-slide">
            <app-quick-card
              [id]="52"
              title="Atalhos"
              link="/menu"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div *ngIf="tipoSlider() === 0">
      <div class="swiper" >
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <app-quick-card
              [id]="1"
              title="Cotas"
              link="/cotas"
              icon="apsa-icon-colorido-cotas"
              [compact]="design === 'compact'"
            ></app-quick-card>
          </div>

          <ng-container *ngFor="let feature of mostUsedFeatures" >
            <div class="swiper-slide" *ngIf="feature.id !== 1">
                <app-quick-card
                  [id]="feature.id"
                  [title]="returnNome(feature)"
                  [link]="feature.rota"
                  [icon]="feature.icon"
                  [compact]="design === 'compact'"
              ></app-quick-card>
            </div>
          </ng-container>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </ds-light-collapse>
</div>
