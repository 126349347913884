import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { TermoAceiteEspacoDTO } from 'src/app/modules/reserva/services/DTO/response/TermoAceiteEspaco.DTO';
import { StateService } from '../../services/stateService';
import { environment } from 'src/environments/environment';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal-termos',
  templateUrl: './modal-termos.component.html',
  styleUrls: ['./modal-termos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalTermosComponent implements OnInit, OnDestroy {
  @Input() open: boolean;
  @Input() termoAceiteEspaco: TermoAceiteEspacoDTO;
  @Output() eventClose = new EventEmitter();

  private urlDownload: string = environment.apiGT.endpoints.checklistreports.get.downloadArquivoGT;
  private condLogado = this.stateService.getCondominioLogado();

  constructor(
    public stateService: StateService,
    private modalService: ModalService,
  ) { }

  ngOnDestroy() {
    this.modalService.removeHasModalClassFromContainerDiv();
  }

  ngOnInit() { }

  baixarTermo(obj) {
    var url: string = null;
    if (obj.Space)
      url = this.urlDownload + "?TipoArquivo=1&TermoAceiteEspacoUid={0}&download=true".replace("{0}", obj.AcceptanceTermFile.Uid)
    else
      url = this.urlDownload + "?TipoArquivo=2&Agencia={0}&Conta={1}&download=true".replace("{0}", this.condLogado.Agencia).replace("{1}", this.condLogado.ContaEmpresa)

    this.stateService.openExternalLink(url);
  }

  close() {
    this.eventClose.emit(false);
    this.stateService.exibirFooterHeaderForModal();
  }


}
