<div class="favoritos" *ngIf="favoritos && favoritos.length > 0">
    <section class="titulo-editar-area">
        <p class="titulo">Favoritos</p>
        <p class="editar" (click)="openModalEdicao()">
            Editar
            <i class="fas fa-edit"></i>
        </p>
    </section>

    <div class="swiper-area" *ngIf="favoritos && favoritos.length > 0 && swiperUpdated" >
        <swiper-container #swiper [slidesPerView]="getSlidesPerView()" [spaceBetween]="1" [pagination]="true" [init]="false" >
            <swiper-slide *ngFor="let favorito of favoritos">
                <div class="card-favorito" (click)="handleClick(favorito)">
                    <div class="img-wrapper" >
                        <ng-container *ngIf="getTipoImg(favorito?.img) === 2 || getTipoImg(favorito?.img) === 3; else icone" >
                            <div *ngIf="getTipoImg(favorito?.img) === 2" [innerHTML]="favorito?.img" ></div>
                            <img *ngIf="getTipoImg(favorito?.img) === 3" [src]="favorito?.img" alt="logo">
                        </ng-container>
    
                        <ng-template #icone >
                            <i [class]="favorito?.img" ></i>
                        </ng-template>
                    </div>
                    <p class="titulo" [ngClass]="{'__small': favorito?.titulo?.length > 28 }" > 
                        {{ favorito?.titulo?.length > 42 ? favorito?.titulo?.slice(0,42).trim() : favorito?.titulo }}{{favorito?.titulo?.length > 42 ? '...' : '' }} 
                    </p>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</div>