<ion-app>
  <div class="Sidebar Sidebar-ios"
    [ngClass]="{'__is-openMobile': this.constant.statusMenu, '__is-close': !this.constant.statusMenu}">

    <div class="Sidebar_Close" (click)="closeMenuMobile()">
      <i class="fas fa-chevron-left"></i>
    </div>

    <div class="Sidebar_Content">
      <ng-container *ngIf="!isAdmView">
        <div *ngIf="this.numeroDeCondominios > 4" class="Sidebar_Content_Search">
          <div class="Content_Search_Input">
            <input type="text" placeholder="Busque seus condomínios" (keyup)="searchEvent(buscaElemento)"
              #buscaElemento>

            <div class="Search_Input_IconSearch">
              <i class="fas fa-search"></i>
            </div>

            <div class="Search_Input_IconClean" (click)="limparFiltroMenu()">
              <i class="fas fa-times"></i>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="this.stateService.possuiAcessoCentralAtendimento()" class="MenuList">
        <div class="MenuList_Title">GERENCIAL</div>
        <div *ngIf="this.stateService.possuiAcessoCentralAtendimento()"
           class="MenuList_Content">
          <div class="MenuList_Content_Item" >
            <div class="Content_Item_Label" (click)="this.stateService.redirecionarCentralAtendimento();">
              <div class="Item_Label_Name">
                CENTRAL DE ATENDIMENTO
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
            <!-- <pre>{{possuiAcessoControlePagamentos}}</pre> -->
            <div *ngIf="possuiAcessoControlePagamentos" class="Content_Item_Label" routerLink="/centralpagamentos">
              <div class="Item_Label_Name">
                CONTROLE DE PAGAMENTOS
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.stateService.possuiPerfilPrestadorServico()" class="MenuList">
        <div class="MenuList_Title">PRESTADOR DE SERVIÇOS</div>
        <div (click)="this.stateService.redirecionarPrestadorServico()"
          *ngIf="this.stateService.possuiPerfilPrestadorServico();" class="MenuList_Content">
          <div class="MenuList_Content_Item">
            <div class="Content_Item_Label">
              <div class="Item_Label_Name">
                INFORME DE RENDIMENTO
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.stateService.usuarioPossuiAcessoARedeDeParcerias()" class="MenuList">
        <div class="MenuList_Title">GERENCIAL</div>
        <div class="MenuList_Content" *ngIf="stateService.usuarioPossuiAcessoARedeDeParcerias()"
          (click)="stateService.redirecionarRedeDeParcerias()">
          <div class="MenuList_Content_Item __is-open">
            <div class="Content_Item_Submenu">
              <div class="Item_Submenu_Content ">
                <div class="Submenu_Content_Item dashboard_submenu">
                  <a class="Content_Item_Current Dashboard_Parcerias_Item">
                    <div class="Item_Current_Icon">
                      <img src="../../../assets/svg/dashboard-rede-parcerias.svg" />
                    </div>

                    <div class="Item_Current_Name __is-dashboard">
                      PAINEL DE GESTÂO
                    </div>
                  </a>

                  <div class="Item_Label_Icon">
                    <i class="fas fa-chevron-right"></i>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div
        *ngIf="this.stateService.possuiPerfilGerencial() && (this.stateService.possuiPerfilColaboradorTiApsa() || this.stateService.pessoaPossuiAcessoFuncionalidade(890))"
        class="MenuList">
        <div class="MenuList_Title">UTILIDADES</div>

        <div class="MenuList_Content">
          <div class="MenuList_Content_Item"
            *ngIf="this.stateService.possuiPerfilGerencial() && this.stateService.pessoaPossuiAcessoFuncionalidade(891)"
            (click)="this.stateService.redirecionarEvidenciasEmail()">
            <div class="Content_Item_Label">
              <div class="Item_Label_Name">
                EVIDÊNCIAS DE ENVIO DE E-MAILS
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="MenuList_Content">
          <div class="MenuList_Content_Item"
            *ngIf="this.stateService.possuiPerfilGerencial() && this.stateService.pessoaPossuiAcessoFuncionalidade(891)"
            (click)="this.stateService.redirecionarConsultarNovosDocs()">
            <div class="Content_Item_Label">
              <div class="Item_Label_Name">
                CONSULTAR NOVOS DOCUMENTOS
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="MenuList_Content">
          <div class="MenuList_Content_Item"
            *ngIf="this.stateService.possuiPerfilGerencial() && this.stateService.pessoaPossuiAcessoFuncionalidade(891)"
            (click)="this.stateService.redirecionarTarefasAutomaticas()">
            <div class="Content_Item_Label">
              <div class="Item_Label_Name">
                TAREFAS AUTOMÁTICAS
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
        
        <div class="MenuList_Content">
          <div class="MenuList_Content_Item"
            *ngIf="this.stateService.possuiPerfilGerencial() && this.stateService.pessoaPossuiAcessoFuncionalidade(891)"
            routerLink="/consulta-cpf" routerLinkActive="active"
          >
            <div class="Content_Item_Label">
              <div class="Item_Label_Name">
                CONSULTA POR CPF
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- <div *ngIf="this.stateService.possuiPerfilProcuradorParaEmissaoBoleto()" class="MenuList">
        <div class="MenuList_Title">PROCURADOR</div>
        <div (click)="this.stateService.redirecionarEmissaoBoletoProcurador()" *ngIf="this.stateService.possuiPerfilProcuradorParaEmissaoBoleto();" class="MenuList_Content">
          <div class="MenuList_Content_Item">
            <div class="Content_Item_Label">
              <div class="Item_Label_Name">
                EMISSÃO DE BOLETOS
              </div>
              <div class="Item_Label_Icon">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div *ngIf="isAdmView" class="MenuList">
        <div class="MenuList_Title">MENU</div>

        <div class="MenuList_Content">
          <div *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(836)" class="MenuList_Content_Item __is-open">
            <div class="Content_Item_Submenu">
              <div class="Item_Submenu_Content">
                <div class="Submenu_Content_Item" [ngClass]="{ '__is-active': isRoute('/clientes-contratacao') }">
                  <a routerLink="/clientes-contratacao" routerLinkActive="active" class="Content_Item_Current">
                    <div class="Item_Current_Icon">
                      <span class="apsa-icon-colorido-funcionarios">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                      </span>
                    </div>

                    <div class="Item_Current_Name">
                      Implantação de Clientes
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="!stateService.possuiPerfilCentralAtendimento() && stateService.pessoaPossuiAcessoFuncionalidade(842)"
            class="MenuList_Content_Item __is-open">
            <div class="Content_Item_Submenu">
              <div class="Item_Submenu_Content">
                <div class="Submenu_Content_Item" [ngClass]="{ '__is-active': isRoute('/chame-moto') }">
                  <a routerLink="/chame-moto" routerLinkActive="active" class="Content_Item_Current">
                    <div class="Item_Current_Icon __is-entrega-expressa">
                      <span class="apsa-icon-colorido-motorcycle">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                        <span class="path5"></span>
                        <span class="path6"></span>
                        <span class="path7"></span>
                        <span class="path8"></span>
                        <span class="path9"></span>
                        <span class="path10"></span>
                      </span>
                    </div>

                    <div class="Item_Current_Name">
                      Chame a Moto
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(851)" class="MenuList_Content_Item __is-open">
            <div class="Content_Item_Submenu">
              <div class="Item_Submenu_Content">
                <div class="Submenu_Content_Item" [ngClass]="{ '__is-active': isRoute('/envio-documento-geral') }">
                  <a routerLink="/envio-documento-geral" routerLinkActive="active" class="Content_Item_Current">
                    <!-- <div class="Item_Current_Icon __is-entrega-expressa">
                      <span class="apsa-icon-colorido-entrega-expressa-colorido">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                        <span class="path5"></span>
                      </span>
                    </div> -->

                    <div class="Item_Current_Name">
                      Relatório de Envio de Documentos
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(833)" class="MenuList_Content_Item __is-open">
            <div class="Content_Item_Submenu">
              <div class="Item_Submenu_Content">
                <div class="Submenu_Content_Item" [ngClass]="{ '__is-active': isRoute('/relatorio-pagamentos') }">
                  <a routerLink="/relatorio-pagamentos" routerLinkActive="active" class="Content_Item_Current">
                    <!-- <div class="Item_Current_Icon __is-entrega-expressa">
                      <span class="apsa-icon-colorido-entrega-expressa-colorido">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                        <span class="path5"></span>
                      </span>
                    </div> -->

                    <div class="Item_Current_Name">
                      Relatório de Pagamentos
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- <div *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(856)" class="MenuList_Content_Item __is-open">
            <div class="Content_Item_Submenu">
              <div class="Item_Submenu_Content">
                <div
                  class="Submenu_Content_Item"
                  [ngClass]="{ '__is-active': rotaAtual.startsWith('/processamento-de-documentos') }"
                >
                  <a routerLink="/processamento-de-documentos" routerLinkActive="active" class="Content_Item_Current">
                     <span class="apsa-icon-colorido-envio-documentos">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                      <span class="path4"></span>
                      <span class="path5"></span>
                      <span class="path6"></span>
                    </span> 

                    <div class="Item_Current_Name">
                      Documentos Não Tratados
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <ng-container *ngIf="!isAdmView">
        <div class="MenuList" *ngIf="(menuList | filterMenu:filterCondominioMenu).length > 0">
          <div class="MenuList_Title">Condomínios</div>

          <div class="MenuList_Content">
            <div *ngFor="let item of menuList | filterMenu:filterCondominioMenu;" class="MenuList_Content_Item"
              [ngClass]="{'__is-open': isMenuActive(item) && !isMenuAdmOpen}">
              <div class="Content_Item_Label" (click)="selecionarMenu(item)">
                <div class="Item_Label_Name">
                  {{item.label}}
                  <span class="Label_Name_Agencia">{{ item.agenciaConta }}</span>
                </div>

                <div class="Item_Label_Icon">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>

              
              <div class="Content_Item_Submenu" *ngIf="isMenuActive(item) && !isMenuAdmOpen">
                <div class="Item_Submenu_Content">
                  
                  <app-busca-lista-historico (click)="displayModalBusca = true" ></app-busca-lista-historico>
                  
                  <div *ngIf="stateService.possuiPerfilCondomino() || stateService.possuiPerfilProcuradorParaEmissaoBoleto()" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && (rotaAtual === '/cotas' || rotaAtual === '/cotas/visualizacaoIndividual' )}">
                    <a class="Content_Item_Current" routerLink="/cotas/visualizacaoIndividual" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <img src="../../../assets/icon/segunda-via-boletos-default.svg" />
                      </div>

                      <div class="Item_Current_Name">
                        2ª via de boletos
                      </div>
                    </a>
                  </div>

                  <div
                    *ngIf="stateService.possuiPerfilSindico() || this.stateService.possuiPerfilGerencial() || this.stateService.possuiPerfilConselheiro()"
                    class="Submenu_Content_Item" [ngClass]="{ '__is-active': rotaAtual === '/arquivo-digital'}"
                  >
                    <a class="Content_Item_Current" routerLink="/arquivo-digital" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <i class="fas fa-compact-disc" style="color: #fff" ></i>
                      </div>

                      <div class="Item_Current_Name">
                        Arquivo Digital
                      </div>
                    </a>
                  </div>

                  <div
                    *ngIf="(possuiPermissaoAcesso(205) || possuiPermissaoAcesso(819) || (stateService.permitirEntregaExpressa() === 0 && stateService.possuiAcessoFuncionalidade(206)))"
                    class="Submenu_Content_Item" [ngClass]="{
                    '__is-open' :  isMenuItemOpen('o-condominio') || isRoute('/dados-condominio', '/cadastro-de-espacos', '/calendario-visitas', '/achados-perdidos'),
                    '__is-active': isRoute('/dados-condominio', '/cadastro-de-espacos', '/calendario-visitas', '/achados-perdidos')
                  }">
                    <div class="Content_Item_Current" (click)="openMenuItem('o-condominio')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-condominio-digital">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                          <span class="path12"></span>
                          <span class="path13"></span>
                          <span class="path14"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        O Condomínio
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div *ngIf="ehGestaoTotal && possuiPermissaoAcesso(292)" class="Item_Sub_Option">
                        <a routerLink="/achados-perdidos" routerLinkActive="active">
                          <div class="Sub_Option_Icon">
                            <i class="fas fa-box-open" style="color: #fff; margin-left: -4px;"></i>
                          </div>

                          <div class="Sub_Option_Name">
                            Achados e perdidos
                          </div>
                        </a>
                      </div>
                      <div *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(292)" class="Item_Sub_Option">
                        <a routerLink="/achados-perdidos-geral" routerLinkActive="active">
                          <div class="Sub_Option_Icon">
                            <i class="fas fa-box-open" style="color: #fff; margin-left: -4px;"></i>
                          </div>

                          <div class="Sub_Option_Name">
                            <!-- Achados e perdidos para condominios que não é GT -->
                            Achados e perdidos
                          </div>
                        </a>
                      </div>

                      <div *ngIf="possuiPermissaoAcesso(205)" class="Item_Sub_Option">
                        <a routerLink="/dados-condominio" routerLinkActive="active">
                          <div class="Sub_Option_Icon">
                            <span class="apsa-icon-colorido-sobre-condominio-colorido">
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                              <span class="path4"></span>
                              <span class="path5"></span>
                              <span class="path6"></span>
                              <span class="path7"></span>
                              <span class="path8"></span>
                              <span class="path9"></span>
                              <span class="path10"></span>
                              <span class="path11"></span>
                              <span class="path12"></span>
                              <span class="path13"></span>
                              <span class="path14"></span>
                              <span class="path15"></span>
                              <span class="path16"></span>
                              <span class="path17"></span>
                              <span class="path18"></span>
                              <span class="path19"></span>
                              <span class="path20"></span>
                              <span class="path21"></span>
                              <span class="path22"></span>
                              <span class="path23"></span>
                              <span class="path24"></span>
                              <span class="path25"></span>
                              <span class="path26"></span>
                              <span class="path27"></span>
                              <span class="path28"></span>
                              <span class="path29"></span>
                              <span class="path30"></span>
                              <span class="path31"></span>
                              <span class="path32"></span>
                            </span>
                          </div>

                          <div class="Sub_Option_Name">Dados do Condomínio</div>
                        </a>
                      </div>

                      <div *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(819)" class="Item_Sub_Option">
                        <a routerLink="/cadastro-de-espacos" routerLinkActive="active">
                          <div class="Sub_Option_Icon">
                            <span class="apsa-icon-colorido-espacos-colorido">
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                            </span>
                          </div>

                          <div class="Sub_Option_Name">Espaços</div>
                        </a>
                      </div>

                      <div
                        *ngIf="stateService.permitirEntregaExpressa() === 0 && stateService.possuiAcessoFuncionalidade(206)"
                        class="Item_Sub_Option">
                        <a routerLink="/calendario-visitas" routerLinkActive="active">
                          <div class="Sub_Option_Icon calendarioVisitasIcon">
                            <i class="far fa-calendar-check"></i>
                          </div>

                          <div class="Sub_Option_Name">
                            Calendário de visitas
                          </div>
                        </a>
                      </div>

                      <!-- <div *ngIf="ehGestaoTotal && possuiPermissaoAcesso(801)" class="Item_Sub_Option">
                        <a routerLink="/cadastro-de-espacos-gt" routerLinkActive="active">
                          <div class="Sub_Option_Icon">
                            <span class="apsa-icon-colorido-espacos-colorido">
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                            </span>
                          </div>
  
                          <div class="Sub_Option_Name">Espaços</div>
                        </a>
                      </div> -->
                    </div>
                  </div>

                  <div
                    *ngIf="possuiPermissaoAcesso(211) && (!this.stateService.possuiApenasPerfilCondomino() && !this.stateService.possuiApenasPerfilMoradorGT())"
                    class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/condomino'}">
                    <a class="Content_Item_Current" routerLink="/condomino" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-condominio-digital">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                          <span class="path12"></span>
                          <span class="path13"></span>
                          <span class="path14"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Condôminos
                      </div>
                    </a>
                  </div>

                  <div *ngIf="possuiPermissaoAcesso(810)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/documento'}">
                    <a class="Content_Item_Current" routerLink="/documento" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-malotes">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Normas e Documentos
                      </div>
                    </a>
                  </div>

                  <div *ngIf="possuiPermissaoAcesso(283) || possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)"
                    class="Submenu_Content_Item" [ngClass]="{
                      '__is-open' :  isMenuItemOpen('financeiro') || isRoute('/cotas') || isRoute('/prestacao-conta-digital') || isRoute('/quitacao-anual') || isRoute('/acompanhamento-orcamentario') || isRoute('/cotas-inadimplentes') || isRoute('/receitas-previstas') || isRoute('/prestacao-contas') || isRoute('/extrato/sindico'),
                      '__is-active': isRoute('/cotas') || isRoute('/prestacao-conta-digital') || isRoute('/quitacao-anual') || isRoute('/acompanhamento-orcamentario') || isRoute('/cotas-inadimplentes') || isRoute('/receitas-previstas') || isRoute('/prestacao-contas') || isRoute('/extrato/sindico')
                    }">
                    <div class="Content_Item_Current" (click)="openMenuItem('financeiro')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-financeiro">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Financeiro
                      </div>
                    </div>

                    <!--NOVO EXTRATO-->
                    <div class="Content_Item_Sub" *ngIf="possuiPermissaoAcesso(218)">
                      <div class="Item_Sub_Option">
                        <a routerLink="/extrato/sindico" routerLinkActive="active">
                          <div class="Sub_Option_Name">Extrato</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub" *ngIf="possuiPermissaoAcesso(214)">
                      <div class="Item_Sub_Option">
                        <a routerLink="/acompanhamento-orcamentario" routerLinkActive="active">
                          <div class="Sub_Option_Name">Acompanhamento orçamentário</div>
                        </a>
                      </div>
                    </div>

                    <div *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)" class="Content_Item_Sub">
                      <div *ngIf="true" class="Item_Sub_Option">
                        <a routerLink="/cotas" routerLinkActive="active">
                          <!-- <div class="Sub_Option_Icon">
                            <span class="apsa-icon-colorido-cotas">
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                              <span class="path4"></span>
                              <span class="path5"></span>
                            </span>
                          </div> -->

                          <div *ngIf="this.stateService.possuiApenasPerfilCondomino(); then segundaVia else cotas">
                          </div>

                          <ng-template #segundaVia>
                            <div class="Sub_Option_Name">2ª via de boletos</div>
                          </ng-template>

                          <ng-template #cotas>
                            <div class="Sub_Option_Name">Cotas</div>
                          </ng-template>
                        </a>
                      </div>
                    </div>

                    <div *ngIf="possuiPermissaoAcesso(283)" class="Content_Item_Sub">
                      <div *ngIf="true" class="Item_Sub_Option">
                        <a routerLink="/prestacao-conta-digital" routerLinkActive="active">
                          <!-- <div class="Sub_Option_Icon">
                            Ícone prestação contas digital
                          </div> -->

                          <div class="Sub_Option_Name">Pasta de prestação de contas digital</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub"
                      *ngIf="possuiPermissaoAcesso(215) || possuiPermissaoAcesso(216) || possuiPermissaoAcesso(217)">
                      <div class="Item_Sub_Option">
                        <a routerLink="/prestacao-contas" routerLinkActive="active">
                          <div class="Sub_Option_Name">Prestação de contas</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub" *ngIf="possuiPermissaoAcesso(885)">
                      <div class="Item_Sub_Option">
                        <a routerLink="/cotas-inadimplentes" routerLinkActive="active">
                          <div class="Sub_Option_Name">Relatório de Inadimplentes</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub" *ngIf="possuiPermissaoAcesso(226)">
                      <div class="Item_Sub_Option">
                        <a routerLink="/receitas-previstas" routerLinkActive="active">
                          <div class="Sub_Option_Name">Receitas previstas</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div *ngIf="this.verificaContaPool() && possuiPermissaoAcessoValidacaoCentralAtendimento(835)"
                        class="Item_Sub_Option">
                        <a routerLink="/quitacao-anual" routerLinkActive="active">
                          <!-- <div class="Sub_Option_Icon">
                            <span class="apsa-icon-colorido-colorido-informe-quitacao"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>
                          </div> -->

                          <div class="Sub_Option_Name">Quitação Anual</div>
                        </a>
                      </div>
                    </div>

                    <div
                      *ngIf="this.stateService.condominioPodeExibirCartao() && possuiPermissaoAcessoValidacaoCentralAtendimento(833)"
                      class="Content_Item_Sub">
                      <div class="Item_Sub_Option">
                        <a routerLink="/relatorio-pagamentos" routerLinkActive="active">
                          <!-- <div class="Sub_Option_Icon">
                            <span class="apsa-icon-colorido-cotas">
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                              <span class="path4"></span>
                              <span class="path5"></span>
                            </span>
                          </div> -->

                          <div class="Sub_Option_Name">Relatório de pagamentos</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="ehGestaoTotal && possuiPermissaoAcesso(285) && !perfilLogadoEhGestorOuSindico"
                    class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/ocorrenciaGT'}">
                    <a class="Content_Item_Current" routerLink="/ocorrenciaGT" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-fale-com-gestor">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Fale com o síndico
                      </div>
                    </a>
                  </div>

                  <div *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(816) && !perfilLogadoEhGestorOuSindico"
                    class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/ocorrencia'}">
                    <a class="Content_Item_Current" routerLink="/ocorrencia" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-fale-com-gestor">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Fale com o síndico
                      </div>
                    </a>
                  </div>

                  <div
                    *ngIf="!item.ref.ehGestaoTotal && (possuiPermissaoAcesso(823) || possuiPermissaoAcesso(822)) && (this.stateService.possuiApenasPerfilCondomino() || this.stateService.possuiApenasPerfilMoradorGT())"
                    class="Submenu_Content_Item" [ngClass]="{
                    '__is-open': isMenuItemOpen('reserva') || isRoute('/reserva-conv', '/reserva-configuracao'),
                    '__is-active': isRoute('/reserva-conv', '/reserva-configuracao')
                  }">
                    <div class="Content_Item_Current" (click)="openMenuItem('reserva')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </div>
                      <div class="Item_Current_Name">
                        Reservas
                      </div>
                    </div>
                    <div class="Content_Item_Sub">
                      <div *ngIf="!item.ref.ehGestaoTotal && possuiPermissaoAcesso(823)" class="Item_Sub_Option">
                        <a routerLink="/reserva-conv" routerLinkActive="active">
                          <div class="Sub_Option_Name">Fazer Reserva</div>
                        </a>
                      </div>
                      <div *ngIf="!item.ref.ehGestaoTotal && possuiPermissaoAcesso(822)" class="Item_Sub_Option">
                        <a routerLink="/reserva-configuracao" routerLinkActive="active">
                          <div class="Sub_Option_Name">Configuração de Reservas</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="item.ref.ehGestaoTotal && (possuiPermissaoAcesso(286) || possuiPermissaoAcesso(294) || possuiPermissaoAcesso(304) || possuiPermissaoAcesso(295)) && (this.stateService.possuiApenasPerfilCondomino() || this.stateService.possuiApenasPerfilMoradorGT())"
                    class="Submenu_Content_Item" [ngClass]="{
                      '__is-open': isMenuItemOpen('reserva') || isRoute('/reserva', '/disponibilidade'),
                      '__is-active': isRoute('/reserva', '/disponibilidade')
                    }">
                    <div class="Content_Item_Current" (click)="openMenuItem('reserva')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </div>
                      <div class="Item_Current_Name">
                        Reservas
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div class="Item_Sub_Option">
                        <a routerLink="/reserva" routerLinkActive="active">
                          <div class="Sub_Option_Name">Fazer Reserva</div>
                        </a>
                      </div>
                      <div *ngIf="possuiPermissaoAcesso(295)" class="Item_Sub_Option">
                        <a routerLink="/disponibilidade" routerLinkActive="active">
                          <div class="Sub_Option_Name">Gerenciar Disponibilidade</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="possuiPermissaoAcesso(211) && (this.stateService.possuiApenasPerfilCondomino() || this.stateService.possuiApenasPerfilMoradorGT())"
                    class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/condomino'}">
                    <a class="Content_Item_Current" routerLink="/condomino" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-condominio-digital">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                          <span class="path12"></span>
                          <span class="path13"></span>
                          <span class="path14"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Condôminos
                      </div>
                    </a>
                  </div>

                  <div class="Submenu_Content_Item"
                    *ngIf="(possuiPermissaoAcesso(227) || possuiPermissaoAcesso(276) || possuiPermissaoAcesso(243) || (possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || (ehGestaoTotal && possuiPermissaoAcesso(293))) || stateService.possuiPerfilGerencial()"
                    [ngClass]="{
                      '__is-open': isMenuItemOpen('gestaoDePessoal') || 
                      isRoute(
                        '/funcionario', 
                        '/colaboradores', 
                        '/minhas-solicitacoes/cadastro/34', 
                        '/minhas-solicitacoes/cadastro/35', 
                        '/minhas-solicitacoes/cadastro/36', 
                        '/minhas-solicitacoes/cadastro/37',
                        '/lancamento-proventos-descontos'
                        ),
                      '__is-active': isRoute(
                        '/funcionario', 
                        '/colaboradores', 
                        '/minhas-solicitacoes/cadastro/34', 
                        '/minhas-solicitacoes/cadastro/35', 
                        '/minhas-solicitacoes/cadastro/36', 
                        '/minhas-solicitacoes/cadastro/37',
                        '/lancamento-proventos-descontos'
                        )
                    }">
                    <div class="Content_Item_Current" (click)="openMenuItem('gestaoDePessoal')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-funcionarios">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Gestão de Pessoal
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div class="Item_Sub_Option"
                        *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/minhas-solicitacoes/cadastro/38" routerLinkActive="active">
                          <div class="Sub_Option_Name">Admissão de Funcionário</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option" *ngIf="(ehGestaoTotal && possuiPermissaoAcesso(293)) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/colaboradores" routerLinkActive="active">
                          <div class="Sub_Option_Name">Colaboradores</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option"
                        *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial() ">
                        <a routerLink="/minhas-solicitacoes/cadastro/35" routerLinkActive="active">
                          <div class="Sub_Option_Name">Concessão de Férias do Funcionário</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option" *ngIf="possuiPermissaoAcesso(227) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/funcionario" routerLinkActive="active">
                          <div class="Sub_Option_Name">Funcionário</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option"
                        *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial() ">
                        <a routerLink="/minhas-solicitacoes/cadastro/36" routerLinkActive="active">
                          <div class="Sub_Option_Name">Informação/Alteração de Horas Extras</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option" *ngIf="possuiPermissaoAcesso(276) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/quadro-horarios" routerLinkActive="active">
                          <div class="Sub_Option_Name">Jornada de Trabalho</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option" *ngIf="possuiPermissaoAcesso(243) || (this.stateService.possuiPerfilFuncionario()) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/lancamento-proventos-descontos" routerLinkActive="active">
                          <div class="Sub_Option_Name">Lançamento de proventos e descontos</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option"
                        *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/minhas-solicitacoes/cadastro/39" routerLinkActive="active">
                          <div class="Sub_Option_Name">Recrutamento e Seleção de Funcionário com Sindicância</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option"
                        *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/minhas-solicitacoes/cadastro/34" routerLinkActive="active">
                          <div class="Sub_Option_Name">Simulação/Realização de Rescisão de Funcionário</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option"
                        *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/minhas-solicitacoes/cadastro/40" routerLinkActive="active">
                          <div class="Sub_Option_Name">Sindicância do Funcionário</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option"
                        *ngIf="(possuiPermissaoAcesso(853) && possuiPermissaoAcesso(854)) || stateService.possuiPerfilGerencial()">
                        <a routerLink="/minhas-solicitacoes/cadastro/37" routerLinkActive="active">
                          <div class="Sub_Option_Name">Supressão de Horas Extras</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="possuiPermissaoAcesso(306)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openGestaoPessoal && !item.openComunicacao && !item.openReserva) && rotaAtual === '/envio-pagamentos'}">
                    <a class="Content_Item_Current" routerLink="/envio-pagamentos" routerLinkActive="active">
                      <div class="Item_Current_IconEnvioDeDocumentos">
                        <img src="../../../assets/img/envioDePagamentosMenu.png" alt="icone envio de pagamentos">
                      </div>
                      <div class="Item_Current_Name">
                        Envio de Pagamentos
                      </div>
                    </a>
                  </div>
                  
                  <div *ngIf="possuiPermissaoAcesso(306)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openGestaoPessoal && !item.openComunicacao && !item.openReserva) && rotaAtual === '/envio-documento'}">
                    <a class="Content_Item_Current" routerLink="/envio-documento" routerLinkActive="active">
                      <div class="Item_Current_IconEnvioDeDocumentos">
                        <span class="apsa-icon-colorido-envio-documentos">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                        </span>
                      </div>
                      <div class="Item_Current_Name">
                        Envio de Documentos
                      </div>
                    </a>
                  </div>

                  <div
                    *ngIf="this.stateService.permitirEntregaExpressa() != 0 && possuiPermissaoAcessoValidacaoCentralAtendimento(842)"
                    class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openGestaoPessoal && !item.openComunicacao && !item.openReserva) && rotaAtual === '/chame-moto'}">
                    <a class="Content_Item_Current" routerLink="/chame-moto" routerLinkActive="active">
                      <div class="Item_Current_Icon __is-entrega-expressa">
                        <span class="apsa-icon-colorido-motorcycle">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Chame a Moto
                      </div>
                    </a>
                  </div>

                  <div *ngIf="item.ref.ehGestaoTotal && possuiPermissaoAcesso(287)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/checklist'}">
                    <a routerLink="/checklist" routerLinkActive="active" class="Content_Item_Current">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-check">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Checklist
                      </div>
                    </a>
                  </div>

                  <div *ngIf="possuiPermissaoAcesso(814) || possuiPermissaoAcesso(811) || possuiPermissaoAcesso(291)"
                    class="Submenu_Content_Item" [ngClass]="{
                      '__is-open' :  isMenuItemOpen('comunicacao') || isRoute('/enquetes', '/mural', '/muralGT'),
                      '__is-active': isRoute('/enquetes', '/mural', '/muralGT')
                    }">
                    <div class="Content_Item_Current" (click)="openMenuItem('comunicacao')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-comunicacao">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Comunicação
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div *ngIf="possuiPermissaoAcesso(811)" class="Item_Sub_Option">
                        <a routerLink="/enquetes" routerLinkActive="active">
                          <div class="Sub_Option_Icon">
                            <span class="apsa-icon-colorido-enquete">
                              <span class="path1"></span>
                              <span class="path2"></span>
                            </span>
                          </div>

                          <div class="Sub_Option_Name">Enquetes</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option" *ngIf="this.ehGestaoTotal">
                        <a routerLink="/muralGT" routerLinkActive="active"
                          *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(291)">
                          <div class="Sub_Option_Icon">
                            <i class="apsa-icon-solid-mural Option_Icon_SolidWithColor"></i>
                          </div>

                          <div class="Sub_Option_Name">Mural</div>
                        </a>
                      </div>

                      <div class="Item_Sub_Option" *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(814)">
                        <a routerLink="/mural" routerLinkActive="active" *ngIf="!this.ehGestaoTotal">
                          <div class="Sub_Option_Icon">
                            <i class="apsa-icon-solid-mural Option_Icon_SolidWithColor"></i>
                          </div>

                          <div class="Sub_Option_Name">Mural</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="Submenu_Content_Item"
                  *ngIf="possuiPermissaoAcesso(305)"
                    [ngClass]="{ '__is-active': isRoute('/courrier') || isRoute('/courrier/cadastro')}">
                  <!-- <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(305)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': isRoute('/courrier') || isRoute('/courrier/cadastro')}"> -->
                    <a routerLink="/courrier" routerLinkActive="active" class="Content_Item_Current">
                      <div class="Item_Current_Icon CourrierIcon">
                        <i class="fas fa-business-time"></i>
                        <i class="fas fa-clock"></i>
                      </div>

                      <div class="Item_Current_Name">
                        Courrier
                      </div>
                    </a>
                  </div>

                  <div *ngIf="this.validaExibicaoContrato()" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': rotaAtual === '/contratos'}">
                    <a class="Content_Item_Current" routerLink="/contratos" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <i class="fas fa-file-signature" style="color: #fff;"></i>
                      </div>

                      <div class="Item_Current_Name">
                        Contratos
                      </div>
                    </a>
                  </div>

                  <!-- <div *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/cotas'}">
                    <a class="Content_Item_Current" routerLink="/cotas" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-cotas">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                        </span>
                      </div>
  
                      <div class="Item_Current_Name">
                        Cotas
                      </div>
                    </a>
                  </div> -->

                  <!-- *ngIf="this.stateService.possuiPerfilFuncionario()" -->
                  <div class="Submenu_Content_Item" *ngIf="possuiPermissaoAcesso(267)"
                    [ngClass]="{ '__is-active': rotaAtual === '/funcionario/meus-dados'}">
                    <a class="Content_Item_Current" routerLink="/funcionario/meus-dados" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-funcionarios">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </span>
                      </div>
                      <div class="Item_Current_Name">
                        Meus Dados
                      </div>
                    </a>
                  </div>

                  <div *ngIf="ehGestaoTotal && possuiPermissaoAcesso(285) && perfilLogadoEhGestorOuSindico"
                    class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/ocorrenciaGT'}">
                    <a class="Content_Item_Current" routerLink="/ocorrenciaGT" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-fale-com-gestor">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Ocorrências
                      </div>
                    </a>
                  </div>

                  <div *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(816) && perfilLogadoEhGestorOuSindico"
                    class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/ocorrencia'}">
                    <a class="Content_Item_Current" routerLink="/ocorrencia" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-fale-com-gestor">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Ocorrências
                      </div>
                    </a>
                  </div>
                  <!--Não exibe para AQUI para condominos e moradores-->
                  <div
                    *ngIf="!item.ref.ehGestaoTotal && (possuiPermissaoAcesso(823)||possuiPermissaoAcesso(822)) && (!this.stateService.possuiApenasPerfilCondomino() && !this.stateService.possuiApenasPerfilMoradorGT())"
                    class="Submenu_Content_Item" [ngClass]="{
                    '__is-open': isMenuItemOpen('reserva') || isRoute('/reserva-conv', '/reserva-configuracao'),
                    '__is-active': isRoute('/reserva-conv', '/reserva-configuracao')
                  }">
                    <div class="Content_Item_Current" (click)="openMenuItem('reserva')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </div>
                      <div class="Item_Current_Name">
                        Reservas
                      </div>
                    </div>
                    <div class="Content_Item_Sub">
                      <div *ngIf="!item.ref.ehGestaoTotal && possuiPermissaoAcesso(823)" class="Item_Sub_Option">
                        <a routerLink="/reserva-conv" routerLinkActive="active">
                          <div class="Sub_Option_Name">Fazer Reserva</div>
                        </a>
                      </div>
                      <div *ngIf="!item.ref.ehGestaoTotal && possuiPermissaoAcesso(822)" class="Item_Sub_Option">
                        <a routerLink="/reserva-configuracao" routerLinkActive="active">
                          <div class="Sub_Option_Name">Configuração de Reservas</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <!--Não exibe para AQUI para condominos e moradores-->
                  <div
                    *ngIf="item.ref.ehGestaoTotal && (possuiPermissaoAcesso(286) || possuiPermissaoAcesso(294) || possuiPermissaoAcesso(304) || possuiPermissaoAcesso(295)) && (!this.stateService.possuiApenasPerfilCondomino() && !this.stateService.possuiApenasPerfilMoradorGT() )"
                    class="Submenu_Content_Item" [ngClass]="{
                      '__is-open': isMenuItemOpen('reserva') || isRoute('/reserva', '/disponibilidade'),
                      '__is-active': isRoute('/reserva', '/disponibilidade')
                    }">
                    <div class="Content_Item_Current" (click)="openMenuItem('reserva')">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </div>
                      <div class="Item_Current_Name">
                        Reservas
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div class="Item_Sub_Option">
                        <a routerLink="/reserva" routerLinkActive="active">
                          <div class="Sub_Option_Name">Fazer Reserva</div>
                        </a>
                      </div>
                      <div *ngIf="possuiPermissaoAcesso(295)" class="Item_Sub_Option">
                        <a routerLink="/disponibilidade" routerLinkActive="active">
                          <div class="Sub_Option_Name">Gerenciar Disponibilidade</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <!-- <div *ngIf="possuiPermissaoAcesso(218)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': (!item.openComunicacao && !item.openReserva) && rotaAtual === '/extrato/sindico'}">
                    <a class="Content_Item_Current" routerLink="/extrato/sindico" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-extrato">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                        </span>
                      </div>
                      <div class="Item_Current_Name">
                        Extrato
                      </div>
                    </a>
                  </div> -->

                  <div class="Submenu_Content_Item" [ngClass]="{
                    '__is-open': 
                      isMenuItemOpen('relatoriosGerenciais') || 
                      isRoute('/relatorios-gerenciais/acoes') || 
                      isRoute('/relatorios-gerenciais/financeiro') || 
                      isRoute('/relatorios-gerenciais/visitas-dp'),
                    '__is-active': 
                      isRoute('/relatorios-gerenciais/acoes') || 
                      isRoute('/relatorios-gerenciais/financeiro') || 
                      isRoute('/relatorios-gerenciais/visitas-dp')
                  }" *ngIf="
                    this.ehCondominioPrime()
                    && (possuiPermissaoAcesso(873)
                    || possuiPermissaoAcesso(874)
                    || possuiPermissaoAcesso(875))
                  ">
                    <div class="Content_Item_Current" (click)="openMenuItem('relatoriosGerenciais')">
                      <div class="Item_Current_Icon" style="position: relative;">
                        <div class="relatorios-prime-icon">
                          <i class="fas fa-file-alt"></i>
                        </div>
                      </div>

                      <div class="Item_Current_Name">
                        Relatórios Gerenciais
                      </div>
                    </div>

                    <div class="Content_Item_Sub" *ngIf="possuiPermissaoAcesso(873) && ((this.ehCondominioPrime() && ehGestaoTotal)
                            || this.stateService.condominioPodeVisualizarRelatAcoes())">
                      <div *ngIf="true" class="Item_Sub_Option">
                        <a routerLink="/relatorios-gerenciais/acoes" routerLinkActive="active">
                          <div class="Sub_Option_Name">Ações</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub" *ngIf="possuiPermissaoAcesso(874)">
                      <div *ngIf="true" class="Item_Sub_Option">
                        <a routerLink="/relatorios-gerenciais/financeiro" routerLinkActive="active">
                          <div class="Sub_Option_Name">Financeiro</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub" *ngIf="possuiPermissaoAcesso(875)">
                      <div *ngIf="true" class="Item_Sub_Option">
                        <a routerLink="/relatorios-gerenciais/visitas-dp" routerLinkActive="active">
                          <div class="Sub_Option_Name">Visitas DP</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="stateService.possuiPerfilCentralAtendimento() || stateService.possuiPerfilSindico() || possuiPermissaoAcesso(881)"
                    class="Submenu_Content_Item" [ngClass]="{ '__is-active': rotaAtual === '/pasta-dp'}">
                    <a class="Content_Item_Current" routerLink="/pasta-dp" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <i class="fas fa-folder-open" style="color: #fff"></i>
                      </div>

                      <div class="Item_Current_Name">
                        Documentos Fiscais
                      </div>
                    </a>
                  </div>
                  
                  <div *ngIf="possuiPermissaoAcesso(207)" class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': rotaAtual === '/processos-juridicos'}">
                    <a class="Content_Item_Current" routerLink="/processos-juridicos" routerLinkActive="active">
                      <div class="Item_Current_Icon">
                        <i class="fas fa-gavel" style="color: #fff"></i>
                      </div>

                      <div class="Item_Current_Name">
                        Processos Jurídicos
                      </div>
                    </a>
                  </div>

                  <div class="Submenu_Content_Item"
                    *ngIf="this.ehGestaoTotal && (possuiPermissaoAcesso(299) || possuiPermissaoAcesso(303))" [ngClass]="{
                      '__is-open' :  isMenuItemOpen('relatorios') || isRoute('/relatorio-ocorrencias') || isRoute('/inventario'),
                      '__is-active': isRoute('/relatorio-ocorrencias') || isRoute('/inventario')
                    }">
                    <div class="Content_Item_Current" (click)="openMenuItem('relatorios')">
                      <div class="Item_Current_Icon">
                        <i class="fas fa-copy" style="color: #fff"></i>
                      </div>

                      <div class="Item_Current_Name">
                        Relatórios GT
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(299)" class="Item_Sub_Option">
                        <a routerLink="/relatorio-ocorrencias" routerLinkActive="active">
                          <div class="Sub_Option_Name">Ocorrências</div>
                        </a>
                      </div>
                    </div>

                    <div class="Content_Item_Sub">
                      <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(303)" class="Item_Sub_Option">
                        <a routerLink="/inventario" routerLinkActive="active">
                          <div class="Sub_Option_Name">Inventário</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="MenuList" *ngIf="(menuList | filterMenu:filterLocatarioMenu).length > 0">
          <div class="MenuList_Title">Locatário</div>

          <div class="MenuList_Content">
            <div *ngFor="let item of menuList | filterMenu:filterLocatarioMenu as locatarioMenu;"
              class="MenuList_Content_Item" [ngClass]="{'__is-open': isMenuActive(item) }">
              <div class="Content_Item_Label" (click)="selecionarMenu(item)">
                <div class="Item_Label_Name">
                  {{item.label}}
                </div>

                <div class="Item_Label_Icon">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>

              <div class="Content_Item_Submenu" *ngIf="isMenuActive(item)">
                <div class="Item_Submenu_Content">
                  <div class="Submenu_Content_Item" [ngClass]="{ '__is-active': rotaAtual === '/pagamentos'}">
                    <a routerLink="/pagamentos" routerLinkActive="active" class="Content_Item_Current">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-pagamento-locatario">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Pagamentos
                      </div>
                    </a>
                  </div>
                  
                  <div class="Submenu_Content_Item __is-new " [ngClass]="{ '__is-active': rotaAtual === '/boletos-pagos-locatario'}">
                    <a routerLink="/boletos-pagos-locatario" routerLinkActive="active" class="Content_Item_Current">
                      <div class="Item_Current_Icon">
                        <img src="../../../assets/icon/segunda-via-boletos-default.svg" />
                        <!-- <span class="apsa-icon-colorido-pagamento-locatario">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                        </span> -->
                      </div>

                      <div class="Item_Current_Name">
                        Boletos pagos
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="MenuList" *ngIf="(menuList | filterMenu:filterProprietarioMenu).length > 0">
          <div class="MenuList_Title">Proprietário</div>

          <div class="Informe_Rendimentos" *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(132)">
            <span class="Informe_Rendimentos_Icon">
              <i class="fas fa-file-invoice" style="font-size: 24px;"></i>
            </span>
            <a routerLink="/informe-rendimento" routerLinkActive="active">
              Informe de rendimentos dos seus imóveis
            </a>
          </div>

          <div class="Informe_Rendimentos">
            <div class="Informe_Rendimentos_Icon">
              <span class="apsa-icon-colorido-extrato">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
              </span>
            </div>
            <a (click)="updateImovelMostrado('clear')" routerLink="/extrato/proprietario" routerLinkActive="active">
              Extrato de locação
            </a>
          </div>

          <div class="MenuList_Content">
            <div *ngFor="let item of menuList | filterMenu:filterProprietarioMenu;" class="MenuList_Content_Item"
              [ngClass]="{'__is-open': isMenuActive(item) }">
              <div class="Content_Item_Label" (click)="selecionarMenu(item)">
                <div class="Item_Label_Name">
                  {{item.label}}
                </div>

                <div class="Item_Label_Icon">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>

              <div class="Content_Item_Submenu" *ngIf="isMenuActive(item)">
                <div class="Item_Submenu_Content">
                  <div class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': rotaAtual === '/dados-imovel' && item.idMenu == this.constant.condominioImovelMostrado }">
                    <a (click)="updateImovelMostrado(item)" routerLink="/dados-imovel" routerLinkActive="active"
                      class="Content_Item_Current">
                      <div class="Item_Current_Icon __is-bigger">
                        <span class="apsa-icon-colorido-dados-imovel">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Dados do imóvel
                      </div>
                    </a>
                  </div>

                  <div class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': isRoute('/extrato/proprietario') && item.idMenu == this.constant.condominioImovelMostrado }">
                    <a (click)="updateImovelMostrado(item)" routerLink="/extrato/proprietario" routerLinkActive="active"
                      class="Content_Item_Current">
                      <div class="Item_Current_Icon">
                        <span class="apsa-icon-colorido-extrato">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                        </span>
                      </div>

                      <div class="Item_Current_Name">
                        Extrato
                      </div>
                    </a>
                  </div>

                  <div class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': isRoute('/relacao-de-devedores') && item.idMenu == this.constant.condominioImovelMostrado }"
                    *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(606)">
                    <a (click)="updateImovelMostrado(item)" routerLink="/relacao-de-devedores" routerLinkActive="active"
                      class="Content_Item_Current">
                      <div class="Item_Current_Icon">
                        <i class="fas fa-gavel" style="color: #fff"></i>
                      </div>

                      <div class="Item_Current_Name">
                        Relação de Devedores
                      </div>
                    </a>
                  </div>

                  <div class="Submenu_Content_Item"
                    [ngClass]="{ '__is-active': isRoute('/processos-juridicos') && item.idMenu == this.constant.condominioImovelMostrado }"
                    *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(604)">
                    <a (click)="updateImovelMostrado(item)" routerLink="/processos-juridicos" routerLinkActive="active"
                      class="Content_Item_Current">
                      <div class="Item_Current_Icon">
                        <i class="fas fa-gavel" style="color: #fff"></i>
                      </div>
                      <div class="Item_Current_Name">
                        Processos Jurídicos
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="Sidebar_Footer">
      <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(828)" class="Sidebar_Footer_Item"
        (click)='handleClickComodidades()'>
        <div class="Footer_Item_Icon">
          <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
          </span>
        </div>

        <div class="Footer_Item_Label">
          Comodidades para você!
        </div>
      </div>

      <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(117)" class="Sidebar_Footer_Item"
        (click)='handleFornecedoresOptionClick()'>
        <div class="Footer_Item_Icon">
          <i class="fas fa-toolbox" style="font-size: 24px;"></i>
        </div>

        <div class="Footer_Item_Label">
          Fornecedores
        </div>
      </div>

      <div class="Sidebar_Footer_Item __is-new" (click)="handleClickVisiteONossoSite()">
        <div class="Footer_Item_Icon position-relative">
          <i class="far fa-building"></i>

          <div class="overlayed-icon">
            <i class="fas fa-heart"></i>
          </div>
        </div>

        <div class="Footer_Item_Label">
          Contrate seu seguro
        </div>
      </div>

      <div class="Sidebar_Footer_Item" (click)="openModalAvaliacao()">
        <div class="Footer_Item_Icon position-relative">
          <i class="fas fa-star"></i>
        </div>

        <div class="Footer_Item_Label">
          {{stateService.platformIsDeviceMobile() ? "Avalie nosso App" : "Avalie nosso site"}}
        </div>
      </div>
    </div>
  </div>

  <div class="Application">
    <app-header [totalAlertas]="totalAlertas" [listNotifications]="listNotifications"
    [trocaDeCondominio]="trocaDeCondominio" (eventAbriModalConta)="abrirFecharModalDadosConta($event)"
    (onOpenModalAvaliacao)="openModalAvaliacao()">
  </app-header>
  
    <section class="Container" [ngClass]="{'custom-ios-height': stateService.platformIsDeviceMobile() && stateService.platformIsIos() }" >
      <ion-router-outlet main></ion-router-outlet>
    </section>

    <app-nav-tab *ngIf="isMobileDevice" (eventOpenNotificationModal)="abriModalNotificacoesMobile()"></app-nav-tab>
  </div>
</ion-app>

<app-modal-edicao-favoritos *ngIf="stateService.getCondominioLogado()" [abrirModal]="abrirModalEdicaoFavoritos" ></app-modal-edicao-favoritos>

<app-dados-conta [open]="modalService.isOpen('app-dados-conta')" (eventClose)="fecharModalDadosConta(0);"
(toggleAdesao)='exibeModalAdesaoBoletoDigital()' (toggleAdesaoIPTU)='exibeModalAdesaoIPTU()'>
</app-dados-conta>

<app-notificacoes-modal [open]="openNotificationModalMobile" (eventClose)="openNotificationModalMobile = false">
</app-notificacoes-modal>

<modal-avaliar-app 
[modalAvaliacaoOpen]="modalAvaliacao"  
[form]="modalAvaliacaoForm"
[formPlaceholder]="returnPlaceHolder()"
(onSubmit)="enviarAvaliacao()"
(closeModalAvaliacao)="closeModalAvaliacao()"
>
</modal-avaliar-app>

<app-popup-avaliacao [isOpen]="popupAvaliacaoMobile" [dadosLoja]="obterLojaApp"></app-popup-avaliacao>

<app-mural-cadastrado id="custom-mural-cadastrado" className="__is-open">
</app-mural-cadastrado>

<app-text-input-with-confirmation id="text-input-with-confirmation" className="__is-open">
</app-text-input-with-confirmation>

<app-text-area-with-confirmation id="text-area-with-confirmation" className="__is-open">
</app-text-area-with-confirmation>

<app-ligthboxes-default id="custom-generico" className="__is-open">
</app-ligthboxes-default>

<app-image-with-toggle-and-ok-button id="image-with-toggle-and-ok-button" className="__is-open">
</app-image-with-toggle-and-ok-button>

<app-image-with-link-button id="image-with-link-button" className="__is-open" [visaoCentral]="this.stateService.visaoCentralAtendimento()">
</app-image-with-link-button>

<app-contratacao-de-cliente id="popup-cliente-contratacao" className="__is-open">
</app-contratacao-de-cliente>

<app-image-with-title-and-toggle-button id="image-with-title-and-toggle-button" className="__is-open">
</app-image-with-title-and-toggle-button>

<modal-adesao-de-boleto-digital *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(850)"
  [isOpen]="openAdesaoModalMobile" (closeModal)='exibeModalAdesaoBoletoDigital()'>
</modal-adesao-de-boleto-digital>

<app-modal-adesao-iptu 
  *ngIf="openAdesaoIPTUModalMobile" 
  [isOpenModalIPTU]="openAdesaoIPTUModalMobile"
  (closeModalIPTU)="exibeModalAdesaoIPTU()"
 >

</app-modal-adesao-iptu>


<modal-assinatura-digital [isOpenModalAssinatura]="openAssinaturaDigitalMobile"
  (closeModalAssinaturaDigital)='exibeModalAssinaturaDigital()'>
</modal-assinatura-digital>

<yes-or-no-with-link id="yes-or-no-with-link" className="__is-open">
</yes-or-no-with-link>

<app-modal-cancelamento-adesao-recorrencia id="cancela-adesao-recorrencia" className="__is-open">
</app-modal-cancelamento-adesao-recorrencia>

<app-modal-with-text-field [form]="modalAvaliacaoForm"
  [formPlaceholder]="returnPlaceHolder()" (onSubmit)="enviarAvaliacao()" (closeModalAvaliacao)="closeModalAvaliacao()">
</app-modal-with-text-field>

<app-modal-politicas-condicoes *ngIf="!verificaAceiteUsuario()" [open]="!verificaAceiteUsuario()"
  (eventClose)="this.userAceitouTermos = true" [salvarNoLocalStorage]="false"></app-modal-politicas-condicoes>

<app-text-link-options id="text-link-options" className="__is-open" ></app-text-link-options>

<ng-container *ngIf="(stateService.platformIsDeviceMobile() || stateService.platformIsMobileWeb()) && displayModalBusca && condominioLogado">
  <div class="modal-busca-itens" [ngClass]="{'__display': displayModalBusca}" >
    <div class="info">
      <div class="dados">
        <p class="nome-condominio">{{ condominioLogado?.nome }}</p>
        <p class="conta-condominio">{{ condominioLogado?.agencia }} - {{ condominioLogado?.contaEmpresa }}</p>
      </div>
      <div class="fechar" (click)="displayModalBusca = false" >Fechar</div>
    </div>
    <div class="area-busca">
      <app-busca-lista-historico [focoInput]="displayModalBusca" ></app-busca-lista-historico>
    </div>
  </div>
</ng-container>
