import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TermoAdesaoCondicoesUsoService } from 'src/app/modules/termo-adesao-condicoes-uso/services/termo-adesao-condicoes-uso.service';

@Component({
  selector: 'app-termo-adesao-condicoes-uso-text',
  templateUrl: './termo-adesao-condicoes-uso-text.component.html',
  styleUrls: ['./termo-adesao-condicoes-uso-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermoAdesaoCondicoesUsoTextComponent implements OnInit {
  public termos: string;

  constructor(private service: TermoAdesaoCondicoesUsoService) { }

  ngOnInit() {
    this.getTermosCondicoesUso();
  }

  getTermosCondicoesUso() {
    this.service.getTermosCondicoesUso().subscribe(response => 
      this.termos = response[0].txt_TermosCondicoesUso, err => console.error(err));
  }
}
