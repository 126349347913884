import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CondominioService } from '../../services/condominio.service';
import { StateService } from '../../services/stateService';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'modal-seleao-condominio',
  templateUrl: './modal-seleao-condominio.component.html',
  styleUrls: ['./modal-seleao-condominio.component.scss'],
})
export class ModalSeleaoCondominioComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() formProp;
  @Input() condominioUnidade: any[];
  @Output() abriModal = new EventEmitter()
  @Output() enviar = new EventEmitter()
  @Input() detalhes = null;
  
  constructor(
    private componentRef: ElementRef,
    public themeService: ThemeService, 
    public stateService: StateService,
    public condominioService: CondominioService
  ) {   }

  ngOnInit() {}

  ngAfterViewInit(): void {
    document.body.appendChild(this.componentRef.nativeElement) 
  }

  ngOnDestroy() {
    document.body.removeChild(this.componentRef.nativeElement);
  }

  abrirFormConteudo(){
    this.abriModal.emit()
  }

  selecionarItem(item){
    if(item !== null && item !== undefined){
      this.formProp.get('propriedade').setValue(item)
    }
  }

  enviarPropriedade(){
    this.enviar.emit();
    this.abriModal.emit();
  }

  getImgSeguros(icon:string){
    let tema = this.themeService.getActiveThemeName().value

    if(tema === 'Theme_Default' || tema === 'Theme_Grayed'){
      return `assets/svg/icon-${icon}.svg`
    }else{
      return`assets/svg/icon-${icon}-dark.svg`
    }
  }

  openExternalLink() {
    this.cadastrarCliqueSchatz();
    this.stateService.openExternalLink(this.detalhes.link);
    this.abriModal.emit();
  }

  cadastrarCliqueSchatz() {
    let unidade = this.condominioUnidade.find(unidade => (unidade as String).includes(this.stateService.getCondominioLogado()?.nome));

    let payload = {
      tipoUsuario: 
        this.stateService.getCondominioLogado() ? 'Condômino' : 
        this.stateService.possuiPerfilLocatario() ? 'Locatário' : 
        this.stateService.possuiPerfilProprietario() ? 'Proprietario' : null,
      unidadeImovel: unidade ? unidade : null,
      nomeUsuario: this.stateService.getPessoaLogada().Nome,
      idPessoa: this.stateService.getPessoaLogada().Id,
      Botao: this.detalhes.icon.charAt(0).toUpperCase() + this.detalhes.icon.slice(1)
    }

    this.condominioService.schatzCliques(payload).subscribe({next: res => {
      // console.log('cadastrou clique', res);
    }});
  }

}
