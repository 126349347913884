<div class="NotificacoesComponent">
  <div class="ListActions" *ngIf="!loading && notifications.length > 0">
    <ng-container *ngIf="newNotifications.length > 0">
      <div class="ListActions_Separator">
        Novos alertas
      </div>
  
      <div class="ListActions_Item" *ngFor="let notification of newNotifications">
        <label class="__is-clickable" (click)="routeTo(notification.LinkCondominioDigital)">
          <div class="ListActions_Item_Ico __is-new">
            <i class="apsa-icon-solid-bell"></i>
            <div class="Item_Ico_Contador"></div>
          </div>
  
          <div class="ListActions_Item_Label">{{ notification.Message }}</div>
        </label>
      </div>
    </ng-container>

    <ng-container *ngIf="oldNotifications.length > 0">
      <div class="ListActions_Separator">
        Histórico dos últimos 30 dias
      </div>

      <div class="ListActions_Item" *ngFor="let notification of oldNotifications">
        <label class="__is-clickable" (click)="routeTo(notification.LinkCondominioDigital)">
          <div class="ListActions_Item_Ico">
            <i class="apsa-icon-outline-bell"></i>
          </div>
  
          <div class="ListActions_Item_Label">{{ notification.Message }}</div>
        </label>
      </div>
    </ng-container>
  </div>

  <div class="ListActions" *ngIf="!loading && notifications.length === 0">
    <div class="ListActions_Item">
      <label>
        <div class="ListActions_Item_Label">Nenhum alerta encontrado</div>
      </label>
    </div>
  </div>

  <div class="ListActions"  *ngIf="loading">
    <div class="ListActions_Item">
      <label>
        <div class="ListActions_Item_Label">Carregando alertas...</div>
      </label>
    </div>
  </div>
</div>