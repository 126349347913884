import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CondominioService } from '../../services/condominio.service';
import { IBannerRota } from '../../services/DTO/response/IBannerRota';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'app-announcement-message',
  templateUrl: './announcement-message.component.html',
  styleUrls: ['./announcement-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnouncementMessageComponent implements OnInit, OnDestroy {
  @Input('p-custom-route') customRoute: string;
  @Input('p-is-primary-background') primaryBackground: boolean = false;
  
  public announcement: IBannerRota;
  public route: string;
  public subscription: Subscription;

  constructor(
    public stateService: StateService,
    private condominioService: CondominioService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(_ => {
      this.fetchData();
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  fetchData() {
    this.route = this.customRoute || this.angularRoute;
    
    this.condominioService.getBannerRota(this.route).subscribe(
      res => {
        if(res)
          this.announcement = res;
        else
          this.announcement = undefined;
      },
      err => {
        this.announcement = undefined;
      },
    );
  }

  dismiss() {
    sessionStorage.setItem(this.dismissCacheKey, '');
  }

  handleAccessClick() {
    if(this.announcement.ehLinkInterno) {
      this.router.navigate([this.announcement.link]);
    }
    else {
      this.stateService.openExternalLink(this.announcement.link);
    }
  }

  get userAlreadyDismissed(): boolean {
    return sessionStorage.getItem(this.dismissCacheKey) !== null;
  }

  private get angularRoute() {
    return this.router.url.slice(1);
  }

  private get dismissCacheKey() {
    return `dismissed ${this.route} announcement`;
  }

  isDesktop(): boolean {
    return window.innerWidth >= 1000;
  }
}
