<nav class="TabNav" [ngClass]="{'padding-bottom-ios': stateService.platformIsDeviceMobile() && stateService.platformIsIos() }" >
  <div class="TabNav_Banner">
    Banner fixo na navegação
  </div>

  <div class="TabNav_List">
    <div
      class="TabNav_List_Item"
      [ngClass]="{ '__is-active': rotaAtual === '/home'}"
      *ngIf="deveExibirOpcaoPerfil"
    >
      <a (click)="redirectHome()">
        <div class="List_Item_Icon">
          <i class="fas fa-users"></i>
        </div>
        
        <div class="List_Item_Label">Perfil</div>
      </a>
    </div>

    <div
      class="TabNav_List_Item"
      [ngClass]="{ '__is-active': rotaAtual === '/atalhos'}"
      *ngIf="deveExibirAtalhos"
    >
      <a routerLink="/atalhos">
        <div class="List_Item_Icon">
          <i class="apsa-icon-solid apsa-icon-solid-house"></i>
        </div>

        <div class="List_Item_Label">Home</div>
      </a>
    </div>

    <div
    *ngIf="deveExibirAtalhos"
      class="TabNav_List_Item"
      [ngClass]="{ '__is-active': rotaAtual === '/menu'}"
    >
      <a routerLink="/menu" routerLinkActive="active">
        <div class="List_Item_Icon">
          <i class="apsa-icon-solid apsa-icon-solid-menu-funcionalidades"></i>
        </div>

        <div class="List_Item_Label">Serviços</div>
      </a>
    </div>


    <div *ngIf="deveExibirAtalhos" class="TabNav_List_Item">
      <a (click)="openNotificationModal()">
        <div class="List_Item_Icon">
          <i class="demo-icon apsa-icon-solid-bell"></i>
          <div *ngIf="hasNewNotification" class="List_Item_NewNotificationIndicator">{{ totalAlertas }}</div>
        </div>

        <div class="List_Item_Label">Notificações</div>
      </a>
    </div>

    <div class="TabNav_List_Item">
      <a (click)="openMenuMobile()">
        <div class="List_Item_Icon">
          <i class="fas fa-bars"></i>
        </div>
        
        <div class="List_Item_Label">Menu</div>
      </a>
    </div>
  </div>
</nav>