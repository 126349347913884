import { Injectable, ElementRef } from "@angular/core";
import { DefaultLightBoxesComponent } from './default/default.component';

@Injectable({ providedIn: "root" })
export class LightBoxesService {
    private modals: any[] = [];
    private copyModals: any[] = [];

    add(modal: any) {
        // add modal to array of active modals
        this.copyModals = [...this.modals]
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string, mensagem: { title: string, text: string }, time: number, fechar:boolean = false) {
        let modal = this.modals.reverse().find(x => x.id === id);
        if (!modal) {
            modal = this.copyModals.reverse().find(x => x.id === id)
        }
        return modal.open(mensagem, time, fechar);
    }

    // openYesOrNoWithLink(id: string, mensagem: { title: string, text: string, link: string }, time: number) {
    //   let modal = this.modals.reverse().find(x => x.id === id);

    //   if (!modal) {
    //       modal = this.copyModals.reverse().find(x => x.id === id)
    //   }

    //   return modal.open(mensagem, time);
    // }

    openClienteContratacao(id: string, mensagem: { title: string, nome: string, cpf: string }, time: number) {
        let modal = this.modals.reverse().find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.reverse().find(x => x.id === id)
        }

        return modal.open(mensagem, time);
    }

    openImageModal(id: string, mensagem: { title: string, userId?: string, imgPath: string, toggleLabel: string, url: string } , time: number,  closeButton?: boolean ) {
        let modal = this.modals.reverse().find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.reverse().find(x => x.id === id)
        }

        return modal.open(mensagem, time, closeButton);
    }

    openModalImageLinkButton(id: string, data: { path: string , userId: string, url?: string, sameTab?: boolean}, time: number) {
        let modal = this.modals.reverse().find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.reverse().find(x => x.id === id)
        }

        return modal.open(data, time);
    }

    openModalCancelarAdesao(id: string, uid: string, userId?: string) {
        let modal = this.modals.reverse().find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.reverse().find(x => x.id === id)
        }

        return modal.open(uid, userId);
    }



    openForModalConfirmation(id: string, mensagem: { title: string, email: string, text: string }, time: number) {
        let modal = this.modals.reverse().find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.reverse().find(x => x.id === id)
        }

        return modal.open(mensagem, time);
    }

    openTextAreaWithConfirmationPopup(mensagem: { title: string, textAreaDefaultValue: string, text: string }, time: number) {
      const id = "text-area-with-confirmation";

      let modal = this.modals.reverse().find(x => x.id === id);

      if (!modal) {
          modal = this.copyModals.reverse().find(x => x.id === id)
      }

      return modal.open(mensagem, time);
    }

    openYesOrNoWithLink(id: string, mensagem: { title: string, text: string, link: string | null, buttonTxt1?: string,  buttonTxt2?: string }, time: number) {
      let modal = this.modals.reverse().find(x => x.id === id);
        // console.log('executou lightboxe openYesOrNoWithLink')
      if (!modal) {
          modal = this.copyModals.reverse().find(x => x.id === id)
      }

      return modal.open(mensagem, time);
    }

    close(id: string) {
        // close modal specified by id
        const modal = this.modals.reverse().find(x => x.id === id);
        modal.close();
    }

      
  openOkPopup(title: string, message: string, time:number = 30_000, fechar:boolean = false): Promise<boolean> {
    return this.open(
      'custom-generico',
      { title, text: message },
      time,
      fechar
    );
  }

  openYesOrNoPopup(title: string, text: string): Promise<boolean> {
    return this.open('custom-mural-cadastrado', { title, text }, 0);
  }

  openTextLinkOptions(id: string, mensagem: { title: string, subtitle: string, link: string | null, buttonTxt1?: string,  buttonTxt2?: string }) {
    let modal = this.modals.reverse().find(x => x.id === id);
    // console.log('executou lightboxe TextLinkOptions')
    if (!modal) {
        modal = this.copyModals.reverse().find(x => x.id === id)
    }

    return modal.open(mensagem);
  }

  clear() {
    this.modals = [];
    this.copyModals = [];
  }
}
