import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StateService } from 'src/app/shared/services/stateService';
import { environment } from '../../../../environments/environment';
import { UnidadeDTO } from '../../../shared/services/DTO/Request/Unidade.DTO';
import { TermoAceiteEspacoDTO } from '../services/DTO/response/TermoAceiteEspaco.DTO';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { ListarHorariosDisponiveisReservaEspacoDTO } from './DTO/request/ListarHorariosDisponiveisReservaEspaco.DTO';
import { ListarHorariosDisponiveisReservaMudancaDTO } from './DTO/request/ListarHorariosDisponiveisReservaMudanca.DTO';
import { PeriodoReservaColecaoDTO } from './DTO/response/PeriodoReservaColecao.DTO';
import { EspacoDTO } from 'src/app/shared/services/DTO/Request/Espaco.DTO';
import { TermoAceiteMudancaDTO } from './DTO/response/TermoAceiteMudanca.DTO';
import { BlocoGtDTO } from 'src/app/shared/services/DTO/response/BlocoGt.DTO';
import { EspacoSemImagensDTO } from './DTO/response/EspacoSemImagens.DTO';
import { ConfiguracoesReservaEspacoDTO } from './DTO/response/ConfiguracoesReservaEspaco.DTO';
import { MoradorDTO } from 'src/app/shared/services/DTO/Request/Morador.DTO';
import { HorariosDisponiveisReservaDTO } from './DTO/response/HorariosDisponiveisReserva.DTO';
import { ListarReservasCondominioDTO } from './DTO/request/ListarReservasCondominio.DTO';
import { isNullOrUndefined } from 'util';
import { ListarReservasCondominioResultDTO } from './DTO/response/ListarReservasCondominioResult.DTO';
import { CadastroReservaDTO } from './DTO/request/CadastroReserva.DTO';
import { ReservaEspacoDTO } from 'src/app/shared/services/DTO/response/ReservaEspaco.DTO';
import { ResultEspacoListDTO } from '../../ocorrencia/services/DTO/response/ResultEspacoList.DTO';
import { DetalheHorarioDTO } from './DTO/response/DetalheHorario.DTO';

@Injectable({
  providedIn: 'root'
})
export class ReservaService {
  private urlApiHost = environment.apiGT.host;
  private methods = environment.apiGT.endpoints;
  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.stateService.getTokenApiGT()
    });
  }

  constructor(private http: HttpClient, public stateService: StateService) { }

  /*listarUnidadesMorador(
    cpfMorador: string
  ): Observable<{ Units: Array<UnidadeDTO> }> {
    return this.http.get<{ Units: Array<UnidadeDTO> }>(
      `${this.urlApiHost +
      this.methods.checklistreports.get.buscarUnidadesMorador}/${cpfMorador}`,
      { headers: this.getHeader() }
    );
  }*/

  listarUnidadesCondominio() {
    const condominio = this.stateService.getCondominioLogado();
    const parametros = new HttpParams()
      .set('agency', condominio.Agencia)
      .set('account', condominio.ContaEmpresa)
      .set('showAllUnits', 'true'); // hoje é controlado por um botão "exibir apenas unidades com morador"

    return this.http.get<UnidadeDTO>(
      `${this.urlApiHost +
      this.methods.checklistreports.get.buscarUnidadesCondominio}`,
      { headers: this.getHeader(), params: parametros }
    );
  }

  listarBlocosUnidades(showAllUnits: boolean) {
    const ehMoradorGT = this.stateService.possuiPerfilMoradorGT();
    const condominio = this.stateService.getCondominioLogado();
    const cpfMorador = this.stateService.getPessoaLogada().CpfCnpj;
    let parametros = new HttpParams()
      .set('agency', condominio.Agencia)
      .set('account', condominio.ContaEmpresa)
      .set('showAllUnits', showAllUnits.toString()); // hoje é controlado por um botão "exibir apenas unidades com morador"
    const url = (ehMoradorGT) ? `${this.urlApiHost + this.methods.checklistreports.get['buscarUnidadesMoradorGT']}` : `${this.urlApiHost + this.methods.checklistreports.get.buscarBlocosUnidades}`

    if (ehMoradorGT) {
      parametros = parametros.append('cpf', cpfMorador)
    }

    return this.http.get<BlocoGtDTO>(url, { headers: this.getHeader(), params: parametros });
  }

  listarEspacosReservaveis() {
    const condominio = this.stateService.getCondominioLogado();
    return this.http.get<{ Data: EspacoDTO[] }>(
      `${this.urlApiHost +
        this.methods.checklistreports.get.buscarEspacosReservaveis}`
        .replace('{0}', condominio.Agencia)
        .replace('{1}', condominio.ContaEmpresa),
      { headers: this.getHeader() }
    );
  }

  listarEspacosReservaveisParaUnidadesComCotasAbertas() {
    const condominio = this.stateService.getCondominioLogado();

    return this.http.get<{ Data: EspacoDTO[] }>(
      `${this.urlApiHost +
        this.methods.checklistreports.get.buscarEspacosReservaveisComCotasAbertas}`
        .replace('{0}', condominio.Agencia)
        .replace('{1}', condominio.ContaEmpresa),
      { headers: this.getHeader()}
    );
  }

  buscarTermoAceiteMudanca() {
    const condominio = this.stateService.getCondominioLogado();
    const parametros = new HttpParams()
      .set('a', condominio.Agencia)
      .set('c', condominio.ContaEmpresa)
      .set('limit', '10');

    return this.http.get<TermoAceiteMudancaDTO>(
      `${this.urlApiHost +
      this.methods.checklistreports.get.buscarTermoAceiteMudanca}`,
      { headers: this.getHeader(), params: parametros }
    );
  }

  buscarTermoAceiteEspaco(uidEspaco: string) {
    return this.http.get<TermoAceiteEspacoDTO>(
      `${this.urlApiHost +
      this.methods.checklistreports.get
        .buscarTermoAceiteEspaco}/${uidEspaco}`,
      { headers: this.getHeader() }
    );
  }

  buscarEspaco(uidEspaco: string) {
    const parametros = new HttpParams().set('id', uidEspaco);

    return this.http.get<UnidadeDTO>(
      `${this.urlApiHost +
      this.methods.checklistreports.get.buscarEspacoSemImagens}`,
      { headers: this.getHeader(), params: parametros }
    );
  }

  buscarConfiguracoesReservaEspaco(uidEspaco: Guid) {
    return this.http.get<UnidadeDTO[]>(
      `${this.urlApiHost +
      this.methods.checklistreports.get
        .buscarConfiguracoesReservaEspaco}/${uidEspaco}`,
      { headers: this.getHeader() }
    );
  }

  buscarMoradorPorUnidaDE(uidUnidade: Guid) {
    return this.http.get<MoradorDTO>(
      `${this.urlApiHost +
      this.methods.checklistreports.get
        .buscarMoradorPorUnidade}/${uidUnidade}`,
      { headers: this.getHeader() }
    );
  }

  buscarReservaPorTipoeEspaco(uidReserva: string, tipo: string) {
    return this.http.get<ReservaEspacoDTO>(
      `${this.urlApiHost +
        this.methods.checklistreports.get
          .buscarReservaPorTipoeId}`.replace("{0}", uidReserva).replace("{1}", tipo),
      { headers: this.getHeader() }
    );
  }

  cancelarReserva(uidReserva: string, profileCode: string, tipo: string) {
    let options = { headers: this.getHeader() }
    const cpfResp = this.stateService.getPessoaLogada().CpfCnpj
    return this.http.put<ReservaEspacoDTO>(
      `${this.urlApiHost +
        this.methods.checklistreports.get
          .cancelarReserva}`.replace("{0}", uidReserva).replace("{1}", profileCode).replace("{2}", tipo).replace("{3}", cpfResp), {},
      { headers: this.getHeader() }
    );
  }

  atualizarReserva(obj: CadastroReservaDTO) {
    let options = { headers: this.getHeader() }
    return this.http.put<CadastroReservaDTO>(`${this.urlApiHost + this.methods.checklistreports.get.atualizarReserva}`, obj, options)

    // let options = { headers: this.getHeader() }
    // const cpfResp = this.stateService.getPessoaLogada().CpfCnpj
    // return this.http.put<ReservaEspacoDTO>(
    //   `${this.urlApiHost +
    //     this.methods.checklistreports.get
    //       .atualizarReserva}`.replace("{0}", uidReserva).replace("{1}", profileCode).replace("{2}", tipo).replace("{3}", cpfResp), {},
    //   { headers: this.getHeader() }
    // );
  }

  listarHorariosDisponiveisReservaEspaco(
    obj: ListarHorariosDisponiveisReservaEspacoDTO
  ) {
    const condominio = this.stateService.getCondominioLogado();
    const parametros = new HttpParams()
      .set('agency', condominio.Agencia)
      .set('account', condominio.ContaEmpresa)
      .set('spaceId', obj.spaceId)
      .set('reservationDate', obj.reservationDate)
      .set('unityMXMId', obj.unityMXMId.toString())
      .set('openQuotas', obj.openQuotas.toString());

    return this.http.get<PeriodoReservaColecaoDTO>(
      `${this.urlApiHost +
      this.methods.checklistreports.get
        .listarHorariosDisponiveisReservaEspaco}`,
      { headers: this.getHeader(), params: parametros }
    );
  }

  listarHorariosDisponiveisReservaMudanca(
    obj: ListarHorariosDisponiveisReservaMudancaDTO
  ) {
    const condominio = this.stateService.getCondominioLogado();
    const parametros = new HttpParams()
      .set('agency', condominio.Agencia)
      .set('account', condominio.ContaEmpresa)
      .set('condominiumMXMId', obj.condominiumMXMId)
      .set('subdivisaoMXMId', obj.subdivisaoMXMId)
      .set('reservationDate', obj.reservationDate)
      .set('unityMXMId', obj.unityMXMId);

    return this.http.get<PeriodoReservaColecaoDTO>(
      `${this.urlApiHost +
      this.methods.checklistreports.get
        .listarHorariosDisponiveisReservaMudanca}`,
      { headers: this.getHeader(), params: parametros }
    );
  }

  listarReservas(dataInicial?: string, dataFinal?: string, statusReserva?: string, uidEspaco?: string, paginaAtual?: number ) {

    const condominio = this.stateService.getCondominioLogado();

    let parametros = new HttpParams()
      .set('agencia', condominio.Agencia)
      .set('conta', condominio.ContaEmpresa)
      .set('limit', '20')

    let url = `${this.urlApiHost + this.methods.checklistreports.get.listarReservasCondominio}`

    if (dataInicial) {
      parametros = parametros.append('dataInicial', dataInicial);
    }
    if (dataFinal) {
      parametros = parametros.append('dataFinal', dataFinal);
    }
    if(uidEspaco) {
      parametros = parametros.append('espaco', uidEspaco);
    }
    if(paginaAtual) {
      parametros = parametros.append('offset', paginaAtual.toString());
    }
    if (statusReserva) {
      if(statusReserva == "1"){
        parametros = parametros.append('situacao', '4645D26E-3B59-4459-90BF-4C3745FB9B8F');
      } else if (statusReserva == '2'){
        parametros = parametros.append('situacao', '1A624426-A2E8-4606-8FC5-78371DEA1F96');
      }
      
    }

    return this.http.get<ListarReservasCondominioResultDTO>(url, { headers: this.getHeader(), params: parametros });
  }


  listarReservasMoradorGT(dataInicial: string, dataFinal: string, statusReserva?: string, space?: string, paginaAtual?: number) {

    const condominio = this.stateService.getCondominioLogado();
    let cpfMorador = this.stateService.getPessoaLogada().CpfCnpj;

    let parametros = new HttpParams()
      .set('agency', condominio.Agencia)
      .set('account', condominio.ContaEmpresa)
      .set('cpf', cpfMorador);

    let url = `${this.urlApiHost + this.methods.checklistreports.get['buscarReservasMoradorGT']}`;

    if (dataInicial) {
      parametros = parametros.append('initialDate', dataInicial);
    }

    if (dataFinal) {
      parametros = parametros.append('finalDate', dataFinal);
    }

    if (space) {
      parametros = parametros.append('space', space);
    }
    if (statusReserva){
      parametros = parametros.append('situacao', statusReserva);
    }

    if(paginaAtual) {
      parametros = parametros.append('offset', paginaAtual.toString());
    }

    return this.http.get<ListarReservasCondominioResultDTO>(url, { headers: this.getHeader(), params: parametros });
  }


  cadastrarReservaEspaco(obj: CadastroReservaDTO[]) {
    let options = { headers: this.getHeader() }
    return this.http.post<CadastroReservaDTO>(`${this.urlApiHost + this.methods.checklistreports.get.cadastrarReserva}`, obj, options)
  }

  cadastrarReservaMudanca(obj: CadastroReservaDTO[]) {
    let options = { headers: this.getHeader() }
    return this.http.post<CadastroReservaDTO>(`${this.urlApiHost + this.methods.checklistreports.get.cadastrarReservaMudanca}`, obj, options)
  }

  buscarDetalhesEspaco(uid: string) {
    return this.http.get<EspacoDTO>(`${this.urlApiHost + this.methods.checklistreports.get.listarEspacosCadastroOcorrencia}/${uid}`, { headers: this.getHeader() })
  }

  detalheEspacoCard(uid){
    const parametros = new HttpParams()
      .set('uidEspaco', uid)

    return this.http.get<Array<DetalheHorarioDTO>>(`${this.urlApiHost + this.methods.checklistreports.get.detalheCardEspaco}`,
      {headers: this.getHeader(), params:parametros})
  }

  detalheMudancaCard(id){
    const condominio = this.stateService.getCondominioLogado()
    const parametros = new HttpParams()
      .set('contaEmpresa', condominio.contaEmpresa)
      .set('idSubDivisao', id)

    return this.http.get<Array<DetalheHorarioDTO>>(`${this.urlApiHost + this.methods.checklistreports.get.detalheCardMudanca}`,
      {headers:this.getHeader(), params: parametros})

  }

  idParametroReserva(id:string = '85'){
    const parametros = new HttpParams()
      .set('idParametro', id)

      const header =  new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.stateService.getTokenApiCondominioDigital()
      });
 

    return this.http.get<[]>(`${ environment.apiCondominioDigital.host + environment.apiCondominioDigital.endpoints.methods.idParametroReserva}`,
      {headers: header, params: parametros})

  }
}
