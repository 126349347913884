import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CondominioService } from '../../services/condominio.service';
import Swiper from 'swiper';
import { StateService } from '../../services/stateService';
import { Validations } from '../../utils/validations';
import { LightBoxesService } from '../lightboxes';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

interface ISeguros {
  title: string,
  link: string,
  icon: string,
  breve: boolean,
  ativo: boolean
}
@Component({
  selector: 'app-seguros-swiper',
  templateUrl: './seguros-swiper.component.html',
  styleUrls: ['./seguros-swiper.component.scss'],
})
export class SegurosSwiperComponent implements OnInit {

  @ViewChild('swiper') swiperRef: ElementRef | undefined;

  public slideOpts = {
    initialSlide: 0,
    slidesPerView: 'auto',
    pager: true,
    spaceBetween: 8,
  }
  public slidesPerView = 3;
  public swipperPagination =  {
    el: '.swiper-pagination',
    clickable: true,
    enabled: true,
    type: 'bullets'
  }

  public tipoSeguros : ISeguros[];
  // public segurosMock = [
  //   {
  //     title:"Conteúdo",
  //     link:"/seguros/conteudo",
  //     icon:"conteudo",
  //     breve:false
  //   },
  //   {
  //     title:"Carro",
  //     link:"https://schatzcorretora.aggilizador.com.br/auto",
  //     icon:"carro",
  //     breve:false
  //   },
  //   {
  //     title:"Moto",
  //     link:"https://schatzcorretora.aggilizador.com.br/auto",
  //     icon:"moto",
  //     breve:false
  //   },
  //   {
  //     title:"Residencial",
  //     link:"https://schatzcorretora.aggilizador.com.br/residence",
  //     icon:"residencial",
  //     breve:false
  //   },
  //   {
  //     title:"Empresarial",
  //     link:"https://schatzcorretora.aggilizador.com.br/business",
  //     icon:"empresarial",
  //     breve:false
  //   },
  //   {
  //     title:"Vida",
  //     link:"https://schatzcorretora.aggilizador.com.br/life",
  //     icon:"vida",
  //     breve:false
  //   },
  //   {
  //     title:"Plano de saúde",
  //     link:"/seguros/saude",
  //     icon:"saude",
  //     breve:false
  //   },
  //   {
  //     title:"Pet",
  //     link:"",
  //     icon:"pet",
  //     breve:true
  //   },
  //   {
  //     title:"Outros",
  //     link:"https://schatzcorretora.aggilizador.com.br/several",
  //     icon:"outros-seguros",
  //     breve:false
  //   },
  // ]

  public swiperOptions = {
    spaceBetween: 10,
    slidesPerView: this.itensPorTamanhoDeTela(),
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      enabled: true,
      type: 'bullets'
    }
  }

  public tipoSelecionado = null;
  public abrirModalSeguros: boolean = false;
  public listaPerfis: any[] = [];
  public condominioUnidade= [];
  public imoveis = [];
  public unidades = [];
  public formProp: UntypedFormGroup;

  constructor(
    private condominioService: CondominioService,
    private stateService: StateService, 
    private lightboxesService: LightBoxesService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getTipoSeguros();
    
    this.listaPerfis = this.stateService.obterPerfisEmTodosOsCondominiosEImoveis().map(perfil => {
      return perfil.idPerfil
    });

    this.formProp = this.fb.group({
      propriedade: ['', Validators.required],
    });

    if (this.listaPerfis.indexOf(5) != -1) {
      // console.log('possuiPerfilCondomino')
      

      this.imoveis = this.stateService.getListaCondominiosImoveis().condominios;
      // console.log('imoveis:',this.imoveis)
      this.stateService.listarCondominiosUnidades().subscribe(
        (item) => {
          if (item) {
            item.forEach(informacoes => {
              let imovelPool = this.imoveis.filter(imovel => {
                return imovel.condominio.contaEmpresa === informacoes.contaEmpresaCondominio && imovel.condominio.agencia === informacoes.agenciaCondominio
              }).map(unidade => {
                // console.log('unidade',  unidade)
                return {'ehContaPool': unidade.condominio.ehContaPool}
              })
              // console.log('imovel pool', informacoes)
              informacoes.unidades.forEach(unidade => {
                let condominio = {
                  condominio: informacoes.nomeCondominio ,
                  nomeExibicao:informacoes.nomeCondominio + ' - ' + unidade.descricaoUnidade,
                  unidade: unidade,
                  agencia: informacoes.agenciaCondominio,
                  conta: informacoes.contaEmpresaCondominio,
                  subDivisao: unidade.idSubdivisao,
                  idUnidade:unidade.idUnidade,
                  nomeUnidade:unidade.descricaoUnidade,
                  codUnidade:unidade.idUnidade,
                  ehPool: imovelPool,
                  codWeb:informacoes.contaCondominio
                }
                
                this.condominioUnidade.push(condominio)
              });
            });

            this.unidades = this.condominioUnidade.map( ({nomeExibicao}) => nomeExibicao).sort((a, b) => (a.toUpperCase() > b.toUpperCase()) ? 1 : -1)
              // console.log('unidades', this.unidades)
            this.unidades.push('Outro Imóvel')
            
          }
        },
        (err) => {
          if (err) {
            // console.log(err, 'erro');
          }
        }
      );
      
    }
    //regras pra locatário
    if (this.listaPerfis.indexOf(2) != -1) {
      // console.log('possuiPerfilLocatario')
      let unidadesMapeadas = this.stateService.getListaCondominiosImoveis()
      // console.log('lista imóveis', unidadesMapeadas)
      this.condominioUnidade = unidadesMapeadas.imoveisLocatario.map(res => {
        let item = {
          nomeExibicao:res.imovel.enderecoimovel,
          codImovel:res.imovel.codigoImovelPrinipal,
        }
        return item
      })

      this.unidades = this.condominioUnidade.map(unidade => {
        return unidade.nomeExibicao
      }).sort((a, b) => (a.toUpperCase() > b.toUpperCase()) ? 1 : -1)
      this.unidades.push('Outro Imóvel')
    }
  }

  getTipoSeguros(){
    this.condominioService.getSeguros().subscribe(res =>{
      this.tipoSeguros = res

      this.initializeSwiper()
    })
  }

  initializeSwiper() {
    setTimeout(() => {
      const swiper = new Swiper('.swipper', {
        spaceBetween: 10,
        slidesPerView: this.itensPorTamanhoDeTela(),
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          enabled: true,
          type: 'bullets'
        }
      });
    }, 200);
  }

  

  itensPorTamanhoDeTela(): number {
    if(window.innerWidth < 300) {
      return 2.5;
    }else if(window.innerWidth < 800) {
      return 3.5;
    }  else {
      return 5;
    }
  }

  setDetalhesEAbreModal(tipoSelecionado) {
    this.tipoSelecionado = tipoSelecionado;

    if(this.tipoSelecionado.icon === 'outros-seguros') {
      this.cadastrarCliqueSchatz();
      this.stateService.openExternalLink(this.tipoSelecionado.link);
      return;
    }

    this.abrirModalSeguros = true;
  }

  enviarPropriedade(){
    if (this.formProp.invalid) {
      Validations.markFormGroupTouched(this.formProp);
      return;
    }
    // console.log('condominio', this.condominioUnidade)
    let unidade = this.condominioUnidade.filter(unidade => {
      return unidade.nomeExibicao === this.formProp.get('propriedade').value
    })[0] 

    if(this.formProp.get('propriedade').value === 'Outro Imóvel' || this.listaPerfis.indexOf(5) !== -1 && unidade.ehPool == false ){
      this.stateService.openExternalLink('https://schatzcorretora.aggilizador.com.br/residence')
      this.cadastrarCliqueSchatz();
      // this.abreFormProp = !this.abreFormProp;
      this.formProp.reset()
    }else{

      let pessoaLogada = this.stateService.getPessoaLogada()
      // this.abreFormProp = !this.abreFormProp
      const dadosFormPropriedade= {
        // propriedade: this.formProp.get('propriedade').value,
        idCampanha: 5,
        nomeCondominio: unidade.condominio || '',
        agência: unidade.agencia || 0,
        conta: unidade.conta || 0,
        codUnidade:this.listaPerfis.indexOf(2) == -1 ? unidade.codUnidade : 0,
        codWeb:this.listaPerfis.indexOf(2) == -1 ? unidade.codWeb : 0,
        nomeCliente: pessoaLogada.Nome || '',
        idCliente: pessoaLogada.Id || 0,
        cpfCnpj: pessoaLogada.CpfCnpj || '', 
        email: pessoaLogada.Email || '',
        endereco: unidade.nomeExibicao,
        sqsubd:unidade.subDivisao || 0,
        squnid:unidade.idUnidade || 0,
        codigoImovel: this.listaPerfis.indexOf(2) != -1 ? unidade.codImovel : 0 , //locatario
      }

      // console.log("dados", dadosFormPropriedade)
  
      this.condominioService.cadastrarPessoaSeguroConteudo(dadosFormPropriedade).subscribe(
        (response) => {
          // console.log('response', response)
          this.abrirModalSeguros = false;
          this.cadastrarCliqueSchatz();
          if(response.indexOf('http') != -1){
            
            window.open(response, '_blank');
            // this.lightBoxesService.openOkPopup('Sucesso', 'Sua solicitação foi enviada para corretora, em breve entraremos em contato.')
            this.lightboxesService.openOkPopup('Seguro Conteúdo Schatz', 'Acesse nosso hotsite para mais informações e contratação do seguro conteúdo. <br><br><br><bv></bv> <a href="'+ response +'" target="_blank" style="text-decoration: none;"><button _ngcontent-dgg-c142="" class="Button Button_Primary Button_Solid __is-full"><div _ngcontent-dgg-c142="" class="Button_Content"><div _ngcontent-dgg-c142="" class="Button_Content_Label">Acessar detalhes </div></div></button></a>',60000, true);
          }else{

            this.lightboxesService.openOkPopup('Atenção', response)
          }
          this.formProp.reset()
        },
        (error:any) => {
          // console.log(error)
          this.abrirModalSeguros = false;
          this.lightboxesService.openOkPopup('Erro', 'Sua solicitação não foi enviada')
        }
      );
    }

  }

  get unidade(){
    let condUnidade = this.condominioUnidade.find(unidade => {
      return unidade.nomeExibicao === this.formProp.get('propriedade').value
    })

    if(condUnidade){
      return condUnidade.nomeExibicao ? condUnidade.nomeExibicao : null
    }else{
      return null
    }
  }

  cadastrarCliqueSchatz() {
    

    // console.log('unidade filtrada:', unidade);

    let payload = {
      tipoUsuario: 
        this.stateService.getCondominioLogado() ? 'Condômino' : 
        this.stateService.possuiPerfilLocatario() ? 'Locatário' : 
        this.stateService.possuiPerfilProprietario() ? 'Proprietario' : null,
      unidadeImovel:  this.unidade,
      nomeUsuario: this.stateService.getPessoaLogada().Nome,
      idPessoa: this.stateService.getPessoaLogada().Id,
      Botao: this.tipoSelecionado.icon.charAt(0).toUpperCase() + this.tipoSelecionado.icon.slice(1)
    }

    this.condominioService.schatzCliques(payload).subscribe({next: res => {
      // console.log('cadastrou clique', res);
    }});
  }


}
