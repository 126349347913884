export class AlertaDTO {
    Id: string;
    Message: string;
    Link: string;
    NewAlert: boolean;
    Type: number;
    LinkCondominioDigital : string;
}

export class ResultListAlertaDTO{
    Data : AlertaDTO[];
    IsValid : boolean;
    Errors : any;
}