export class ImagemArquivoDTO {
    File: string;
    FileName: string;
    Lenght: number;
    ContentType: string;
    FileExtension: string;
    src: string;
    UrlDownload: string;

    // constructor(public file: string, public name: string, public tamanho: number) {
    //     this.File = file
    //     this.FileName = name
    //     this.Lenght = tamanho
    //     this.ContentType = "image/jpg";
    // }

    constructor(public file: string, type: string, name: string, src?: string) {
        this.File = file;
        this.ContentType = type;
        this.FileName = name;
        this.src = src;
    }
}
