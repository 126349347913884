import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modals: Object = {};

  constructor() { }

  add(modalName: string, isOpen: boolean = false) {
    this.modals[modalName] = isOpen;
  }

  open(modalName: string) {
    if(!this.modals[modalName]) {
      this.modals[modalName] = true;
    }
  }

  close(modalName: string) {
    if(this.modals[modalName]) {
      this.modals[modalName] = false;
    }
  }

  isOpen(modalName: string) {
    return this.modals[modalName];
  }

  removeHasModalClassFromContainerDiv() {
    const containers = document.querySelectorAll('.Container');

    containers.forEach(container => container.classList.remove('__has-modal'));
  }
}
