import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { LightBoxesService } from "../lightboxes.services";
import { FormControl } from '@angular/forms';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-image-with-title-and-toggle-button',
  templateUrl: './image-with-title-and-toggle-button.component.html',
  styleUrls: ['./image-with-title-and-toggle-button.component.scss'],
})
export class ImageWithTitleAndToggleButton implements OnInit {
  @Input() id: string;
  @Input() className: string;
  private element: any;
  private promisse: Promise<boolean>;
  private resolve;
  // private toggle = new FormControl(false);
  private url: string;
  private userId: string;

  constructor(private sessionStorageService: SessionStorageService, private service: LightBoxesService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    // TODO: Retirado para não bugar
    // this.service.remove(this.id);
    this.element.remove();
  }

  // abrirLink() {
  //   if (this.url) {
  //     window.open(this.url, '_blank')
  //   }
  // }


  open(mensagem: { title: string, userId: string, imgPath: string, toggleLabel: string, url: string }, time: number = 1000): Promise<boolean> {
    this.userId = mensagem.userId;
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('Popup_Header_Title')[0].innerHTML = mensagem.title;
    //this.element.getElementsByClassName('FormGroupToggle_Label')[0].innerHTML = mensagem.toggleLabel;
    this.element.getElementsByClassName('LightboxImage')[0].setAttribute('src', mensagem.imgPath);
    this.url = mensagem.url;
    //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.add(this.className);

    if (time === 0) return new Promise(resolve => { this.resolve = resolve; });

    return new Promise(resolve => {
      this.resolve = resolve;

      setTimeout(function () {
        a.firstChild.classList.remove(n);
        //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.remove(this.className);
        resolve(false);
      }, time);
    });
  }

  dontShowItAgain() {

    // let exibeModalLocal = JSON.parse(localStorage.getItem('exibeBannerFaleComAApsa'));    
    
    // exibeModalLocal.find(item => {
    //   if(item.id === this.userId) {
    //     item.exibeModal = false;
    //   }
    // });
    
    // localStorage.setItem('exibeBannerFaleComAApsa', JSON.stringify(exibeModalLocal));
    this.close();
  }

  confirmar(): void {
    // this.resolve(this.toggle.value);
    this.close();
  }

  close(): void {
    this.resolve(false);
    this.element.firstChild.classList.remove(this.className);
  }
}
