<div class="Lightbox">
  <div class="Popup Popup_ImageWithToggleAndOkButton" [ngClass]="{'closeModal': buttonCloseModal}">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Titulo genérico</div>
    </div>
    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        <div class="iconClose" (click)="close()" *ngIf="buttonCloseModal">
          <i class="fas fa-times"></i>
        </div>
        <img (click)="abrirLink()" [ngClass]="{'LightboxImage': !buttonCloseModal}">
        <!-- <div class="FormGroupToggle">
          <div class="FormGroupToggle_Label">
            Aqui vai qualquer texto
          </div>

          <div class="FormGroupToggle_Switch">
            <ion-toggle [formControl]="toggle"></ion-toggle>
          </div>
        </div> -->
      </div>
    </div>

    <div class="Popup_Footer" *ngIf="!buttonCloseModal">
      <div class="Popup_Footer_Buttons">
        <button class="Button Button_Primary Button_Solid __is-full" (click)="confirmar()">
          <div class="Button_Content">
            <div class="Button_Content_Label">
              Ok
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>

</div>
