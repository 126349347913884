import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'ds-box-with-dashed-border',
  templateUrl: './box-with-dashed-border.component.html',
  styleUrls: ['./box-with-dashed-border.component.scss'],
})
export class BoxWithDashedBorderComponent implements OnInit {
  @Input() icon: string;
  @Input() fillImage: string | SafeUrl | null;
  @Output() onDelete = new EventEmitter();
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  handleDeleteClick() {
    this.onDelete.emit();
  }

  handleClick() {
    this.onClick.emit();
  }
  
}
