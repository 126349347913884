import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { StateService } from 'src/app/shared/services/stateService';
import { ICadastrarAdesaoRecorrenciaDTO } from './DTO/request/ICadastrarAdesaoRecorrencia.DTO';
import { IEditarAdesaoRecorrenciaDTO } from './DTO/request/IEditarAdesaoRecorrencia.DTO';
import { IGetHashBandeiraDTO } from '../../cotas/services/DTO/response/ResultCotasGetHashBandeiras.DTO';
import { TokenizarCartaoRequestDTO } from '../../cotas/services/DTO/request/TokenizarCartaoRequestDTO';

@Injectable({
  providedIn: 'root'
})
export class RecorrenciaCartaoCreditoService {
  
  private host = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods;

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('tokenApiCondominioDigital')
    });
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    ) {}

  public cadastraAdesaoCartao(obj: ICadastrarAdesaoRecorrenciaDTO){
    return this.http.post<any>(
      `${ this.host + this.methods.recorrenciaCartao.cadastrarRecorrencia }`, obj, { headers: this.getHeader() }
      )
  } 

  public cancelarAdesaoCartao(uid: string, idUsuario: string){
    let params = new HttpParams()
      .set('uid', uid)
      .set('idUsuario', idUsuario)

    return this.http.get<any>(
      `${ this.host + this.methods.recorrenciaCartao.cancelarRecorrencia }`, { params, headers: this.getHeader() }
      )
  } 

  public getInfoCartao(cpfCnpjCondomino: string, contas: string){   
    let params = new HttpParams()
      .set('cpfCnpjCondomino', cpfCnpjCondomino)
      .set('contas', contas)

    return this.http.get<any>(
      `${ this.host + this.methods.recorrenciaCartao.getInfoCartao }`, { params, headers: this.getHeader() }
      )
  } 
  
  public editarAdesaoCartao(obj: IEditarAdesaoRecorrenciaDTO){
    return this.http.post<any>(
      `${ this.host + this.methods.recorrenciaCartao.editarRecorrencia }`, obj, { headers: this.getHeader() }
      )
    } 
    
  public getTermosUso(){
    return this.http.get<any>(
      `${ this.host + this.methods.recorrenciaCartao.getTermoUso }`, { headers: this.getHeader() }
      )
  } 

  getHashBandeiras() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('tokenApiCondominioDigital')
    });

    const route = `${this.host + this.methods.cotas.getHashBandeiras}`;
    return this.http.get<IGetHashBandeiraDTO[]>(route, { headers: headers });
  }

  tokenizarCartao(model: TokenizarCartaoRequestDTO) {
    var obj = {
      customerName: model.customerName,
      cardNumber: model.cardNumber,
      expirationDate: model.expirationDate.trim(),
      brand: model.brand,
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('tokenApiCondominioDigital')
    });

    return this.http.post<any>(`${this.host + this.methods.cotas.tokenizarCartaoCredito}`, obj, { headers: headers });
  }

}
