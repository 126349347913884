import { StateService } from "./../../../services/stateService";
import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { LightBoxesService } from "../lightboxes.services";
import { Platform } from "@ionic/angular";
import { CondominioDTO } from "src/app/shared/services/DTO/response/Condominio.DTO";

@Component({
  selector: "app-image-with-link-button",
  templateUrl: "./image-with-link-button.component.html",
  styleUrls: ["./image-with-link-button.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ImageWithLinkButtonComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;
  @Input() visaoCentral: any;

  private element: any;
  private resolve: any;
  private userId: string;
  private url: string;
  private sameTab: boolean = false;

  constructor(
    private el: ElementRef,
    private router: Router,
    public platform: Platform,
    private service: LightBoxesService,
    // private stateService: StateService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) return;

    document.body.appendChild(this.element);

    this.element.addEventListener("click", (el) => {
      if (el.target.className === "Lightbox") this.close();
    });

    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.element.remove();
  }

  open(
    data: { path: string; userId: string; url?: string, sameTab?: boolean },
    time: number = 1000
  ): Promise<boolean> {
    this.userId = data.userId;
    this.element.firstChild.classList.add(this.className);
    this.element
      .getElementsByClassName("LightboxImage")[0]
      .setAttribute("src", data.path);
    this.url = data.url;
    
    if(data.sameTab) this.sameTab = data.sameTab

    if (time === 0)
      return new Promise((resolve) => {
        this.resolve = resolve;
      });

    return new Promise((resolve) => {
      this.resolve = resolve;

      setTimeout(function () {
        this.element.firstChild.classList.remove(this.className);
        resolve(false);
      }, time);
    });
  }

  close(): void {
    this.resolve(false);
    this.element.firstChild.classList.remove(this.className);
    // sessionStorage.setItem('exibeBannerFaleComAApsa', JSON.stringify(false));
  }

  dontShowItAgain() {
    let exibeModalLocal = JSON.parse(
      localStorage.getItem("exibeBannerProventosDescontos")
    );
    
    if (
      this.getCondominioLogado() &&
      this.visaoCentral
    ) {
      exibeModalLocal.find((items) => {
        return items.id === this.userId;
      }).contasCondominos[0].exibeModal = false;
    } else if (this.getCondominioLogado()) {
      exibeModalLocal
        .find((items) => {
          return items.id === this.userId;
        })
        .contasCondominos.find((item) => {
          if (item.contaEmpresa === this.getCondominioLogado().contaEmpresa) {
            item.exibeModal = false;
          }
        });
    } else {
      const item = exibeModalLocal.find(item => {
        return item.id === this.userId;
      });

      if(item?.contasCondominos[0]) {
        item.contasCondominos[0].exibeModal = false;
      }
    }

    localStorage.setItem(
      "exibeBannerProventosDescontos",
      JSON.stringify(exibeModalLocal)
    );
    this.close();
  }

  redirectFalaComApsa() {
    if (this.platform.is("desktop")) {
      let container = document.querySelector(
        ".Header_Atalho_CentralAtendimento"
      );

      if (!container.classList.contains("__is-open")) {
        container.classList.add("__is-open");
      }
      this.close();
    }

    if (this.platform.is("mobile")) {
      this.router.navigate(["/fale-com-a-apsa"]);
      this.close();
    }
  }

  abrirLink() {
    if (this.url) {
      if(this.sameTab) {
        this.router.navigate([this.url])
        this.close();
      } else {
        window.open(this.url, "_blank");
      }
      
    }
  }

  getCondominioLogado(): CondominioDTO {
    if (sessionStorage.getItem("condominioLogado")) {
      const obj = JSON.parse(sessionStorage.getItem("condominioLogado"));
      if (obj == "undefined" || obj == undefined) {
        sessionStorage.clear();
        // console.log("VEIO LOGIN EXPIRADO 5")
        this.router.navigate(["/"], {
          queryParams: {
            showMsg: true,
            title: "Acesso expirado",
            mensagem: "Faça o login novamente",
          },
        });
        return;
      }
      const condominio = new CondominioDTO(
        obj.nome,
        obj.agencia,
        obj.conta,
        obj.contaEmpresa,
        obj.cnpj,
        obj.prime,
        obj.ehGestaoTotal,
        obj.ehContaPool,
        obj.exibeCartao,
        obj.perfis,
        obj.funcionalidades,
        obj.carteira,
        obj.uf,
        obj.optins,
        obj.pdfase2,
        obj.municipio,
        obj.nomePerfil
      );

      return condominio;
    }
  }
}
