<section>
  <div class="Lightbox TextAreaWithConfirmationPopup" [ngClass]="{ '__is-open': this.modalAvaliacaoOpen }">
    <div class="Popup Popup_ComunicacaoGenerica">
      <div class="Popup_Header">
        <div class="Popup_Header_Title">Gostando do Site?</div>
      </div>
  
      <div class="Popup_Content">
        <div class="Popup_Content_MsgDefault">
          Avalie sua experiência utilizando nosso site. Sua opinião é muito importante para continuarmos melhorando!
        </div>
      </div>
  
      <div class="FormGroup" [formGroup]="form" [ngClass]="getErrorClass()">
      <!-- <div> -->
        <div class="FormGroup_Input">
          <textarea
            rows="2"
            formControlName="descricao"
            [placeholder]="this.formPlaceholder"
          >
          </textarea>
        </div>
  
        <ng-container *ngIf="descricaoValue.errors">
          <div class="FormGroup_Erro" *ngIf="descricaoValue.errors.minlength">
          A descrição deve ter no mínimo 20 caracteres.
          </div>
  
          <div class="FormGroup_Erro" *ngIf="descricaoValue.errors.required">
            Este campo é obrigatório.
          </div>
        </ng-container>
      </div>
  
      <div class="Popup_Footer">
        <div class="Popup_Footer_Buttons">
          <button class="Button Button_Primary Button_Outline __is-full" (click)="close()">
            <div class="Button_Content">
              <div class="Button_Content_Label">
                Cancelar
              </div>
            </div>
          </button>
  
          <button class="Button Button_Primary Button_Solid __is-full" (click)="confirmar()">
            <div class="Button_Content">
              <div class="Button_Content_Label">
                Enviar
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
