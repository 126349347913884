import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';

@Component({
  selector: 'ds-link-download',
  templateUrl: './link-download.component.html',
  styleUrls: ['./link-download.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LinkDownloadComponent implements OnInit {
  @Input('p-title') title: string;
  @Input('p-button-name') buttonName: string;
  @Input('p-bg-color') bgColor: string;
  @Input('p-color') color: string;
  @Input() urlDownload: string;

  constructor(public stateService: StateService) { }

  ngOnInit() {}

  handleDownloadDoc() {
    this.stateService.openExternalLink(this.urlDownload);
  }

}
