import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
  animations: [
    trigger('open', [
      state('true', style({ height: AUTO_STYLE })),
      state('false', style({ height: '0px' })),
      transition('false => true', animate('250ms ease-in-out')),
      transition('true => false', animate('250ms ease-in-out'))
    ])
  ],
})
export class CollapsibleComponent {
  @Input('p-open') open: boolean = false;
}
