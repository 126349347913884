<div class="Lightbox TextAreaWithConfirmationPopup" [ngClass]="{ '__is-open': true }">
  <div class="Popup_ComunicacaoGenerica">
        
    <div class="Popup_Content">
      
      <div class="modalSelecao" >       
        
        <div class="modalBody">
          <div class="list">
            <!-- <div class="listItem">
              <span class="item">Todos</span>
              <input
                    type="radio"
                    class="radio"
                    [checked]="true"
                    (change)="onSelect()"
                  >
            </div> -->
            <div class="listItem" *ngFor="let item of itensLista; index as i">
              <span class="item">{{item.descricao}}</span>
              <input
                    type="radio"
                    class="radio"
                    [checked]="item.check"
                    (change)="onSelect(i)"
                  >
            </div>
          </div>
        </div>     
      
      </div>
    </div>
    
  </div>
</div>