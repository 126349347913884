import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

const LIST_DESIGN_STORAGE_KEY = 'menuListDesign';

@Injectable({ providedIn: 'root' })
export class AppShortcutsFacade {
  private listDesign = new BehaviorSubject<boolean>(false);

  constructor() {
    this.listDesign.next(this.getListDesignValue());
  }

  toggleMenuDesign() {
    const toggledValue = !this.listDesign.getValue();

    this.listDesign.next(toggledValue);

    localStorage.setItem(LIST_DESIGN_STORAGE_KEY, toggledValue.toString());
  }

  isListDesign(): boolean {
    return this.listDesign.getValue();
  }

  isListDesignObservable(): Observable<boolean> {
    return this.listDesign.asObservable();
  }

  private getListDesignValue(): boolean {
    return localStorage.getItem(LIST_DESIGN_STORAGE_KEY) === 'true';
  }
}