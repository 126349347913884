import { NgModule, LOCALE_ID, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
// import { Base64 } from '@ionic-native/base64/ngx';
// import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
// import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { register } from 'swiper/element';
import { StatusBar } from '@capacitor/status-bar';

import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { Chooser } from "@awesome-cordova-plugins/chooser/ngx";
import { MobileAccessibility } from "@awesome-cordova-plugins/mobile-accessibility/ngx"
import { OneSignal } from "@awesome-cordova-plugins/onesignal/ngx"

register();

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// Containers
import { LayoutComponent } from './containers/layout/layout.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AnalyticsGuardService } from './shared/interceptors/analytics-guard-child';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { Clipboard } from '@ionic-native/clipboard/ngx';
// import { FilePath } from '@ionic-native/file-path/ngx';
// import { FileChooser } from '@ionic-native/file-chooser/ngx';
// import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
// import { File } from '@ionic-native/file/ngx'
// import { Device } from '@ionic-native/device/ngx';
import { GlobalErrorHandler } from './shared/interceptors/GlobalErrorHandler';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import 'hammerjs';
import CapacitorCameraPluginImpl from './shared/plugins/Camera/CapacitorCameraPluginImpl.service';
import CordovaCameraPluginImpl from './shared/plugins/Camera/CordovaCameraPluginImpl.service';
import { ChooserService } from './shared/plugins/Chooser/chooser.service';
import { CapacitorBiometryPluginImplService } from './shared/plugins/Biometry/CapacitorBiometryPluginImpl.service';
import { BiometryPluginToken } from './shared/plugins/Biometry/BiometryPlugin';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;
const APP_CONTAINERS = [LayoutComponent, DefaultLayoutComponent];
registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [AppComponent, ...APP_CONTAINERS ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        IonicModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        NgxMaskModule.forRoot(),
    ],
    providers: [
        // StatusBar,
        // InAppBrowser,
        // Clipboard,
        // Base64,
        // FilePath,
        // FileChooser,
        // IOSFilePicker,
        // BackgroundMode,
        AnalyticsGuardService,
        // MobileAccessibility,
        // OneSignal,
        // File,
        // FingerprintAIO,
        // Device,
        Chooser,
        BarcodeScanner,
        Camera,
        MobileAccessibility,
        OneSignal,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            provide: 'CameraPluginImpl',
            useClass: CordovaCameraPluginImpl,
        },
        {
            provide: BiometryPluginToken,
            useClass: CapacitorBiometryPluginImplService,
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: "ChooserPluginImpl", useClass: ChooserService}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {}
}