<div class="DesignSystem_EmptyResultComponent" [ngClass]="{'__is-fading': isFading}">
  <div class="IconContainer">
    <div class="BigIcon">
      <i class="fas fa-folder-open"></i>
    </div>
  </div>

  <div class="TextContainer">
    <p>Nenhum resultado para a sua busca</p>
  </div>

  <div class="ButtonContainer">
    <ds-button type="Outline" (click)="handleButtonClick()">
      Limpar filtro
    </ds-button>
  </div>
</div>