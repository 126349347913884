import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'ds-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {
  @Input() color: string = 'Grey';
  @Input() hasFooter: boolean = false;
  @Input('p-title') title: string = '';

  constructor() { }

  ngOnInit() {}

}
