import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, AfterViewChecked, Sanitizer, ViewChild, AfterViewInit, INJECTOR, Inject } from '@angular/core';
import { ImagemArquivoMaloteDTO } from 'src/app/modules/envio-documento/services/DTO/request/ImagemArquivoMalote.DTO';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { StateService } from 'src/app/shared/services/stateService';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { LightBoxesService } from '../../lightboxes';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Platform } from '@ionic/angular';
// import { BackgroundMode } from "@ionic-native/background-mode/ngx";
import { ImagemArquivoDTO } from "src/app/shared/services/DTO/Request/ImagemArquivo.DTO";
import * as moment from "moment";
import { ChooserService } from "src/app/shared/plugins/Chooser/chooser.service";
import { Camera, CameraPlugin, CameraResultType, CameraSource, ImageOptions, } from '@capacitor/camera';
import { InputFileMobile } from '../input-file-mobile/input-file-mobile.component';

@Component({
  selector: "app-upload-html5",
  templateUrl: "./upload-html5.component.html",
  styleUrls: ["./upload-html5.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [RouterModule],
})
export class UploadHtml5Component implements OnInit, AfterViewChecked {
  avatar: any;
  public reader = new FileReader();
  public form: UntypedFormGroup;
  @Input() listaImagens: ImagemArquivoMaloteDTO[] = [];
  @Input() isFullButton: boolean = true;
  @ViewChild("fileInput") fileInput;
  @Input() mimeTypesFilter: string = undefined;

  @Input() exibeBotaoDocumentosMobile: boolean = false;
  @Input() imagesOnly: boolean = false;
  @Input() pdfOnly: boolean = false;
  @Input() descricaoBotao: string;
  @Output() atualizarFormListaImagens: EventEmitter<ImagemArquivoMaloteDTO[]> = new EventEmitter();
  @Output() getUrlImagem: EventEmitter<any> = new EventEmitter();
  public valoresAceitosDefault: string =
    ".xls, .xlsx, .jpg, .jpeg, .png, .doc, .pdf, .docx";
  public valoresAceitosOutros = ".pdf, .jpg, .jpeg, .png, .btm, .tiff, .tif";
  public valoresImagens = ".jpg, .jpeg, .png, .tiff, .tif";
  public rotaAtual = this.router.url;
  public window: any = window;
  public rotasBotaoUploadDocumentos = [
    "/envio-documento",
    "/envio-pagamentos",
    "/minhas-solicitacoes/cadastro",
    "/mural/cadastro",
    "/muralGT/cadastro",
    "/mural/edicao/",
    "/muralGT/edicao",
  ];

  constructor(
    private activedRoute: ActivatedRoute,
    public platform: Platform,
    private router: Router,
    private lightBoxesService: LightBoxesService,
    public constants: ConstantsService,
    private fb: UntypedFormBuilder,
    public stateService: StateService,
    private lightboxesService: LightBoxesService,
    private inputMobile: InputFileMobile,
    @Inject('CameraPluginImpl') private cameraPlugin: CameraPlugin, 
    @Inject("ChooserPluginImpl") private chooser: ChooserService
  ) {
    activedRoute.params.subscribe((val) => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    // this.createForm();
  }

  ngAfterViewChecked(): void {
    this.stateService.platformIsDesktopOrMobileWeb() &&
      this.updateInputRender();
  }

  getDescricao(): string {
    return this.descricaoBotao ? this.descricaoBotao : "Anexar Imagens";
  }

  getDescricaoListagemArquivos(): string {
    return this.exibeBotaoDocumentosMobile ||
      this.rotasBotaoUploadDocumentos.includes(this.rotaAtual)
      ? "Lista de anexos"
      : "Lista de imagens";
  }

  updateInputRender() {
    if (this.fileInput) {
      let validacaoRotas =
        this.rotaAtual == "/envio-documento" ||
        this.rotaAtual == "/envio-pagamentos";
      if (validacaoRotas) {
        this.fileInput.nativeElement.setAttribute(
          "accept",
          this.valoresAceitosOutros
        );
        document
          .getElementsByClassName("Form_Upload_Input")[0]
          .setAttribute("accept", this.valoresAceitosOutros);
      } else {
        this.fileInput.nativeElement.setAttribute(
          "accept",
          this.valoresAceitosDefault
        );
      }

      if (this.imagesOnly) {
        this.fileInput.nativeElement.setAttribute(
          "accept",
          this.valoresImagens
        );
      }
    }

    // if(this.fileInput && this.imagesOnly) {
    //   this.fileInput.nativeElement.setAttribute('accept', this.valoresImagens);
    // }
  }


  onFileChange(event) {
    // console.log(event.target.files[0])
    const tamanho = event.target.files[0].size;
    const tamanhoEspecifico =
      this.exibeBotaoDocumentosMobile ||
      this.rotasBotaoUploadDocumentos.includes(this.rotaAtual)
        ? this.tamanhoMaxArquivos
        : undefined;

    if (this.stateService.verificarTamanhoUpload(tamanho, tamanhoEspecifico)) {
      if (event.target.files && event.target.files.length > 0) {
        let file = event.target.files[0];
        const ext = file.name.split(".")[file.name.split(".").length - 1]
          ? file.name.match(/\.([^\.]+)$/)[1]
          : null;

        if (!ext) {
          this.lightBoxesService.open(
            "custom-generico",
            {
              title: "Atenção",
              text: "O documento enviado não possui extensão, por favor tente anexar um arquivo que contenha uma extensão válida",
            },
            5000
          );
          return;
        }

        if (
          this.imagesOnly &&
          !this.valoresImagens.includes(ext.toLowerCase())
        ) {
          this.lightBoxesService.open(
            "custom-generico",
            { title: "Atenção", text: "O arquivo enviado não é uma imagem." },
            5000
          );
          return;
        }

        let valido;

        if (
          this.rotaAtual == "/envio-documento" ||
          this.rotaAtual == "/envio-pagamentos"
        )
          valido = this.valoresAceitosOutros.includes(ext.toLowerCase());
        // else if (
        //   this.rotaAtual == '/mural/cadastro' ||
        //   this.rotaAtual == '/muralGT/cadastro' ||
        //   this.rotaAtual.includes('/mural/edicao') ||
        //   this.rotaAtual.includes('/muralGT/edicao')
        // ) valido = this.valoresImagens.includes(ext.toLowerCase());
        else valido = this.valoresAceitosDefault.includes(ext.toLowerCase());
        
        if(this.pdfOnly && ext.toLowerCase() !== "pdf") {
          valido = false
        }

        if (valido) {
          this.reader.readAsDataURL(file);
          this.reader.onload = () => {
            let validarTamanho = true;
            if(file.type == 'application/pdf' && (this.rotaAtual == "/envio-pagamentos" || (this.rotaAtual == "/envio-documento" && this.pdfOnly))){
              var bin = atob(this.reader.result.toString().split(",")[1]);
              let pageCount;
              if(bin.match(/\/Type\s*\/Page\b/g) == null){
                pageCount = 0;
              }else{
                pageCount = bin.match(/\/Type\s*\/Page\b/g).length;
              }
              
              validarTamanho = pageCount > 30 ? false : true
            }

            if(validarTamanho){
              
              this.avatar = {
                filename: file.name,
                filetype: file.type,
                value: this.reader.result.toString().split(",")[1],
              };
  
              // console.log('file result', this.reader.result.toString().split(",")[1])
              
  
              var src = this.stateService.getCaminhoImagemExibicao(
                file.type,
                this.reader.result.toString().split(",")[1]
              );
              this.listaImagens.push(
                new ImagemArquivoMaloteDTO(
                  this.reader.result.toString().split(",")[1],
                  file.type,
                  file.name,
                  src
                )
              );
              this.atualizarFormListaImagens.emit(this.listaImagens);
              //this.form.get('avatar').setValue('');
              this.avatar = "";
            }else{
              this.lightBoxesService.open(
                "custom-generico",
                {
                  title: "Atenção",
                  text: "O arquivo não pode conter mais de 30 páginas.",
                },
                5000
              );
            }
          };

        } else {
          this.avatar = null;
          this.lightBoxesService.open(
            "custom-generico",
            {
              title: "Atenção",
              text: "O formato de arquivo informado não é aceito.",
            },
            5000
          );
        }
      }
    }
  }

  get tamanhoMaxArquivos(){
    return this.pdfOnly ? 5000000 : 9000000
  }
  get formControls() {
    return this.form.controls;
  }

  async anexarDocumentosApps() {
    const filters = this.mimeTypesFilter ? [this.mimeTypesFilter] : []

    const tamanhoEspecifico =
      this.exibeBotaoDocumentosMobile ||
      this.rotasBotaoUploadDocumentos.includes(this.rotaAtual)
        ? 9000000
        : undefined;

    await this.inputMobile.anexar(filters).then(arquivo => {
      let src = this.stateService.getCaminhoImagemExibicao(
        arquivo.Tipo,
        arquivo.Conteudo
      );
      const fileName = moment().format("DD-MM-YYYY_HH-mm-ss") + ".pdf";
      this.listaImagens.push(
        new ImagemArquivoMaloteDTO(arquivo.Conteudo, arquivo.Tipo, fileName, src)
      );
      this.atualizarFormListaImagens.emit(this.listaImagens);
    });
  }

  async openCamera(type: number) {

    const options: ImageOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      source: type === 1 ? CameraSource.Camera : CameraSource.Photos
    }

    await Camera.getPhoto(options).then(photo => {
      if(photo) {
        let src = this.stateService.getCaminhoImagemExibicao(
          `image/${photo.format}`,
          photo.dataUrl.split(',')[1]
        );
        const fileName = moment().format("DD-MM-YYYY_HH-mm-ss") + ".jpeg";
        this.listaImagens.push(
          new ImagemArquivoMaloteDTO(photo.dataUrl.split(',')[1], `image/${photo.format}`, fileName, src)
        );
        this.atualizarFormListaImagens.emit(this.listaImagens);
      }
    })

  }


  excluirAnexo(obj) {
    var index = this.listaImagens.indexOf(obj);
    this.listaImagens.splice(index, 1);
    this.atualizarFormListaImagens.emit(this.listaImagens);
  }

  openLinkAnexo(url) {
    if (url) {
      this.stateService.openExternalLink(url);
    } else {
      this.lightboxesService.open(
        "custom-generico",
        { title: "Atenção", text: "URL inválida" },
        6000
      );
    }
  }

  limitChars(str: string) {
    const max = window.innerWidth > 800 ? 15 : 25;

    if (str.length > max) {
      return str.slice(0, max) + "...";
    } else {
      return str;
    }
  }
}
