export class ColorfulIcon {
  public static construct(iconClass: string): string {
    return this.selectIcon(iconClass);
  }

  private static selectIcon(iconClass: string): string {
    switch(iconClass) {
      case 'apsa-icon-colorido-entrega-expressa-colorido':
        return this.createIconSpan(5, 'apsa-icon-colorido-entrega-expressa-colorido');

      case 'apsa-icon-colorido-check':
        return this.createIconSpan(2, 'apsa-icon-colorido-check');

      case 'apsa-icon-colorido-colaboradores':
        return this.createIconSpan(6, 'apsa-icon-colorido-colaboradores');

      case 'apsa-icon-colorido-reservas1':
        return this.createIconSpan(3, 'apsa-icon-colorido-reservas1');

      case 'apsa-icon-colorido-condominio-digital':
        return this.createIconSpan(14, 'apsa-icon-colorido-condominio-digital'); 

      case 'apsa-icon-colorido-cotas':
        return this.createIconSpan(5, 'apsa-icon-colorido-cotas');

      case 'apsa-icon-colorido-sobre-condominio-colorido':
        return this.createIconSpan(32, 'apsa-icon-colorido-sobre-condominio-colorido');

      case 'apsa-icon-colorido-enquete':
        return this.createIconSpan(2, 'apsa-icon-colorido-enquete');

      case 'apsa-icon-colorido-comunicacao':
          return this.createIconSpan(8, 'apsa-icon-colorido-comunicacao');

      case 'apsa-icon-colorido-envio-documentos':
        return this.createIconSpan(6, 'apsa-icon-colorido-envio-documentos');

      case 'apsa-icon-colorido-extrato':
        return this.createIconSpan(9, 'apsa-icon-colorido-extrato');

      case 'apsa-icon-colorido-espacos-colorido':
        return this.createIconSpan(3, 'apsa-icon-colorido-espacos-colorido');

      case 'apsa-icon-colorido-fale-com-gestor':
        return this.createIconSpan(7, 'apsa-icon-colorido-fale-com-gestor');

      case 'apsa-icon-colorido-funcionarios':
        return this.createIconSpan(3, 'apsa-icon-colorido-funcionarios');

      case 'apsa-icon-solid-mural':
        return this.createFakeColorfulIcon('apsa-icon-solid-mural');

      case 'apsa-icon-colorido-funcionarios':
        return this.createIconSpan(3, 'apsa-icon-colorido-funcionarios');

      case 'apsa-icon-colorido-malotes':
        return this.createIconSpan(2, 'apsa-icon-colorido-malotes');

      case 'apsa-icon-colorido-financeiro':
        return this.createIconSpan(4, 'apsa-icon-colorido-financeiro');

      case 'apsa-icon-colorido-fale-com-gestor':
        return this.createIconSpan(7, 'apsa-icon-colorido-fale-com-gestor');

      case 'apsa-icon-colorido-colorido-informe-quitacao':
        return this.createIconSpan(8, 'apsa-icon-colorido-colorido-informe-quitacao');

      case 'apsa-icon-colorido-cotas':
        return this.createIconSpan(5, 'apsa-icon-colorido-cotas');

      case 'apsa-icon-colorido-pagamento-locatario':
        return this.createIconSpan(4, 'apsa-icon-colorido-pagamento-locatario');

      case 'apsa-icon-colorido-colorido_beneficios_pra_voce':
        return this.createIconSpan(4, 'apsa-icon-colorido-colorido_beneficios_pra_voce');
      
      case 'apsa-icon-colorido-dados-imovel':
        return this.createIconSpan(5, 'apsa-icon-colorido-dados-imovel');

      case 'apsa-icon-colorido-extrato':
        return this.createIconSpan(9, 'apsa-icon-colorido-extrato');

      case 'apsa-icon-colorido-motorcycle':
        return this.createIconSpan(10, 'apsa-icon-colorido-motorcycle');

      default:
        return null;
    }
  }

  private static createIconSpan(amount: number, outerSpanClass: string): string {
    let spanContainingPaths = `<span class="${outerSpanClass}">`;
    let paths = '';

    for(let i = 0; i < amount; i++)
      paths += `<span class="path${i+1}"></span>`;

    spanContainingPaths += paths;
    spanContainingPaths += '</span>';

    return spanContainingPaths;
  }

  private static createFakeColorfulIcon(className: string): string {
    return `<i class="${className}></i>`;
  }
}
