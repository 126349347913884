import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ds-option-button',
  templateUrl: './option-button.component.html',
  styleUrls: ['./option-button.component.scss'],
})
export class OptionButtonComponent implements OnInit, OnChanges {
  public open:boolean = false
  public preenchido:boolean = false;
  
  
  
  @Input() opcoes: any[] = []
  @Input() label: string
  @Input() reset: number = 0
  @Input() width: boolean = false;
  @Input() itemSelecionado: string = "Clique para selecionar"
  @Input() placeholder: string = "Clique para selecionar"
  @Output() selecionaItem = new EventEmitter()
  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.reset && changes.reset.currentValue){
      console.log('dentro do change')
      this.itemSelecionado = this.placeholder;
    } 
  }

  selecionarItem(item?:any){
    // console.log('item', item)
    if(item !== null && item !== undefined){
      this.preenchido = true;
      this.itemSelecionado = item;
      this.selecionaItem.emit(item)
    } else{
      this.preenchido = false;
      this.itemSelecionado = null;
      this.selecionaItem.emit(null);
    }
    this.open = !this.open
     
  }

  cleanSelect(){
    this.itemSelecionado = null;
    this.preenchido = false;
  }

}
