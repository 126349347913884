import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImagemArquivoDTO } from 'src/app/shared/services/DTO/Request/ImagemArquivo.DTO';
import { Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import * as moment from 'moment';
import { StateService } from 'src/app/shared/services/stateService';

@Component({
  selector: 'app-button-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  // providers: [Camera]
})
export class FileComponent implements OnInit {

  @Input() text: string;
  @Input() icon: string;
  @Input() accept: string;
  @Input() type: number;
  @Input() platform: string;
  @Output() onFileChange: EventEmitter<any> = new EventEmitter();


  // public listaImagens: ImagemArquivoDTO[] = [];
  public reader = new FileReader();
  public id;

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private stateService: StateService) {

    this.id = Math.random().toString(36).substring(2)
  }

  ngOnInit() {
    // console.log(this.platform)
  }

  async openCamera(type: number) {

    const options: ImageOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      source: type === 1 ? CameraSource.Camera : CameraSource.Photos,
      direction: CameraDirection.Rear,
      allowEditing: false,
      correctOrientation: true,
    }

    await Camera.getPhoto(options).then(photo => {
      if(photo) {
        let src = this.stateService.getCaminhoImagemExibicao(
          `image/${photo.format}`,
          photo.dataUrl.split(',')[1]
        );
        const fileName = moment().format("DD-MM-YYYY_HH-mm-ss") + ".jpeg";
        this.onFileChange.emit(
          new ImagemArquivoDTO(photo.dataUrl.split(',')[1], `image/${photo.format}`, fileName, src)
        )
      }
    })
  }



}
