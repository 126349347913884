<div class="NotificacoesModalComponent Modal" [ngClass]="{ '__is-open': open }">
  <div class="Modal_Close" (click)="close()">
    <i class="fa fa-times"></i>
  </div>

  <div class="Modal_Header">
    Notificações
  </div>

  <div class="Modal_Content">
    <app-notificacoes></app-notificacoes>
  </div>
</div>