import { Guid } from 'guid-typescript';
import { BlocoGtDTO } from './BlocoGt.DTO';
import { CondominioGTDTO } from './CondominioGT.DTO';
import { TipoEspacoDTO } from './TipoEspaco.DTO';
import { ItemDTO } from './Item.DTO';
import { ChecklistDTO } from './Checklist.DTO';
import { ImagemArquivoDTO } from './ImagemArquivo.DTO';

export class EspacoDTO {
  Uid: Guid;
  Block: BlocoGtDTO
  Complement: string;
  Condominium: CondominioGTDTO
  Description: string;
  Enabled: boolean;
  GuestListRequired: boolean;
  Images: Array<ImagemArquivoDTO>
  Items: ItemDTO
  Name: string;
  QrCode: string;
  Reserveable: boolean;
  SpaceType: TipoEspacoDTO;
  allowDelete: boolean;
  totalImages: number;
  Checklists: Array<ChecklistDTO>;
  Reservations?: any[]

  constructor(public uid: Guid, nome?: string, complemento?: string, descricao?: string, guestList?: boolean, images?: Array<ImagemArquivoDTO>, reservavel?: boolean, totalImagens?: number, Reservations?: any[]) {
    this.Uid = uid;
    if (nome) this.Name = nome;
    if (complemento) this.Complement = complemento;
    if (descricao) this.Description = descricao;
    if (guestList) this.GuestListRequired = guestList;
    if (images){ 
      this.Images = images
    }else{
      this.Images= []
    };
    if (reservavel) this.Reserveable = reservavel;
    if (totalImagens) this.totalImages = totalImagens;
    if(Reservations) this.Reservations = Reservations
  }


}
