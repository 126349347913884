<div class="Modal Modal_AdesaoDeBoletoDigital" [ngClass]="{ '__is-open': isOpen }">
  <div class="Modal_Header">
    <div class="Modal_Header_Title">
      Adesão de boleto digital
    </div>
  </div>

  <div class="Modal_Content">
    <div class="Block">
      <div class="Block_Container">
        <adesao-de-boleto-digital
        [listaCondominios]="listaCondominios"
        [listaImoveis]="listaImoveis"
        [imoveisAgrupadosPorContrato]="imoveisAgrupadosPorContrato"
        (listaImoveisEditada)="enviarListaEditada($event)"


        (close)='close()'
        >
        </adesao-de-boleto-digital>

        <div class="Text_Container">
          <p class="Paragraph __is-small">
            As unidades selecionadas não receberão mais o boleto impresso.
            Se o mesmo já foi emitido, a adesão passa a valer a partir do
            próximo vencimento.
          </p>
  
          <p class="Paragraph __is-small">
            Você poderá alterar a adesão das suas unidades a qualquer momento,
            na opção "Adesão de Boleto Digital" no menu do usuário,
  
            <span class="mobile">
              no canto superior direito.
            </span>
  
            <span class="desktop">
              na barra superior.
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="Modal_Footer">
    <div class="Modal_Footer_ListActions">
      <div class="Footer_ListActions_Item __is-full-width">
        <ds-button (click)="cadastraEditaAdesaoUnidade()" full="true" leftIcon="fas fa-check">
          Salvar preferências
        </ds-button>
      </div>

      <!-- <div class="Footer_ListActions_Item __is-full-width __is-flex">
        <button (click)="close()" class="RememberLater">
          Lembrar depois
        </button>
      </div> -->
    </div>
  </div>
</div>