<div class="PoliticaPrivacidadeTextComponent">
  <div [innerHTML]="termos"></div>
  <!-- <article>
    <h1>II - POLÍTICA DE PRIVACIDADE</h1>
  
    <h2>
      1. CONSIDERAÇÕES INICIAIS
    </h2>
    <p>
      1.1 A Política de Privacidade existe com a finalidade de esclarecer o USUÁRIO acerca do tratamento das INFORMAÇÕES e DADOS fornecidos e/ou coletadas através do SITE - ÁREA DO CLIENTE, sendo parte integrante dos TERMOS E CONDIÇÕES DE USO & POLÍTICA DE PRIVACIDADE.<br><br>
      1.2 A APSA se preocupa com a privacidade dos seus USUÁRIOS e por isso utiliza seus DADOS e INFORMAÇÕES de forma consciente e responsável. A APSA, no âmbito das ações reguladas neste instrumento, compromete-se a tratar somente os DADOS e INFORMAÇÕES que sejam necessários para a realização de sua atividade fim, de suas obrigações legais e contratuais, e do que dispõem estes TERMOS E CONDIÇÕES DE USO & POLÍTICA DE PRIVACIDADE.<br><br>
      1.3 A APSA empregará esforços para resguardar de qualquer tratamento abusivo ou irregular, as INFORMAÇÕES e DADOS tratados do USUÁRIO pelo SITE - ÁREA DO CLIENTE.
    </p>
  
    <h2>
      2. DADOS
    </h2>
    <p>
      2.1 É necessário o preenchimento dos DADOS e INFORMAÇÕES solicitados para a utilização de serviços e funcionalidades disponibilizados pelo SITE - ÁREA DO CLIENTE, sendo de única e exclusiva responsabilidade do USUÁRIO a veracidade e exatidão dos referidos DADOS e INFORMAÇÕES. À APSA não cabe qualquer responsabilidade civil ou criminal decorrente de DADOS e INFORMAÇÕES inverídicos, omissos ou incorretos.<br><br>
      2.2 Caso haja a mínima suspeita por parte da APSA de INFORMAÇÕES inverídicas, omissas ou incorretas, a mesma se reserva ao direito de suspender, excluir ou até mesmo bloquear acesso ou cadastro do USUÁRIO ao SITE - ÁREA DO CLIENTE, pelo tempo que entender necessário, não tendo o USUÁRIO direito a qualquer tipo de indenização de ordem material ou moral e/ou compensação de qualquer natureza.
    </p>
  
    <h2>
      3. TRATAMENTO DOS DADOS
    </h2>
    <p>
      3.1 O SITE - ÁREA DO CLIENTE trata DADOS fornecidos pelo USUÁRIO tais quais número de telefone, nome, endereço, CPF ou CNPJ, e-mail, data de nascimento, inclusive DADOS informados fora do SITE – ÁREA DO CLIENTE (tais como dados do contrato e relação de trabalho, para empregados em CONDOMÍNIO, ou dados constantes de escrituras de compra e venda, para condôminos) e INFORMAÇÕES automáticas, tais quais endereço de IP (internet protocol), características do dispositivo de acesso, informações para a adequada autenticação.<br><br>
      3.2 Ao fazer USO do SITE - ÁREA DO CLIENTE, o USUÁRIO aceita automaticamente os presentes termos, assim como está ciente e de acordo com o tratamento de todas as INFORMAÇÕES e DADOS necessários para o adequado funcionamento e finalidade do SITE - ÁREA DO CLIENTE.<br><br>
      3.3 Todos os DADOS e INFORMAÇÕES consideradas relevantes pela APSA, fornecidas pelo USUÁRIO através do SITE - ÁREA DO CLIENTE, serão armazenados no banco de dados da APSA, por tempo limitado, e poderão ser acessados por pessoas qualificadas e autorizadas pela APSA. A permissão para tal acesso é definida internamente pela APSA conforme as responsabilidades envolvidas.<br><br>
      3.4 A APSA poderá utilizar os DADOS e INFORMAÇÕES fornecidos para análise e estudos, com a finalidade de aprimorar os seus serviços, e para fins de contato com o USUÁRIO, podendo oferecer serviços e disponibilizar conteúdo direcionado aos USUÁRIOS, assim como para demais finalidades que considerar úteis, com o intuito de oferecer novos serviços aos USUÁRIOS.<br><br>
      3.5 Ao utilizar o SITE - ÁREA DO CLIENTE, o USUÁRIO manifesta livremente seu expresso consentimento acerca do tratamento de seus DADOS e INFORMAÇÕES por empresas parceiras da APSA, podendo inclusive ser compartilhados com escritórios de advocacia contratados pela APSA.<br><br>
      3.6 A APSA compromete-se a cumprir as disposições legais referentes ao armazenamento de DADOS e INFORMAÇÕES, especialmente aquelas previstas na Lei Federal nº 13.709/2018.<br><br>
      3.7 A APSA, sem necessidade de nenhum aviso ou notificação ao USUÁRIO, poderá utilizar e fornecer os DADOS do USUÁRIO para o fim de: cumprir ordem legal ou judicial; defender ou exercer direitos em âmbito judicial ou administrativo; manter atualizado o banco de DADOS da APSA; efetuar pesquisas relacionadas ao aperfeiçoamento do serviço e à utilização do SITE - ÁREA DO CLIENTE.<br><br>
      3.8 A APSA não é responsável pelo tratamento de INFORMAÇÕES de sites (que não seja proprietária) por terceiros, bem como pelo armazenamento dos DADOS coletados pelos mesmos, ainda que feitos por links a partir do SITE - ÁREA DO CLIENTE.<br><br>
      3.9. A APSA empregará esforços para resguardar as INFORMAÇÕES e DADOS do USUÁRIO tratados a partir do SITE - ÁREA DO CLIENTE. Todavia, considerando que não há meio de transmissão e retenção de DADOS eletrônicos plenamente eficaz e seguro, a APSA não pode assegurar que terceiros não autorizados não logrem êxito no acesso indevido, eximindo-se de qualquer responsabilidade por danos e prejuízos decorrentes de condutas ilícitas de terceiros (a exemplo de transmissão de vírus ao SITE - ÁREA DO CLIENTE ou invasão de seu banco de dados).
    </p>
  
    <h2>
      4. DISPOSIÇÕES GERAIS DA POLÍTICA DE PRIVACIDADE
    </h2>
    <p>
      4.1 Os USUÁRIOS poderão entrar em contato com a APSA em caso de dúvida acerca dos termos desta Política de Privacidade através do e-mail atendimento@apsa.com.br.<br><br>
      4.2 Fica, desde já, o USUÁRIO ciente que:<br><br>
      a) o conteúdo desta Política de Privacidade pode ser alterado a critério da APSA, independente de aviso ou notificação, razão pela qual é recomendável ao usuário a verificação rotineira e habitual deste documento. Em caso de alteração, as modificações produzem todos os efeitos a partir do momento da disponibilização no SITE - ÁREA DO CLIENTE.<br><br>
      b) caso o USUÁRIO não concorde com o conteúdo da presente Política de Privacidade não deverá fazer USO do SITE - ÁREA DO CLIENTE, pois a partir do USO do SITE - ÁREA DO CLIENTE é manifestada sua livre vontade e expressa ciência e consentimento sobre o tratamento, pela APSA, dos DADOS e INFORMAÇÕES fornecidos e/ou enviados por si a partir do SITE - ÁREA DO CLIENTE.<br><br>
      4.3 Fica terminantemente proibido o USO, pelo USUÁRIO, de qualquer ferramenta, software ou dispositivo para captação de DADOS e/ou para interferir no funcionamento adequado do SITE - ÁREA DO CLIENTE e suas funcionalidades, bem como transmitir vírus.<br><br>
      4.4 A APSA poderá registrar todas as atividades realizadas pelos USUÁRIOS através do endereço do IP e login em conta de acesso, que poderão ser utilizados como meio probatório caso seja necessário.<br><br>
      4.5 Os USUÁRIOS que não respeitarem os termos destes TERMOS E CONDIÇÕES DE USO & POLÍTICA DE PRIVACIDADE OU não puderem ter sua identidade verificada OU fornecerem INFORMAÇÃO ou DADO incorretos OU sobre eles recaia suspeita de cadastro falso ou de pessoa diversa OU que pratiquem atos que possam por em risco o SITE - ÁREA DO CLIENTE ou a própria APSA OU que pratiquem atos contrários à boa-fé e aos bons costumes; poderão ser excluídos do SITE - ÁREA DO CLIENTE sem prejuízo de outras medidas e eventual responsabilidade por perdas e danos causados à APSA, além de eventual responsabilização criminal.<br><br>
      4.6 A tolerância do eventual descumprimento de quaisquer das cláusulas e condições da presente Política de Privacidade pelo USUÁRIO não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo pela APSA
    </p>
  
    <h2>
      III - LEGISLAÇÃO E FORO
    </h2>
    <p>
      Todos os termos do presente instrumento designado por TERMOS E CONDIÇÕES DE USO & POLÍTICA DE PRIVACIDADE são regidos pela legislação vigente na República Federativa do Brasil. Fica eleito o foro da comarca da capital do estado do Rio de Janeiro para dirimir quaisquer questões relacionadas ao presente e às relações com base neste instrumento estabelecidas.
    </p>
  </article> -->
</div>