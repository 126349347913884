<div class="BoxWIthDashedBorderComponent_Container" *ngIf="!fillImage; else hasImage" (click)="handleClick()">
  <div class="Icon">
    <i class="{{icon}}"></i>
  </div>
  
  <div class="Message">
    <p>
      <ng-content></ng-content>
    </p>
  </div>
</div>

<ng-template #hasImage>
  <div class="BoxWIthDashedBorderComponent_HasImage">
    <div class="BoxWIthDashedBorderComponent_Container" (click)="handleClick()">
      <img [src]="fillImage">
    </div>
  
    <div class="DeleteIcon" (click)="handleDeleteClick()">
      <i class="fas fa-trash"></i>
    </div>
  </div>
</ng-template>