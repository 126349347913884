import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'caixa-opcoes',
  templateUrl: './caixa-opcoes.component.html',
  styleUrls: ['./caixa-opcoes.component.scss'],
})
export class CaixaOpcoesComponent implements OnInit {
  public open:boolean = false
  public itemSelecionado; 
  // public itemPrincipalSelecionado; 
  @Input() itemPrincipalSelecionado;
  @Input() opcoes: any[] = []
  @Input() label: string
  @Input() novo: boolean = false;
  @Input() itemPrincipal: string;
  @Output() selecionaItem = new EventEmitter()
  @Output() selecionaItemPrincipal = new EventEmitter()
  constructor() { }

  ngOnInit() {}


  selecionarItem(item?:any){
    
    // console.log('item', item)
    if(item !== null && item !== undefined){
      this.itemSelecionado = item;
      this.selecionaItem.emit(item)
    } else{
      this.itemSelecionado = null;
      this.selecionaItem.emit(null);
    }
     
  }

  selecionarItemPrincipal(item:any){
    this.open = !this.open
    if(this.itemPrincipalSelecionado == item){
      // this.itemPrincipalSelecionado = null
      this.selecionaItemPrincipal.emit(null)
      this.selecionaItem.emit(null)
    }else{
      this.selecionaItemPrincipal.emit(item)
      // this.itemPrincipalSelecionado = item;
    }
    this.itemSelecionado = null;
  }

}
