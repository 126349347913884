import { Injectable } from "@angular/core";
import { LightBoxesService } from "../components/lightboxes";
import { CondominioService } from "./condominio.service";
import { StateService } from "./stateService";

export const KEY_EXIBIR_BANNER_CONDOMINIAL = 'BannersSelecaoDeCondominioOuImovel';

@Injectable({ providedIn: 'root'})
export class BannerModalService {

  constructor(
    private condominioService: CondominioService,
    private lightboxesService: LightBoxesService,
    public stateService: StateService,
  ) {

  }

  exibirBannerSelecaoDeCondominio(): Promise<'DISPLAYED' | 'NOT_DISPLAYED'> {
    return new Promise((resolve) => {
      try {
        let userAceitouTermos:boolean;
        const idPessoa = this.stateService.getPessoaLogada().Id;
        const bannersExibidos = JSON.parse(localStorage.getItem(KEY_EXIBIR_BANNER_CONDOMINIAL));
        let tipoDeBannerExibido: 'contaCondominios' | 'imoveisProprietario' | 'imoveisLocatario' | null = null;
      
        if(!bannersExibidos) {
          const newBannerExibidos = [this.createVisualizedBannerObject(idPessoa)]; 
          localStorage.setItem(KEY_EXIBIR_BANNER_CONDOMINIAL, JSON.stringify(newBannerExibidos));
        }
        else if(!bannersExibidos.some(banner => banner.id === idPessoa)) {
          bannersExibidos.push(this.createVisualizedBannerObject(idPessoa));
          localStorage.setItem(KEY_EXIBIR_BANNER_CONDOMINIAL, JSON.stringify(bannersExibidos));
        }
        
        this.stateService.usuarioAceitouTermosCondicoes().subscribe(
          response => {
            userAceitouTermos = response.usuarioAceitou;
    
            if(userAceitouTermos) {
              this.condominioService.getBannerRota('banner-selecao-condominio').subscribe(res => {
                if(res && res.exibirBanner) {
                  const exibeModalLocal = JSON.parse(localStorage.getItem(KEY_EXIBIR_BANNER_CONDOMINIAL));
                  const contaEmpresaDoCondominioLogado = this.stateService.getCondominioLogado() ? this.stateService.getCondominioLogado().contaEmpresa : null;
                  const codigoDoImovelLogado = this.stateService.getImovelLogado()?.codigoImovel || this.stateService.getImovelLogado()?.codigoImovelPrinipal;
                  
                  let obj = exibeModalLocal?.find(item => item.id === idPessoa);              
                  let newObj = contaEmpresaDoCondominioLogado ? obj?.contaCondominios?.find(item => item.contaEmpresa == contaEmpresaDoCondominioLogado) : obj?.contaCondominios[0]?.contaEmpresa;
  
                  if(obj && newObj === undefined && codigoDoImovelLogado) {
                    if(obj?.imoveisProprietario?.length > 0) {
                      newObj = obj?.imoveisProprietario?.find(item => item.codigo == codigoDoImovelLogado);
                      if(newObj) tipoDeBannerExibido = 'imoveisProprietario';
                    }
                    
                    if(obj?.imoveisLocatario.length > 0) {
                      newObj = obj?.imoveisLocatario?.find(item => item.codigo == codigoDoImovelLogado);
                      if(newObj) tipoDeBannerExibido = 'imoveisLocatario';
                    }   
                  }
                  else if (obj && newObj !== undefined) {
                    tipoDeBannerExibido = 'contaCondominios';
                  }
          
                  if (newObj && !newObj.jaExibiuModal) {
                    this.lightboxesService.openModalImageLinkButton("image-with-link-button", {
                      path: window.innerWidth >= 800 ? res.urlImagemDesktop : res.urlImagemMobile,
                      userId: idPessoa,
                      url: res.exibirLink ? res.link : null,
                    }, 0);
    
                    window.setTimeout(() => {
                      if(tipoDeBannerExibido === 'contaCondominios') {
                        exibeModalLocal?.forEach(itemA => {
                          if(itemA?.contaCondominios?.length > 0) {
                            itemA.contaCondominios.forEach(itemB => {
                              if(itemB?.contaEmpresa === newObj?.contaEmpresa) {
                                itemB.jaExibiuModal = true;
                              }
                            });
                          }
                        });
                      }
                      else if(tipoDeBannerExibido === 'imoveisLocatario') {
                        exibeModalLocal?.forEach(itemA => {
                          if(itemA?.imoveisLocatario?.length > 0) {
                            itemA.imoveisLocatario.forEach(itemB => {
                              if(itemB?.codigo === newObj?.codigo) {
                                itemB.jaExibiuModal = true;
                              }
                            });
                          }
                        });
                      }
                      else if(tipoDeBannerExibido === 'imoveisProprietario') {
                        exibeModalLocal?.forEach(itemA => {
                          if(itemA?.imoveisProprietario?.length > 0) {
                            itemA.imoveisProprietario.forEach(itemB => {
                              if(itemB?.codigo === newObj?.codigo) {
                                itemB.jaExibiuModal = true;
                              }
                            });
                          }
                        });
                      }
      
                      localStorage.setItem(KEY_EXIBIR_BANNER_CONDOMINIAL, JSON.stringify(exibeModalLocal));
                    }, 1000)

                    resolve('DISPLAYED');
                  }
                  else
                    resolve('NOT_DISPLAYED');
                }
                else {
                  resolve('NOT_DISPLAYED');
                }
              });
            }
            else {
              resolve('NOT_DISPLAYED');
            }
          },
          error => resolve('NOT_DISPLAYED'),
        );
      } catch(err) {
        resolve('NOT_DISPLAYED');
      }
    });
  }

  private createVisualizedBannerObject(idPessoa: string) {
    return {
      id: idPessoa,
      contaCondominios: this.stateService.getListaCondominiosImoveis()?.condominios?.map(c => {
        return {
          contaEmpresa: c.condominio.contaEmpresa,
          jaExibiuModal: false,
        };
      }) || [],
      imoveisProprietario: this.stateService.getListaCondominiosImoveis()?.imoveisProprietario?.map(i => {
        return {
          codigo: i.imovel.codigoImovel,
          jaExibiuModal: false,
        };
      }) || [],
      imoveisLocatario: this.stateService.getListaCondominiosImoveis()?.imoveisLocatario?.map(i => {
        return {
          codigo: i.imovel.codigoImovel || i.imovel.codigoImovelPrinipal,
          jaExibiuModal: false,
        };
      }) || [],
    }
  }
}