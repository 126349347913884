import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AcompanhamentoOrcamentarioService } from 'src/app/modules/acompanhamento-orcamentario/services/acompanhamento-orcamentario.service';
import { CondominioService } from '../../services/condominio.service';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'app-saldo-devedor',
  templateUrl: './saldo-devedor.component.html',
  styleUrls: ['./saldo-devedor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SaldoDevedorComponent implements OnInit, OnDestroy {
  public saldoDevedor: string | null = null;
  public subscription: Subscription;

  constructor(
    private acompanhamentoOrcamentarioService: AcompanhamentoOrcamentarioService,
    private condominioService: CondominioService,
    public stateService: StateService,
  ) { }

  ngOnInit() {
    this.fetchAPIData();
    this.subscription = CondominioService.trocaCondominio.subscribe(_ => {
      this.fetchAPIData();
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  fetchAPIData() {
    this.fetchSaldoDevedor();
  }

  fetchSaldoDevedor() {
    this.acompanhamentoOrcamentarioService.getSaldoDevedor().subscribe(
      response => {
        this.saldoDevedor = response;
        console.log(this.saldoDevedor);
      },
      err => {
        this.saldoDevedor = null;
      },
    );
  }
}
