import { Injectable } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { PrestacaoContasDetalhadas } from 'src/app/modules/prestacao-contas/services/DTO/response/PrestacaoContasDetalhada';

@Injectable({
  providedIn: 'root'
})

export class HomeService {
  subject = new BehaviorSubject<boolean>(null);
  perfilEhGestorOuSindico: boolean;

  private host = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods.prestacaoContas;

  constructor(private http: HttpClient, private routerService: StateService, public stateService: StateService) {

  }

  verificarPerfilLogado() {
    let boolObservable = of(this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico());

    boolObservable.subscribe(item => {
      this.perfilEhGestorOuSindico = item;
      this.getPerfilLogado();
    })
    return this.subject.asObservable();
  }


  getPerfilLogado() {
    this.subject.next(this.perfilEhGestorOuSindico);
  }

  //// RELACIONADO À BIOMETRIA //////
  getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  cadastrarDigital(id_pessoa, uid_autenticacao, device_model, device_platform) {
    let urlApiHost = environment.apiCondominioDigital.host;
    let methods = environment.apiCondominioDigital.endpoints.methods;

    var endpoint = methods.autenticacaoDigital.create;
    var url = urlApiHost + endpoint;


    var body = {
      "id_pessoa": id_pessoa,
      "uid_autenticacao": uid_autenticacao,
      "device_model": device_model,
      "device_platform": device_platform
    }

    return this.http.post<any>(url, body, { headers: this.getHeader() });
  }


  deletarDigital(id_pessoa) {
    let urlApiHost = environment.apiCondominioDigital.host;
    let methods = environment.apiCondominioDigital.endpoints.methods;

    var endpoint = methods.autenticacaoDigital.delete;
    var url = urlApiHost + endpoint;

    let params = new HttpParams().set('id_pessoa', id_pessoa);

    return this.http.delete<any>(url, { params, headers: this.getHeader() });
  }

  getPrestacaoContasDetalhada(meses: any): Observable<any[]> {
    const url = this.host + this.methods.getPrestacaoContasDetalhada;
    const params = {
      agencia: this.stateService.getCondominioLogado().agencia,
      contaCondominio: this.stateService.getCondominioLogado().conta,
      meses: meses,
    };
    return this.http.get<any[]>(url, { params, headers: this.getHeader() });
  }

  getPeriodoDisponivel(): Observable<string[]> {
    const url = this.host + this.methods.getPeriodoDisponivel;
    return this.http.get<string[]>(url, { headers: this.getHeader() });
  }

  idParametroReserva(id:string = '777'){
    const parametros = new HttpParams()
      .set('idParametro', id)

      const header =  new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.stateService.getTokenApiCondominioDigital()
      });
  

    return this.http.get<string[]>(`${ environment.apiCondominioDigital.host + environment.apiCondominioDigital.endpoints.methods.idParametroReserva}`,
      {headers: header, params: parametros})
  
  }
  ///////////////////////////////////////
}
