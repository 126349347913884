import { StateService } from 'src/app/shared/services/stateService';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ColorfulIcon } from '../../utils/ColorfulIcon';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-quick-card',
  templateUrl: './quick-card.component.html',
  styleUrls: ['./quick-card.component.scss'],
})

export class QuickCardComponent {
  @Input() id: any;
  @Input() title: any;
  @Input() icon: string;
  @Input() link: string;
  @Input() compact: boolean = false;
  @Input() type: number = 1; //1 default - 2 seguros.
  @Input() soonTag: boolean = false;
  @Input() detalhes = null;
  @Output() emitDetalhesSeguro = new EventEmitter();

  constructor(
    private router: Router,
    public stateService: StateService,
    public themeService: ThemeService,
    ) {
  }

  ngOnInit() {
    if((this.stateService.possuiApenasPerfilCondomino() || this.stateService.possuiPerfilProcuradorParaEmissaoBoleto())  && this.title === 'Cotas') {
      this.title = '2ª Via de Boletos';
    } else if(this.title === 'Cotas') {
      this.title = '2ª Via de Cotas'
    }
  }


  constructIcon() {
    if(this.id === 50) 
      return "<i class='fab fa-whatsapp WhatsappIcon'></i>";
    else if(this.id === 51)
      return "<i class='fas fa-copy RelatoriosIcon'></i>";
    else if(this.id === 52)
      return "<i class='apsa-icon-solid apsa-icon-solid-menu-funcionalidades'></i>";
    else
      return ColorfulIcon.construct(this.icon);
  }

  redirect(){
    if(this.soonTag === true) return;
    console.log(this.link.indexOf('http'))
    if(!this.detalhes) {
      if(this.id === 50 || this.link.indexOf('http') != -1) 
      this.stateService.openExternalLink(this.link);
      else
        this.router.navigate([this.link]);
    } else {
      if(this.detalhes.icon === 'saude' || this.detalhes.icon === 'pet') {
        this.router.navigate([this.link]);
      } else {
        this.emitDetalhesSeguro.emit(this.detalhes);
      }
    }
  }

  getImgSeguros(icon:string){
    let tema = this.themeService.getActiveThemeName().value

    // if(tema === 'Theme_Default' || icon === 'pet'){
    if(tema === 'Theme_Default'){
      return `assets/svg/icon-${icon}.svg`
    }else{
      return`assets/svg/icon-${icon}-home-dark.svg`
    }
  }
}
