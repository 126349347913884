export type ItemBusca = {
    titulo: string;
    rota: string;
    img: string;
    click: string;
    subItem: string;
}
export const ItensListaMenu: ItemBusca[] = [
    {
    titulo: '2ª via de boletos',
    rota: '/cotas/visualizacaoIndividual',
    img: '../../../../assets/icon/segunda-via-boletos-primary.svg',
    click: null,
    subItem: null
    },
    {
    titulo: 'Comodidades para você!',
    rota: null,
    img: '<span class="apsa-icon-colorido-colorido_beneficios_pra_voce"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>',
    click: 'comodidades',
    subItem: null,
    },
    {
    titulo: 'Extrato',
    rota: '/extrato/sindico',
    img: '<span class="apsa-icon-colorido-extrato"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>',
    click: null,
    subItem: 'Financeiro > Extrato',
    },
    {
    titulo: 'Normas e documentos',
    rota: '/documento',
    img: '<span class="apsa-icon-colorido-malotes"><span class="path1"></span><span class="path2"></span></span>',
    click: null,
    subItem: 'O condomínio > Normas e documentos'
    },
    {
    titulo: 'Quero alugar/vender',
    rota: null,
    img: 'far fa-building',
    click: 'alugar/vender',
    subItem: null
    },
    {
    titulo: 'Prestação de contas',
    rota: '/prestacao-contas',
    img: 'fas fa-coins',
    click: null,
    subItem: 'Financeiro > Prestação de contas'
    },
    {
    titulo: 'Fazer Reserva',
    rota: '/reserva',
    img: '<span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Reservas > Fazer Reserva'
    },
    {
    titulo: 'Fazer Reserva',
    rota: '/reserva-conv',
    img: '<span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Reservas > Fazer Reserva'
    },
    {
    titulo: 'Ocorrências',
    rota: '/ocorrencia',
    img: '<span class="apsa-icon-colorido-fale-com-gestor"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>',
    click: null,
    subItem: 'O condomínio > Ocorrências'
    },
    {
    titulo: 'Ocorrências',
    rota: '/ocorrenciaGT',
    img: '<span class="apsa-icon-colorido-fale-com-gestor"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>',
    click: null,
    subItem: 'O condomínio > Ocorrências'
    },
    {
    titulo: 'Enquetes',
    rota: '/enquetes',
    img: '<span class="apsa-icon-colorido-enquete"><span class="path1"></span><span class="path2"></span></span>',
    click: null,
    subItem: 'Comunicação > Enquetes'
    },
    {
    titulo: 'Mural',
    rota: '/mural',
    img: 'apsa-icon-solid-mural Card_Icon_SolidAsColorfull',
    click: null,
    subItem: 'Comunicação > Mural'
    },
    {
    titulo: 'Condôminos',
    rota: '/condomino',
    img: '<span class="apsa-icon-colorido-condominio-digital"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>',
    click: null,
    subItem: 'O condomínio > Condôminos'
    },
    {
    titulo: 'Funcionário',
    rota: '/funcionario',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Funcionário'
    },
    {
    titulo: 'Dados do Condomínio',
    rota: '/dados-condominio',
    img: '<span class="apsa-icon-colorido-sobre-condominio-colorido"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span><span class="path29"></span><span class="path30"></span><span class="path31"></span><span class="path32"></span></span>',
    click: null,
    subItem: 'O condomínio > Dados do Condomínio'
    },
    {
    titulo: 'Envio de Pagamentos',
    rota: '/envio-pagamentos',
    img: '../../../assets/img/envioDePagamentosAtalho.png',
    click: null,
    subItem: null
    },
    {
    titulo: 'Envio de Documentos',
    rota: '/envio-documento',
    img: '../../../assets/img/envioDePagamentosAtalho.png',
    click: null,
    subItem: null
    },
    {
    titulo: 'Chame a Moto',
    rota: '/chame-moto',
    img: '<span class="apsa-icon-colorido-motorcycle"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>',
    click: null,
    subItem: null
    },
    {
    titulo: 'Contrate seu seguro',
    rota: '/seguros',
    img: 'far fa-building',
    click: null,
    subItem: null
    },
    {
    titulo: 'Fornecedores',
    rota: null,
    img: 'fas fa-tools',
    click: 'fornecedores',
    subItem: null
    },
    {
    titulo: 'Contratos',
    rota: '/contratos',
    img: 'fas fa-file-signature',
    click: null,
    subItem: null
    },
    // {
    // titulo: 'Financeiro',
    // rota: null,
    // img: 'fas fa-file-signature',
    // click: 'selectModule("financeiro")',
    // subItem: null
    // },
    {
    titulo: 'Gestão de pessoal',
    rota: null,
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: 'selectModule("gestaoDePessoal")',
    subItem: null
    },
    {
    titulo: 'Condomínio',
    rota: null,
    img: '<span class="apsa-icon-colorido-condominio-digital"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>',
    click: 'selectModule("gestaoDePessoal")',
    subItem: null
    },
    {
    titulo: 'Comunicação',
    rota: null,
    img: '<span class="apsa-icon-colorido-comunicacao"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>',
    click: 'selectModule("comunicacao")',
    subItem: null
    },
    {
    titulo: 'Checklist',
    rota: '/checklist',
    img: '<span class="apsa-icon-colorido-check"><span class="path1"></span><span class="path2"></span></span>',
    click: null,
    subItem: null
    },
    {
    titulo: 'Colaboradores',
    rota: '/colaboradores',
    img: '<span class="apsa-icon-colorido-colaboradores"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Colaboradores'
    },
    {
    titulo: 'Espaços',
    rota: '/cadastro-de-espacos',
    img: '<span class="apsa-icon-colorido-espacos-colorido"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'O condomínio > Espaços'
    },
    {
    titulo: 'Meus Dados',
    rota: '/funcionario/meus-dados',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: null
    },
    {
    titulo: 'Relatório de Ocorrências',
    rota: '/relatorio-ocorrencias',
    img: 'fas fa-copy',
    click: null,
    subItem: null
    },
    {
    titulo: 'Relatório de Ações',
    rota: '/relatorios-gerenciais/acoes',
    img: 'fas fa-file-alt',
    click: null,
    subItem: null
    },
    {
    titulo: 'Relatório Financeiro',
    rota: '/relatorios-gerenciais/financeiro',
    img: 'fas fa-file-alt',
    click: null,
    subItem: null
    },
    {
    titulo: 'Relatório Visitas DP',
    rota: '/relatorios-gerenciais/visitas-dp',
    img: 'fas fa-file-alt',
    click: null,
    subItem: null
    },
    {
    titulo: 'Relatório de Inventário',
    rota: '/inventario',
    img: 'fas fa-copy',
    click: null,
    subItem: null
    },
    {
    titulo: 'Pagamentos',
    rota: '/pagamentos',
    img: '<span class="apsa-icon-colorido-pagamento-locatario"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>',
    click: null,
    subItem: null
    },
    {
    titulo: 'Dados do Imóvel',
    rota: '/dados-imovel',
    img: '<div class="Item_Current_Icon"><span class="apsa-icon-colorido-dados-imovel"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span></div>',
    click: null,
    subItem: null
    },
    {
    titulo: 'Relação de Devedores',
    rota: '/relacao-de-devedores',
    img: 'fas fa-gavel',
    click: null,
    subItem: null
    },
    {
    titulo: 'Processos Jurídicos',
    rota: '/processos-juridicos',
    img: 'fas fa-gavel',
    click: null,
    subItem: null
    },
    {
    titulo: 'Extrato',
    rota: '/extrato/proprietario',
    img: '<span class="apsa-icon-colorido-extrato"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>',
    click: null,
    subItem: 'Financeiro > Extrato'
    },
    {
    titulo: 'Acompanhamento Orçamentário',
    rota: '/acompanhamento-orcamentario',
    img: 'fas fa-database Card_Icon_SolidAsColorfull',
    click: null,
    subItem: 'Financeiro > Acompanhamento Orçamentário'
    },
    {
    titulo: 'Pasta de prestação de contas digital',
    rota: '/prestacao-conta-digital',
    img: '<span class="apsa-icon-colorido-financeiro"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>',
    click: null,
    subItem: 'Financeiro > Pasta de prestação de contas digital'
    },
    {
    titulo: 'Visualizar cotas',
    rota: '/cotas',
    img: '<span class="apsa-icon-colorido-cotas"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>',
    click: null,
    subItem: 'Financeiro > Cotas'
    },
    {
    titulo: 'Relatório de Inadimplentes',
    rota: '/cotas-inadimplentes',
    img: 'fas fa-copy',
    click: null,
    subItem: 'Financeiro > Relatório de Inadimplentes'
    },
    {
    titulo: 'Receitas previstas',
    rota: '/receitas-previstas',
    img: 'fas fa-copy',
    click: null,
    subItem: 'Financeiro > Receitas previstas'
    },
    {
    titulo: 'Quitação Anual',
    rota: '/quitacao-anual',
    img: '<span class="apsa-icon-colorido-colorido-informe-quitacao"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>',
    click: null,
    subItem: 'Financeiro > Quitação Anual'
    },
    // {
    // titulo: 'Gestão de pessoal',
    // rota: '/quitacao-anual',
    // img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    // click: null,
    // subItem: null
    // },
    {
    titulo: 'Lançamento de proventos e descontos',
    rota: '/lancamento-proventos-descontos',
    img: 'fas fa-file-invoice-dollar',
    click: null,
    subItem: 'Gestão de pessoal > Lançamento de proventos e descontos'
    },
    {
    titulo: 'Jornada de Trabalho',
    rota: '/quadro-horarios',
    img: 'fas fa-business-time',
    click: null,
    subItem: 'Gestão de pessoal > Jornada de Trabalho'
    },
    {
    titulo: 'Documentos Fiscais',
    rota: '/pasta-dp',
    img: 'fas fa-folder-open',
    click: null,
    subItem: 'O condomínio > Documentos Fiscais'
    },
    {
    titulo: 'Minhas solicitações',
    rota: '/minhas-solicitacoes',
    img: 'fas fa-tasks',
    click: null,
    subItem: null
    },
    {
    titulo: 'Calendário de visitas',
    rota: '/calendario-visitas',
    img: 'far fa-calendar-check',
    click: null,
    subItem: 'O condomínio > Calendário de visitas'
    },
    {
    titulo: 'Achados e perdidos',
    rota: '/achados-perdidos',
    img: 'fas fa-box-open',
    click: null,
    subItem: 'O condomínio > Achados e perdidos'
    },
    {
    titulo: 'Achados e perdidos',
    rota: '/achados-perdidos-geral',
    img: 'fas fa-box-open',
    click: null,
    subItem: null
    },
    {
    titulo: 'Courrier',
    rota: '/courrier',
    img: 'fas fa-business-time',
    click: null,
    subItem: null
    },
    {
    titulo: 'Gerenciar Disponibilidade',
    rota: '/disponibilidade',
    img: '<span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Reservas > Gerenciar Disponibilidade'
    },
    {
    titulo: 'Configuração de Reservas',
    rota: '/reserva-configuracao',
    img: '<span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Reservas > Configuração de Reservas'
    },
    {
    titulo: 'Mural',
    rota: '/muralGT',
    img: 'apsa-icon-solid-mural Card_Icon_SolidAsColorfull',
    click: null,
    subItem: null
    },
    {
    titulo: 'Arquivo digital',
    rota: '/arquivo-digital',
    img: 'fas fa-compact-disc',
    click: null,
    subItem: null
    },
    {
    titulo: 'Achados e perdidos',
    rota: '/achados-perdidos',
    img: 'fas fa-box-open',
    click: null,
    subItem: null
    },
    {
    titulo: 'Relatório de pagamentos',
    rota: '/relatorio-pagamentos',
    img: 'fas fa-file-alt',
    click: null,
    subItem: 'Financeiro > Relatório de pagamentos'
    },
    {
    titulo: 'Admissão de Funcionário',
    rota: '/minhas-solicitacoes/cadastro/38',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Admissão de Funcionário'
    },
    {
    titulo: 'Concessão de Férias do Funcionário',
    rota: '/minhas-solicitacoes/cadastro/35',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Concessão de Férias do Funcionário'
    },
    {
    titulo: 'Informação / Alteração de Horas Extras',
    rota: '/minhas-solicitacoes/cadastro/36',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Informação / Alteração de Horas Extras'
    },
    {
    titulo: 'Lançamento de proventos e descontos',
    rota: '/lancamento-proventos-descontos',
    img: 'fas fa-file-invoice-dollar',
    click: null,
    subItem: 'Gestão de pessoal > Lançamento de proventos e descontos'
    },
    {
    titulo: 'Recrutamento e Seleção de Funcionário com Sindicância',
    rota: '/minhas-solicitacoes/cadastro/39',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Recrutamento e Seleção de Funcionário com Sindicância'
    },
    {
    titulo: 'Simulação / Realização de Rescisão de Funcionário',
    rota: '/minhas-solicitacoes/cadastro/34',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Simulação/Realização de Rescisão de Funcionário'
    },
    {
    titulo: 'Sindicância do Funcionário',
    rota: '/minhas-solicitacoes/cadastro/40',
    img: '<span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
    click: null,
    subItem: 'Gestão de pessoal > Sindicância do Funcionário'
    },
    {
    titulo: 'Supressão de Horas Extras',
    rota: '/minhas-solicitacoes/cadastro/37',
    img: 'fas fa-file-invoice-dollar',
    click: null,
    subItem: 'Gestão de pessoal > Supressão de Horas Extras'
    },
    {
    titulo: 'Atendimento virtual',
    rota: 'https://api.whatsapp.com/send/?phone=5521967810747&text=Oi&app_absent=0',
    img: 'fab fa-whatsapp',
    click: null,
    subItem: null
    }
]