<!-- 

<div class="Lightbox TextAreaWithConfirmationPopup" [ngClass]="{ '__is-open': showModal }">
  <div class="Popup Popup_ComunicacaoGenerica">
    <div class="Popup_Content">
      <div class="modalBackGround">
      </div>
      
    </div>
  </div>
</div> -->


<div class="Lightbox TextAreaWithConfirmationPopup" *ngIf="showModal && ehMobile == false"  [ngClass]="{ '__is-open': true }">
  <div class="Popup_ComunicacaoGenerica">
        
    <div class="Popup_Content">
      
      <div class="modalConfirmacao" >
        <div class="modalHeader" [ngClass]="{'_sucesso': sucesso === true, '_falha': sucesso === false }">
          <!-- <div class="imgArea"> -->
          <div class="imgArea" *ngIf="!sucesso;else falha">
            <svg  width="46" height="40" viewBox="0 0 46 40" [attr.fill]="corIcone" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.15039 19C6.67053 8.7619 15.1323 0.625 25.5 0.625C36.2032 0.625 44.875 9.29688 44.875 20C44.875 30.7031 36.2032 39.375 25.5 39.375C24.6529 39.375 23.8184 39.3207 23 39.2153V35.4263C23.814 35.5571 24.6491 35.625 25.5 35.625C34.1329 35.625 41.125 28.6328 41.125 20C41.125 11.3672 34.1329 4.375 25.5 4.375C17.2032 4.375 10.4217 10.8336 9.9065 19H6.15039ZM23.6954 22.6484L30.3282 27.4688C30.75 27.7734 31.336 27.6797 31.6407 27.2656L33.1094 25.2422C33.4141 24.8203 33.3282 24.2344 32.9063 23.9297L27.6875 20.1328V9.0625C27.6875 8.54688 27.2657 8.125 26.75 8.125H24.25C23.7344 8.125 23.3125 8.54688 23.3125 9.0625V21.8906C23.3125 22.1875 23.4532 22.4688 23.6954 22.6484Z" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 22.5C1.55964 22.5 1 23.0596 1 23.75C1 24.4404 1.55964 25 2.25 25H11.9742C12.6646 25 13.2242 24.4404 13.2242 23.75C13.2242 23.0596 12.6646 22.5 11.9742 22.5H2.25ZM5.88794 27.75C5.88794 27.0596 6.44758 26.5 7.13794 26.5H16.8622C17.5525 26.5 18.1122 27.0596 18.1122 27.75C18.1122 28.4404 17.5525 29 16.8622 29H7.13794C6.44758 29 5.88794 28.4404 5.88794 27.75ZM10.7758 31.75C10.7758 31.0596 11.3354 30.5 12.0258 30.5H21.75C22.4403 30.5 23 31.0596 23 31.75C23 32.4404 22.4403 33 21.75 33H12.0258C11.3354 33 10.7758 32.4404 10.7758 31.75Z"/>
            </svg>
            
            <!-- <img src="../../../../assets/svg/clock-prox-dia.svg" alt="Ícone de Relógio"> -->
          </div>

          <div class="textArea">
            <h6 [ngClass]="{'tituloFalha': sucesso === false }">Falta pouco para concluir! </h6>
            <p id="subtitle">{{tituloHeader}}</p>
            <p>{{mensagemHeader}}</p>
          </div>

          <!-- <div class="imgArea"*ngIf="!sucesso;else falha">
            <i class="fa fas fa-exclamation-triangle exclamacao"></i>
          </div>
          <div class="textArea">
            <h6>{{tituloHeader}}</h6>
            <p>{{mensagemHeader}}</p>
          </div> -->
        </div>
        
        <ng-template #falha>
          <div class="imgArea check">
            <ng-container *ngIf="!menor">
              <svg width="46" height="40" viewBox="0 0 46 40" [attr.fill]="corIcone"  xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.15039 19C6.67053 8.7619 15.1323 0.625 25.5 0.625C36.2032 0.625 44.875 9.29688 44.875 20C44.875 30.7031 36.2032 39.375 25.5 39.375C24.6529 39.375 23.8184 39.3207 23 39.2153V35.4263C23.814 35.5571 24.6491 35.625 25.5 35.625C34.1329 35.625 41.125 28.6328 41.125 20C41.125 11.3672 34.1329 4.375 25.5 4.375C17.2032 4.375 10.4217 10.8336 9.9065 19H6.15039ZM23.6954 22.6484L30.3282 27.4688C30.75 27.7734 31.336 27.6797 31.6407 27.2656L33.1094 25.2422C33.4141 24.8203 33.3282 24.2344 32.9063 23.9297L27.6875 20.1328V9.0625C27.6875 8.54688 27.2657 8.125 26.75 8.125H24.25C23.7344 8.125 23.3125 8.54688 23.3125 9.0625V21.8906C23.3125 22.1875 23.4532 22.4688 23.6954 22.6484Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 22.5C1.55964 22.5 1 23.0596 1 23.75C1 24.4404 1.55964 25 2.25 25H11.9742C12.6646 25 13.2242 24.4404 13.2242 23.75C13.2242 23.0596 12.6646 22.5 11.9742 22.5H2.25ZM5.88794 27.75C5.88794 27.0596 6.44758 26.5 7.13794 26.5H16.8622C17.5525 26.5 18.1122 27.0596 18.1122 27.75C18.1122 28.4404 17.5525 29 16.8622 29H7.13794C6.44758 29 5.88794 28.4404 5.88794 27.75ZM10.7758 31.75C10.7758 31.0596 11.3354 30.5 12.0258 30.5H21.75C22.4403 30.5 23 31.0596 23 31.75C23 32.4404 22.4403 33 21.75 33H12.0258C11.3354 33 10.7758 32.4404 10.7758 31.75Z" />
              </svg>
            </ng-container>
            
            <ng-container *ngIf="menor">
              <svg  width="54" height="32" [attr.fill]="corIcone" viewBox="0 0 54 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.6626 20.7686H9.12543C8.80933 20.7686 8.50619 20.8941 8.28267 21.1176C8.05916 21.3411 7.93359 21.6443 7.93359 21.9604C7.93359 22.2764 8.05916 22.5796 8.28267 22.8031C8.50619 23.0266 8.80933 23.1522 9.12543 23.1522H18.6626C18.9787 23.1522 19.2818 23.0266 19.5053 22.8031C19.7289 22.5796 19.8544 22.2764 19.8544 21.9604C19.8544 21.6443 19.7289 21.3411 19.5053 21.1176C19.2818 20.8941 18.9787 20.7686 18.6626 20.7686Z"/>
                <path d="M15.0806 14.8066H5.5434C5.2273 14.8066 4.92416 14.9322 4.70064 15.1557C4.47713 15.3792 4.35156 15.6824 4.35156 15.9984C4.35156 16.3145 4.47713 16.6177 4.70064 16.8412C4.92416 17.0647 5.2273 17.1903 5.5434 17.1903H15.0806C15.3967 17.1903 15.6998 17.0647 15.9233 16.8412C16.1468 16.6177 16.2724 16.3145 16.2724 15.9984C16.2724 15.6824 16.1468 15.3792 15.9233 15.1557C15.6998 14.9322 15.3967 14.8066 15.0806 14.8066Z"/>
                <path d="M11.5071 8.84668H1.9699C1.6629 8.8607 1.37312 8.99252 1.16083 9.21473C0.948542 9.43693 0.830078 9.73242 0.830078 10.0397C0.830078 10.347 0.948542 10.6425 1.16083 10.8647C1.37312 11.0869 1.6629 11.2188 1.9699 11.2328H11.5071C11.8141 11.2188 12.1038 11.0869 12.3161 10.8647C12.5284 10.6425 12.6469 10.347 12.6469 10.0397C12.6469 9.73242 12.5284 9.43693 12.3161 9.21473C12.1038 8.99252 11.8141 8.8607 11.5071 8.84668Z"/>
                <path d="M49.7953 0.501953H11.5074C11.2952 0.50211 11.0868 0.558921 10.9039 0.666519C10.721 0.774116 10.5702 0.928596 10.4669 1.114C10.3637 1.2994 10.3118 1.50901 10.3167 1.72116C10.3216 1.9333 10.383 2.1403 10.4947 2.32077C10.5582 2.56697 10.7165 2.77805 10.9351 2.90796L28.198 17.0728C29.2267 17.8537 30.4846 18.2727 31.776 18.2646C32.9678 18.2646 34.3985 17.9063 35.3515 17.0728L50.8503 4.09728V27.921C50.8598 28.0687 50.8399 28.2169 50.792 28.357C50.744 28.497 50.6689 28.6263 50.5709 28.7372C50.4728 28.8482 50.3539 28.9387 50.2208 29.0036C50.0877 29.0685 49.9431 29.1064 49.7953 29.1153C49.7507 29.1203 49.7056 29.1203 49.661 29.1153H11.5074C11.2004 29.1293 10.9106 29.2611 10.6983 29.4833C10.486 29.7055 10.3675 30.001 10.3675 30.3083C10.3675 30.6157 10.486 30.9111 10.6983 31.1333C10.9106 31.3555 11.2004 31.4874 11.5074 31.5014H49.6585C50.5899 31.518 51.4898 31.1641 52.1603 30.5174C52.8308 29.8707 53.217 28.9842 53.234 28.0529C53.2365 28.0098 53.2365 27.9666 53.234 27.9235V4.07986C53.2507 3.14993 52.8982 2.25129 52.2537 1.58069C51.6092 0.9101 50.7252 0.522209 49.7953 0.501953ZM33.9208 15.1644C33.2797 15.6303 32.5076 15.8812 31.715 15.8812C30.9225 15.8812 30.1504 15.6303 29.5093 15.1644L14.6474 2.88806H48.5861L33.9208 15.1644Z"/>
                <path d="M48.1074 24.7016L40.9539 17.5507C40.8445 17.4411 40.7145 17.3541 40.5714 17.2947C40.4283 17.2353 40.2749 17.2047 40.12 17.2046C39.9651 17.2045 39.8117 17.2349 39.6685 17.294C39.5253 17.3532 39.3952 17.44 39.2856 17.5495C39.176 17.6589 39.089 17.7889 39.0296 17.932C38.9702 18.0751 38.9396 18.2284 38.9395 18.3834C38.9393 18.5383 38.9697 18.6917 39.0289 18.8349C39.0881 18.978 39.1749 19.1081 39.2843 19.2178L46.4378 26.3786C46.6489 26.5995 46.939 26.7278 47.2445 26.7353C47.55 26.7427 47.846 26.6288 48.0676 26.4184L48.1074 26.3786C48.2174 26.2748 48.3058 26.1503 48.3676 26.0123C48.4294 25.8743 48.4633 25.7254 48.4675 25.5742C48.4716 25.4231 48.4459 25.2726 48.3918 25.1314C48.3377 24.9902 48.2563 24.861 48.1522 24.7513C48.1396 24.7328 48.1245 24.7161 48.1074 24.7016Z"/>
                </svg>
            </ng-container>
            <!-- <img src="../../../../../assets/svg/clock-regular.svg" alt="Ícone de Relógio"> -->
            <!-- <img src="https://areaexclusiva.apsa.com.br/digital/assets/svg/clock-regular.svg" alt="Ícone de Relógio"> -->
          </div>
        </ng-template>

        <div class="warning-card" *ngIf="textoAviso">
            <span>Atenção!</span>
            <p [innerHTML]="textoAviso" ></p>
        </div>
        
        <div class="modalBody" [ngClass]="{'modalMenor': menor}">
          <div class="textBody">
            
            <!-- PARA TESTES -->
            <!-- <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div>
            <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div>
            <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div>
            <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div> -->


            <div class="textGroup" *ngFor="let item of dadosModal">
              <h6 class="title">{{item.titulo}}</h6>
              <ng-container class="text" *ngIf="item.titulo !== 'Anexos'; else anexo">
                <div [ngClass]="{'container_texto': !texto}" *ngFor="let texto of item.texto">
                  <i class="fas fa-exclamation-triangle fa-sm" style="color: #d33131;" *ngIf="!texto"></i>
                  <p [ngClass]="{'__Alert': !texto}">{{texto || 'Este campo ainda não foi definido.'}}<p>
                </div> 
              </ng-container>
      
              <ng-template  #anexo>
                <div *ngFor="let texto of item.texto">
                  <a href="{{texto.src}}">{{texto.Nome}}</a>
                </div>
              </ng-template>
            </div>

          </div>
        </div>
        
        <div class="checkBoxArea" *ngIf="form">
          <form [formGroup]="this.form" >
            <div class="checkbox-area" >
              <ion-checkbox formControlName = "aceiteTermo"
              color="primary" (click)="aceitarTermo();"></ion-checkbox>
        
              <!-- <p (click)="aceitarTermo()">Concordo com as informações registradas acima em relação ao vencimento, valor, dados bancários e histórico; além de declarar que li atentamente todas as informações fornecidas antes de enviar, garantindo que sejam verdadeiras.</p> -->
              <p >{{mensagemCheck}}</p>
            </div>

          </form>
          
        </div>

        <div class="buttonArea">
          <button class="Button Button_Outline Button_Solid " (click)="cancelarCadastro()">
            <div class="Button_Content">
              <div class="Button_Content_Label">Retomar</div>
            </div>
          </button>
          <button *ngIf="this.form" class="Button Button_Primary Button_Solid "
            [ngClass]="{ '__is-inactive': this.form.controls.aceiteTermo.value == false || this.campoVazio }" 
            [disabled]="this.form.controls.aceiteTermo.value === false || this.campoVazio"
            (click)="confirmarCadastro()">
            <div class="Button_Content">
              <div class="Button_Content_Label">Enviar</div>
            </div>
          </button>
          <button *ngIf="!this.form" class="Button Button_Primary Button_Solid "
            [ngClass]="{ '__is-inactive': this.campoVazio }" 
            [disabled]="this.campoVazio"
            (click)="confirmarCadastro()">
            <div class="Button_Content">
              <div class="Button_Content_Label">Enviar</div>
            </div>
          </button>
        </div>
      
      
      </div>
    </div>
    
  </div>
</div>

<!-- <pre>{{'show modal - ' +  showModal}}</pre>
<pre>{{'eh mobile - ' + ehMobile}}</pre> -->
<div class="modalConfirmacaoMobile" *ngIf="showModal && ehMobile == true" >
  <div class="modalHeader" [ngClass]="{'_sucesso': sucesso === true, '_falha': sucesso === false }">
    <div class="imgArea" *ngIf="!sucesso;else falha">
      <!-- <img src="../../../../../assets/svg/clock-prox-dia.svg" alt="Ícone de Relógio"> -->
      <svg width="46" height="40" viewBox="0 0 46 40" [attr.fill]="corIcone" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.15039 19C6.67053 8.7619 15.1323 0.625 25.5 0.625C36.2032 0.625 44.875 9.29688 44.875 20C44.875 30.7031 36.2032 39.375 25.5 39.375C24.6529 39.375 23.8184 39.3207 23 39.2153V35.4263C23.814 35.5571 24.6491 35.625 25.5 35.625C34.1329 35.625 41.125 28.6328 41.125 20C41.125 11.3672 34.1329 4.375 25.5 4.375C17.2032 4.375 10.4217 10.8336 9.9065 19H6.15039ZM23.6954 22.6484L30.3282 27.4688C30.75 27.7734 31.336 27.6797 31.6407 27.2656L33.1094 25.2422C33.4141 24.8203 33.3282 24.2344 32.9063 23.9297L27.6875 20.1328V9.0625C27.6875 8.54688 27.2657 8.125 26.75 8.125H24.25C23.7344 8.125 23.3125 8.54688 23.3125 9.0625V21.8906C23.3125 22.1875 23.4532 22.4688 23.6954 22.6484Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 22.5C1.55964 22.5 1 23.0596 1 23.75C1 24.4404 1.55964 25 2.25 25H11.9742C12.6646 25 13.2242 24.4404 13.2242 23.75C13.2242 23.0596 12.6646 22.5 11.9742 22.5H2.25ZM5.88794 27.75C5.88794 27.0596 6.44758 26.5 7.13794 26.5H16.8622C17.5525 26.5 18.1122 27.0596 18.1122 27.75C18.1122 28.4404 17.5525 29 16.8622 29H7.13794C6.44758 29 5.88794 28.4404 5.88794 27.75ZM10.7758 31.75C10.7758 31.0596 11.3354 30.5 12.0258 30.5H21.75C22.4403 30.5 23 31.0596 23 31.75C23 32.4404 22.4403 33 21.75 33H12.0258C11.3354 33 10.7758 32.4404 10.7758 31.75Z"/>
      </svg>
        
    </div>

    <div class="textArea">
      <h6 [ngClass]="{'tituloFalha': sucesso === false }">Falta pouco para concluir! </h6>
      <p id="subtitle">{{tituloHeader}}</p>
      <p>{{mensagemHeader}}</p>
    </div>
  </div>
  
  <ng-template #falha>
    <div class="imgArea check">
      <ng-container *ngIf="!menor">
        <svg width="46" height="40" viewBox="0 0 46 40" [attr.fill]="corIcone"  xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.15039 19C6.67053 8.7619 15.1323 0.625 25.5 0.625C36.2032 0.625 44.875 9.29688 44.875 20C44.875 30.7031 36.2032 39.375 25.5 39.375C24.6529 39.375 23.8184 39.3207 23 39.2153V35.4263C23.814 35.5571 24.6491 35.625 25.5 35.625C34.1329 35.625 41.125 28.6328 41.125 20C41.125 11.3672 34.1329 4.375 25.5 4.375C17.2032 4.375 10.4217 10.8336 9.9065 19H6.15039ZM23.6954 22.6484L30.3282 27.4688C30.75 27.7734 31.336 27.6797 31.6407 27.2656L33.1094 25.2422C33.4141 24.8203 33.3282 24.2344 32.9063 23.9297L27.6875 20.1328V9.0625C27.6875 8.54688 27.2657 8.125 26.75 8.125H24.25C23.7344 8.125 23.3125 8.54688 23.3125 9.0625V21.8906C23.3125 22.1875 23.4532 22.4688 23.6954 22.6484Z" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 22.5C1.55964 22.5 1 23.0596 1 23.75C1 24.4404 1.55964 25 2.25 25H11.9742C12.6646 25 13.2242 24.4404 13.2242 23.75C13.2242 23.0596 12.6646 22.5 11.9742 22.5H2.25ZM5.88794 27.75C5.88794 27.0596 6.44758 26.5 7.13794 26.5H16.8622C17.5525 26.5 18.1122 27.0596 18.1122 27.75C18.1122 28.4404 17.5525 29 16.8622 29H7.13794C6.44758 29 5.88794 28.4404 5.88794 27.75ZM10.7758 31.75C10.7758 31.0596 11.3354 30.5 12.0258 30.5H21.75C22.4403 30.5 23 31.0596 23 31.75C23 32.4404 22.4403 33 21.75 33H12.0258C11.3354 33 10.7758 32.4404 10.7758 31.75Z" />
        </svg>
      </ng-container>
      
      <ng-container *ngIf="menor">
        <svg  width="54" height="32" [attr.fill]="corIcone" viewBox="0 0 54 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.6626 20.7686H9.12543C8.80933 20.7686 8.50619 20.8941 8.28267 21.1176C8.05916 21.3411 7.93359 21.6443 7.93359 21.9604C7.93359 22.2764 8.05916 22.5796 8.28267 22.8031C8.50619 23.0266 8.80933 23.1522 9.12543 23.1522H18.6626C18.9787 23.1522 19.2818 23.0266 19.5053 22.8031C19.7289 22.5796 19.8544 22.2764 19.8544 21.9604C19.8544 21.6443 19.7289 21.3411 19.5053 21.1176C19.2818 20.8941 18.9787 20.7686 18.6626 20.7686Z"/>
          <path d="M15.0806 14.8066H5.5434C5.2273 14.8066 4.92416 14.9322 4.70064 15.1557C4.47713 15.3792 4.35156 15.6824 4.35156 15.9984C4.35156 16.3145 4.47713 16.6177 4.70064 16.8412C4.92416 17.0647 5.2273 17.1903 5.5434 17.1903H15.0806C15.3967 17.1903 15.6998 17.0647 15.9233 16.8412C16.1468 16.6177 16.2724 16.3145 16.2724 15.9984C16.2724 15.6824 16.1468 15.3792 15.9233 15.1557C15.6998 14.9322 15.3967 14.8066 15.0806 14.8066Z"/>
          <path d="M11.5071 8.84668H1.9699C1.6629 8.8607 1.37312 8.99252 1.16083 9.21473C0.948542 9.43693 0.830078 9.73242 0.830078 10.0397C0.830078 10.347 0.948542 10.6425 1.16083 10.8647C1.37312 11.0869 1.6629 11.2188 1.9699 11.2328H11.5071C11.8141 11.2188 12.1038 11.0869 12.3161 10.8647C12.5284 10.6425 12.6469 10.347 12.6469 10.0397C12.6469 9.73242 12.5284 9.43693 12.3161 9.21473C12.1038 8.99252 11.8141 8.8607 11.5071 8.84668Z"/>
          <path d="M49.7953 0.501953H11.5074C11.2952 0.50211 11.0868 0.558921 10.9039 0.666519C10.721 0.774116 10.5702 0.928596 10.4669 1.114C10.3637 1.2994 10.3118 1.50901 10.3167 1.72116C10.3216 1.9333 10.383 2.1403 10.4947 2.32077C10.5582 2.56697 10.7165 2.77805 10.9351 2.90796L28.198 17.0728C29.2267 17.8537 30.4846 18.2727 31.776 18.2646C32.9678 18.2646 34.3985 17.9063 35.3515 17.0728L50.8503 4.09728V27.921C50.8598 28.0687 50.8399 28.2169 50.792 28.357C50.744 28.497 50.6689 28.6263 50.5709 28.7372C50.4728 28.8482 50.3539 28.9387 50.2208 29.0036C50.0877 29.0685 49.9431 29.1064 49.7953 29.1153C49.7507 29.1203 49.7056 29.1203 49.661 29.1153H11.5074C11.2004 29.1293 10.9106 29.2611 10.6983 29.4833C10.486 29.7055 10.3675 30.001 10.3675 30.3083C10.3675 30.6157 10.486 30.9111 10.6983 31.1333C10.9106 31.3555 11.2004 31.4874 11.5074 31.5014H49.6585C50.5899 31.518 51.4898 31.1641 52.1603 30.5174C52.8308 29.8707 53.217 28.9842 53.234 28.0529C53.2365 28.0098 53.2365 27.9666 53.234 27.9235V4.07986C53.2507 3.14993 52.8982 2.25129 52.2537 1.58069C51.6092 0.9101 50.7252 0.522209 49.7953 0.501953ZM33.9208 15.1644C33.2797 15.6303 32.5076 15.8812 31.715 15.8812C30.9225 15.8812 30.1504 15.6303 29.5093 15.1644L14.6474 2.88806H48.5861L33.9208 15.1644Z"/>
          <path d="M48.1074 24.7016L40.9539 17.5507C40.8445 17.4411 40.7145 17.3541 40.5714 17.2947C40.4283 17.2353 40.2749 17.2047 40.12 17.2046C39.9651 17.2045 39.8117 17.2349 39.6685 17.294C39.5253 17.3532 39.3952 17.44 39.2856 17.5495C39.176 17.6589 39.089 17.7889 39.0296 17.932C38.9702 18.0751 38.9396 18.2284 38.9395 18.3834C38.9393 18.5383 38.9697 18.6917 39.0289 18.8349C39.0881 18.978 39.1749 19.1081 39.2843 19.2178L46.4378 26.3786C46.6489 26.5995 46.939 26.7278 47.2445 26.7353C47.55 26.7427 47.846 26.6288 48.0676 26.4184L48.1074 26.3786C48.2174 26.2748 48.3058 26.1503 48.3676 26.0123C48.4294 25.8743 48.4633 25.7254 48.4675 25.5742C48.4716 25.4231 48.4459 25.2726 48.3918 25.1314C48.3377 24.9902 48.2563 24.861 48.1522 24.7513C48.1396 24.7328 48.1245 24.7161 48.1074 24.7016Z"/>
          </svg>
      </ng-container>
      <!-- <img src="../../../../../assets/svg/clock-regular.svg" alt="Ícone de Relógio"> -->
    </div>
  </ng-template>

  <div class="warning-card" *ngIf="textoAviso">
    <span>Atenção!</span>
    <p [innerHTML]="textoAviso" ></p>
  </div>
  
  <div class="modalBody">
    <div class="textBody">
      <div class="textGroup" *ngFor="let item of dadosModal">
        <h6 class="title">{{item.titulo}}</h6>
        <ng-container *ngIf="item.titulo !== 'Anexos'; else anexo">
          <p class="text" *ngFor="let texto of item.texto">
            {{texto}}
          </p>
        </ng-container>

        <ng-template  #anexo>
          <div *ngFor="let texto of item.texto">
            <a href="{{texto.src}}">{{texto.Nome}}</a>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="checkBoxArea" *ngIf="form">
      <form action="" [formGroup]="this.form">
        <div class="checkbox-area">
          <div class="checkBoxContainer">
            <ion-checkbox mode="md" formControlName="aceiteTermo" color="primary"></ion-checkbox>
          </div>
          <p>{{mensagemCheck}}</p>
        </div>

      </form>
    </div>
  </div>
  <div class="buttonArea">
    <button class="Button Button_Outline Button_Solid " 
      
      (click)="cancelarCadastro()">
      <div class="Button_Content">
        <div class="Button_Content_Label">Retomar</div>
      </div>
    </button>
    <button *ngIf="this.form" class="Button Button_Primary Button_Solid "
      [ngClass]="{ '__is-inactive': this.form.controls.aceiteTermo.value == false || this.campoVazio }" 
      [disabled]="this.form.controls.aceiteTermo.value === false || this.campoVazio"
      (click)="confirmarCadastro()">
      <div class="Button_Content">
        <div class="Button_Content_Label">Enviar</div>
      </div>
    </button>
    <button *ngIf="!this.form" class="Button Button_Primary Button_Solid "
      [ngClass]="{ '__is-inactive': this.campoVazio }" 
      [disabled]="this.campoVazio"
      (click)="confirmarCadastro()">
      <div class="Button_Content">
        <div class="Button_Content_Label">Enviar</div>
      </div>
    </button>
  
  </div>


</div>