<label *ngIf="label !== null" class="FormGroup_Label">{{ label }}</label>
<div class="selectArea">
  <div class="selectInput">
    <div
      (click)="selecionarItemPrincipal(itemPrincipal)"
      class="itemInicial"
      [ngClass]="{ itemSlecionado: (itemPrincipal == itemPrincipalSelecionado) && itemSelecionado == null }"
    >
      <p>{{ itemPrincipal }}</p>
      <div *ngIf="novo" class="novoLabel">Novo!</div>
      <!-- (click)="open = !open" -->
      <i *ngIf="opcoes && opcoes.length > 0; else arrow"
        [ngClass]="open ? 'fa-chevron-up' : 'fa-chevron-down'"
        class="fas"
      ></i>
      <ng-template #arrow>
        <i *ngIf="(itemPrincipal == itemPrincipalSelecionado) && itemSelecionado == null" class="fas fa-arrow-right"></i>
      </ng-template>
    </div>
    <div class="optionsArea" [ngClass]="{ __ShowOptions: itemPrincipal == itemPrincipalSelecionado }">
      <!-- <div class="optionItem" (click)="selecionarItem()"></div> -->
      <div
        [ngClass]="{ itemSlecionado: itemSelecionado == opcao }"
        class="optionItem"
        (click)="selecionarItem(opcao)"
        *ngFor="let opcao of opcoes"
      >
        <!-- {{ getOpcaoSelect(opcao) }} -->
        {{ opcao.DscTipoDocumento }}
        <i class="fas fa-arrow-right"></i>
      </div>
    </div>
  </div>
</div>
