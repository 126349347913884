import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/modules/home/services/home.service';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/modules/login/services/login.service';
import { ColorfulIcon } from '../../utils/ColorfulIcon';
import { StateService } from '../../services/stateService';
import { CondominioService } from '../../services/condominio.service';
import Swiper from 'swiper';
import { IMostUsedFeatures } from 'src/app/modules/login/services/DTO/response/IMostUsedFeatures';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-most-used-features',
  templateUrl: './most-used-features.component.html',
  styleUrls: ['./most-used-features.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MostUsedFeaturesComponent implements OnInit, AfterContentInit {
  @Input('p-design') design: 'default' | 'compact' = 'default';

  @ViewChild('swiper') swiperRef: ElementRef | undefined;

  swiper?: Swiper;

  public mostUsedFeatures: IMostUsedFeatures[] = [];
  public maisUsadosSession: Array<any>;

  constructor(
    private loginService: LoginService,
    public stateService: StateService,
    private homeService: HomeService,
    private activatedRoute: ActivatedRoute,
    private condominioService: CondominioService,
  ) {
    this.activatedRoute.params.subscribe((val) => {
      // this.ngOnInit();
    });
  }
  
  ngOnInit() {
    if (this.stateService.getCondominioLogado() && !this.stateService.visaoCentralAtendimento() && !this.stateService.possuiPerfilFuncionario()) {
      this.fetchDataFromAPI();
    }
  }
  
  ngAfterContentInit(): void {
    this.initializeSwiper();
  }
  
  initializeSwiper() {
    setTimeout(() => {
      const swiper = new Swiper('.swiper', {
        spaceBetween: 8,
        slidesPerView: this.itensPorTamanhoDeTela(),
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          enabled: true,
          type: 'bullets'
        }
      });
    }, 200);
  }

  returnNome(obj: any) {
    if (obj.id == 14 || obj.id == 15) {
      if (!this.stateService.possuiPerfilSindico()) return 'Fale com o síndico';
    }

    if (obj.id == 1 && this.stateService.possuiApenasPerfilCondomino()) {
      return '2ª via de boletos';
    }
    return obj.nome;
  }

  fetchDataFromAPI() {
    this.fetchMostUsedFeatures();
  }

  constructIcon(icon: string) {
    return ColorfulIcon.construct(icon);
  }

  async fetchMostUsedFeatures() {
    this.maisUsadosSession = JSON.parse(
      sessionStorage.getItem('atalhosMaisUsados')
    );
    if (!this.maisUsadosSession) {
      await this.getFuncionalidades('semSession');
      return;
    } else if (
      this.maisUsadosSession[0].dataAtual != new Date().toDateString()
    ) {
      await this.getFuncionalidades('dataAntiga');
      return;
    } else {
      let arrayControleCondominio = [];
      let arrayControlePessoa = [];

      for (let pessoa of this.maisUsadosSession) {
        if ('idPessoa' in pessoa) {
          arrayControlePessoa.push(pessoa.idPessoa);
          if (pessoa.idPessoa == this.stateService.getPessoaLogada().Id) {
            for (let condominio of pessoa.maisUsadosCondominio) {
              // console.log(condominio);
              arrayControleCondominio.push(condominio.condominio);
              if (
                condominio.condominio ==
                this.stateService.getCondominioLogado().contaEmpresa
              ) {
                this.mostUsedFeatures = condominio.maisUsados;
                return;
              } else if (
                arrayControleCondominio.length ==
                pessoa.maisUsadosCondominio.length
              ) {
                await this.getFuncionalidades('novoCondominio', pessoa);
                return;
              }
            }
          } else if (
            arrayControlePessoa.length ==
            this.maisUsadosSession.length + 1
          ) {
            await this.getFuncionalidades('novaPessoa');
            return;
          }
        }
      }
    }
  }

  async getFuncionalidades(tipo: string, pessoa?) {
    this.loginService.getFuncionalidadesFrequentes().subscribe({
      next: features => {
        this.mostUsedFeatures = features;
        this.initializeSwiper();
        
        if (tipo == 'semSession') {
          this.createSessionStorage(this.mostUsedFeatures);
        } else if (tipo == 'dataAntiga') {
          sessionStorage.removeItem('atalhosMaisUsados');
          this.createSessionStorage(this.mostUsedFeatures);
        } else if (tipo == 'novoCondominio') {
          const novosCondominios = [
            {
              condominio: this.stateService.getCondominioLogado()
                .contaEmpresa,
              maisUsados: this.mostUsedFeatures,
            },
          ];
          const pessoaAtualizada = {
            idPessoa: this.stateService.getPessoaLogada().Id,
            maisUsadosCondominio: pessoa.maisUsadosCondominio.concat(
              novosCondominios
            ),
          };
          this.maisUsadosSession.splice(
            this.maisUsadosSession.indexOf(pessoa),
            1,
            pessoaAtualizada
          );
          sessionStorage.setItem(
            'atalhosMaisUsados',
            JSON.stringify(this.maisUsadosSession)
          );
          return;
        } else if (tipo == 'novaPessoa') {
          // console.log('novapessoa');
          const condominio = this.stateService.getCondominioLogado()
            .contaEmpresa;
          const arrayCondominio = [
            {
              condominio: condominio,
              maisUsados: this.mostUsedFeatures,
            },
          ];
          const novaPessoa = {
            idPessoa: this.stateService.getPessoaLogada().Id,
            maisUsadosCondominio: arrayCondominio,
          };
          this.maisUsadosSession.concat(novaPessoa);
          sessionStorage.setItem(
            'atalhosMaisUsados',
            JSON.stringify(this.maisUsadosSession)
          );
        }
      },
      // complete: () => { console.log('features:', this.mostUsedFeatures) }
    });

    
  }

  createSessionStorage(response) {
    const condominio = this.stateService.getCondominioLogado().contaEmpresa;
    const maisUsadosCondominio = [
      {
        condominio: condominio,
        maisUsados: response,
      },
    ];
    const arrayMaisUsados = [
      { dataAtual: new Date().toDateString() },
      {
        idPessoa: this.stateService.getPessoaLogada().Id,
        maisUsadosCondominio: maisUsadosCondominio,
      },
    ];
    sessionStorage.setItem(
      'atalhosMaisUsados',
      JSON.stringify(arrayMaisUsados)
    );
  }

  possuiPermissaoAcesso(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  get hasFeatures(): boolean {
    return this.mostUsedFeatures != null && this.mostUsedFeatures?.length > 0;
  }

  itensPorTamanhoDeTela(): number {
    if(window.innerWidth < 300) {
      return 2.5;
    }else if(window.innerWidth < 800) {
      return 3.5;
    }  else {
      return 5;
    }
  }

  tipoSlider(): number {
    if(this.stateService.possuiApenasPerfilCondomino()) {
      return 1;
    } 
    if(this.stateService.possuiPerfilSindico() || this.stateService.possuiPerfilSubSindico() || this.stateService.possuiPerfilConselheiro()) {
      return 2;
    }
    return 0;
  }

}
