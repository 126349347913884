<div class="Lightbox ">
  <div class="Popup TextLinkOptions">
    <div class="content-area">
      <div class="title"></div>
      <div class="subtitle"></div>
      <div class="buttons-area">
        <button (click)="recusar()" class="recusar"></button>
        <button (click)="confirmar()" class="confirmar"></button>
        
      </div>
    </div>
    
    <div class="logo-apsa">
      <img src="../../../../../assets/img/logo_branco_header.png" alt="logo apsa">
    </div>
    
  </div>
</div>
