
<div class="FormGroup" *ngIf="units.length > 0">
  <div class="FormGroup_Label">Selecione a unidade</div>
  <div class="FormGroup_Input">
    <div class="ListSelect">
      <div class="ListSelect_Content __is-grid-list">
        <div class="ListSelect_Content_Item" [ngClass]="{
            '__is-selected': unit.Uid === item.Uid, 'is-disabled': this.disabled
          }" (click)="!disabled && selectUnit(item)" *ngFor="let item of units">
          <a>
            <div class="Content_Item_Label">{{ item.Description }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>