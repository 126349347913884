<div
  class="CollapseLight"
  [ngClass]="{ '__is-open': isCollapseOpen }"
>
  <div class="Collapse_Header" (click)="toggle()">
    <div class="Collapse_Header_Title">{{ title }}</div>

    <div class="Collapse_Header_Icon">
      <i *ngIf="!icon; else collapseIconGiven" class="fas fa-caret-down"></i>

      <ng-template #collapseIconGiven>
        <i class="{{ icon }}"></i>
      </ng-template>
    </div>
  </div>

  <div class="Collapse_Content">
    <ng-content></ng-content>
  </div>
</div>