import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';
import { Platform } from '@ionic/angular';
// import { FileChooser } from '@ionic-native/file-chooser/ngx';
// import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
// import { FilePath } from '@ionic-native/file-path/ngx';
// import { Base64 } from '@ionic-native/base64/ngx';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { DomSanitizer } from '@angular/platform-browser';
import IImage from './IImage';
import { ConstantsService } from '../../services/constants.service';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';

enum ACTION_TYPE {
  CHANGE_FIRST_SLOT,
  ADD_NEW_IMAGE_SLOT,
  CHANGE_SLOT_IMAGE,
};

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit, OnChanges {
  @Input('p-max') max? = 0;
  @Input() image: IImage;
  @Output() onChange = new EventEmitter<IImage[]>();
  @ViewChild('fileInput') fileInput: ElementRef;

  public imageSlots: IImage[] = [{ id: 0, src: null, type: null, name: null, content: null }];
  public lastClickedImageSlotID: number;
  public lastClickedActionType: ACTION_TYPE;
  public canAddMoreImage: boolean = (this.max - this.imageSlots.length) > 0;
  public reader = new FileReader();

  constructor(
    public stateService: StateService,
    public platform: Platform,
    // private fileChooser: FileChooser,
    // private IosFilePicker: IOSFilePicker,
    // private filePath: FilePath,
    // private base64: Base64,
    private lightBoxesService: LightBoxesService,
    private sanitizer: DomSanitizer,
    public constantsService: ConstantsService,
    // private camera: Camera
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.image) {
      this.imageSlots[0] = {
        ...this.imageSlots[0],
        src: this.image.src,
        content: this.image.content,
        type: this.image.type,
        name: this.image.name,
      };

      this.onChange.emit(this.imageSlots);
    }
  }

  handleItemClick(itemID: number) {
    this.lastClickedActionType = ACTION_TYPE.CHANGE_SLOT_IMAGE;
    this.lastClickedImageSlotID = itemID;

    if (this.stateService.platformIsDesktopOrMobileWeb())
      this.browserUpload();
    else
      this.nativeUpload();
  }

  handleAddImage() {
    if (!this.imageSlots[0].src) {
      this.lastClickedActionType = ACTION_TYPE.CHANGE_FIRST_SLOT;
    }
    else {
      this.lastClickedActionType = ACTION_TYPE.ADD_NEW_IMAGE_SLOT;
    }

    if (this.stateService.platformIsDesktopOrMobileWeb()) {
      this.browserUpload();
    }
  }

  handleItemDelete(itemID: number) {
    if ((itemID === this.imageSlots[0].id) && (this.imageSlots.length === 1)) {
      this.imageSlots[0].src = '';
      this.onChange.emit([]);
    }
    else {
      this.imageSlots = this.imageSlots.filter(item => item.id !== itemID);
      this.canAddMoreImage = (this.max - this.imageSlots.length) > 0;
      this.onChange.emit(this.imageSlots);
    }
  }

  browserUpload() {
    const fileInputElement = this.fileInput.nativeElement as HTMLInputElement;
    fileInputElement.click();
  }

  nativeUpload() {
    //if (this.platform.is('android')) {
    //this.anexarArquivoAndroid();
    this.openCamera(2);
    // }
    // else {
    //   this.anexarArquivoIOS();
    // }
  }

  handleFileChange(event) {
    if (this.stateService.verificarTamanhoUpload(event.target.files[0].size)) {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        this.reader.readAsDataURL(file);
        this.reader.onload = () => {
          const fileContent = this.reader.result.toString().split(',')[1];
          const src = this.stateService.getCaminhoImagemExibicao(file.type, fileContent);
          const srcSanitazed = this.sanitizer.bypassSecurityTrustUrl(src);

          switch (this.lastClickedActionType) {
            case ACTION_TYPE.CHANGE_SLOT_IMAGE:
              const slotIndex = this.imageSlots.findIndex((slot) => {
                if (slot.id === this.lastClickedImageSlotID)
                  return true;
              });

              this.imageSlots[slotIndex] = {
                ...this.imageSlots[slotIndex],
                src: srcSanitazed,
                content: fileContent,
                type: file.type,
                name: file.name,
              };
              this.onChange.emit(this.imageSlots);
              break;

            case ACTION_TYPE.ADD_NEW_IMAGE_SLOT:
              const lastSlotIndex = this.imageSlots.length - 1;
              const lastSlotId = this.imageSlots[lastSlotIndex].id;

              this.imageSlots.push({
                id: lastSlotId + 1,
                src: srcSanitazed,
                content: fileContent,
                type: file.type,
                name: file.name,
              });
              this.onChange.emit(this.imageSlots);

              this.canAddMoreImage = (this.max - this.imageSlots.length) > 0;
              break;

            case ACTION_TYPE.CHANGE_FIRST_SLOT:
              this.imageSlots[0] = {
                ...this.imageSlots[0],
                src: srcSanitazed,
                content: fileContent,
                type: file.type,
                name: file.name,
              };
              this.onChange.emit(this.imageSlots);
              break;
          }
        };
      }
    }

    // Permite que o mesmo arquivo seja adicionado 
    (event.target as HTMLInputElement).value = null;
    return false;
  }

  async openCamera(type: number) {
    const options: ImageOptions = {
      quality: 50,
      resultType: this.platform.is('android') && type == 1 ? CameraResultType.Base64 : CameraResultType.DataUrl,
      source: type === 1 ? CameraSource.Camera : CameraSource.Photos,
      direction: CameraDirection.Rear,
      allowEditing: false,
      correctOrientation: true,
    }

    Camera.getPhoto(options).then(photo => {
      if(photo) {
        if (this.stateService.platformIsIos() || (this.stateService.platformIsAndroid() && type == 2)) {
          let src = this.stateService.getCaminhoImagemExibicao("image/jpeg", photo.dataUrl)
          this.popularImagemForMobile(photo.dataUrl, src);
        }

        if (((this.stateService.platformIsAndroid() || !this.stateService.platformIsIos()) && type == 1)) {
          let src = this.stateService.getCaminhoImagemExibicao("image/jpeg", photo.base64String.toString().split(',')[1])
          this.popularImagemForMobile(photo.base64String, src);
        }
      }
    })

  }

  popularImagemForMobile(imageData: string, src: string) {
    switch (this.lastClickedActionType) {
      case ACTION_TYPE.CHANGE_SLOT_IMAGE:
        const slotIndex = this.imageSlots.findIndex((slot) => {
          if (slot.id === this.lastClickedImageSlotID)
            return true;
        });

        this.imageSlots[slotIndex] = {
          ...this.imageSlots[slotIndex],
          content: imageData,
          type: "image/jpeg",
          name: "filename.jpeg",
          src: src,
        };
        this.onChange.emit(this.imageSlots);
        break;

      case ACTION_TYPE.ADD_NEW_IMAGE_SLOT:
        const lastSlotIndex = this.imageSlots.length - 1;
        const lastSlotId = this.imageSlots[lastSlotIndex].id;

        this.imageSlots.push({
          id: lastSlotId + 1,
          content: imageData,
          type: "image/jpeg",
          name: "filename.jpeg",
          src: src,
        });
        this.onChange.emit(this.imageSlots);

        this.canAddMoreImage = (this.max - this.imageSlots.length) > 0;
        break;

      case ACTION_TYPE.CHANGE_FIRST_SLOT:
        this.imageSlots[0] = {
          ...this.imageSlots[0],
          content: imageData,
          type: "image/jpeg",
          name: "filename.jpeg",
          src: src,
        };
        this.onChange.emit(this.imageSlots);
        break;
    }
  }

  // anexarArquivoAndroid() {
  //   this.fileChooser.open()
  //     .then(uri => {
  //       this.filePath.resolveNativePath(uri)
  //         .then(nativeUri => {
  //           let filetype: string;

  //           if (nativeUri.toLowerCase().includes(".jpg")) {
  //             filetype = "image/jpg";
  //           }
  //           else if (nativeUri.toLowerCase().includes(".jpeg")) {
  //             filetype = "image/jpeg";
  //           }
  //           else if (nativeUri.toLowerCase().includes(".png")) {
  //             filetype = "image/png";
  //           }
  //           else {
  //             this.lightBoxesService.open(
  //               'custom-generico',
  //               { title: 'Atenção', text: 'O formato de arquivo informado não é aceito.' },
  //               5000
  //             );
  //             return;
  //           }

  //           let fileName = uri.split('/')[uri.split('/').length - 1];

  //           this.base64.encodeFile(nativeUri)
  //             .then(base64File => {
  //               //let tamanhoDoArquivo = ((base64File.toString().split(',')[1].length) * 6) / 8

  //               // if (this.stateService.verificarTamanhoUpload(tamanhoDoArquivo)) {
  //               const src = this.stateService.getCaminhoImagemExibicao(filetype, base64File.toString().split(',')[1])

  //               switch (this.lastClickedActionType) {
  //                 case ACTION_TYPE.CHANGE_SLOT_IMAGE:
  //                   const slotIndex = this.imageSlots.findIndex((slot) => {
  //                     if (slot.id === this.lastClickedImageSlotID)
  //                       return true;
  //                   });

  //                   this.imageSlots[slotIndex] = {
  //                     ...this.imageSlots[slotIndex],
  //                     content: base64File.toString().split(',')[1],
  //                     type: filetype,
  //                     name: fileName,
  //                     src: src,
  //                   };
  //                   this.onChange.emit(this.imageSlots);
  //                   break;

  //                 case ACTION_TYPE.ADD_NEW_IMAGE_SLOT:
  //                   const lastSlotIndex = this.imageSlots.length - 1;
  //                   const lastSlotId = this.imageSlots[lastSlotIndex].id;

  //                   this.imageSlots.push({
  //                     id: lastSlotId + 1,
  //                     content: base64File.toString().split(',')[1],
  //                     type: filetype,
  //                     name: fileName,
  //                     src: src,
  //                   });
  //                   this.onChange.emit(this.imageSlots);

  //                   this.canAddMoreImage = (this.max - this.imageSlots.length) > 0;
  //                   break;

  //                 case ACTION_TYPE.CHANGE_FIRST_SLOT:
  //                   this.imageSlots[0] = {
  //                     ...this.imageSlots[0],
  //                     content: base64File.toString().split(',')[1],
  //                     type: filetype,
  //                     name: fileName,
  //                     src: src,
  //                   };
  //                   this.onChange.emit(this.imageSlots);
  //                   break;
  //               }
  //               //}
  //             })
  //         })
  //         .catch(error => this.lightBoxesService.open(
  //           'custom-generico',
  //           { title: 'Atenção', text: 'Erro ao ler o arquivo' },
  //           5000
  //         ));
  //     })
  // }

  // anexarArquivoIOS() {
  //   this.IosFilePicker.pickFile()
  //     .then(uri => {
  //       let correctPath = uri.substr(0, uri.lastIndexOf('/') + 1);
  //       let filetype: string;

  //       if (uri.toLowerCase().includes(".jpg")) {
  //         filetype = "image/jpg";
  //       }
  //       else if (uri.toLowerCase().includes(".jpeg")) {
  //         filetype = "image/jpeg";
  //       }
  //       else if (uri.toLowerCase().includes(".png")) {
  //         filetype = "image/png";
  //       }

  //       else {
  //         this.lightBoxesService.open(
  //           'custom-generico',
  //           { title: 'Atenção', text: 'O formato de arquivo informado não é aceito.' },
  //           5000
  //         );
  //         return;
  //       }

  //       let fileName = uri.split('/')[uri.split('/').length - 1]

  //       this.base64.encodeFile(correctPath)
  //         .then(base64File => {
  //           let tamanhoDoArquivo = ((base64File.toString().split(',')[1].length) * 6) / 8;

  //           if (this.stateService.verificarTamanhoUpload(tamanhoDoArquivo)) {
  //             const src = this.stateService.getCaminhoImagemExibicao(filetype, base64File.toString().split(',')[1]);

  //             switch (this.lastClickedActionType) {
  //               case ACTION_TYPE.CHANGE_SLOT_IMAGE:
  //                 const slotIndex = this.imageSlots.findIndex((slot) => {
  //                   if (slot.id === this.lastClickedImageSlotID)
  //                     return true;
  //                 });

  //                 this.imageSlots[slotIndex] = {
  //                   ...this.imageSlots[slotIndex],
  //                   content: base64File.toString().split(',')[1],
  //                   type: filetype,
  //                   name: fileName,
  //                   src: src,
  //                 };
  //                 this.onChange.emit(this.imageSlots);
  //                 break;

  //               case ACTION_TYPE.ADD_NEW_IMAGE_SLOT:
  //                 const lastSlotIndex = this.imageSlots.length - 1;
  //                 const lastSlotId = this.imageSlots[lastSlotIndex].id;

  //                 this.imageSlots.push({
  //                   id: lastSlotId + 1,
  //                   content: base64File.toString().split(',')[1],
  //                   type: filetype,
  //                   name: fileName,
  //                   src: src,
  //                 });
  //                 this.onChange.emit(this.imageSlots);

  //                 this.canAddMoreImage = (this.max - this.imageSlots.length) > 0;
  //                 break;

  //               case ACTION_TYPE.CHANGE_FIRST_SLOT:
  //                 this.imageSlots[0] = {
  //                   ...this.imageSlots[0],
  //                   content: base64File.toString().split(',')[1],
  //                   type: filetype,
  //                   name: fileName,
  //                   src: src,
  //                 };
  //                 this.onChange.emit(this.imageSlots);
  //                 break;
  //             }
  //           }
  //         })
  //         .catch(error => this.lightBoxesService.open(
  //           'custom-generico',
  //           { title: 'Atenção', text: 'Erro ao ler o arquivo' },
  //           5000
  //         )
  //         );
  //     });
  // }
}
