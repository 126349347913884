import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CondominosListDTO } from './DTO/request/CondominosList.DTO';
import { ResultCondominosListDTO } from './DTO/response/ResultCondominosList.DTO';
import { HttpParams } from "@angular/common/http";
import { StateService } from '../../../shared/services/stateService';
import { ResultCondominoDadosDTO } from './DTO/response/ResultCondominoDadosDTO';
import { ResultColaboradorDTO } from './DTO/response/ResultColaboradorDTO';
import { ResultMoradorDTO } from './DTO/response/ResultMoradorDTO';
import { ColaboradorDTO } from './DTO/request/ColaboradorDTO';
import { MoradorRequestDTO } from './DTO/request/MoradorDTO';
import { ResponsavelRequestDTO } from './DTO/request/ResponsavelDTO';
import { getLocaleFirstDayOfWeek } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CondominoService {
  public static modalParametroSubject: Subject<any> = new Subject();
  private urlApiHost = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods;

  constructor(private http: HttpClient, public stateService: StateService, private routerService: StateService) { }

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.routerService.getTokenApiCondominioDigital()
    });
  }

  private getHeaderGT() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.routerService.getTokenApiGT()
    });
  }

  public getAllAlerts(agencia: string, conta: string) {
    let host = environment.apiGT.host;
    let method = environment.apiGT.endpoints.parameterCollaboratorAlert.get;

    let parametros = new HttpParams()
      .set("agency", agencia)
      .set("account", conta);
    return this.http.get<any>(`${host + method}`, { params: parametros, headers: this.getHeaderGT() });
  }

  public cadastrarAlerta(agencia: string, conta: string, cnpj: string, mes: string) {
    let host = environment.apiGT.host;
    let method = environment.apiGT.endpoints['parameterCollaboratorAlert'].post;
    let url = `${host + method}`;
    let parametro = {
      "Condominium": {
        "Agency": agencia.toString(),
        "Account": conta.toString(),
        "Cnpj": cnpj.toString(),
      },
      "MonthsForAlert": mes
    };
    return this.http.post<any>(url, parametro, { headers: this.getHeaderGT() });
  }

  public alterarAlerta(id: string, cnpj: string, agencia: string, conta: string, mes: string) {
    let host = environment.apiGT.host;
    let method = environment.apiGT.endpoints['parameterCollaboratorAlert'].put + "/";
    let url = `${host + method + id}`;
    let parametro = {
      "Condominium": {
        "Agency": agencia.toString(),
        "Account": conta.toString(),
        "Cnpj": cnpj.toString(),
      },
      "MonthsForAlert": mes
    };
    return this.http.put<any>(url, parametro, { headers: this.getHeaderGT() });
  }

  public listarCondominos(descricaoUnidade: string, numeroPagina: number) {
    var condominio = this.routerService.getCondominioLogado();
    var parametros = new HttpParams()
      .set("contaCondominio", condominio.Conta)
      .set("contaEmpresa", condominio.contaEmpresa)
      .set("filtroPalavra", (typeof (descricaoUnidade) == "undefined") ? "" : descricaoUnidade)
      .set("paginaAtual", numeroPagina.toString())
      .set("tamanhoPagina", "20")
          
    return this.http.get<ResultCondominosListDTO>(
      `${this.urlApiHost + this.methods.condomino.listarPorCondominio}`,
      { params: parametros, headers: this.getHeader() }
    );
  }

  public getDadosCondominos(idUnidade: string, conta: string): Observable<ResultCondominoDadosDTO[]> {

    const params = new HttpParams()
      .set('idUnidade', idUnidade)
      .set('conta', conta)


    return this.http.get<ResultCondominoDadosDTO[]>(`${this.urlApiHost + this.methods.condomino.getDadosCondominos}`, { params, headers: this.getHeader() })
  }

  public getDadosProcurador(idUnidade: string, conta: string): Observable<ResultCondominoDadosDTO[]> {
    const params = new HttpParams()
      .set('idUnidade', idUnidade)
      .set('conta', conta)
    return this.http.get<ResultCondominoDadosDTO[]>(`${this.urlApiHost + this.methods.condomino.getDadosProcurador}`, { params, headers: this.getHeader() })
  }

  public getDadosColaborador(agencia: string, contaEmpresa: string, unity: string): Observable<ResultColaboradorDTO[]> {
    const host = environment.apiGT.host;
    const method = environment.apiGT.endpoints.checklistreports.get.buscarDadosColaborador;
    let url = `${host + method}`;
    const params = new HttpParams()
      .set('agency', agencia)
      .set('unity', unity)
      .set('account', contaEmpresa)

    return this.http.get<ResultColaboradorDTO[]>(url, { params, headers: this.getHeaderGT() })
  }

  public deleteColaborador(uidColaborador: string, idUnidade: string): Observable<any> {
    const host = environment.apiGT.host;
    const method = environment.apiGT.endpoints.checklistreports.delete.deleteColaborador;
    let url = `${host + method}`;
    const parametros = new HttpParams()
      .set('idMorador', uidColaborador)
      .set('idUnidade', idUnidade)

    return this.http.delete<any>(url, { params: parametros, headers: this.getHeaderGT() })

  }

  public getColaboradorById(id: string) {
    const host = environment.apiGT.host;
    const method = environment.apiGT.endpoints.collaborator.get;
    let url = `${host + method}/${id}`;

    return this.http.get<ResultColaboradorDTO[]>(url, { headers: this.getHeaderGT() })
  }

  public cadastraColaborador(params: ColaboradorDTO) {
    let host = environment.apiGT.host;
    let method = environment.apiGT.endpoints.collaborator.post;
    let url = `${host + method}`;

    return this.http.post<any>(url, params, { headers: this.getHeaderGT() });
  }

  public editarColaborador(params: ColaboradorDTO) {
    let host = environment.apiGT.host;
    let method = environment.apiGT.endpoints.collaborator.put;
    let url = `${host + method}/${params.Uid}`;

    return this.http.put<any>(url, params, { headers: this.getHeaderGT() });
  }

  public cadastraResponsavel(params: ResponsavelRequestDTO) {

    const url = `${this.urlApiHost + this.methods.Unidade.cadastraMorador}`;

    return this.http.post<any>(url, params, { headers: this.getHeader() });
  }

  public editarResponsavel(params: ResponsavelRequestDTO) {
    let host = environment.apiGT.host;
    let method = environment.apiGT.endpoints.resident.put;
    let url = `${host + method}/${params.Uid}`;

    return this.http.put<any>(url, params, { headers: this.getHeaderGT() });
  }

  public getResponsavelById(id: string) {
    const host = environment.apiGT.host;
    const method = environment.apiGT.endpoints.resident.get;
    let url = `${host + method}/${id}`;

    return this.http.get<ResultMoradorDTO[]>(url, { headers: this.getHeaderGT() })
  }

  public cadastraMorador(params: MoradorRequestDTO) {
    const url = `${this.urlApiHost + this.methods.Unidade.cadastraMorador}`;
    return this.http.post<any>(url, params, { headers: this.getHeader() });
  }

  public getMoradorById(id: string) {
    const host = environment.apiGT.host;
    const method = environment.apiGT.endpoints.resident.get;
    let url = `${host + method}/${id}`;

    return this.http.get<ResultMoradorDTO[]>(url, { headers: this.getHeaderGT() })
  }

  public editarMorador(params: MoradorRequestDTO) {
    let host = environment.apiGT.host;
    let method = environment.apiGT.endpoints.resident.put;
    let url = `${host + method}/${params.Uid}`;

    return this.http.put<any>(url, params, { headers: this.getHeaderGT() });
  }


  public getDadosMorador(contaEmpresa: string, unity: string, unityDescription: string): Observable<ResultMoradorDTO> {
    const host = environment.apiGT.host;
    const method = environment.apiGT.endpoints.checklistreports.get.buscarDadosMorador;
    let url = `${host + method}`;
    const params = new HttpParams()
      .set('account', contaEmpresa)
      .set('unityId', unity)
      .set('unityDescription', unityDescription)

    return this.http.get<ResultMoradorDTO>(url, { params, headers: this.getHeaderGT() })
  }

  public deleteMorador(idMorador: string, idUnidade: string): Observable<any> {
    const host = environment.apiGT.host;
    const method = environment.apiGT.endpoints.checklistreports.delete.deleteMorador;
    let url = `${host + method}`;
    const parametros = new HttpParams()
      .set('idMorador', idMorador)
      .set('idUnidade', idUnidade)

    return this.http.delete<any>(url, { params: parametros, headers: this.getHeaderGT() })

  }

  public getDadosMoradoresConvencional(contaEmpresa: string, idUnidade: string) {
    const url = this.urlApiHost + this.methods.morador.getDadosMoradorConv;
    const params = new HttpParams().set('contaEmpresa', contaEmpresa).set('idUnidade', idUnidade);
    return this.http.get(url, { headers: this.getHeader(), params })
  }

  public cadastraMoradorConvencional(obj) {
    let url = this.urlApiHost + this.methods.morador.cadastraMorador;
    delete obj.tipo;
    return this.http.post(url, obj, { headers: this.getHeader() })
  }
  
  public editaMoradorConvencional(payload, id: string) {
    const url = `${this.urlApiHost}${this.methods.morador.getDadosMoradorConv}/${id}`;
    delete payload.tipo;
    return this.http.put(url, payload, { headers: this.getHeader()})
  }
  
  public deletaMoradorConvencional(idMorador: string, idUnidade: string) {
    const url = this.urlApiHost + this.methods.morador.deletaMoradorConv;
    const params = new HttpParams().set('idMorador', idMorador).set('idUnidade', idUnidade)
    return this.http.delete(url, { headers: this.getHeader(), params })
  }

  public getMoradorConvencionalById(id: string) {
    const url = `${this.urlApiHost}${this.methods.morador.getDadosMoradorConv}/${id}`;
    return this.http.get(url, { headers: this.getHeader()});
  }

  public getMoradorConvencionalByCpf(cpf: string): Observable<any> {
    const cpfNumeros = cpf.replace('.', '').replace('.','').replace('-','');
    const url = `${this.urlApiHost}${this.methods.morador.getDadosMoradorPorCpf}/${cpfNumeros}`;

    return this.http.get(url, { headers: this.getHeader() });
  }

  getMoradorConvencionalResponsavel(conta: string): Observable<any[]> {
    const url = `${this.urlApiHost}${this.methods.morador.getDadosMoradorConv}/${conta}/true`;
    return this.http.get<any[]>(url, { headers: this.getHeader()});
  }

  cadastraColaboradorConvencional(body) {
    const url = this.urlApiHost + this.methods.colaborador;
    return this.http.post(url, body, { headers: this.getHeader() });
  }

  editaColaboradorConvencional(id, body) {
    const url = this.urlApiHost + this.methods.colaborador;
    const params = new HttpParams().set('id', id);
    return this.http.put(url, body, { headers: this.getHeader(), params });
  }

  deletaColaboradorConvencional(id: string, idUnidade: string, contaEmpresa: string) {
    const url = this.urlApiHost + this.methods.colaborador;
    const params = new HttpParams().set('id', id).set('idUnidade', idUnidade).set('contaEmpresa', contaEmpresa);
    return this.http.delete(url, { headers: this.getHeader(), params });
  }

  getColaboradoresConvencional(idUnidade: string, contaEmpresa: string) {
    const url = this.urlApiHost + this.methods.colaborador;
    const params = new HttpParams().set('idUnidade', idUnidade).set('contaEmpresa', contaEmpresa);
    return this.http.get(url, { headers: this.getHeader(), params });
  }

  getColaboradorConvencionalById(id: string, idUnidade: string, contaEmpresa: string) {
    const url = `${this.urlApiHost}${this.methods.colaborador}/${id}`;
    const params = new HttpParams().set('idUnidade', idUnidade).set('contaEmpresa', contaEmpresa)
    return this.http.get(url, { headers: this.getHeader(), params });
  }



}
