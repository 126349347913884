import { Injectable } from "@angular/core";

import { Chooser } from "@awesome-cordova-plugins/chooser/ngx";
// import { Base64 } from "@ionic-native/base64/ngx";
import { FileMobile } from "../../services/DTO/response/FileMobile";
// import { FilePath } from "@ionic-native/file-path/ngx";
import { IChooser } from "./IChooser";

@Injectable({ providedIn: "root" })
export class ChooserService implements IChooser {
  constructor(
    public chooser: Chooser,
    // private base64: Base64,
    // private filePath: FilePath
  ) {}

  async getFileMobile(mimeTypes?: any) {
    // let fileDTO: FileMobile;

    // const file = await this.chooser.getFile(mimeTypes);

    // let filetype = this.getFileType(file.name);
    
    // let path:string = null;
    // let base: string = null;

    // console.log('file', file)
    // // encoder para imagens
    // if(filetype === 'image/jpeg' || filetype === 'image/png' ){
      
    //   path = await this.filePath.resolveNativePath(file.uri);
    //   base = await this.base64.encodeFile(path);
    // }

    // fileDTO = {
    //   data: file.data,
    //   dataURI: base !== null ? base : file.dataURI,
    //   // dataURI: file.dataURI,
    //   mediaType: file.mediaType,
    //   name: file.name,
    //   uri: file.uri,
    //   fileType: filetype,
    // };

    // return fileDTO;
  }

  getFileType(file: string) {
    if (file.toLowerCase().includes("pdf")) {
      return "pdf";
    } else if (file.toLowerCase().includes("xls")) {
      return "xls";
    } else if (file.toLowerCase().includes("xlsx")) {
      return "xlsx";
    } else if (
      file.toLowerCase().includes("jpeg") ||
      file.toLowerCase().includes("jpg")
    ) {
      return "image/jpeg";
    } else if (file.toLowerCase().includes("png")) {
      return "image/png";
    } else {
      return "alert";
    }
  }
}
