<div class="slider-select-mobile-component">
  <div class="month-selection">
    <button
      class="arrow"
      (click)="previousMonth()"
      [ngClass]="{'__is-disabled': (monthSelected | async) === 0}"
    >
      <i class="fas fa-chevron-left"></i>
    </button>
    
    <swiper-container #swiper [allowTouchMove]="false" [slidesPerView]="1" >
      <swiper-slide *ngFor="let month of listaPeriodoDisponivel" >
        <button class="month">{{ month }}</button>
      </swiper-slide>
    </swiper-container>
      
    <button
      class="arrow"
      (click)="nextMonth()"
      [ngClass]="{'__is-disabled': (monthSelected | async) === listaPeriodoDisponivel.length - 1}"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>