import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ModalService } from './../../services/modal.service';
import { StateService } from 'src/app/shared/services/stateService';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation, SimpleChanges } from '@angular/core';

import { AdesaoDeBoletoDigitalService } from '../../services/adesao-de-boleto-digital.service';
import { LightBoxesService } from '../lightboxes';
import { Router } from '@angular/router';
import { rawListeners } from 'process';

@Component({
  selector: 'adesao-de-boleto-digital-selecao-de-unidades',
  templateUrl: './adesao-de-boleto-digital-selecao-de-unidades.component.html',
  styleUrls: ['./adesao-de-boleto-digital-selecao-de-unidades.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdesaoDeBoletoDigitalSelecaoDeUnidadesComponent implements OnInit {

  @Input() listaCondominios
  @Input() listaImoveis
  @Input() imoveisAgrupadosPorContrato
  @Output() closeModal = new EventEmitter();
  @Output() listaImoveisEditada = new EventEmitter()

  public condominioSelecionado = [];
  public todasAsUnidadesAceitas = false;
  public accordionOpen = false
  public aderirTodasUnidades = false
  public naoAderirUnidades = false
  public aderirTodosApartamentos = false

  constructor(
    public stateService: StateService,
    private adesaoService: AdesaoDeBoletoDigitalService,
    private lightBoxesService: LightBoxesService,
    private router: Router,
    private modalService: ModalService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  toggleCollapse(condominio: any) {
    condominio.isOpen = !condominio.isOpen;
  }

  aderirDesaderirCondominio(contaCondominio) {
    for (let condominio of this.listaCondominios) {
      if (contaCondominio == condominio.contaCondominio) {
        let naoAparece = !this.condominioSelecionado.includes(contaCondominio);

        for (let unidade of condominio.unidades) {
          if (naoAparece) unidade.unidadeAderida = true;
          else unidade.unidadeAderida = false;
        };

        this.toggleTodasUnidadeCondominio(contaCondominio, naoAparece);
      }
    }
  }

  toggleTodasUnidadeCondominio(conta, refBoleano) {
    if (refBoleano) {
      this.condominioSelecionado.push(conta);
    } else if (!refBoleano) {
      const index = this.condominioSelecionado.indexOf(conta);
      this.condominioSelecionado.splice(index, 1);
    }
  }

  aderirDesaderirUnidade(contaCondominio, idUnidade, idSubdivisao) {
    for (let condominio of this.listaCondominios) {
      if (contaCondominio == condominio.contaCondominio) {
        for (let unidade of condominio.unidades) {
          if (idUnidade == unidade.idUnidade)
            unidade.unidadeAderida = !unidade.unidadeAderida;
        }
      }
    }
  }

  aderirUnidade(contaCondominio, idUnidade) {
    for(let unidade of this.buscarUnidadesDoCondominio(contaCondominio)) {
      if (idUnidade == unidade.idUnidade)
        unidade.unidadeAderida = true;  
    }
  }

  desaderirUnidade(contaCondominio, idUnidade) {
    for(let unidade of this.buscarUnidadesDoCondominio(contaCondominio)) {
      if (idUnidade == unidade.idUnidade)
        unidade.unidadeAderida = false;
    }
  }
  
  aderirDesaderirImovel(contratoLocacao:string, enderecoImoveis: [], aderir: boolean) {      

    // Mudança para o Payload
    this.listaImoveis = this.listaImoveis.map(imovel => {
      for(let i = 0; i <= enderecoImoveis.length; i++) {
        if ((contratoLocacao != 'undefined') &&  contratoLocacao == imovel.contratoLocacao) {       
          return {...imovel, aderido: aderir  } 
        }
        return imovel
      } 
    });
    this.listaImoveisEditada.emit(this.listaImoveis)
    // Mudança na View 
    this.imoveisAgrupadosPorContrato.forEach(imovel => {
      if (contratoLocacao == imovel.contratoLocacao) {       
        imovel.aderido = aderir;   
      } 
    });
  }

  aderirDesaderirTodosImoveis(aderir: boolean) {
   
    // Mudança para o Payload
    this.listaImoveis =   this.listaImoveis.map(imovel => {
      if(aderir) {
        return {...imovel, aderido: true  }
      } else {
        return  {...imovel, aderido: false  }
      }
    });

    this.listaImoveisEditada.emit(this.listaImoveis)

    // Mudança na View 
    this.imoveisAgrupadosPorContrato.forEach(imovel => {
      if(aderir) {
        imovel.aderido = true
      } else {
        imovel.aderido = false
      }
    });
  }

  toggleTodasAsUnidades() {
    this.todasAsUnidadesAceitas = !this.todasAsUnidadesAceitas
  }

  checkCondominioAderido(conta) {
    let condominioDesmarcado = false;

    for (let condominio of this.listaCondominios) {
      if (conta == condominio.contaCondominio) {
        for (let unidade of condominio.unidades) {
          if (!unidade.unidadeAderida) condominioDesmarcado = true;
        }
      }
    }

    return !condominioDesmarcado;
  }

  aderirTodas(contaCondominio) {
    for(let unidade of this.buscarUnidadesDoCondominio(contaCondominio)) {
      unidade.unidadeAderida = true;
    }
  }

  naoAderir(contaCondominio) {
    for(let unidade of this.buscarUnidadesDoCondominio(contaCondominio)) {
      unidade.unidadeAderida = false;
    }
  }

  private *buscarUnidadesDoCondominio(contaCondominio) {
    for (let condominio of this.listaCondominios) {
      if (contaCondominio == condominio.contaCondominio) {
        for (let unidade of condominio.unidades) {
          yield unidade;
        }
      }
    }
  }
}
