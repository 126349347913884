<div class="Collapse" [ngClass]="{ '__is-open': isCollapseOpen }">
  <div class="Collapse_Header" (click)="isCollapseOpen = !isCollapseOpen">
    <div class="Collapse_Header_Title">{{ title }}</div>

    <div class="Collapse_Header_Icon">
      <i *ngIf="!icon; else collapseIconGiven" class="fas fa-chevron-down"></i>

      <ng-template #collapseIconGiven>
        <i class="{{ icon }}"></i>
      </ng-template>
    </div>
  </div>

  <div class="Collapse_Content">
    <ng-content></ng-content>
  </div>
</div>