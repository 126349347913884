import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Formaters } from './formaters';
export class Validations {

    static ValidaCpf(controle: AbstractControl) {
        const cpf = controle.value;

        if (!cpf) {
            return null;
        }

        if (_ValidaRegexCPF(cpf)) {
            return _AlgoritmoReceitaFederalCPF(Formaters.LimpaCaracteresELetras(cpf))
                ? null
                : { cpfInvalido: true };
        }

        return { cpfInvalido: true };
    }

    static markFormGroupTouched(formGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    static ValidarCnpj(cnpj){
        if (!cnpj){
            return false;
        } else {
            return _validarCNPJ(cnpj)
        }

        
    }

    static ValidarCpf(cpf){
        if (!cpf){
            return false;
        } else {
            return _AlgoritmoReceitaFederalCPF(cpf);
        }
    }

    static ValidarPis(pis){
        if(!pis){
            return false;
        } else {
            return _ValidarPis(pis);
        }
    }

}

function _ValidaRegexCPF(cpf) {
    var re = /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/;
    return re.test(cpf);
}
function _AlgoritmoReceitaFederalCPF(cpf) {
    // tslint:disable-next-line: one-variable-per-declaration
    let add, rev;
    cpf = cpf.replace(/[^\d]+/g, '');
    // tslint:disable-next-line: triple-equals
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
    )
        return false;
    // Valida 1o digito
    add = 0;
    // tslint:disable-next-line: radix
    for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(10))) return false;
    return true;
}

//conferir com Ed
function _validarCNPJ(cnpj) {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') return false;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;
           
    return true;
    
}

function _ValidarPis(pis){
    let multiplicadorBase = "3298765432";
    let total = 0;
    let resto = 0;
    let multiplicando = 0;
    let multiplicador = 0;
    let digito = 99;
    
    // Retira a mascara
    let numeroPIS = pis.replace(/[^\d]+/g, '');

    if (numeroPIS.length !== 11 || 
        numeroPIS === "00000000000" || 
        numeroPIS === "11111111111" || 
        numeroPIS === "22222222222" || 
        numeroPIS === "33333333333" || 
        numeroPIS === "44444444444" || 
        numeroPIS === "55555555555" || 
        numeroPIS === "66666666666" || 
        numeroPIS === "77777777777" || 
        numeroPIS === "88888888888" || 
        numeroPIS === "99999999999") {
        return false;
    } else {
        for (var i = 0; i < 10; i++) {
            multiplicando = parseInt( numeroPIS.substring( i, i + 1 ) );
            multiplicador = parseInt( multiplicadorBase.substring( i, i + 1 ) );
            total += multiplicando * multiplicador;
        }

        resto = 11 - total % 11;
        resto = resto === 10 || resto === 11 ? 0 : resto;

        digito = parseInt("" + numeroPIS.charAt(10));
        return resto === digito;
    }
}

type ValidatorReturn = { [key: string]: any } | null;

export function phoneDigit9Validator(): ValidatorFn {
  return (control: AbstractControl): ValidatorReturn => {
    const value = control.value;

    if(value && value.length === 15){
      if(value[5] !== '9')
        return error('O número deve começar com o digito 9.');
    }
  };
}

function error(errorMessage: string) {
  return {
    digit9: errorMessage,
  };
}