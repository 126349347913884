import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DateUtils } from 'src/app/shared/utils/dateUtils';

export interface IMonthPickerData {
  month: number,
  year: number,
  initialDatePtBr: string,
  finalDatePtBr: string,
}

@Component({
  selector: 'ds-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthPickerComponent implements OnInit, OnDestroy {
  @Input('value') picked: IMonthPickerData = {
    month: moment().month() + 1,
    year: moment().year(),
    initialDatePtBr: DateUtils.getFirstDateOfThisMonthFormatted(),
    finalDatePtBr: DateUtils.getLastDateOfThisMonthFormatted(),
  };

  public yearPage$ = new BehaviorSubject<number>(moment().year());
  public canGoBack: boolean;
  public canGoNext: boolean;
  public subscription?: Subscription;
  public months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Nov', 'Out', 'Dez'];
  public selectablesMonths: { month: string, selectable: boolean }[] = [];
  @Output() onPick = new EventEmitter<IMonthPickerData>();
  @Input() max?: string; // mm/yyyy
  @Input() min?: string; // mm/yyyy
  @Input() fullWidth: boolean = false;

  constructor() { }

  ngOnInit() {
    this.yearPage = this.picked.year;

    this.subscription = this.yearPage$.subscribe(() => {
      this.selectablesMonths = this.generateSelectableMonths();
      this.canGoBack = this._canGoBack();
      this.canGoNext = this._canGoNext();
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  pick(month: number) {
    if(this.selectablesMonths[month-1].selectable) {
      const diasNoMes = moment(`${month}/${this.yearPage}`, 'MM/YYYY').daysInMonth();

      const monthFormatted = month < 10 ? `0${month}` : month.toString();

      this.onPick.emit({
        month,
        year: this.yearPage,
        initialDatePtBr: `${this.yearPage}-${monthFormatted}-01`,
        finalDatePtBr: `${this.yearPage}-${monthFormatted}-${diasNoMes}`
      });
    }
  }

  nextPage() {
    if(this.canGoNext)
      this.yearPage++;
  }

  previousPage() {
    if(this.canGoBack)
      this.yearPage--;
  }

  getActiveClass(month: number, disabled: boolean) {
    return {
      'active': this.isActive(month),
      'disabled': disabled,
    }
  }

  isActive(month: number) {
    return this.picked.month === month && this.yearPage === this.picked.year;
  }

  _canGoBack(): boolean {
    if(this.min) {
      const minYear = parseInt(this.min.split('/')[1]);

      return this.yearPage !== minYear;
    }
    else {
      return true;
    }
  }

  _canGoNext(): boolean {
    if(this.max) {
      const maxYear = parseInt(this.max.split('/')[1]);

      return this.yearPage !== maxYear;
    }
    else {
      return true;
    }
  }

  generateSelectableMonths(): { month: string, selectable: boolean }[] {
    let minMonth: number;
    let maxMonth: number;
    let minYear: number;
    let maxYear: number;

    if(this.min) {
      minYear = parseInt(this.min.split('/')[1]);
      minMonth = parseInt(this.min.split('/')[0]);
    }

    if(this.max) {
      maxMonth = parseInt(this.max.split('/')[0]);
      maxYear = parseInt(this.max.split('/')[1]);
    }

    if(this.min && this.yearPage === minYear) {
      return this.months.map((month, i) => ({ month, selectable: minMonth <= i+1  }));
    }
    else if(this.max && this.yearPage === maxYear) {
      return this.months.map((month, i) => ({ month, selectable: maxMonth >= i+1  }));
    }
    else {
      return this.months.map(month => ({ month, selectable: true }));
    }
  }

  get yearPage() {
    return this.yearPage$.value;
  }

  set yearPage(newYear: number) {
    this.yearPage$.next(newYear);
  }
}
