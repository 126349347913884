<div class="Lightbox" [ngClass]="{ '__is-open': isOpen }">
  <div class="Popup Popup_ChangeUserImage">
    <div class="close-container">
      <i
        (click)="hide()"
        class="fas fa-times"
      ></i>
    </div>
    
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Trocar foto do usuário</div>
    </div>

    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Como deseja enviar a foto?
      </div>
    </div>
  
    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <div class="PopupFooterItem">
          <ds-button
            (click)="uploadPhotoFromDeviceGalery()"
            full="true"
            leftIcon="fas fa-images"
          >
            Galeria
          </ds-button>
        </div>

        <div class="PopupFooterItem">
          <ds-button
            (click)="takePhoto()"
            full="true"
            type="Outline"
            leftIcon="fas fa-camera"
          >
            Tirar foto
          </ds-button>
        </div>
      </div>
    </div>
  </div> 
</div>
