import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'app-modal-confirmacao-informacoes',
  templateUrl: './modal-confirmacao-informacoes.component.html',
  styleUrls: ['./modal-confirmacao-informacoes.component.scss'],
})
export class ModalConfirmacaoInformacoesComponent implements OnDestroy, OnInit, AfterViewInit, OnChanges {

  @Input() dadosModal:any;
  @Input() showModal:boolean;
  @Input() checkbox:boolean = false;
  @Input() sucesso:boolean;
  @Input() mensagemHeader:string;
  @Input() mensagemCheck:string;
  @Input() tituloHeader:boolean;
  @Input() form: UntypedFormGroup;
  @Input() menor: boolean = false;
  @Output() confirmar = new EventEmitter()
  @Output() cancelar = new EventEmitter()
  @Input() textoAviso: string = null;

  public campoVazio:boolean = false;
  public testeCheck = true;
  public ehMobile = false;
  public corIcone: string = '';

  constructor(private componentRef: ElementRef, private formBuilder: UntypedFormBuilder, public stateService: StateService) {
    if(window.innerWidth < 800){
      this.ehMobile = true
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.showModal.currentValue) {
        this.corIcone = this.getCorIcone();
        if(window.innerWidth < 800) {
          window.scrollTo({top: 0});
        }
      }
  }

  ngOnInit(): void {
    // console.log('criou o modal', this.form);
  }

  ngOnDestroy() {
    if(window.innerWidth > 800){
      document.body.removeChild(this.componentRef.nativeElement);
    }
  }

  ngAfterViewInit(): void {
    if(window.innerWidth > 800){
      document.body.appendChild(this.componentRef.nativeElement)
    }
    
    let getTemaAcessibilidade = this.stateService.getTemaAcessibilidade();
    // console.log('thema', getTemaAcessibilidade)
  }

  confirmarCadastro(){
    if(this.form && this.form.controls.aceiteTermo.value === true) {
      this.confirmar.emit(true);
    } else {
      this.confirmar.emit();
    }
  }

  aceitarTermo(){
    // this.testeCheck = !this.testeCheck
    // this.form.controls.aceiteTermo.setValue(!this.form.controls.aceiteTermo.value)
    this.tipoDeServiçoVazio();
    
  }

  cancelarCadastro(){
    if(this.form){
      this.form.controls.aceiteTermo.setValue(false)
    }
    this.cancelar.emit()
    this.campoVazio = false;
  }

  tipoDeServiçoVazio(){

    for(let i = 0; i < this.dadosModal.length; i++) {
      // console.log('dadosModal:',this.dadosModal[i])
      
        if((this.dadosModal[i].titulo!='Anexos') && (this.dadosModal[i].texto[0] === undefined)) {
          this.campoVazio = true;
        }
      
    }
  }

  goToExternalLink(url: string): void {
    this.stateService.openExternalLink(url);
  }

  getCorIcone():string{
    // console.log("sucesso", this.sucesso)
    let cor
    if(this.sucesso){
      cor = this.stateService.getTemaAcessibilidade() === 'Theme_Dark' ? '#96C9FF': '#081D58'
    }else{
      cor = this.stateService.getTemaAcessibilidade() === 'Theme_Dark' ? '#96C9FF': '#E4CF1E'
    }
    return cor;
  }
}
