import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'mobile-shortcuts-with-list-design',
  templateUrl: './mobile-shortcuts-with-list-design.component.html',
  styleUrls: ['./mobile-shortcuts-with-list-design.component.scss'],
})
export class MobileShortcutsWithListDesignComponent implements OnInit {
  public ehGestaoTotal: boolean;
  public ehContaPool: boolean;
  public possuiPerfilSindico: boolean;
  public possuiPerfilLocatario: boolean;
  public possuiPerfilProprietario: boolean;
  public possuiPerfilGestorSindico: boolean;

  constructor(
    public stateService: StateService,
    private router: Router,
    private activedRoute: ActivatedRoute
  ) { 
    activedRoute.params.subscribe(val => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.ehGestaoTotal = (sessionStorage.getItem('ehGestaoTotal') == "true");
    this.ehContaPool = (this.stateService.getCondominioLogado()) ? this.stateService.getCondominioLogado().ehContaPool : false;
    this.possuiPerfilSindico = this.stateService.possuiPerfilSindico();
    this.possuiPerfilLocatario = this.stateService.possuiPerfilLocatario();
    this.possuiPerfilProprietario = this.stateService.possuiPerfilProprietario();
    this.possuiPerfilGestorSindico = this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico()
  }

  possuiPermissaoAcesso(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  possuiPermissaoAcessoValidacaoCentralAtendimento(idFuncionalidade: number){
    if(this.stateService.visaoCentralAtendimento())
      return this.stateService.possuiAcessoFuncionalidadeGeral(idFuncionalidade);
    else
      return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  validaExibicaoContrato(){
    var gerencial = this.stateService.possuiAcessoCentralAtendimento();
    var condominio = this.stateService.getCondominioLogado();
    if(gerencial && condominio && !this.ehGestaoTotal) {
      if(condominio && (condominio.agencia == "19" || condominio.agencia == "20")) {
        return true;
      } else return false;
    } 
    else if(condominio && !this.ehGestaoTotal)
    {
      return (condominio.agencia == "19" || condominio.agencia == "20") && (this.stateService.possuiAcessoFuncionalidadeGeral(866) || this.stateService.possuiAcessoFuncionalidadeGeral(289));
    }
    else return this.stateService.possuiAcessoFuncionalidadeGeral(866) || this.stateService.possuiAcessoFuncionalidadeGeral(289);
  }
  
  handleClickComodidades(){
    this.stateService.abrirLinkDeComodidadesDeAcordoComOPerfil()
  }
}
